import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { v4 as uuidv4 } from "uuid";
import {
  BsFileArrowDown,
  BsShield,
  BsShieldLock,
  BsCardText,
} from "react-icons/bs";
import { FiScissors } from "react-icons/fi";
import { HiOutlineTicket, HiBadgeCheck } from "react-icons/hi";
import { BiBadgeCheck } from "react-icons/bi";
import { ImMan } from "react-icons/im";
import { FaChild } from "react-icons/fa";

var QRCode = require("qrcode.react");

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      query: "",
      loadBarProgress: true,
      spinning: "none",
      updateDisplay: "block",
      numPages: null,
      pageNumber: 1,
      loadingText: true,
    };
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    const eventid = localStorage.getItem("eventid");
    const eventname = localStorage.getItem("eventname");
    const reprint = this.props.location.state.reprint;
    document.title = "Ticket print - " + " " + eventname + " - " + businessname;
    // alert(eventid);
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TICKETREADYTODOWNLOAD&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype +
        "&eventid=" +
        eventid +
        "&reprint=" +
        reprint
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [], loadBarProgress: false });
        } else {
          this.setState({
            details: response.data,
            loadBarProgress: false,
            loadingText: false,
          });
          window.print();
        }
      })
      .catch((error) => console.log(error));

    setTimeout(() => {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=ALLOCATETICKETS&businessid=" +
          businessid +
          "&eventid=" +
          eventid
      )
        .then((response) => {
          if (response.data.val == 0) {
          } else {
          }
        })
        .catch((error) => console.log(error));
    }, 3000);
  }

  download = () => {
    window.print();
  };

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let ticketnumber = "";
    const { loadingText } = this.state;
    // const ref = React.createRef();
    return (
      <div className={"text-center"}>
        {loadingText && (
          <div className="text-center w-100 mt-5 small-details">
            <ClipLoader size={40} color={"#8d949e"} />
            <br />
            Fetching tickets...
          </div>
        )}
        {this.state.details.length < 1 ? (
          <div className="mt-3 pl-3 pr-3">
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 15.5,
                color: "#5B7083",
              }}
            >
              It looks like the tickets you are trying to print have already
              been printed.
              <br />
              If this is not the case, please dial <span>074 261 2017</span> for
              assistance.
              <br />
              <br />
              <Link to="/dashboard" className="color-blue">
                Dashboard
              </Link>
            </label>
          </div>
        ) : (
          <div className="col-12 p-0">
            {this.state.details.map((item, index) => {
              ticketnumber = uuidv4().toUpperCase().substr(0, 7);
              return (
                <div className={"col-lg-12 p-0 mb-0 vertical-scroll-mobile"}>
                  {/* <label
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: "-18.3px",
                      zIndex: 1000,
                    }}
                  >
                    <FiScissors size={20} color={"#000"} />
                  </label> */}
                  <div className="w-100 p-0">
                    <div className="w-100">
                      <div className="row" style={{ display: "flex" }}>
                        <div
                          className="col-12 p-0"
                          style={{ cursor: "pointer" }}
                        >
                          <div style={{ width: "100%" }}>
                            <table
                              width={"100%"}
                              align={"center"}
                              cellpadding={0}
                              cellspacing={0}
                              style={{ fontFamily: "arial" }}
                            >
                              <tr
                                style={{
                                  background: "rgb(242, 242, 242, 0.9)",
                                }}
                              >
                                <td
                                  rowspan={4}
                                  style={{
                                    color: "#333",
                                    textAlign: "center",
                                    fontWeight: 400,
                                    fontSize: 17,
                                    backgroundImage: `url(
                                    "https://karamale.com/logok.png"
                                  )`,
                                    backgroundSize: "cover",
                                    borderRight: "thin solid #fff",
                                    paddingLeft: "15",
                                    paddingRight: "15",
                                  }}
                                >
                                  <label
                                    style={{
                                      fontSize: 17,
                                      marginTop: 13,
                                      padding: 8,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      border: "thin solid #e61d25",
                                      backgroundColor: "transparent",
                                      color: "#e61d25",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Event details
                                  </label>
                                  <br />
                                  <label style={{ marginTop: 0, fontSize: 15 }}>
                                    {item.eventdate}{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item.eventmonth}, {item.eventyear}
                                    </span>
                                  </label>
                                  <br />

                                  <table
                                    width={"100%"}
                                    style={{
                                      fontSize: 17,
                                      marginTop: 0,
                                      padding: 10,
                                      textAlign: "center",
                                      border: "hidden",
                                    }}
                                    cellspacing={0}
                                    cellpadding={0}
                                    border={0}
                                  >
                                    <tr
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <td
                                        width={"25%"}
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 400,
                                          paddingBottom: 5,
                                          fontSize: 15,
                                        }}
                                        cellspacing={0}
                                        cellpadding={0}
                                      >
                                        <b>Time:</b>{" "}
                                        <span style={{ color: "grey" }}>
                                          {" "}
                                          {item.starttime}
                                        </span>{" "}
                                        <br />
                                        <b>Venue:</b>
                                        <br />
                                        <label
                                          style={{
                                            color: "grey",
                                            paddingTop: 0,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {item.venue}
                                        </label>
                                      </td>
                                    </tr>
                                  </table>

                                  <table
                                    align={"center"}
                                    style={{
                                      width: "100%",
                                      marginTop: 10,
                                      borderRadius: 100,
                                      color: "#fff",
                                      padding: 10,
                                    }}
                                  >
                                    <tr
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                    >
                                      <td
                                        style={{ padding: 0, border: "none" }}
                                      ></td>
                                    </tr>
                                    <tr
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                    >
                                      <td
                                        style={{
                                          borderTop: "hidden",
                                          textAlign: "center",
                                          marginTop: 0,
                                          paddingTop: 0,
                                          border: "none",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            borderTop: "thin solid #dcdcdc",
                                            marginBottom: 15,
                                          }}
                                        />
                                        <b
                                          style={{
                                            color: "#e61d25",
                                            fontSize: 15,
                                            fontWeight: 400,
                                          }}
                                        >
                                          Ticket By
                                        </b>{" "}
                                        <img
                                          src="https://karamale.com/malelogo.png"
                                          width={100}
                                          style={{
                                            marginBottom: 5,
                                            marginTop: 5,
                                          }}
                                        />
                                        <br />
                                        <label
                                          style={{
                                            color: "#e61d25",
                                            marginTop: 3,
                                            fontSize: 15,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {" "}
                                          <b style={{ color: "#e61d25" }}>
                                            Hosting an event? <br />
                                          </b>{" "}
                                          Call/Whatsapp: 074 261 2017
                                        </label>
                                      </td>
                                    </tr>
                                  </table>
                                </td>

                                <td
                                  colspan={2}
                                  width={"60%"}
                                  style={{
                                    borderBottom: "thin solid #fff",
                                    background: "#e61d25",
                                    fontSize: 20,
                                    color: "#fff",
                                    textAlign: "center",
                                    padding: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {localStorage.getItem("eventname")}
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 19,
                                    }}
                                  >
                                    By {businessname}{" "}
                                    <HiBadgeCheck size={23} color={"skyblue"} />
                                  </span>
                                </td>

                                <td
                                  style={{
                                    background: "#e61d25",
                                    borderLeft: "2px dashed #fff",
                                    borderTop: "thin solid #fff",
                                    textAlign: "center",
                                  }}
                                >
                                  <label
                                    style={{
                                      padding: 5,
                                      paddingTop: 5,
                                      height: 30,
                                      width: 30,
                                      background: "#fff",
                                      color: "#e61d25",
                                      textAlign: "center",
                                      fontSize: 12,
                                      fontWeight: 500,
                                      borderRadius: 100,
                                      borderBottom: "thin solid #fff",
                                    }}
                                  >
                                    {index + 1}
                                  </label>
                                </td>
                              </tr>

                              <tr
                                style={{
                                  background: "#e61d25",
                                  textAlign: "center",
                                  fontSize: 15,
                                }}
                              >
                                <td
                                  style={{
                                    padding: 0,
                                    borderBottom: "thin solid #fff",
                                    borderRight: "thin solid #fff",
                                    color: "#fff",
                                    fontSize: 15,
                                  }}
                                >
                                  <b>Order Details</b>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    borderBottom: "thin solid #fff",
                                    borderLeft: "thin solid #fff",
                                    color: "#fff",
                                    fontSize: 15,
                                  }}
                                >
                                  <b>Ticket Details</b>
                                </td>
                                <td
                                  width={"15%"}
                                  style={{
                                    background: "#e61d25",
                                    padding: 20,
                                    color: "#fff",
                                    borderLeft: "2px dashed #fff",
                                  }}
                                ></td>
                              </tr>
                              <tr style={{ background: "#e61d25" }}>
                                <td
                                  style={{
                                    padding: 0,
                                    borderBottom: "thin solid #fff",
                                    borderRight: "thin solid #fff",
                                    textAlign: "center",
                                    fontSize: 15,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                  }}
                                >
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Recipient:
                                  </b>{" "}
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 15,
                                    }}
                                  >
                                    Customer
                                  </span>
                                  <br />
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Ticket Holder:
                                  </b>{" "}
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 15,
                                    }}
                                  >
                                    Customer
                                  </span>
                                </td>

                                <td
                                  style={{
                                    padding: 0,
                                    borderBottom: "thin solid #fff",
                                    borderRight: "2px dashed #fff",
                                    borderLeft: "thin solid #fff",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontSize: 15,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                  }}
                                >
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Type:
                                  </b>{" "}
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 15,
                                    }}
                                  >
                                    {item.category == "vip"
                                      ? "VIP"
                                      : item.category == "vvip"
                                      ? "VVIP"
                                      : "General"}
                                  </span>
                                  <br />
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Amount:
                                  </b>{" "}
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 15,
                                    }}
                                  >
                                    R
                                    {parseInt(item.age_group) == 1
                                      ? item.kids_price
                                      : item.price}
                                  </span>
                                  <br />
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Total Amount:
                                  </b>{" "}
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      color: "#fff",
                                      fontSize: 15,
                                    }}
                                  >
                                    R
                                    {parseInt(item.age_group) == 1
                                      ? item.kids_price
                                      : item.price}
                                  </span>
                                </td>

                                <td
                                  width={"15%"}
                                  style={{
                                    background: "#e61d25",
                                    padding: 0,
                                    borderLeft: "2px dashed #fff",
                                    borderTop: "thin solid #fff",
                                    textAlign: "center",
                                    color: "#fff",
                                    fontSize: 15,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                  }}
                                >
                                  <label
                                    className="mb-0 font-size-12 color-white"
                                    style={{ fontSize: 15 }}
                                  >
                                    <BsFileArrowDown size={23} color={"#fff"} />{" "}
                                    Scan Here
                                  </label>
                                  <br />
                                  <QRCode
                                    value={"1212-" + item.ticketnumber}
                                    logoSize={30}
                                    size={110}
                                    className="mt-3 mb-3"
                                  />
                                  <br />
                                  {item.ticketnumber}
                                </td>
                              </tr>

                              <tr
                                style={{
                                  background: "#e61d25",
                                  textAlign: "center",
                                  fontSize: 15,
                                }}
                              >
                                <td
                                  style={{
                                    padding: 15,
                                    color: "#fff",
                                    borderRight: "thin solid #fff",
                                  }}
                                >
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Admission:
                                  </b>{" "}
                                  1
                                </td>
                                <td
                                  style={{
                                    padding: 15,
                                    color: "#fff",
                                    borderRight: "2px dashed #fff",
                                    borderLeft: "thin solid #fff",
                                    fontSize: 15,
                                  }}
                                >
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Group:
                                  </b>{" "}
                                  {item.age_group == 1 ? (
                                    <label
                                      className="mb-0"
                                      style={{ fontSize: 15 }}
                                    >
                                      <FaChild color={"lime"} size={25} /> Child
                                    </label>
                                  ) : (
                                    <label
                                      className="mb-0"
                                      style={{ fontSize: 15 }}
                                    >
                                      <ImMan color={"yellow"} size={25} /> Adult
                                    </label>
                                  )}
                                </td>
                                <td
                                  style={{
                                    padding: 15,
                                    color: "#fff",
                                    borderLeft: "2 dashed #fff",
                                    textAlign: "center",
                                    fontSize: 15,
                                  }}
                                >
                                  <b style={{ color: "#fff", fontSize: 15 }}>
                                    Contact the host
                                  </b>
                                  <br /> {item.contacts}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages });
    // setNumPages(numPages);
  }

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1),
    } else {
    }
    const { loadBarProgress } = this.state;

    return (
      <div className="view p-0" style={{ backgroundColor: "#fff" }}>
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-12 p-0">{this.details()}</div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Download;
