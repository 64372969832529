import React from 'react'
import "./Footer.css";
import { Link, NavLink } from "react-router-dom";

class Footer extends React.Component {
    render () {
        const date = new Date();
        const year = date.getFullYear();
        return (
            <footer className="row">
                    <div className="container">
                       <div className="row">
                           <div className="col-lg-10">
                           <NavLink
                    to="/budget"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                               Calculate budget</NavLink>
                               <NavLink
                    to="/feedback"
                    className="links"
                    exact
                    activeClassName="activeLink"
                    style={{display:'none'}}
                  >
                               Feedback</NavLink>
                               &nbsp;&nbsp;&nbsp;&nbsp;
                               <NavLink
                    to="/contactus"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                               Contact us</NavLink>
                           </div>
                           <div className="col-lg-2">
                            &copy;   {year} Karamale
                           </div>
                       </div>
                    </div>
            </footer>
        )
    }
}

export default Footer;