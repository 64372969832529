import React from "react";
import { BsX } from "react-icons/bs";
import "./CloseX.css";

class CloseX extends React.Component {
  render() {
    return (
      <div className="close-x">
        {/* <ReactTooltip /> */}
        <BsX onClick={this.props.click} />
      </div>
    );
  }
}

export default CloseX;
