import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import { IoIosArrowBack } from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Header5 from "./components/Header5";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      customername: "",
      phone: "",
      email: "",
      dtregistered: "",
      bankname: "",
      account: "",
      accountnumber: "",
      branchcode: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("customername")) {
      const customername = localStorage.getItem("customername");
      const customerid = localStorage.getItem("customerid");
      document.title = "Profile - " + customername + " - Customer";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
          customerid
      )
        .then((response) => {
          this.setState({
            customername: response.data.customername,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
            facebook: "",
            twitter: "",
            instagram: "",
            address: "",
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
    if (localStorage.getItem("businessname")) {
      const businessname = localStorage.getItem("businessname");
      const businessid = localStorage.getItem("businessid");
      document.title = "Profile - " + businessname + " - Businessname";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=BUSINESSDETAILS&accountid=" +
          businessid
      )
        .then((response) => {
          this.setState({
            customername: response.data.businessname,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
            facebook: response.data.facebook,
            twitter: response.data.twitter,
            instagram: response.data.instagram,
            address: response.data.address,
          });
        })
        .catch(function (error) {
          alert(error);
        });
    } else {
      const partnername = localStorage.getItem("partnername");
      const partnerid = localStorage.getItem("partnerid");
      document.title = "Karamale | Profile";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PARTNERDETAILS&partnerid=" +
          partnerid
      )
        .then((response) => {
          this.setState({
            customername: response.data.partnername,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
            bankname: response.data.bankname,
            branchcode: response.data.branchcode,
            account: response.data.account,
            accountnumber: response.data.accountnumber,
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  }

  render() {
    let { accountname, dashboard } = "";
    if (localStorage.getItem("customername") === null) {
      if (localStorage.getItem("businessname") === null) {
        if (localStorage.getItem("partnername") === null) {
          return <Redirect to="/login" />;
        } else {
          accountname = localStorage.getItem("partnername");
          dashboard = "in";
        }
      } else {
        accountname = localStorage.getItem("businessname");
        dashboard = "dashboard";
      }
    } else {
      accountname = localStorage.getItem("customername");
      dashboard = "customerdashboard";
    }
    const customername = accountname;
    const twitter = this.state.twitter;
    return (
      <div className="view">
        {localStorage.getItem("customername") ? (
          <Header2 />
        ) : localStorage.getItem("businessname") ? (
          <Header3 />
        ) : (
          <Header5 />
        )}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to={dashboard} className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <Link
                          to={{
                            pathname: "editprofile",
                            state: {
                              customername: customername,
                              email: this.state.email,
                              phone: this.state.phone,
                              dtregistered: this.state.dtregistered,
                              facebook: this.state.facebook,
                              twitter: this.state.twitter,
                              instagram: this.state.instagram,
                              address: this.state.address,
                            },
                          }}
                          className="link-btn"
                        >
                          <div className="edit-btn">Edit</div>
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0 pr-0">
                      <label className="ticket-title pl-0 pr-0 w-100">
                        PROFILE
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  {localStorage.getItem("customername")
                    ? "Fullname"
                    : localStorage.getItem("businessname")
                    ? "Business name"
                    : "Partner's name"}
                  <br />
                  <label className="small-details">{customername}</label>
                  <br /> <br />
                  Email address
                  <br />
                  <label className="small-details">{this.state.email}</label>
                  <br /> <br />
                  Phone number
                  <br />
                  <label className="small-details">{this.state.phone}</label>
                  <br />
                  <br />
                  Account created
                  <br />
                  <label className="small-details">
                    {this.state.dtregistered}
                  </label>
                </div>
              </div>
              {localStorage.getItem("customername") ? (
                ""
              ) : localStorage.getItem("partnername") ? (
                <div className=" BoxDashboardContainer mt-4 mb-5 pl-2 pr-2">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0 pr-0">
                        <label className="ticket-title pl-0 pr-0 w-100">
                          BANK DETAILS
                          <label className="float-right">
                            <Link to="/bdetails">
                              <label className="mb-0 color-blue font-normal">
                                Add/Edit bank details
                              </label>
                            </Link>
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className="h-divider w-100"></div>
                  </div>
                  <div className="col-lg-12 details-body">
                    <span>Details</span>
                    <br />
                    <br />
                    Bank name
                    <br />
                    <label className="small-details">
                      {this.state.bankname}
                    </label>
                    <br /> <br />
                    Branch code
                    <br />
                    <label className="small-details">
                      {this.state.branchcode}
                    </label>
                    <br /> <br />
                    Account type
                    <br />
                    <label className="small-details">
                      {this.state.account}
                    </label>
                    <br />
                    <br />
                    Account number
                    <br />
                    <label className="small-details">
                      {this.state.accountnumber}
                    </label>
                  </div>
                </div>
              ) : (
                <div className=" BoxDashboardContainer mt-4 mb-5 pl-2 pr-2">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0">
                        <label className="ticket-title pl-0 pr-0">
                          SOCIAL MEDIA
                        </label>
                      </div>
                    </div>

                    <div className="h-divider w-100"></div>
                  </div>
                  <div className="col-lg-12 details-body">
                    <span>Details</span>
                    <br />
                    <br />
                    Facebook
                    <br />
                    <label className="small-details">
                      {this.state.facebook == "" ||
                      this.state.facebook == "null" ||
                      this.state.facebook == "@" ? (
                        "Facebook profile link not added"
                      ) : (
                        <Link
                          to={{ pathname: this.state.facebook }}
                          target="_blank"
                          className="color-blue"
                        >
                          {this.state.facebook}
                        </Link>
                      )}
                    </label>
                    <br /> <br />
                    Twitter
                    <br />
                    <label className="small-details">
                      {this.state.twitter === "" ||
                      this.state.twitter === "null" ||
                      this.state.twitter === "@" ? (
                        "Twitter/X profile link not added"
                      ) : (
                        <Link
                          to={{
                            pathname:
                              "https://twitter.com/" + this.state.twitter,
                          }}
                          target="_blank"
                          className="color-blue"
                        >
                          @{this.state.twitter}
                        </Link>
                      )}
                    </label>
                    <br /> <br />
                    Instagram
                    <br />
                    <label className="small-details">
                      {this.state.instagram == "" ||
                      this.state.instagram == "null" ||
                      this.state.instagram == "@" ? (
                        "Instagram profile link not added"
                      ) : (
                        <Link
                          to={{
                            pathname:
                              "https://instagram.com/" + this.state.instagram,
                          }}
                          target="_blank"
                          className="color-blue"
                        >
                          @{this.state.instagram}
                        </Link>
                      )}
                    </label>
                    <br />
                    <br />
                    Business address
                    <br />
                    <label className="small-details">
                      {this.state.address == "" || this.state.address == null
                        ? "Business address not added"
                        : this.state.address}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Profile;
