import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import { BsCreditCard } from "react-icons/bs";
import { RiBankCardLine } from "react-icons/ri";

import ReactTooltip from "react-tooltip";

class Buy extends Component {
  state = {
    error: null,
    products: [],
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const ticketid = query.get("ticketid");
    const apiUrl =
      "/api/select/payment/method?eventid=" +
      eventid +
      "&ticketid=" +
      ticketid;

    Axios.get(apiUrl).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  render() {
    const { products } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const ticketid = query.get("ticketid");
    const q = query.get("q");
    return (
      <div className="container">
        {products.map((events) => (
          <div className="row mt-4 mb-5">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-4">
              <div className="backbtn mb-3 event-title">
                <Link
                  to={"/event?q=" + q + "&id=" + eventid}
                  className="backArrow"
                  data-tip="Back"
                >
                  <ReactTooltip />
                  <BsArrowLeft />
                </Link>{" "}
                &nbsp;&nbsp;&nbsp; {events.event_name}
              </div>
              <div className="nainer">
                <div className="">
                  <img
                    src={
                      "http://localhost/karamalenew/src/posters/" +
                      events.businessId +
                      "/" +
                      events.thumbnail
                    }
                    alt={events.thumbnail}
                    width="100%"
                  />
                </div>
                <div className="col-lg-12 p-3">
                  <div className="eventName">
                    <strong className="textWeight">{events.event_name}</strong>
                    <br />
                    {events.eventDate}
                    <span className="time">Time: {events.start_time}</span>
                    <br />
                    <br />
                    <span className="boldText">Description</span>
                    <br />
                    {events.event_des}
                    <br />
                    <br />
                    <span className="boldText">Venue</span>
                    <br />
                    {events.event_location} <br />
                    <br />
                    <span className="boldText">Theme</span>
                    <br />
                    {events.event_theme}
                  </div>
                </div>
              </div>
              <div className="nainer mt-4">
                <div className="col-lg-12 pt-2 pb-2 greyText">
                  <span className="boldText">Business Info</span>
                  <br />
                  <strong className="textWeight">Company </strong>{" "}
                  {events.business_name} <br />
                  <strong className="textWeight">Primary number </strong>{" "}
                  {events.cell_number} <br />
                  <strong className="textWeight">Seconday number</strong>{" "}
                  {events.tel} <br />
                  <strong className="textWeight">Email </strong> {events.email}{" "}
                  <br /> <br />
                  <span className="boldText">Address</span> <br />
                  {events.address}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="nainer margins">
                <div className="ticket-title">{events.ticket_cat} Ticket</div>
                <div className="p-2">
                  <div className="upperCasedText">
                    <span className="capitalizedText textLight">
                      Adults: R{events.price}
                      <br />
                      Tickets Available: {events.avail_tickets}
                      <br />
                      <br />
                      <span>Choose payment method</span>
                      <br />
                      <div className="row mt-3">
                        <div className="col-lg-6 pr-1">
                          <Link
                            to={{
                              pathname: "finalize",
                              state: {
                                price: events.price,
                                businessid: events.businessId,
                                ticketid: events.ticketid,
                                eventid: events.eventid,
                                ticketref: events.ticketref,
                                command: 1,
                              },
                              search:
                                "q=" +
                                q +
                                "&eventid=" +
                                eventid +
                                "&ticketid=" +
                                ticketid +
                                "&command=1",
                            }}
                            className="eventLinks"
                          >
                            <div className="card-payment-method">
                              <BsCreditCard /> Card
                            </div>
                          </Link>
                        </div>

                        <div className="col-lg-6 pl-1">
                          <Link
                            to={{
                              pathname: "eft",
                              state: {
                                price: events.price,
                                businessid: events.businessId,
                                ticketid: events.ticketid,
                                eventid: events.eventid,
                                ticketref: events.ticketref,
                                command: 1,
                              },
                              search:
                                "q=" +
                                q +
                                "&eventid=" +
                                eventid +
                                "&ticketid=" +
                                ticketid +
                                "&command=2",
                            }}
                            className="eventLinks"
                          >
                            <div className="eft-payment-method">
                              <RiBankCardLine /> EFT
                            </div>
                          </Link>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Buy;
