import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Header5 from "./components/Header5";

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      error: "",
      successError: "",
      errorDisplay: "none",
    };
  }

  componentDidMount() {
    let { customerid, customername } = "";

    if (localStorage.getItem("customername") !== null) {
      customername = localStorage.getItem("customername") + " - Customer";
      customerid = localStorage.getItem("customerid");
    } else if (localStorage.getItem("businessname") !== null) {
      customername = localStorage.getItem("businessname") + " - Organiser";
      customerid = localStorage.getItem("businessid");
    } else if (localStorage.getItem("partnername") !== null) {
      customername = localStorage.getItem("partnername") + " - Affiliate";
      customerid = localStorage.getItem("partnerid");
    }
    document.title = customername + " - Feedback";
  }

  feedback = (event) => {
    this.setState({
      feedback: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    // const accountid = localStorage.getItem("customerid");
    const accounttype = localStorage.getItem("accounttype");
    const feedback = this.state.feedback;
    let customerid = "";

    if (localStorage.getItem("customername") !== null) {
      customerid = localStorage.getItem("customerid");
    } else if (localStorage.getItem("businessname") !== null) {
      customerid = localStorage.getItem("businessid");
    } else if (localStorage.getItem("partnername") !== null) {
      customerid = localStorage.getItem("partnerid");
    }

    const accountid = customerid;
    // alert(accountid);

    if (feedback.trim() === "") {
      this.setState({
        error: "Enter your feedback",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=FEEDBACK&accountid=" +
          accountid +
          "&accounttype=" +
          accounttype +
          "&feedback=" +
          feedback
      )
        .then((response) => {
          this.setState({
            error: "We appreciate your feedbank. Thank you!",
            errorDisplay: "block",
            successError: "success-login",
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    let { accountname, dashboard } = "";
    if (localStorage.getItem("customername") === null) {
      if (localStorage.getItem("businessname") === null) {
        if (localStorage.getItem("partnername") === null) {
          return <Redirect to="/login" />;
        } else {
          accountname = localStorage.getItem("partnername");
          dashboard = "in";
        }
      } else {
        accountname = localStorage.getItem("businessname");
        dashboard = "dashboard";
      }
    } else {
      accountname = localStorage.getItem("customername");
      dashboard = "customerdashboard";
    }
    const customername = accountname;
    return (
      <div className="view">
        {localStorage.getItem("customername") !== null ? (
          <Header2 />
        ) : localStorage.getItem("businessname") !== null ? (
          <Header3 />
        ) : (
          <Header5 />
        )}

        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to={dashboard} className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          Send
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        SHARE YOUR FEEDBACK, IDEAS AND MORE...
                        <br />
                        <label className="note-line">
                          Let's build <span>Karamale</span> together, and make
                          it SA's number one
                        </label>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body pb-3">
                  What's your mind?
                  <br />
                  <textarea
                    placeholder="Type here..."
                    onChange={this.feedback}
                    className="edit-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Feedback;
