import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactTooltip from "react-tooltip";
import logo from "../logo.png";
import "../App.css";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import SlideDrawer from "./slideDrawer/SlideDrawer3";
import Backdrop from "./backDrop/Backdrop";
import CloseX from "./CloseX/CloseX";
import {
  BsHouseDoor,
  BsArrowLeftShort,
  BsPlusCircle,
  BsPerson,
  BsBarChart,
  BsInfoCircle,
  BsList,
  BsSearch,
  BsPeople,
  BsCalendar,
  BsAppIndicator,
  BsBoundingBoxCircles,
} from "react-icons/bs";

import { BiPhoneCall } from "react-icons/bi";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosPeople,
  IoMdPeople,
} from "react-icons/io";
import { RiDashboardLine, RiCalendarEventLine } from "react-icons/ri";

// import { render } from "@testing-library/react";
import Axios from "axios";
import { isEmptyObject } from "jquery";

class Header extends React.Component {
  state = {
    searchValue: "",
    response: [],
    queryResults: [],
    resultsComponent: false,
    open: true,
    openLogo: false,
    arrowIcon: true,
    email: "",
    phone: "",
    customername: "",
    sideDrawerOpen: false,
    showHeader: "",
    campaignCount: 0,
    label: "",
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {
    const businessid = localStorage.getItem("businessid");

    // get ongoing campaign
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.campaignCount > 0) {
        this.setState({
          campaignCount: response.data.campaignCount,
          label: (
            <label className="campaigncounts">
              {response.data.campaignCount}
            </label>
          ),
        });
      } else {
        this.setState({
          campaignCount: 0,
          label: <label style={{ background: "#fff", color: "#fff" }}>0</label>,
        });
      }
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const businessid = localStorage.getItem("businessid");

  //   Axios.get(
  //     "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
  //       businessid
  //   ).then((response) => {
  //     if (response.data.campaignCount > 0) {
  //       this.setState({
  //         campaignCount: response.data.campaignCount,
  //         label: (
  //           <label className="campaigncounts">
  //             {response.data.campaignCount}
  //           </label>
  //         ),
  //       });
  //     } else {
  //       this.setState({
  //         campaignCount: 0,
  //         label: <label style={{ background: "#fff", color: "#fff" }}>0</label>,
  //       });
  //     }
  //   });
  // }

  handleSearchArrow = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
    // this.setState({ searchValue: "" });
  };

  Appheader = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
    // this.setState({ searchValue: "" });
  };

  handleSearch = async (event) => {
    this.setState({ searchValue: event.target.value });
    const searchVal = event.target.value;
    const formData = new FormData();
    formData.append("search", event.target.value);

    await Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=SEARCHEVENTS&search=" +
        searchVal
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({ queryResults: [] });
      } else {
        this.setState({ queryResults: response.data });
      }

      // console.log(response.data);
    });

    if (!searchVal) {
      this.setState({ open: true });
      this.setState({ openLogo: false });
      this.setState({ arrowIcon: true });
    } else {
      this.setState({ open: false });
      this.setState({ openLogo: true });
      this.setState({ arrowIcon: false });
    }
  };

  render() {
    let slideDrawer;
    let backDrop;
    let closeX;

    if (this.state.sideDrawerOpen) {
      slideDrawer = <SlideDrawer />;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
      closeX = <CloseX click={this.backDropClickHandler} />;
    }

    const { queryResults } = this.state;
    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    let event = "";

    return (
      <div className="App">
        {slideDrawer}
        {backDrop}
        {closeX}
        <header className="App-header" onClick={this.Appheader}>
          <div className="row">
            <div className="Header-wrapper col-lg-4 col-md-10 col-sm-10 col-10 pl-4">
              <BsArrowLeftShort
                className="textGrey hand-cursor search-arrow"
                style={arrowIconStyle}
                onClick={this.handleSearchArrow}
              />{" "}
              &nbsp;
              <Link
                to="/campaignmanager"
                className="links logo-karamale"
                data-tip="Home"
                style={logoStyle}
              >
                <BsHouseDoor size={25} />
                <ReactTooltip />
              </Link>
              <label
                className="campaignmanager-link campaign-link"
                data-tip="Active campaigns"
              >
                Active campaigns {this.state.label}
                <ReactTooltip />
              </label>
              <div
                className="col-12 response textMedium search-results"
                style={style}
              >
                {this.state.queryResults.length < 1 ? (
                  <div className="no-match-found">
                    No match found for{" "}
                    <span className="search-value">
                      "{this.state.searchValue}..."
                    </span>
                  </div>
                ) : (
                  this.state.queryResults.map((item, index) => {
                    const posterUri =
                      "https://karamale.com/apps/kscanner/posters/" +
                      item.businessid +
                      "/" +
                      item.eventid +
                      "/" +
                      item.poster;
                    if (item.indexid >= 1) {
                      return "";
                    } else {
                      return (
                        <div className="row">
                          <div className="col-12 default-padding" key={index}>
                            <div className="row">
                              <Link
                                to={{
                                  pathname: "event",
                                  state: {
                                    fromHome: true,
                                    eventid: item.eventid,
                                    eventDate: item.event_day,
                                    fulldate: fulldate,
                                    customerid:
                                      localStorage.getItem("customerid"),
                                    posterUri: posterUri,
                                    accountid:
                                      localStorage.getItem("customerid"),
                                    accounttype:
                                      localStorage.getItem("accounttype"),
                                    customername: this.state.customername,
                                    email: this.state.email,
                                    phone: this.state.phone,
                                    venue: item.event_location,
                                    description: item.event_des,
                                    businessid: item.businessid,
                                    poster: item.poster,
                                    eventname: item.eventname,
                                    eventday: item.eventday,
                                    starttime: item.start_time,
                                    endtime: item.finish_time,
                                    previousPage:
                                      window.location.pathname.replace(
                                        /\\|\//g,
                                        ""
                                      ),
                                    campaignStatus: "running",
                                    category: "",
                                  },
                                }}
                                className="eventLinks links-width"
                              >
                                <div className="search-results-inner">
                                  <div className="search-container">
                                    <BsSearch />
                                  </div>
                                  <div className="results-container align-self-center text-left">
                                    {item.event_name.length > 27
                                      ? (event =
                                          item.event_name.substr(0, 27) + "...")
                                      : (event = item.event_name)}
                                    <br />

                                    {item.businessname.length > 30 ? (
                                      <span className="businessname">
                                        Event by{" "}
                                        {item.businessname.substr(0, 30)}...
                                      </span>
                                    ) : (
                                      <span className="businessname">
                                        Event by {item.businessname}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                )}
              </div>
            </div>

            <div
              className="col-lg-2 col-md-1 col-2 more-col-mobile mobile-top-links pl-0"
              onClick={this.drawerToggleClickHandler}
            >
              <div className="more-icons" data-tip="More">
                <ReactTooltip />
                <BsList />
              </div>
            </div>

            {/* <div className="col-lg-1 space-div"></div> */}

            <div className="menu-row-desktop col-lg-7 col-md-7 p-0">
              <div className="row">
                {/* <div className="col-lg-2 col-md-2 col-2"></div> */}
                <div className="col-lg-2 col-md-2 col-2 home-link mobile-top-links">
                  {/* <NavLink
                    to="/dashboard"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="Dashboard">
                      {" "}
                      <ReactTooltip />
                      <BsAppIndicator size={25} />
                    </div>
                  </NavLink> */}
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  {/* <NavLink
                    to="/myevents"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="My events">
                      <ReactTooltip />
                      <BsCalendar size={22} />
                    </div>
                  </NavLink> */}
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  {/* <NavLink
                    to="/customers"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="Customers">
                      <ReactTooltip />
                      <BsPeople size={25} />
                    </div>
                  </NavLink> */}
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  {/* <NavLink
                    to="/boosters"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="Boosters">
                      <ReactTooltip />
                      <BsBoundingBoxCircles size={22} />
                    </div>
                  </NavLink> */}
                </div>

                <div className="col-2 mobile-liner"></div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  {/* <NavLink
                    to={{ pathname: "/profile" }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="Profile">
                      <ReactTooltip />
                      <BsPerson size={25} />
                    </div>
                  </NavLink> */}
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/logout"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div className="header-tabs" data-tip="Log out">
                      <ReactTooltip />
                      <IoIosLogOut size={25} />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </header>
        <div className="spacer" />
      </div>
    );
  }
}

export default Header;
