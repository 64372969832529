import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { BiPurchaseTagAlt } from "react-icons/bi";

class Buttons extends Component {
  state = {
    error: null,
    products: [],
  };

  componentDidMount() {
    document.title = "Karamale | Home";
    let eventid = this.props.eventid;
    const apiUrl = "/api/select/tickets?id=" + eventid;

    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            products: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  render() {
    const { products } = this.state;
    // let eventid = this.props.eventid;
    let q = this.props.search;
    return (
      <div className="row">
        {products.map((buttons) => (
          <div className="col-lg-12 col-6 col-sm-6 col-md-6">
            <div className="nainer margins">
              <div className="ticket-title">{buttons.ticket_cat} Ticket</div>
              <div className="btn-container">
                <b>Price:</b> R{buttons.price}
                <br />
                <b>Available:</b> {buttons.avail_tickets}
              </div>
              <div className="btn-container-padding">
                <Link
                  to={{
                    pathname: "/buy",
                    search:
                      "q=" +
                      q +
                      "&ticketid=" +
                      buttons.id +
                      "&eventid=" +
                      this.props.eventid,
                  }}
                >
                  <label className="buy-btn karamale-btn karamale-btn-round">
                    <BiPurchaseTagAlt /> Buy Ticket
                  </label>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Buttons;
