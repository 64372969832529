import React from "react";
import "./App.css";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Header5 from "./components/Header5";
import { FaMoneyBillAlt } from "react-icons/fa";
import { IoMdBusiness } from "react-icons/io";
import { HiCursorClick } from "react-icons/hi";

class Aboutsalespartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title =
      "Karamale Affiliate program - Earn income for your referrals";
  }

  render() {
    return (
      <div className="view">
        {localStorage.getItem("partnerid") !== null ? <Header5 /> : <Header />}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-10 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 p-0">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12 p-0">
                    <div className="affiliate-banner row justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <h2 className="color-white">
                          <span>Creating entrepreneurs.</span>
                        </h2>
                        <label className="color-white">
                          Earn income with Karamale Affiliate program
                        </label>
                        <br />
                        <Link to="partner">
                          <label className="become-an-affiliate-btn">
                            Sign up
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12">
                    <span className="">
                      <h3> Karamale's Affiliate marketing program</h3>
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-selet">
                    <div className="col-12">
                      <label className="item-names">
                        Welcome to Karamale's affiliate marketing program.
                        Karamale partners program help the general public,
                        particularly the influencers, social media players to
                        monetize their traffic, with a potential growth,
                        Karamale partners use unique link-associate tools to
                        direct event organisers to sign up and sell tickets on
                        Karamale, and earn with each sale the event organiser
                        makes.
                        <br />
                        <br />
                        <span>Who qualifies for this program</span>
                        <br />
                        Anyone that wants to earn some cash can partake,
                        influencers, bloggers, event planners, event managers
                        and the rest of the general public can participate in
                        this program.
                        <br />
                        <br />
                        <span>How does it work?</span>
                        <br />
                        Ones you register as an affiliate; you'll receive your
                        affiliate ID, you must use this ID to refer event
                        organisers, event planners and event managers to the
                        website, you only start earning when they sell tickets.
                        <br />
                        <br />
                        You'll be able to see the list of all the recruits and
                        all their active events and admission sales from your
                        dashboard, you'll also monitor payments that are due to
                        you by Karamale.
                        <br />
                        <br />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="mb-3">
                Join 100s of Affiliates and start earning
              </h5>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <HiCursorClick size={70} color={"#999900"} />
                        <br />
                        <br />
                        <span className="font-size-20">Sign up</span>
                        <br />
                        Add your name to 100s of influencers, socialites and
                        more who are earning income with Karamale Affiliate
                        program.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <IoMdBusiness size={70} color={"#ffa500"} />
                        <br />
                        <br />
                        <span className="font-size-20">Recruits</span>
                        <br />
                        Recruit event organisers, event planners, event managers
                        to sign up and sell tickets on Karamale. You can recruit
                        as many clients, no limit.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <FaMoneyBillAlt size={70} color={"#329932"} />
                        <br />
                        <br />
                        <span className="font-size-20">Earn</span>
                        <br />
                        Earn up to 30% in commission from the amount your
                        recruit spend on Karamale to sell tickets. The more
                        recruits the more earnings.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="mb-3">Karamale wants to grow your pocket</h5>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/affiliateid.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Affiliate ID</span>
                        <br />
                        Share your affiliate ID with event organisers, event
                        planners, event managers etc... Encourage them to sell
                        tickets using Karamale so you can earn more.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/recruits.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Recruits</span>
                        <br />
                        Your recruits are Karamale clients that you have
                        referred using your Affiliate ID, this ID is provided
                        for by Karamale and it is unique to you. You'll have
                        access to all your recruits.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/payout.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Your wallet</span>
                        <br />
                        Your wallet or pay out shows how much Karamale will pay
                        you. This amount increases every time your recruit makes
                        a ticket sale. We want you to make as much money.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-selet">
                    <div className="col-12">
                      <label>
                        <span>How many clients can I recruit?</span>
                        <br />
                        There's not limit to how many clients you can recruit
                        for Karamale, we want you to earn more money, so the
                        more clients you bring to Karamale the more you earn.
                        <br />
                        <br />
                        <span>Who do I recruit?</span>
                        <br />
                        Entities you can recruit are event planners, event
                        organisers, event managers or anyone individual that
                        organizes events, those are entities you can recruit,
                        recommend Karamale to them.
                        <br />
                        <br />
                        <span>How do I track referrals?</span>
                        <br />
                        Upon signing up, you'll have access to your dashboard,
                        you'll also receive a unique link that you'll use to
                        recruit clients for Karamale, every client that sign up
                        with Karamale using your link will appear on your
                        Recruit list from the dashboard, you'll know how many
                        admissions they have sold, the event date, and for how
                        long they'll be selling tickets prior to the event.
                        <br />
                        <br />
                        Every sale your recruit makes, you earn a commission, so
                        it will be in your best interest to also assist your
                        recruit to sell tickets, but either way, whether or not
                        they sell tickets via you, you'll still earn a
                        commission; so long they have signed using your link.
                        <br />
                        <br />
                        <span>NB:</span> Your recruits won't appear on your
                        recruit list until they create an event, so don't be
                        surprised if you don't see them on your recruit list
                        even after they have signed up, wait for them to create
                        an event.
                        <br />
                        <br />
                        <span>
                          How will I know if my recruits created and event?
                        </span>
                        <br />
                        For every client you recruit, Karamale will inform you
                        when they create an event, this communication will be
                        carried out through email.
                        <br />
                        <br />
                        <span>How I get paid?</span>
                        <br />
                        When signing up, Karamale will ask you to fill in your
                        bank details, i.e. bank name and account number, branch
                        code is automatically filled depending on the bank you
                        use, Karamale will then use these details to pay you;
                        this is why it is important to make sure your details
                        are correct.
                        <br />
                        <br />
                        <span>NB:</span> Your details will not be shared with
                        anyone or any company for whatever reason.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 p-0">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12 p-0">
                    <div className="affiliate-banner-bottom row justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <h2 className="color-white">
                          <span>Recruit clients. Earn commissions</span>
                        </h2>
                        <label className="color-white">
                          The more the recruits. The more your earnings.
                        </label>
                        <br />
                        <Link to="partner">
                          <label className="become-an-affiliate-btn">
                            Sign up
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Aboutsalespartner;
