import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Header5 from "./components/Header5";

class Changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      newPassword: "",
      retypePassword: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
    };
  }

  componentDidMount() {
    let { customerid, customername } = "";

    if (localStorage.getItem("customername") !== null) {
      customername = localStorage.getItem("customername") + " - Customer";
      customerid = localStorage.getItem("customerid");
    } else if (localStorage.getItem("businessname") !== null) {
      customername = localStorage.getItem("businessname") + " - Organiser";
      customerid = localStorage.getItem("businessid");
    } else if (localStorage.getItem("partnername") !== null) {
      customername = localStorage.getItem("partnername") + " - Affiliate";
      customerid = localStorage.getItem("partnerid");
    }

    document.title = customername + " - Password";
  }

  handlePass1 = (event) => {
    this.setState({
      newPassword: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePass2 = (event) => {
    this.setState({
      retypePassword: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePass3 = (event) => {
    this.setState({
      password: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    let { accountid, customername } = "";

    if (localStorage.getItem("customername") !== null) {
      customername = localStorage.getItem("customername");
      accountid = localStorage.getItem("customerid");
    } else if (localStorage.getItem("businessname") !== null) {
      customername = localStorage.getItem("businessname");
      accountid = localStorage.getItem("businessid");
    } else if (localStorage.getItem("partnername") !== null) {
      customername = localStorage.getItem("partnername");
      accountid = localStorage.getItem("partnerid");
    }

    const accounttype = localStorage.getItem("accounttype");

    const password = this.state.password.trim();
    const newPassword = this.state.newPassword;
    const retypePassword = this.state.retypePassword;
    const credentials = this.props.location.state.credentials;

    if (newPassword == "0000") {
      this.setState({
        error: " Invalid " + credentials,
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (newPassword.trim() < 4) {
      this.setState({
        error:
          credentials.charAt(0).toUpperCase() +
          credentials.slice(1) +
          " should at least have 4 characters",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (newPassword !== retypePassword) {
      this.setState({
        error: "Make sure both your " + credentials + "s",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (password === "") {
      this.setState({
        error:
          "Enter your current " + credentials + " to save new " + credentials,
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHANGEPASSWORD&newpassword=" +
          newPassword +
          "&currentpass=" +
          password +
          "&accountid=" +
          accountid +
          "&subquery=" +
          accounttype
      )
        .then((response) => {
          if (response.data.val == 1) {
            this.setState({
              error: "New " + credentials + " saved and ready to use",
              errorDisplay: "block",
              successError: "success-login",
            });
          } else {
            // alert(accountype);
            this.setState({
              error:
                "Current " +
                credentials +
                " you entered is incorrect, try again",
              errorDisplay: "block",
              successError: "error-login",
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    let { accountname, dashboard } = "";
    if (localStorage.getItem("customername") === null) {
      if (localStorage.getItem("businessname") === null) {
        if (localStorage.getItem("partnername") === null) {
          return <Redirect to="/login" />;
        } else {
          accountname = localStorage.getItem("partnername");
          dashboard = "in";
        }
      } else {
        accountname = localStorage.getItem("businessname");
        dashboard = "dashboard";
      }
    } else {
      accountname = localStorage.getItem("customername");
      dashboard = "customerdashboard";
    }
    const customername = accountname;
    const credentials = this.props.location.state.credentials;
    return (
      <div className="view">
        {localStorage.getItem("customername") !== null ? (
          <Header2 />
        ) : localStorage.getItem("businessname") !== null ? (
          <Header3 />
        ) : (
          <Header5 />
        )}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to={dashboard} className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          Save
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        CHANGE {credentials} DETAILS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body pb-3">
                  <span>Enter new details</span>
                  <br />
                  <br />
                  New {credentials}
                  <br />
                  <input
                    type="password"
                    placeholder="Type here..."
                    onChange={this.handlePass1}
                    className="edit-input"
                  />
                  <br /> <br />
                  Retype {credentials}
                  <br />
                  <input
                    type="password"
                    placeholder="Type here..."
                    onChange={this.handlePass2}
                    className="edit-input"
                  />
                  <br /> <br />
                  Current {credentials}
                  <br />
                  <input
                    type="password"
                    placeholder="Type here..."
                    onChange={this.handlePass3}
                    className="edit-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Changepassword;
