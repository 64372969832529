import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import {
  IoIosArrowBack,
  IoMdCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import Header4 from "./components/Header4";

class Accountstatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      customername: "",
      phone: "",
      email: "",
      dtregistered: "",
    };
  }

  componentDidMount() {
    const boostername = this.props.location.state.boostername;
    document.title = "Account status - " + boostername;
  }

  render() {
    if (localStorage.getItem("boosterid") === null) {
      return <Redirect to="/login" />;
    }

    const boosteremail = this.props.location.state.boosteremail;
    const boosterphone = this.props.location.state.boosterphone;
    const boostername = this.props.location.state.boostername;
    const businessname = this.props.location.state.businessname;
    const businessid = this.props.location.state.businessid;
    const boosterid = this.props.location.state.boosterid;
    const activation = this.props.location.state.activation;
    const balance = this.props.location.state.balance;
    const credit = this.props.location.state.credit;

    return (
      <div className="view">
        <Header4 />

        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={{
                          pathname: "/Boosterdashboard",
                          state: {
                            balance: balance,
                            credit: credit,
                            boosteremail: boosteremail,
                            boosterphone: boosterphone,
                            activation: activation,
                            businessid: businessid,
                            businessname: businessname,
                            boosterid: boosterid,
                            boostername: boostername,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        ACCOUNT STATUS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  {activation == 1 ? (
                    <label style={{ color: "#66b266" }}>
                      <label style={{ fontSize: 20, fontWeight: 500 }}>
                        Account active &nbsp;
                        <IoMdCheckmarkCircleOutline size={20} />
                      </label>
                      <br />
                      This account is permitted to sell tickets
                    </label>
                  ) : (
                    <label style={{ color: "red" }}>
                      <label style={{ fontSize: 20, fontWeight: 500 }}>
                        Account not active <IoIosCloseCircleOutline size={20} />
                      </label>
                      <br />
                      This account cannot sell tickets
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Accountstatus;
