import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import ClipLoader from "react-spinners/ClipLoader";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Header5 from "./components/Header5";

class Editprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      customername: localStorage.getItem("customername"),
      businessname: localStorage.getItem("businessname"),
      partnername: localStorage.getItem("partnername"),
      phone: this.props.location.state.phone,
      email: this.props.location.state.email,
      facebook: this.props.location.state.facebook,
      twitter: this.props.location.state.twitter,
      instagram: this.props.location.state.instagram,
      address: this.props.location.state.address,
      spinning: "none",
      updateDisplay: "block",
    };
  }

  componentDidMount() {
    // alert(localStorage.getItem("partnername"));
    if (localStorage.getItem("customername") !== null) {
      const customername = localStorage.getItem("customername");
      const customerid = localStorage.getItem("customerid");
      document.title = "Edit profile - " + customername + " - Customer";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
          customerid
      )
        .then((response) => {
          this.setState({
            customername: response.data.customername,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
          });
        })
        .catch(function (error) {
          alert(error);
        });
    } else if (localStorage.getItem("businessname") !== null) {
      const businessname = localStorage.getItem("businessname");
      const businessid = localStorage.getItem("businessid");
      document.title = "Edit profile - " + businessname + " - Business";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=BUSINESSDETAILS&accountid=" +
          businessid
      )
        .then((response) => {
          this.setState({
            customername: response.data.businessname,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
            facebook: response.data.facebook,
            twitter: response.data.twitter,
            instagram: response.data.instagram,
            address: response.data.address,
          });
        })
        .catch(function (error) {
          alert(error);
        });
    } else {
      const partnername = localStorage.getItem("partnername");
      const partnerid = localStorage.getItem("partnerid");
      document.title = "Edit profile - " + partnername + " - Sales partner";

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PARTNERDETAILS&partnerid=" +
          partnerid
      )
        .then((response) => {
          this.setState({
            customername: response.data.partnername,
            phone: response.data.phone,
            email: response.data.email,
            dtregistered: response.data.dtregistered,
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  }

  handleCustomername = (event) => {
    if (localStorage.getItem("customername") !== null) {
      this.setState({
        customername: event.target.value,
        errorDisplay: "none",
      });
    } else if (localStorage.getItem("businessname") !== null) {
      this.setState({
        businessname: event.target.value,
        errorDisplay: "none",
      });
    } else {
      this.setState({
        partnername: event.target.value,
        errorDisplay: "none",
      });
    }
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePhone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  handleFacebook = (event) => {
    this.setState({
      facebook: event.target.value,
      errorDisplay: "none",
    });
  };

  handleTwitter = (event) => {
    this.setState({
      twitter: event.target.value,
      errorDisplay: "none",
    });
  };

  handleInstagram = (event) => {
    this.setState({
      instagram: event.target.value,
      errorDisplay: "none",
    });
  };

  handleAddress = (event) => {
    this.setState({
      address: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    let accountid = 0;
    let accountname = "";
    this.setState({ updateDisplay: "none", spinning: "block" });

    if (localStorage.getItem("customerid") !== null) {
      accountid = localStorage.getItem("customerid");
      accountname = "&customername=" + this.state.customername;
    } else if (localStorage.getItem("businessid") !== null) {
      accountid = localStorage.getItem("businessid");
      accountname =
        "&businessname=" + this.state.businessname.split(" ").join("+");
    } else {
      accountid = localStorage.getItem("partnerid");
      accountname =
        "&partnername=" + this.state.partnername.split(" ").join("+");
    }

    const accounttype = localStorage.getItem("accounttype");
    // const customername = this.state.customername;
    const phone = this.state.phone;
    const email = this.state.email;

    if (
      this.state.customername.trim() == "" ||
      this.state.email.trim() == "" ||
      this.state.phone == ""
    ) {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "All fields are required",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=EDITBUSINESSDETAILS&accountid=" +
          accountid +
          "&accounttype=" +
          accounttype +
          accountname +
          "&phone=" +
          this.state.phone +
          "&email=" +
          this.state.email +
          "&facebook=" +
          this.state.facebook +
          "&twitter=" +
          this.state.twitter +
          "&instagram=" +
          this.state.instagram +
          "&address=" +
          this.state.address
      )
        .then((response) => {
          this.setState({ updateDisplay: "block", spinning: "none" });
          if (response.data.val == 2) {
            this.setState({
              error: "Email or phone you provided already exist",
              errorDisplay: "block",
              successError: "error-login",
            });
          } else {
            if (localStorage.getItem("customername") !== null) {
              localStorage.setItem("customername", this.state.customername);
            } else if (localStorage.getItem("businessname") !== null) {
              localStorage.setItem("businessname", this.state.businessname);
            } else {
              localStorage.setItem("partnername", this.state.partnername);
            }
            this.setState({
              error: "Profile updated",
              errorDisplay: "block",
              successError: "success-login",
            });
          }

          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);
        })
        .catch(function (error) {
          alert(error);
          this.setState({
            error: error,
            errorDisplay: "block",
            successError: "error-login",
          });

          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);
        });
    }
  };

  render() {
    let { accountname, dashboard } = "";
    if (localStorage.getItem("customername") === null) {
      if (localStorage.getItem("businessname") === null) {
        if (localStorage.getItem("partnername") === null) {
          return <Redirect to="/login" />;
        } else {
          accountname = localStorage.getItem("partnername");
          dashboard = "in";
        }
      } else {
        accountname = localStorage.getItem("businessname");
        dashboard = "dashboard";
      }
    } else {
      accountname = localStorage.getItem("customername");
      dashboard = "customerdashboard";
    }
    const customername = accountname;
    const dtregistered = this.props.location.state.dtregistered;
    return (
      <div className="view">
        {localStorage.getItem("customername") !== null ? (
          <Header2 />
        ) : localStorage.getItem("businessname") !== null ? (
          <Header3 />
        ) : (
          <Header5 />
        )}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to="/profile" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          <span
                            style={{
                              display: this.state.updateDisplay,
                            }}
                          >
                            {" "}
                            Update
                          </span>
                          <span
                            style={{
                              display: this.state.spinning,
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-4 pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        EDITING PROFILE{" "}
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  {localStorage.getItem("customername")
                    ? "Fullname"
                    : localStorage.getItem("businessname")
                    ? "Business name"
                    : "Partner's name"}
                  <br />
                  <input
                    type="text"
                    placeholder={
                      localStorage.getItem("customername")
                        ? "Name and surname"
                        : "Business name"
                    }
                    value={
                      localStorage.getItem("customername")
                        ? this.state.customername
                        : localStorage.getItem("businessname")
                        ? this.state.businessname
                        : this.state.partnername
                    }
                    onChange={this.handleCustomername}
                    className="edit-input"
                  />
                  <br /> <br />
                  Email
                  <br />
                  <input
                    type="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.handleEmail}
                    className="edit-input"
                  />
                  <br /> <br />
                  Phone number
                  <br />
                  <input
                    type="text"
                    placeholder="Phone number"
                    value={this.state.phone}
                    onChange={this.handlePhone}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Account created
                  <br />
                  <label className="small-details">{dtregistered}</label>
                </div>
              </div>

              {localStorage.getItem("customername") ? (
                ""
              ) : localStorage.getItem("partnername") ? (
                ""
              ) : (
                <div className=" BoxDashboardContainer mb-5 pl-0 pr-0">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0">
                        <label className="ticket-title pl-0 pr-0">
                          EDITING SOCIAL ACCOUNTS
                          <br />
                          <span className="color-grey text-12 font-normal">
                            Make it easy for your attendees to find you
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="h-divider w-100"></div>
                  </div>
                  <div className="col-lg-12 details-body">
                    <span>Details</span>
                    <br />
                    <br />
                    Facebook profile link
                    <br />
                    <input
                      type="text"
                      placeholder="https://facebook.com/username"
                      value={
                        this.state.facebook == "null" ||
                        this.state.facebook == null
                          ? ""
                          : this.state.facebook
                      }
                      onChange={this.handleFacebook}
                      className="edit-input"
                    />
                    <br /> <br />
                    Twitter/X profile link
                    <br />
                    <input
                      type="text"
                      placeholder="https://twitter.com/username"
                      value={
                        this.state.twitter == "null" ||
                        this.state.twitter == null
                          ? ""
                          : this.state.twitter
                      }
                      onChange={this.handleTwitter}
                      className="edit-input"
                    />
                    <br /> <br />
                    Instagram profile link
                    <br />
                    <input
                      type="text"
                      placeholder="https://instagram.com/username"
                      value={
                        this.state.instagram == "null" ||
                        this.state.instagram == null
                          ? ""
                          : this.state.instagram
                      }
                      onChange={this.handleInstagram}
                      className="edit-input"
                    />
                    <br />
                    <br />
                    Business address
                    <br />
                    <textarea
                      value={
                        this.state.address == "null" ||
                        this.state.address == null
                          ? ""
                          : this.state.address
                      }
                      onChange={this.handleAddress}
                      placeholder="Address"
                      className="edit-input mt-2"
                    />
                    <label className="back-arrow mb-0 bottom__button">
                      <div
                        className="save-btn float-right"
                        onClick={() => this.update()}
                      >
                        <span
                          style={{
                            display: this.state.updateDisplay,
                          }}
                        >
                          {" "}
                          Update
                        </span>
                        <span
                          style={{
                            display: this.state.spinning,
                          }}
                        >
                          <ClipLoader
                            color={"#fff"}
                            loading={true}
                            // css={override}
                            size={20}
                          />
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Editprofile;
