import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import QrReader from "react-qr-scanner";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosAdd,
} from "react-icons/io";
import {
  BsCalendar,
  BsPeople,
  BsBoundingBoxCircles,
  BsPerson,
  BsLock,
  BsPen,
  BsCollectionFill,
} from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { SiMinutemailer } from "react-icons/si";
import Header3 from "./components/Header3";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      details: [],
      numberOfclientes: 0,
      numberOfbooster: 0,
      numberOfboosterDeactivated: 0,
      delay: 100,
      result: "No result",
    };

    this.handleScan = this.handleScan.bind(this);
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    document.title = businessname + " - Business dashboard";

    // get numberOfboosterDeactivated
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=NUMBEROFBOOSTERDEACTIVATEDS&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          numberOfboosterDeactivated: response.data.boosterDeactivatedCount,
        });
      } else {
        this.setState({ numberOfboosterDeactivated: 0 });
      }
    });

    // get numberOfclientes
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=NUMBEROFCLIENTELE&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({ numberOfclientes: response.data.clientCount });
      } else {
        this.setState({ numberOfclientes: 0 });
      }
    });

    // get numberOfbooster
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=NUMBEROFBOOSTERS&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({ numberOfbooster: response.data.boostersCount });
      } else {
        this.setState({ numberOfbooster: 0 });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MYEVENTS&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype +
        "&dashboard=yes"
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data });
        }
      })
      .catch((error) => console.log(error));
  }

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let string = "";

    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            let tempEventname = item.eventname;
            const passEventname = tempEventname;
            const campaignstatus = item.campaignstatus;
            let budget = item.budget;
            let ticketset = item.ticketid;
            let markticketset = "";
            let inCheckmark = "";
            let thumbnail = item.thumbnail;
            let pausebtn = "";
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            {
              item.eventname.length > 22
                ? (event = item.eventname.substring(0, 22) + "...")
                : (event = item.eventname);
            }

            if (
              item.thumbnail.trim() === "" ||
              item.thumbnail.trim() === "none"
            ) {
              poster = "https://karamale.com/apps/kscanner/placeholder.png";
              nb = (
                <label
                  data-tip="Complete event setup"
                  className="float-right"
                  style={{ color: "red", float: "right", fontWeight: 400 }}
                >
                  <ReactTooltip />
                  <BiEditAlt />
                </label>
              );
            } else {
              nb = "";
              poster =
                "https://karamale.com/apps/kscanner/posters/" +
                item.businessid +
                "/" +
                item.eventid +
                "/" +
                item.thumbnail;
            }

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-1 pl-1 pb-2 pt-3">
                  <Link
                    to={{
                      pathname: "/editevent",
                      state: {
                        campaignstatus: item.campaignstatus,
                        eventid: item.eventid,
                        eventname: item.eventname,
                        poster: poster,
                        eventdate: item.eventday,
                        enddate: item.enddate,
                        event_date: item.event_day,
                        finish_date: item.finish_day,
                        theme: item.theme,
                        venue: item.venue,
                        expectednum: item.expected,
                        time: item.time,
                        endtime: item.endtime,
                        prevpage: "dashboard",
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-lg-3 col-2 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      </div>
                      <div
                        className="col-lg-9 col-10 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {event} {nb} <br />
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            {item.eventday}
                          </label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}

        {this.state.details.length < 4 ? (
          ""
        ) : (
          <div className="item-select w-100 pr-1 pl-1 pb-3 pt-3 mb-2">
            <Link
              to={{
                pathname: "/myevents",
              }}
              className="w-100"
            >
              <div className="row" style={{ display: "flex" }}>
                <div className="col-lg-3 col-2 col-sm-2 text-center">
                  <div
                    style={{
                      backgroundImage: `url(https://karamale.com/apps/kscanner/placeholder.png)`,
                      width: 55,
                      height: 55,
                      borderRadius: 100,
                      backgroundSize: "100%",
                    }}
                  ></div>
                </div>
                <div
                  className="col-lg-9 col-10 col-sm-10"
                  style={{ cursor: "pointer" }}
                >
                  <label
                    className="item-names"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "#3399cc",
                    }}
                  >
                    More... <br />
                    <label
                      style={{
                        color: "grey",
                        fontSize: 13,
                        fontWeight: "normal",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      See all your events
                    </label>
                  </label>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  };

  handleScan(data) {
    this.setState({
      result: data,
    });
  }
  handleError(err) {
    console.error(err);
  }

  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");

    const previewStyle = {
      height: 240,
      width: 320,
      border: "thin solid red",
    };
    return (
      <div className="view">
        <Header3 />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5  pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-user">
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <IoIosContact size={30} className="profile-icon" />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-11">
                      <label className="custername-profile">
                        {businessname.toUpperCase()}
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100" />
                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "/campaignmanager",
                        state: {
                          accounttype: localStorage.getItem("accounttype"),
                          accountid: localStorage.getItem("businessid"),
                          businessname: localStorage.getItem("businessname"),
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsCollectionFill size={28} color={"#b2b200"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Campaign manager</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "/sms",
                        state: {
                          accounttype: localStorage.getItem("accounttype"),
                          accountid: localStorage.getItem("businessid"),
                          businessname: localStorage.getItem("businessname"),
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <SiMinutemailer size={28} color={"#ffc04c"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Marketing</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link to="/myevents" className="link-btn w-100 menu-links">
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsCalendar size={28} color={"#87ceeb"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">My events</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to="/customers"
                      className="link-btn w-100 menu-links pb-2"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPeople size={30} color={"#66b266"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names w-100">
                          Customers{" "}
                          <label className="float-right small-details">
                            {this.state.numberOfclientes}
                          </label>
                        </label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to="/boosters"
                      className="link-btn w-100 menu-links pb-0"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsBoundingBoxCircles size={28} color={"#cccc00"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names w-100">
                          Boosters{" "}
                          <label className="float-right small-details">
                            <label style={{ color: "#66b266" }}>
                              {this.state.numberOfbooster}
                            </label>{" "}
                            +{" "}
                            <label style={{ color: "red" }}>
                              {this.state.numberOfboosterDeactivated}
                            </label>
                          </label>
                        </label>
                      </div>
                    </Link>
                  </div>

                  {/* <div className="item-select">
                    <Link to="/likes" className="link-btn w-100 menu-links">
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoMdHeartEmpty size={30} color={"#ffa500"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Likes</label>
                      </div>
                    </Link>
                  </div> */}

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "profile",
                        state: {
                          businessid: businessid,
                          businessname: businessname,
                          accountid: businessid,
                          accounttype: accounttype,
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPerson size={30} color={"#3399cc"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Profile</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "changepassword",
                        state: {
                          credentials: "passcode",
                        },
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsLock size={30} color={"#006699"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Change passcode</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "feedback",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPen size={30} color={"#ffc62b"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">
                          Send us feedback and more...
                        </label>
                      </div>
                    </Link>
                  </div>

                  {/* <div className="item-select">
                    <Link
                      to={{
                        pathname: "report",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosPodium size={30} color={"#66b266"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Send report</label>
                      </div>
                    </Link>
                  </div> */}

                  <div className="item-select mb-2">
                    <Link
                      to={{
                        pathname: "logout",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosLogOut size={30} color={"red"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-10">
                        <label className="item-names">Log out</label>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 tickets-assigned">
              <div className=" BoxDashboardContainer mt-5 mb-4 pb-3 pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title p-0">MY EVENTS</label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>
        <Link
          to={{ pathname: "addevent", state: { prevpage: "dashboard" } }}
          className={"add-event"}
        >
          <label>
            <IoIosAdd size={25} />{" "}
            <label className="hide-title">Create event</label>
          </label>
        </Link>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Dashboard;
