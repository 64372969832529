import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import {
  IoIosPeople,
  IoIosAdd,
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
} from "react-icons/io";
import { BsFileSpreadsheet } from "react-icons/bs";
import { DiDatabase } from "react-icons/di";
import { SiMinutemailer } from "react-icons/si";
import Header3 from "./components/Header3";

function Sms(props) {
  useEffect(() => {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");

    document.title = "Marketing campaign";
    const uri = "https://karamale.com/apps/kscanner/webservices.php";
    axios
      .get(uri + "?query=NUMBEROFCLIENTELE&businessid=" + businessid)
      .then((response) => {
        if (response.data.val == 1) {
          setAudience(response.data.clientCount);
        } else {
          setAudience(0);
        }
      });

    axios.get(uri + "?query=KARAMALEDATABASE").then((response) => {
      setDatabase(response.data.database);
    });
  });

  const [audience, setAudience] = useState(0);
  const [database, setDatabase] = useState(0);
  const [offRadioOne, setOffRadioOne] = useState(true);
  const [onRadioOne, setOnRadioOne] = useState(false);

  const [offRadioTwo, setOffRadioTwo] = useState(true);
  const [onRadioTwo, setOnRadioTwo] = useState(false);

  const [offRadioThree, setOffRadioThree] = useState(true);
  const [onRadioThree, setOnRadioThree] = useState(false);

  const [optionOne, setOptionOne] = useState("item-select");
  const [optionTwo, setOptionTwo] = useState("item-select");
  const [optionThree, setOptionThree] = useState("item-select");

  const [recipients, setRecipients] = useState(0);

  function existingAudience() {
    if (offRadioOne == true) {
      setOffRadioOne(false);
      setOnRadioOne(true);
      setOptionOne("item-select-active");
      setOptionTwo("item-select");
      setOptionThree("item-select");
      setOffRadioTwo(true);
      setOnRadioTwo(false);

      setOffRadioThree(true);
      setOnRadioThree(false);
    } else {
      setOffRadioOne(true);
      setOnRadioOne(false);
      setOptionOne("item-select");
    }

    setRecipients(audience);
    localStorage.setItem("audience", audience);
  }

  function karamaleDatabase() {
    if (offRadioTwo == true) {
      setOffRadioTwo(false);
      setOnRadioTwo(true);
      setOptionOne("item-select");
      setOptionTwo("item-select-active");
      setOptionThree("item-select");

      setOffRadioOne(true);
      setOnRadioOne(false);
      setOffRadioThree(true);
      setOnRadioThree(false);
    } else {
      setOffRadioTwo(true);
      setOnRadioTwo(false);
      setOptionTwo("item-select");
    }

    setRecipients(database);
    localStorage.setItem("audience", database);
  }

  function newAudience() {
    if (offRadioThree == true) {
      setOffRadioThree(false);
      setOnRadioThree(true);
      setOptionOne("item-select");
      setOptionTwo("item-select");
      setOptionThree("item-select-active");

      setOffRadioOne(true);
      setOnRadioOne(false);
      setOffRadioTwo(true);
      setOnRadioTwo(false);
    } else {
      setOffRadioThree(true);
      setOnRadioThree(false);
      setOptionThree("item-select");
    }
  }

  function next() {
    let alias = "";
    if (onRadioOne == true) {
      alias = "EXISTINGAUDIENCE";
    } else if (onRadioTwo == true) {
      alias = "DATABASE";
    } else {
      alias = "NEWAUDIENCE";
    }

    if (alias == "NEWAUDIENCE") {
      props.history.push({
        pathname: "uploaddata",
      });
    } else {
      props.history.push({
        pathname: "marketing",
      });
    }

    localStorage.setItem("alias", alias);
    localStorage.setItem("type", "SMS");
  }

  if (localStorage.getItem("businessname") === null) {
    return <Redirect to="/login" />;
  } else {
  }
  const businessname = localStorage.getItem("businessname");
  const businessid = localStorage.getItem("businessid");
  const accounttype = localStorage.getItem("accounttype");

  const previewStyle = {
    height: 240,
    width: 320,
    border: "thin solid red",
  };
  return (
    <div className="view">
      <Header3 />
      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-7 mt-5 pl-0 pr-0">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-12">
                    <label className=" pt-2">
                      <span>Marketing</span>
                    </label>
                    {onRadioOne == false &&
                    onRadioTwo == false &&
                    onRadioThree == false ? (
                      <label class="back-arrow float-right">
                        <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                          <span>Next</span>
                        </div>
                      </label>
                    ) : (
                      <label class="back-arrow float-right">
                        <div class="save-btn btn-radius-3" onClick={next}>
                          <span>Next</span>
                        </div>
                      </label>
                    )}
                  </div>
                </div>

                <div className="h-divider w-100"></div>
                <div className="col-12 text-left p-3 pl-4">
                  <span>Market your next event to...</span>
                </div>
                <div className={optionOne} onClick={existingAudience}>
                  <div className="link-btn w-100 menu-links">
                    <div className="col-lg-1 col-2 text-center">
                      {onRadioOne && (
                        <IoIosRadioButtonOn size={28} color={"#66b266"} />
                      )}
                      {offRadioOne && (
                        <IoIosRadioButtonOff size={28} color={"#b2b200"} />
                      )}
                    </div>
                    <div className="col-1 text-center mobile-hiding">
                      <IoIosPeople size={28} color={"#b2b200"} />
                    </div>
                    <div className="col-lg-10 col-10">
                      <label className="item-names">
                        your existing audience
                      </label>
                      <label className="float-right pt-1 small-details">
                        {audience}
                      </label>
                    </div>
                  </div>
                </div>

                <div className={optionTwo} onClick={karamaleDatabase}>
                  <div className="link-btn w-100 menu-links">
                    <div className="col-lg-1 col-2 text-center">
                      {onRadioTwo && (
                        <IoIosRadioButtonOn size={28} color={"#66b266"} />
                      )}
                      {offRadioTwo && (
                        <IoIosRadioButtonOff size={28} color={"#ffc04c"} />
                      )}
                    </div>

                    <div className="col-1 text-center mobile-hiding">
                      <DiDatabase size={28} color={"#ffc04c"} />
                    </div>
                    <div className="col-lg-10 col-10">
                      <label className="item-names">universal audience</label>
                      <label className="float-right pt-1 small-details">
                        {database}
                      </label>
                    </div>
                  </div>
                </div>

                <div className={optionThree} onClick={newAudience}>
                  <div className="link-btn w-100 menu-links">
                    <div className="col-lg-1 col-2 text-center">
                      {onRadioThree && (
                        <IoIosRadioButtonOn size={28} color={"#66b266"} />
                      )}
                      {offRadioThree && (
                        <IoIosRadioButtonOff size={28} color={"#87ceeb"} />
                      )}
                    </div>
                    <div className="col-1 text-center mobile-hiding">
                      <BsFileSpreadsheet size={28} color={"#87ceeb"} />
                    </div>
                    <div className="col-lg-10 col-10">
                      <label className="item-names">
                        upload your own audience
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        to={{ pathname: "addevent", state: { prevpage: "dashboard" } }}
        className={"add-event"}
      >
        <label>
          <IoIosAdd size={25} />{" "}
          <label className="hide-title">Create event</label>
        </label>
      </Link>
      {/* <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div> */}
    </div>
  );
}

export default Sms;
