import React, { Component } from "react";
import "./SlideDrawer.css";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import {
  BsInfoCircle,
  BsPerson,
  BsPlusCircle,
  BsHouseDoor,
} from "react-icons/bs";
import { IoIosApps, IoIosPricetag } from "react-icons/io";

class SlideDrawer extends Component {
  state = {
    products: [],
  };
  componentDidMount() {
    let search = window.location.search;
    let query = new URLSearchParams(search);
    let ticketid = query.get("ticketid");
    let eventid = query.get("eventid");

    Axios.get(
      "/api/select/payment/method?eventid=" + eventid + "&ticketid=" + ticketid
    ).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  render() {
    let drawerClasses = "slide-drawer";
    if (this.props.show) {
      drawerClasses = "slide-drawer open";
    }
    return (
      <div className={drawerClasses}>
        <div className="row">
          <div className="col-lg-12 p-0">
            <NavLink
              to="/"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight ">
                <BsHouseDoor size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Home
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to="/login"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPlusCircle size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Log in
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to="/promoter"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPerson size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Booster
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/aboutkaramale"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsInfoCircle size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;About us
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/fees"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoIosPricetag size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Fees
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname:
                  "https://play.google.com/store/apps/details?id=co.za.azaniasystems&gl=ZA",
              }}
              target="_blank"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoIosApps size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;App
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideDrawer;
