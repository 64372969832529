import React, { Component } from "react";
import "./App.css";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { IoIosArrowBack } from "react-icons/io";
import Header2 from "./components/Header2";
import Header from "./components/Header";
import TopBarProgress from "react-topbar-progress-indicator";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import ClipLoader from "react-spinners/BeatLoader";
const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

const cookies = new Cookies();
if (!cookies.get("clientid")) {
  cookies.set("clientid", uuidv4().toUpperCase());
} else {
}

class Event extends Component {
  state = {
    error: null,
    products: [],
    tickets: [],
    likeLabel: "",
    liked: "",
    likes: 0,
    loadBarProgress: false,
    eventid: 0,
    fetchingTickets: true,
  };

  componentDidMount() {
    // const eventname = this.props.location.state.eventname;
    // const eventid = this.props.location.state.eventid;

    const query = new URLSearchParams(this.props.location.search);

    let { eventid, eventname, businessid } = 0;

    if (
      query.has("eventid") === true &&
      query.has("eventname") === true &&
      query.has("eventDate") === true &&
      query.has("fulldate") === true &&
      query.has("customerid") === true &&
      query.has("posterUri") === true &&
      query.has("accountid") === true &&
      query.has("accounttype") === true &&
      query.has("customername") === true &&
      query.has("email") === true &&
      query.has("phone") === true &&
      query.has("venue") === true &&
      query.has("description") === true &&
      query.has("businessid") === true &&
      query.has("poster") === true &&
      query.has("eventname") === true &&
      query.has("eventday") === true &&
      query.has("starttime") === true &&
      query.has("endtime") === true &&
      query.has("previousPage") === true &&
      query.has("campaignStatus") === true &&
      query.has("category") === true
    ) {
      eventid = query.get("eventid");
      eventname = query.get("eventname");
      businessid = query.get("businessid");
    } else {
      this.props.history.push("");
    }

    document.title = "Karamale | " + eventname;
    const customerid = query.get("customerid");
    const clientid = cookies.get("clientid");

    // get user operating system
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    // get devices

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = platform;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = platform;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    }

    // get user agent

    let browser = "";
    if (window.navigator.userAgent.indexOf("Edg/") > -1) {
      browser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Edge/") > -1) {
      browser = "Edge";
    } else if (window.navigator.userAgent.indexOf("MSIE") > -1) {
      browser = "Internet Explorer";
    } else if (
      window.navigator.userAgent.indexOf("Safari") > -1 &&
      window.navigator.userAgent.indexOf("Chrome") == -1
    ) {
      browser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Firefox") > -1) {
      browser = "Firefox";
    } else if (window.navigator.userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
    } else if (window.navigator.userAgent.indexOf("Linux") > -1) {
      browser = "Linux";
    }

    const device = os;

    // alert(browser)

    // get likes

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETNUMBEROFLIKES&eventid=" +
        eventid
    ).then((response) => {
      this.setState({ likes: response.data.likes });
    });

    // Check if current customer likes the event
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETLIKEEVENT&customerid=" +
        customerid +
        "&eventid=" +
        eventid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          liked: "yes",
          likeLabel: (
            <label
              className="color-blue font-normal small-details mt-3"
              onClick={() => this.like()}
            >
              Unlike
            </label>
          ),
        });
      } else {
        this.setState({
          liked: "no",
          likeLabel: (
            <label
              className="color-blue font-normal mt-3"
              onClick={() => this.like()}
            >
              Like
            </label>
          ),
        });
      }
    });

    // Calculate impressions
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=INSERTIMPRESSION&eventid=" +
        eventid +
        "&businessid=" +
        businessid +
        "&clientid=" +
        clientid +
        "&device=" +
        device +
        "&browser=" +
        browser
    ).then((response) => {
      // alert(response.data.businessid);
    });

    // console.log("error");
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TICKETS&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        tickets: response.data,
        fetchingTickets: false,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const query = new URLSearchParams(this.props.location.search);

    const eventid = query.get("eventid");
    const eventname = query.get("eventname");
    const customerid = query.get("customerid");
    document.title = "Karamale | " + eventname;

    if (this.state.liked !== prevState.liked) {
      // get likes

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETNUMBEROFLIKES&eventid=" +
          eventid
      ).then((response) => {
        this.setState({ likes: response.data.likes });
      });

      // Check if current customer likes the event

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETLIKEEVENT&customerid=" +
          customerid +
          "&eventid=" +
          eventid
      ).then((response) => {
        if (response.data.val == 1) {
          this.setState({
            likeLabel: (
              <label
                className="color-blue font-normal small-details mt-3"
                onClick={() => this.like()}
              >
                Unlike
              </label>
            ),
          });
        } else {
          this.setState({
            likeLabel: (
              <label
                className="color-blue font-normal mt-3"
                onClick={() => this.like()}
              >
                Like
              </label>
            ),
          });
        }
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=TICKETS&eventid=" +
          eventid
      ).then((response) => {
        this.setState({
          tickets: response.data,
        });
      });
    }
  }

  tickets = () => {
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");

    const accountid = query.get("accountid");
    const eventDate = query.get("eventDate");
    // const fulldate = this.props.location.state.fulldate;
    const customerid = query.get("customerid");
    const posterUri = query.get("posterUri");
    const accounttype = query.get("accounttype");
    const customername = query.get("customername");
    const email = query.get("email");
    const phone = query.get("phone");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const poster = query.get("poster");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");
    const previousPage = query.get("previousPage");
    const campaignStatus = query.get("campaignStatus");
    const category = query.get("category");
    const province = query.get("province");

    // alert(category);

    // const accountid = this.props.location.state.accountid;
    // const eventid = this.props.location.state.eventid;
    // const eventDate = this.props.location.state.eventDate;
    // const fulldate = this.props.location.state.fulldate;
    // const customerid = this.props.location.state.customerid;
    // const posterUri = this.props.location.state.posterUri;
    // const accounttype = this.props.location.state.accounttype;
    // const customername = this.props.location.state.customername;
    // const email = this.props.location.state.email;
    // const phone = this.props.location.state.phone;
    // const venue = this.props.location.state.venue;
    // const description = this.props.location.state.description;
    // const businessid = this.props.location.state.businessid;
    // const poster = this.props.location.state.poster;
    // const eventname = this.props.location.state.eventname;
    // const eventday = this.props.location.state.eventday;
    // const starttime = this.props.location.state.starttime;
    // const endtime = this.props.location.state.endtime;
    // const previousPage = this.props.location.state.previousPage;

    // const campaignStatus = this.props.location.state.campaignStatus;
    let { hide, warningdiv, soldOut, soldOutLabel } = "";
    const date = new Date();
    let day = date.getDate();
    if (day >= 10) {
      day = day;
    } else {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;

    if (month >= 10) {
      month = month;
    } else {
      month = "0" + month;
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;

    return (
      <div className="row justify-content-center">
        {this.state.tickets.map((item, index) => {
          let ticket = "";

          if (item.ticket_cat == "vvip") {
            ticket = "VVIP";
          } else if (item.ticket_cat == "vip") {
            ticket = "VIP";
          } else {
            ticket = "General";
          }

          {
            eventDate >= fulldate ? (hide = "flex") : (hide = "none");
          }

          {
            eventDate >= fulldate
              ? (warningdiv = "none")
              : (warningdiv = "flex");
          }
          return (
            <div className="col-4 col-lg-12 pl-1 pr-1">
              {parseInt(item.avail_tickets) <= 0 &&
              parseInt(item.kids_tickets) <= 0 ? (
                <div className="col-12 col-lg-12 p-0">
                  <label className="ticket-btn-disabled">
                    {ticket} <span className="ticket-price">Sold out</span>
                  </label>
                </div>
              ) : (
                <div className="col-12 col-lg-12 p-0">
                  <Link
                    style={{ display: hide, textDecoration: "none" }}
                    to={{
                      pathname: "/finalize",
                      search:
                        "q=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&pageid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&fulldate=" +
                        fulldate +
                        "&eventDate=" +
                        eventDate +
                        "&price=" +
                        item.price +
                        "&kids_price=" +
                        item.kids_price +
                        "&ticketid=" +
                        item.id +
                        "&ticketref=" +
                        item.ticketref +
                        "&command=1&customerid=" +
                        customerid +
                        "&posterUri=" +
                        posterUri +
                        "&customername=" +
                        customername +
                        "&email=" +
                        email +
                        "&phone=" +
                        phone +
                        "&accountid=" +
                        accountid +
                        "&accounttype=" +
                        accounttype +
                        "&price=" +
                        item.price +
                        "&eventtype=" +
                        category +
                        "&province=" +
                        province +
                        "&venue=" +
                        venue +
                        "&description=" +
                        description +
                        "&businessid=" +
                        businessid +
                        "&eventid=" +
                        eventid +
                        "&poster=" +
                        poster +
                        "&eventname=" +
                        eventname +
                        "&eventday=" +
                        eventday +
                        "&starttime=" +
                        starttime +
                        "&endtime=" +
                        endtime +
                        "&ticketid=" +
                        item.id +
                        "&eventid=" +
                        eventid +
                        "&previousPage=" +
                        previousPage +
                        "&prevPage=event&campaignStatus=" +
                        campaignStatus +
                        "&category=" +
                        ticket +
                        "&sold=" +
                        item.sold +
                        "&avail_tickets=" +
                        item.avail_tickets +
                        "&kids_sold=" +
                        item.kids_sold +
                        "&kids_tickets=" +
                        item.kids_tickets,

                      state: {
                        fromHome: true,
                        fulldate: fulldate,
                        eventDate: eventDate,
                        price: item.price,
                        ticketid: item.ticketid,
                        ticketref: item.ticketref,
                        command: 1,
                        customerid: customerid,
                        posterUri: posterUri,
                        customername: customername,
                        email: email,
                        phone: phone,
                        accountid: accountid,
                        accounttype: accounttype,
                        price: item.price,
                        category: ticket,
                        venue: venue,
                        description: description,
                        businessid: businessid,
                        eventid: eventid,
                        poster: poster,
                        eventname: eventname,
                        eventday: eventday,
                        starttime: starttime,
                        endtime: endtime,
                        ticketid: item.id,
                        eventid: eventid,
                        previousPage: previousPage,
                        prevPage: "event",
                        campaignStatus: campaignStatus,
                      },
                    }}
                  >
                    <label className="desktop-buttons-purchase-options">
                      <label className="ticket-btn mb-0">
                        <span>{ticket} - </span>
                        <span class="break">
                          <br />
                        </span>
                        {/* <span className="ticket-price mobile-break"> */}
                        <label className=" mobile-break mb-0">
                          {item.price <= 0 ? (
                            ""
                          ) : (
                            <label className="mb-0">
                              Adults: R{item.price}
                            </label>
                          )}
                          {item.kids_price <= 0 ? (
                            ""
                          ) : (
                            <label className="mb-0">
                              , Kids: R{item.kids_price}
                            </label>
                          )}
                        </label>
                      </label>
                    </label>

                    <label className="ticket-btn-container small-details font-size-12">
                      <label className="ticket-btn">
                        {ticket}
                        <span class="break">
                          <br />
                        </span>
                      </label>
                      {item.price <= 0 ? (
                        ""
                      ) : (
                        <label className="mb-0">Adults: R{item.price}</label>
                      )}
                      <br />
                      {item.kids_price <= 0 ? (
                        ""
                      ) : (
                        <label className="mb-0">Kids: R{item.kids_price}</label>
                      )}
                    </label>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  like = () => {
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const customerid = query.get("customerid");
    const businessid = query.get("businessid");

    this.setState({ loadBarProgress: true });

    this.props.history.push({
      pathname: "/event",
      search:
        "q=" +
        uuid6 +
        "&queryid=" +
        uuid5 +
        "&pageid=" +
        uuid4 +
        "&uid=" +
        uuid3 +
        "&qKey=" +
        uuid1 +
        "&key=" +
        uuid2 +
        "&uuid=" +
        uuid +
        "&eventid=" +
        query.get("eventid") +
        "&eventDate=" +
        query.get("eventDate") +
        "&fulldate=" +
        query.get("fulldate") +
        "&customerid=" +
        query.get("customerid") +
        "&posterUri=" +
        query.get("posterUri") +
        "&accountid=" +
        query.get("accountid") +
        "&accounttype=" +
        query.get("accounttype") +
        "&customername=" +
        query.get("customername") +
        "&email=" +
        query.get("email") +
        "&phone=" +
        query.get("phone") +
        "&venue=" +
        query.get("venue") +
        "&description=" +
        query.get("description") +
        "&businessid=" +
        query.get("businessid") +
        "&poster=" +
        query.get("poster") +
        "&eventname=" +
        query.get("eventname") +
        "&eventday=" +
        query.get("eventday") +
        "&starttime=" +
        query.get("starttime") +
        "&endtime=" +
        query.get("endtime") +
        "&previousPage=&campaignStatus=running&category=",
      state: {
        fromHome: true,
        eventid: query.get("eventid"),
        eventDate: query.get("eventDate"),
        fulldate: query.get("fulldate"),
        customerid: query.get("customerid"),
        posterUri: query.get("posterUri"),
        accountid: query.get("accountid"),
        accounttype: query.get("accounttype"),
        // customername: customername,
        customername: query.get("customername"),
        email: query.get("email"),
        phone: query.get("phone"),
        venue: query.get("venue"),
        description: query.get("description"),
        businessid: query.get("businessid"),
        poster: query.get("poster"),
        eventname: query.get("eventname"),
        eventday: query.get("eventday"),
        starttime: query.get("starttime"),
        endtime: query.get("endtime"),
        previousPage: "",
        campaignStatus: "running",
        category: "",
      },
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=ADDLIKE&eventid=" +
        eventid +
        "&customerid=" +
        customerid +
        "&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({ liked: "yes" });
      } else {
        this.setState({ liked: "no" });
      }
      this.setState({
        loadBarProgress: false,
      });
    });
  };

  render() {
    const { error, products, loadBarProgress, fetchingTickets } = this.state;

    const query = new URLSearchParams(this.props.location.search);
    const q = query.get("q");
    const queryid = query.get("id");
    const date = new Date();

    let day = date.getDate();
    if (day >= 10) {
      day = day;
    } else {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;

    if (month >= 10) {
      month = month;
    } else {
      month = "0" + month;
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;

    const eventid = query.get("eventid");

    const accountid = query.get("accountid");
    const eventDate = query.get("eventDate");
    // const fulldate = this.props.location.state.fulldate;
    const customerid = query.get("customerid");
    const posterUri = query.get("posterUri");
    const accounttype = query.get("accounttype");
    const customername = query.get("customername");
    const email = query.get("email");
    const phone = query.get("phone");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const poster = query.get("poster");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");
    const previousPage = query.get("previousPage");
    const campaignStatus = query.get("campaignStatus");
    const category = query.get("category");
    const province = query.get("province");
    let backpage = "";

    {
      previousPage === "event" ? (backpage = "") : (backpage = previousPage);
    }

    // const accountid = this.props.location.state.accountid;
    // const eventid = this.props.location.state.eventid;
    // const eventDate = this.props.location.state.eventDate;
    // const fulldate = this.props.location.state.fulldate;
    // const customerid = this.props.location.state.customerid;
    // const posterUri = this.props.location.state.posterUri;
    // const accounttype = this.props.location.state.accounttype;
    // const customername = this.props.location.state.customername;
    // const email = this.props.location.state.email;
    // const phone = this.props.location.state.phone;
    // const venue = this.props.location.state.venue;
    // const description = this.props.location.state.description;
    // const businessid = this.props.location.state.businessid;
    // const poster = this.props.location.state.poster;
    // const eventname = this.props.location.state.eventname;
    // const eventday = this.props.location.state.eventday;
    // const starttime = this.props.location.state.starttime;
    // const endtime = this.props.location.state.endtime;
    // const previousPage = this.props.location.state.previousPage;
    // const campaignStatus = this.props.location.state.campaignStatus;
    // const category = this.props.location.state.category;

    let string = "";
    {
      eventDate >= fulldate
        ? (string = "")
        : (string = (
            <label className="warning-event">
              This event has already taken place.
              <br />
              You can no longer buy tickets
            </label>
          ));
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="view pt-4">
          {localStorage.getItem("customername") === null ? (
            <Header />
          ) : (
            <Header2 />
          )}
          {loadBarProgress && <TopBarProgress />}
          <div className="container">
            <div className="row mt-4 mb-0 w-100">
              {eventDate > fulldate ? (
                <div className="row justify-content-center align-self-center desktop-width">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-2 pl-0 pr-0">
                    <div className="content-detail">
                      <div className="">
                        <img
                          src={posterUri}
                          alt={poster}
                          width="100%"
                          className="img-poster"
                        />
                      </div>

                      <div className="col-lg-12 p-0 mt-4 pb-0">
                        <div className="pb-5">
                          <span className="bolddiv">Description</span>
                          <br />
                          <label className="mb-0 whiteSpace">
                            {description}
                          </label>
                          <br />
                          <br />
                          <span className="bolddiv">Venue</span>
                          <br />
                          {venue}
                          <br />
                          <br />

                          <div
                            class="mapouter"
                            style={{
                              position: "relative",
                              textAlign: "right",
                              height: "500",
                              width: "600",
                            }}
                          >
                            <div
                              class="gmap_canvas"
                              style={{
                                overflow: "hidden",
                                background: "none",
                                height: "500",
                                width: "100%",
                              }}
                            >
                              <iframe
                                width="100%"
                                height="500"
                                id="gmap_canvas"
                                src={
                                  "https://maps.google.com/maps?t=&z=13&ie=UTF8&iwloc=&output=embed&q=" +
                                  venue
                                }
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center align-self-center desktop-width">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-2 pl-0 pr-0">
                    <div className="content-detail">
                      <div className="">
                        <img
                          src={posterUri}
                          alt={poster}
                          width="100%"
                          className="img-poster"
                        />
                      </div>

                      <div className="col-lg-12 p-0 mt-4 pb-0">
                        <div className="pb-5">
                          <span className="bolddiv">Description</span>

                          <br />
                          <label className="mb-0 whiteSpace">
                            {description}
                          </label>
                          <br />
                          <br />
                          <span className="bolddiv">Venue</span>
                          <br />
                          {venue}
                          <br />
                          <br />
                          <div
                            class="mapouter"
                            style={{
                              position: "relative",
                              textAlign: "right",
                              height: "500",
                              width: "600",
                            }}
                          >
                            <div
                              class="gmap_canvas"
                              style={{
                                overflow: "hidden",
                                background: "none",
                                height: "500",
                                width: "100%",
                              }}
                            >
                              <iframe
                                width="100%"
                                height="500"
                                id="gmap_canvas"
                                src={
                                  "https://maps.google.com/maps?t=&z=13&ie=UTF8&iwloc=&output=embed&q=" +
                                  venue
                                }
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
          <div className="tickets">
            {localStorage.getItem("customerid") !== null ? (
              <div>
                {campaignStatus == "running" ? (
                  <div>
                    {this.tickets()} {string}
                    {fetchingTickets && (
                      <label className="mb-0 w-100 text-center small-details">
                        <ClipLoader color={"#39c"} />
                        <br /> Just a moment...
                      </label>
                    )}
                  </div>
                ) : (
                  <div className="warning-event">
                    This event is currently not active on our platform, come
                    back later and try again
                  </div>
                )}
              </div>
            ) : (
              <div className="row row-margin-bottom">
                <div className="col-12 pb-3 letter-head pl-0 pr-0">
                  Log in or Register before you can proceed
                </div>
                <div className="col-6 pl-0 pr-2">
                  <Link to="/login" className="link-btn">
                    <div className="text-center log-in-btn">Log In</div>
                  </Link>
                </div>

                <div className="col-6 pl-2 pr-0">
                  <Link to="/customer" className="link-btn">
                    <div className="text-center new-account-btn ">Register</div>
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div
            className="event-name justify-content-center"
            style={{ display: "flex" }}
          >
            <div
              className="backbtn event-title justify-content-center"
              style={{ display: "flex" }}
            >
              <div className="pt-1 pr-2">
                <Link
                  to={{
                    pathname: "/" + backpage,
                    state: {
                      province: province,
                      category: category,
                      accountid: accountid,
                      eventid: eventid,
                      eventDate: eventDate,
                      // const fulldate = this.props.location.state.fulldate;
                      customerid: customerid,
                      posterUri: posterUri,
                      accounttype: accounttype,
                      customername: customername,
                      email: email,
                      phone: phone,
                      venue: venue,
                      description: description,
                      businessid: businessid,
                      poster: poster,
                      eventname: eventname,
                      eventday: eventday,
                      starttime: starttime,
                      endtime: endtime,
                      previousPage: previousPage,
                      campaignStatus: campaignStatus,
                    },
                  }}
                  className="backArrow"
                  data-tip="Back"
                  data-effect="solid"
                  data-arrow-color="transparent"
                  data-type="dark"
                  data-place="top"
                >
                  <ReactTooltip />
                  <IoIosArrowBack size={40} className="Back-arrow-blue" />
                </Link>
              </div>
              <div className="text-center">
                {eventname}
                <br />
                <span style={{ fontSize: 13, color: "grey", fontWeight: 400 }}>
                  {eventday} @ {starttime}
                </span>
                {/* <br />
                <label className="small-details">
                  {this.state.liked == "yes" ? (
                    <BsHeartFill color={"#ffae19"} />
                  ) : (
                    <BsHeart color={"#ffae19"} />
                  )}{" "}
                  &nbsp;{this.state.likes} &nbsp;&nbsp;
                </label>{" "}
                {this.state.likeLabel} */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Event);
