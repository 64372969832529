import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Select from "react-select";
import {
  IoIosArrowBack,
  IoIosCheckmarkCircleOutline,
  IoIosRadioButtonOff,
} from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Header3 from "./components/Header3";
import TopBarProgress from "react-topbar-progress-indicator";
import NumberFormat from "react-number-format";
import { ImMan } from "react-icons/im";
import { FaChild } from "react-icons/fa";

class Downloadtickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      error: "",
      successError: "error-login",
      errorDisplay: "none",
      businessname: localStorage.getItem("businessname"),
      tickets: "",
      spinning: "none",
      updateDisplay: "block",
      eventtype: "",
      ticket_category: [],
      unchecked: true,
      checked: false,
      select: "item-select",
      ticketCount: 0,
      progressbar: false,
      prepareCount: 0,
      budget: 0,
      balance: 0,
      ticketPrint: 0,
      estimatedRevenue: 0,
      kids_ticket_print: 0,
      kids_estimated_revenue: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location === null) {
      return <Redirect to="/dashboard" />;
    }
  }

  componentDidMount() {
    let { eventid } = "";

    if (!this.props.location.state) {
      return <Redirect to="/dashboard" />;
    } else {
      eventid = this.props.location.state.eventid;
    }

    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    document.title = "Prepare tickets - Karamale ";
    const costs = this.state.ticketPrint * 1;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETBUDGET&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        budget: response.data.budget,
        balance: response.data.balance,
      });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PREPAREDOWNLOADS&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        prepareCount: response.data.prepareCount,
        ticketPrint: response.data.ticketPrint,
        estimatedRevenue: response.data.estimatedRevenue,
      });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TICKETSTATUS&eventid=" +
        eventid +
        "&businessid=" +
        businessid
    ).then((response) => {
      this.setState({ ticketCount: response.data.ticketCount });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PREPARETICKETS&eventid=" +
        eventid +
        "&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.data == 0) {
        this.setState({ ticket_category: [] });
      } else {
        this.setState({ ticket_category: response.data });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.ticketCount !== this.state.ticketCount ||
      prevState.prepareCount !== this.state.prepareCount ||
      prevState.estimatedRevenue !== this.state.estimatedRevenue
    ) {
      const eventid = this.props.location.state.eventid;

      const businessname = localStorage.getItem("businessname");
      const businessid = localStorage.getItem("businessid");
      document.title = "Prepare tickets - Karamale ";

      const costs = this.state.ticketPrint * 1;

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETBUDGET&eventid=" +
          eventid
      ).then((response) => {
        this.setState({
          budget: response.data.balance,
          balance: response.data.budget,
        });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PREPAREDOWNLOADS&eventid=" +
          eventid
      ).then((response) => {
        this.setState({
          prepareCount: response.data.prepareCount,
          ticketPrint: response.data.ticketPrint,
          estimatedRevenue: response.data.estimatedRevenue,
        });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=TICKETSTATUS&eventid=" +
          eventid +
          "&businessid=" +
          businessid
      ).then((response) => {
        this.setState({ ticketCount: response.data.ticketCount });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PREPARETICKETS&eventid=" +
          eventid +
          "&businessid=" +
          businessid
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({ ticket_category: [] });
        } else {
          this.setState({ ticket_category: response.data });
        }
      });
    }
  }

  increase = () => {
    const eventid = this.props.location.state.eventid;
    const eventname = this.props.location.state.eventname;
    const prevpage = this.props.location.state.prevpage;
    const currentpage = "editevent";
    const eventdate = this.props.location.state.eventdate;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;
    this.props.history.push({
      pathname: "campaign",
      state: {
        eventid: eventid,
        eventname: eventname,
        poster: poster,
        eventdate: eventdate,
        enddate: enddate,
        event_date: event_date,
        finish_date: finish_date,
        theme: theme,
        venue: venue,
        expectednum: expectednum,
        time: time,
        endtime: endtime,
        prevpage: prevpage,
        currentpage: currentpage,
      },
    });
  };

  handle_kids_tickets =
    (ticketid, category, instock, price, group) => (event) => {
      setTimeout(() => {
        this.setState({ errorDisplay: "none" });
      }, 4000);

      const eventid = this.props.location.state.eventid;
      if (parseInt(event.target.value) > parseInt(instock)) {
        this.setState({
          errorDisplay: "block",
          error: (
            <label className="mb-0">
              {instock == 0
                ? category + " tickets for kids are out of stock"
                : "Only " + instock + " tickets available for " + category}
            </label>
          ),
          successError: "error-login",
        });
      } else {
        this.setState({
          errorDisplay: "none",
          tickets: event.target.value,
        });

        Axios.get(
          "https://karamale.com/apps/kscanner/webservices.php?query=PREPARETICKETSDOWNLOAD&ticketid=" +
            ticketid +
            "&instock=" +
            instock +
            "&eventid=" +
            eventid +
            "&ticketsNumber=" +
            event.target.value +
            "&price=" +
            price +
            "&group=" +
            group
        ).then((response) => {
          this.setState({
            prepareCount: response.data.prepareCount,
            ticketPrint: response.data.ticketPrint,
            // estimatedRevenue: parseInt(response.data.estimatedRevenue),
            estimatedRevenue: parseInt(response.data.total_revenue),

            // kids_prepare_count: response.data.kids_prepare_count,
            kids_ticket_print: response.data.kids_ticket_print,
            kids_estimated_revenue: response.data.kids_estimated_revenue,
          });
        });
      }

      const eventname = this.props.location.state.eventname;
      const prevpage = this.props.location.state.prevpage;
      const currentpage = "editevent";
      const eventdate = this.props.location.state.eventdate;
      const poster = this.props.location.state.poster;
      const theme = this.props.location.state.theme;
      const venue = this.props.location.state.venue;
      const expectednum = this.props.location.state.expectednum;
      const enddate = this.props.location.state.enddate;
      const time = this.props.location.state.time;
      const endtime = this.props.location.state.endtime;
      const event_date = this.props.location.state.event_date;
      const finish_date = this.props.location.state.finish_date;

      this.props.history.push({
        pathname: "/downloadtickets",
        state: {
          eventid: eventid,
          eventname: eventname,
          poster: poster,
          eventdate: eventdate,
          enddate: enddate,
          event_date: event_date,
          finish_date: finish_date,
          theme: theme,
          venue: venue,
          expectednum: expectednum,
          time: time,
          endtime: endtime,
          prevpage: prevpage,
          currentpage: currentpage,
        },
      });
    };

  handleTickets = (ticketid, category, instock, price, group) => (event) => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 4000);

    const eventid = this.props.location.state.eventid;
    if (parseInt(event.target.value) > parseInt(instock)) {
      this.setState({
        errorDisplay: "block",
        error: (
          <label className="mb-0">
            {instock == 0
              ? category + " tickets for adults are out of stock"
              : "Only " + instock + " tickets available for " + category}
          </label>
        ),
        successError: "error-login",
      });
    } else {
      this.setState({
        errorDisplay: "none",
        tickets: event.target.value,
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PREPARETICKETSDOWNLOAD&ticketid=" +
          ticketid +
          "&instock=" +
          instock +
          "&eventid=" +
          eventid +
          "&ticketsNumber=" +
          event.target.value +
          "&price=" +
          price +
          "&group=" +
          group
      ).then((response) => {
        this.setState({
          prepareCount: response.data.prepareCount,
          ticketPrint: response.data.ticketPrint,
          // estimatedRevenue: parseInt(response.data.estimatedRevenue),
          estimatedRevenue: parseInt(response.data.total_revenue),

          // kids_prepare_count: response.data.kids_prepare_count,
          kids_ticket_print: response.data.kids_ticket_print,
          kids_estimated_revenue: response.data.kids_estimated_revenue,
        });
      });
    }

    const eventname = this.props.location.state.eventname;
    const prevpage = this.props.location.state.prevpage;
    const currentpage = "editevent";
    const eventdate = this.props.location.state.eventdate;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;

    this.props.history.push({
      pathname: "/downloadtickets",
      state: {
        eventid: eventid,
        eventname: eventname,
        poster: poster,
        eventdate: eventdate,
        enddate: enddate,
        event_date: event_date,
        finish_date: finish_date,
        theme: theme,
        venue: venue,
        expectednum: expectednum,
        time: time,
        endtime: endtime,
        prevpage: prevpage,
        currentpage: currentpage,
      },
    });
  };

  handleChange = (e) => {
    this.setState({ eventtype: e.value });
  };

  selected = (ticketid, checked) => {
    this.setState({ progressbar: true });

    const eventid = this.props.location.state.eventid;
    const eventname = this.props.location.state.eventname;
    const prevpage = this.props.location.state.prevpage;
    const currentpage = "editevent";
    const eventdate = this.props.location.state.eventdate;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MARKTICKETCATEGORY&ticketid=" +
        ticketid +
        "&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        ticketCount: response.data.ticketCount,
        ticketPrint: response.data.ticketPrint,
        prepareCount: response.data.prepareCount,
        progressbar: false,
      });
    });

    this.props.history.push({
      pathname: "/downloadtickets",
      state: {
        eventid: eventid,
        eventname: eventname,
        poster: poster,
        eventdate: eventdate,
        enddate: enddate,
        event_date: event_date,
        finish_date: finish_date,
        theme: theme,
        venue: venue,
        expectednum: expectednum,
        time: time,
        endtime: endtime,
        prevpage: prevpage,
        currentpage: currentpage,
      },
    });
  };

  tickets = () => {
    const { unchecked, checked } = this.state;

    return (
      <div className="row justify-content-center">
        {this.state.ticket_category.map((item, index) => {
          return (
            <div className="col-12">
              <div className="col-12 mb-3 text-right p-0">
                {item.checked == 1 ? (
                  <span>
                    <label className="mb-0 small-details font-size-13">
                      <span>ZAR {item.ticket_price}</span> - How many{" "}
                      {item.category == "general"
                        ? "Gen"
                        : item.category == "vip"
                        ? "VIP"
                        : "VVIP"}
                      s ? <ImMan color={"darkorange"} />
                    </label>
                    <input
                      type="number"
                      style={{
                        width: 150,
                        height: 40,
                      }}
                      className="edit-input ml-2"
                      placeholder={"Max of " + item.instock}
                      autoFocus
                      onChange={this.handleTickets(
                        item.ticketid,
                        item.category,
                        item.instock,
                        item.ticket_price,
                        "adults"
                      )}
                      defaultValue={
                        item.executed == 1
                          ? ""
                          : item.ticket_number < 1
                          ? ""
                          : item.ticket_number
                      }
                    />
                    <br />
                    <label className="small-details font-size-13">
                      Estimated revenue: ZAR{" "}
                      {item.executed == 1
                        ? 0.0
                        : item.ticket_number == 0
                        ? 0
                        : item.revenues}
                    </label>
                    <br />
                    <label className="mb-0 small-details font-size-13">
                      <span>ZAR {item.kids_ticket_price}</span> - How many{" "}
                      {item.category == "general"
                        ? "Gen"
                        : item.category == "vip"
                        ? "VIP"
                        : "VVIP"}
                      s ? <FaChild color={"#39c"} />
                    </label>
                    <input
                      type="number"
                      style={{
                        width: 150,
                        height: 40,
                      }}
                      className="edit-input ml-2"
                      placeholder={"Max of " + item.kids_instock}
                      autoFocus
                      onChange={this.handle_kids_tickets(
                        item.ticketid,
                        item.category,
                        item.kids_instock,
                        item.kids_ticket_price,
                        "kids"
                      )}
                      defaultValue={
                        item.kids_executed == 1
                          ? ""
                          : item.kids_ticket_number < 1
                          ? ""
                          : item.kids_ticket_number
                      }
                    />
                    <br />
                    <label className="small-details font-size-13">
                      Estimated revenue: ZAR{" "}
                      {item.kids_executed == 1
                        ? 0.0
                        : item.kids_ticket_number == 0
                        ? 0
                        : item.kids_revenues}
                    </label>
                    <br />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                className={
                  item.checked == 1
                    ? "item-select-active border-1 mb-3 w-100"
                    : "item-select border-1 mb-3 w-100"
                }
                onClick={() => this.selected(item.ticketid, item.checked)}
              >
                <div className="link-btn w-100 menu-links">
                  <div className="col-12 pl-3 pr-3">
                    <label className="w-100 mb-0">
                      <div className="row align-items-center">
                        <div className="col-lg-1 col-2 col-sm-2 col-md-1 pl-0">
                          {item.checked == 1 ? (
                            <label className="mb-0">
                              <IoIosCheckmarkCircleOutline
                                size={30}
                                className="color-blue"
                              />
                            </label>
                          ) : (
                            <label className="mb-0">
                              <IoIosRadioButtonOff
                                size={30}
                                className="color-blue"
                              />
                            </label>
                          )}
                        </div>
                        <div className="col-lg-11 col-10 col-sm-10 col-md-11 pl-0 pr-0">
                          {item.category == "general" ? (
                            <span>General</span>
                          ) : item.category == "vip" ? (
                            <span>VIP</span>
                          ) : (
                            <span>VVIP</span>
                          )}

                          <br />
                          <label className="small-details mb-0">
                            <ImMan color={"darkorange"} /> Loaded:{" "}
                            {item.initial_load}
                            <br />
                            <FaChild color={"#39c"} /> Loaded:{" "}
                            {item.kids_initial_load}
                          </label>
                          <label
                            className={
                              "float-right mb-0 small-details text-right"
                            }
                          >
                            {item.instock} in stock{" "}
                            <ImMan color={"darkorange"} />
                            <br />
                            {item.kids_instock} in stock{" "}
                            <FaChild color={"#39c"} />
                          </label>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  prepare = () => {
    const eventid = this.props.location.state.eventid;
    const ticketLimit = this.state.ticketPrint;
    const costs = this.state.ticketPrint * 1;
    const budget = this.state.balance;
    const balance = this.state.budget;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=LOADTEMPORARYTICKETS&eventid=" +
        eventid +
        "&ticketLimit=" +
        ticketLimit +
        "&reprintNumber=" +
        this.state.ticketPrint
    ).then((response) => {
      localStorage.setItem("ticketLimit", this.state.ticketPrint);
      localStorage.setItem("ticketPrint", this.state.ticketPrint);
      localStorage.setItem("estimatedRevenue", this.state.estimatedRevenue);
      localStorage.setItem("costs", costs.toFixed(1));
      localStorage.setItem("budget", budget);
      localStorage.setItem("balance", balance);

      this.props.history.push({
        pathname: "/ticketdownload",
      });
    });
  };

  close = () => {
    this.setState({ errorDisplay: "none" });
  };

  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
      if (localStorage.getItem("download") === null) {
        return <Redirect to="/dashboard" />;
      }
    }
    const businessname = localStorage.getItem("businessname");
    const { progressbar } = this.state;
    let { prevpage, costs } = "";

    if (!this.props.location.state) {
      return <Redirect to="/dashboard" />;
    } else {
      prevpage = this.props.location.state.prevpage;
      costs = this.state.ticketPrint * 1;
    }

    return (
      <div className="view">
        <Header3 />
        {progressbar && <TopBarProgress />}
        <div className="container">
          <div className="row justify-content-center just-container-insid pb-5">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-5 pl-0 pr-0">
                      <Link
                        to={{
                          pathname: prevpage,
                          state: {
                            prevpage: prevpage,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-7 text-right pr-2">
                      <label className="back-arrow mb-0">
                        {this.state.ticketPrint > 0 &&
                        costs < this.state.budget ? (
                          <div
                            className="save-btn btn-radius-3"
                            onClick={() => this.prepare()}
                            style={{ width: 170 }}
                          >
                            <span
                              style={{
                                display: this.state.updateDisplay,
                              }}
                            >
                              {" "}
                              {/* Prepare {this.state.ticketPrint} tickets */}
                              Prepare for download
                            </span>
                            <span
                              style={{
                                display: this.state.spinning,
                              }}
                            >
                              <ClipLoader
                                color={"#fff"}
                                loading={true}
                                // css={override}
                                size={20}
                              />
                            </span>
                          </div>
                        ) : (
                          <label class="back-arrow float-right mb-0">
                            <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                              <span>Prepare</span>
                            </div>
                          </label>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {parseInt(costs) > parseInt(this.state.budget) && costs > 0 ? (
                <div className="col-12 p-0 mb-2 color-red">
                  Your balance is too low to print {this.state.ticketPrint}{" "}
                  tickets.{" "}
                  <label
                    className="mb-0 color-blue"
                    onClick={() => this.increase()}
                  >
                    Increase budget
                  </label>{" "}
                  or reduce the number.
                </div>
              ) : (
                ""
              )}

              <div className=" BoxDashboardContainer pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        PREPARE TICKETS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body pl-0 pr-0">
                  <div className="col-lg-12 details-body mb-5">
                    <span>Event</span>
                    <br />
                    <span className="small-details details-body">
                      {localStorage.getItem("eventname")}
                    </span>
                    <br />
                    <br />

                    <label className="mb-0 float-right text-right">
                      <label className="text-bold">
                        Budget:{" "}
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={this.state.balance}
                          prefix="ZAR "
                          decimalSeparator="."
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                        <br />
                        Balance:{" "}
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={this.state.budget}
                          prefix="ZAR "
                          decimalSeparator="."
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </label>
                      <br />
                      Cost per ticket download:{" "}
                      <label className="small-details"> ZAR 1.0</label>
                      <br />
                      Quantity:{" "}
                      <label className="small-details">
                        {" "}
                        {this.state.ticketPrint}
                      </label>
                      <br />
                      Total costs:{" "}
                      <label className="small-details">
                        {" "}
                        ZAR {costs.toFixed(1)}
                      </label>
                      <br />
                      Estimated revenue:{" "}
                      <label className="small-details">
                        {" "}
                        ZAR {this.state.estimatedRevenue}
                      </label>
                    </label>
                  </div>
                  <br />

                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="col-12">
                    <label className="mb-0 small-details color-black">
                      Choose category
                    </label>
                  </div>
                  {this.tickets()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
          onclick={() => this.close()}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Downloadtickets;
