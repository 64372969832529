import React from "react";
import "./App.css";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "./components/Footer";
import Header from "./components/Header";

// export default withRouter (Redirect);

class Verification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verification: "",
      error: "",
      errorDisplay: "none",
      spinning: "none",
      updateDisplay: "block",
    };
  }

  componentDidMount() {
    document.title = "Verifying account - Karamale";
    // alert(this.props.location.state.accounttype);
  }

  handleVerify = (event) => {
    this.setState({
      verification: event.target.value,
      errorDisplay: "none",
    });
  };

  verify = () => {
    this.setState({ updateDisplay: "none", spinning: "block" });
    const email = this.props.location.state.email;
    const password = this.props.location.state.password;
    const phone = this.props.location.state.phone;
    const verification = this.state.verification;
    const accounttype = this.props.location.state.accounttype;

    if (verification.trim() === "") {
      this.setState({
        error: "Enter the verification code",
        errorDisplay: "block",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      if (accounttype == "customer") {
        const fname = this.props.location.state.fname;
        const lname = this.props.location.state.lname;
        const customername = fname + " " + lname;
        Axios.get(
          "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERREGISTRATION&customername=" +
            customername +
            "&phone=" +
            phone +
            "&email=" +
            email +
            "&password=" +
            password +
            "&verificationCode=" +
            verification
        )
          .then((response) => {
            if (response.data.val == 1) {
              localStorage.setItem("customerid", response.data.customerid);
              localStorage.setItem("customername", customername);
              localStorage.setItem("accounttype", accounttype);
              this.props.history.push({
                pathname: "/customerdashboard",
                state: {
                  accounttype: accounttype,
                  accountid: response.data.customerid,
                  customername: customername,
                },
              });
            } else {
              this.setState({
                error: response.data.error,
                errorDisplay: "block",
                spinning: "none",
                updateDisplay: "block",
              });
            }
          })
          .catch((error) => console.log(error));
      } else if (accounttype == "business") {
        const businessname = this.props.location.state.business;
        let affiliateid = "";
        if (localStorage.getItem("affiliateid")) {
          affiliateid = "&affiliateid=" + localStorage.getItem("affiliateid");
        } else {
          affiliateid = "";
        }
        Axios.get(
          "https://karamale.com/apps/kscanner/webservices.php?query=BUSINESSREGISTRATION&businessname=" +
            businessname +
            "&phone=" +
            phone +
            "&email=" +
            email +
            "&password=" +
            password +
            "&verificationCode=" +
            verification +
            affiliateid
        )
          .then((response) => {
            if (response.data.val == 1) {
              localStorage.setItem("businessid", response.data.businessid);
              localStorage.setItem("businessname", businessname);
              localStorage.setItem("accounttype", accounttype);
              localStorage.removeItem("affiliateid");
              this.props.history.push({
                pathname: "/dashboard",
                state: {
                  accounttype: accounttype,
                  accountid: response.data.businessid,
                  businessname: businessname,
                },
              });
            } else {
              // alert(response.data.error);
              this.setState({
                error: response.data.error,
                errorDisplay: "block",
                spinning: "none",
                updateDisplay: "block",
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        const businessname = this.props.location.state.business;
        const entity = localStorage.getItem("entity");

        Axios.get(
          "https://karamale.com/apps/kscanner/webservices.php?query=PARTNERREGISTRATION&partnername=" +
            businessname +
            "&phone=" +
            phone +
            "&email=" +
            email +
            "&password=" +
            password +
            "&verificationCode=" +
            verification +
            "&entity=" +
            entity
        )
          .then((response) => {
            if (response.data.val == 1) {
              localStorage.setItem("partnerid", response.data.partnerid);
              localStorage.setItem("partnername", businessname);
              localStorage.setItem("accounttype", accounttype);
              this.props.history.push({
                pathname: "/bdetails",
                state: {
                  accounttype: accounttype,
                  accountid: response.data.partnerid,
                  partnername: businessname,
                },
              });
            } else {
              this.setState({
                error: response.data.error,
                errorDisplay: "block",
                spinning: "none",
                updateDisplay: "block",
              });
            }
          })
          .catch((error) => console.log(error));
      }
    }
  };

  render() {
    if (!localStorage.getItem("verificationAccount")) {
      return <Redirect to="/register" />;
    }

    const email = this.props.location.state.email;
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center login-screen-container w-100">
            <div className="col-lg-5 col-12 align-self-center login-container-adjust">
              <div className="log-title login-logo text-center">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" />
                </Link>
              </div>
              <div className="col-12 pl-0 pr-0">
                <div className="BoxDashboardContainer">
                  <input
                    type="text"
                    placeholder="Enter verification code"
                    value={this.state.verification}
                    onChange={this.handleVerify}
                  />
                  <div className="col-12 response textMedium pl-0">
                    {this.state.response}
                  </div>
                  <button onClick={() => this.verify()}>
                    <span
                      style={{
                        display: this.state.updateDisplay,
                      }}
                    >
                      Verify
                    </span>
                    <span
                      style={{
                        display: this.state.spinning,
                      }}
                    >
                      <ClipLoader
                        color={"#fff"}
                        loading={true}
                        // css={override}
                        size={20}
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div className="text-footer text-center">
                We've sent the <span>verification code</span> to
                <br /> {email}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Verification;
