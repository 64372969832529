import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Select from "react-select";
import { IoIosArrowBack } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Header3 from "./components/Header3";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

class Addevent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      businessname: localStorage.getItem("businessname"),
      eventname: "",
      eventdate: "",
      eventid: "",
      poster: "",
      theme: "",
      venue: "",
      expectednum: "",
      enddate: "",
      time: "",
      endtime: "",
      event_date: "",
      finish_date: "",
      description: "",
      spinning: "none",
      updateDisplay: "block",
      eventtype: "",
      provinces: "",
    };
  }

  componentDidMount() {
    const eventid = this.props.location.state.eventid;

    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    document.title = "Add new event / Karamale ";
  }

  handleEventname = (event) => {
    this.setState({ eventname: event.target.value, errorDisplay: "none" });
  };

  handleVenue = (event) => {
    this.setState({
      venue: event.target.value,
      errorDisplay: "none",
    });
  };

  handleTheme = (event) => {
    this.setState({
      theme: event.target.value,
      errorDisplay: "none",
    });
  };

  handleExpected = (event) => {
    this.setState({
      expectednum: event.target.value,
      errorDisplay: "none",
    });
  };

  handleDate = (event) => {
    this.setState({
      event_date: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEnddate = (event) => {
    this.setState({
      finish_date: event.target.value,
      errorDisplay: "none",
    });
  };

  handleTime = (event) => {
    this.setState({
      time: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEndtime = (event) => {
    this.setState({
      endtime: event.target.value,
      errorDisplay: "none",
    });
  };

  handleDescription = (event) => {
    this.setState({
      description: event.target.value,
      errorDisplay: "none",
    });
  };

  handleChange = (e) => {
    this.setState({ eventtype: e.value });
  };

  handleProvinces = (e) => {
    this.setState({ provinces: e.value });
  };

  update = () => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 3000);
    const businessid = localStorage.getItem("businessid");
    const eventname = this.state.eventname;
    const venue = this.state.venue;
    const theme = this.state.theme;
    const expectednum = this.state.expectednum;
    const time = this.state.time;
    const endtime = this.state.endtime;
    const description = this.state.description;
    const finish_date = this.state.finish_date;
    const event_date = this.state.event_date;
    const eventtype = this.state.eventtype;
    const province = this.state.provinces;
    this.setState({ updateDisplay: "none", spinning: "block" });
    // alert(venue);

    if (eventtype === "") {
      this.setState({
        error: "Please select event type",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (province === "") {
      this.setState({
        error: "Please select the hosting province",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.eventname.trim() === "") {
      this.setState({
        error: "Please enter event name",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.venue.trim() === "") {
      this.setState({
        error: "Please enter venue",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (theme.trim() === "") {
      this.setState({
        error: "Please enter a theme",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.expectednum.trim() === "") {
      this.setState({
        error: "Enter number of people expected to attended",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (event_date === "") {
      this.setState({
        error: "Enter the event date",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (finish_date === "") {
      this.setState({
        error: "Enter the event end date",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.time.trim() === "") {
      this.setState({
        error: "Enter event time",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.endtime.trim() === "") {
      this.setState({
        error: "Enter event end time",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.description.trim() === "") {
      this.setState({
        error: "Enter description",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=ADDEVENT&subquery=ADDEVENT&visa=1&cash=0&accountid=" +
          businessid +
          "&event=" +
          eventname +
          "&venue=" +
          venue +
          "&theme=" +
          theme +
          "&expected=" +
          expectednum +
          "&startdate=" +
          event_date +
          "&enddate=" +
          finish_date +
          "&starttime=" +
          time +
          "&endtime=" +
          endtime +
          "&description=" +
          description +
          "&eventtype=" +
          eventtype +
          "&province=" +
          province
      )
        .then((response) => {
          if (response.data.val == 1) {
            this.props.history.push("/myevents");
          } else {
            // alert(accountype);
            this.setState({
              error: response.data.error,
              errorDisplay: "block",
              successError: "error-login",
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  close = () => {
    this.setState({ errorDisplay: "none" });
  };

  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const businessname = localStorage.getItem("businessname");
    const { eventtype, provinces } = this.state;

    const data = [
      { label: "Beauty pageant", value: "beauty pageant" },
      { label: "Fashion show", value: "Fashion Show" },
      { label: "Product launch", value: "product launch" },
      { label: "Baby shower", value: "baby shower" },
      { label: "Bachelor party", value: "bachelor party" },
      { label: "Birth day celebration", value: "birth day celebration" },
      { label: "Music festival", value: "music festival" },
      { label: "Food festival", value: "food festival" },
      { label: "Street party", value: "street party" },
      { label: "Festival", value: "festival" },
      { label: "PS5", value: "PS5" },
      { label: "Religious", value: "religious" },
      { label: "Music concert", value: "music concert" },
      { label: "Business gathering", value: "business gathering" },
      { label: "Networking", value: "networking" },
      { label: "Movie night", value: "movie night" },
      { label: "color frenzy", value: "color frenzy" },
      { label: "Culture and tradition", value: "culture and tradition" },
      { label: "Tea party", value: "tea party" },
      { label: "Team building", value: "team building" },
      { label: "Woman empowerment", value: "woman empowerment" },
      { label: "Man empowerment", value: "man empowerment" },
      { label: "Conference", value: "conference" },
      { label: "Other", value: "other" },
    ];

    const province = [
      { label: "Eastern Cape", value: "eastern cape" },
      { label: "Free State", value: "free state" },
      { label: "Gauteng", value: "gauteng" },
      { label: "KwaZulu-Natal", value: "kwaZulu-natal" },
      { label: "Limpopo", value: "limpopo" },
      { label: "Mpumalanga", value: "mpumalanga" },
      { label: "Northern Cape", value: "northern cape" },
      { label: "North West", value: "north west" },
      { label: "Western Cape", value: "western cape" },
    ];
    const prevpage = this.props.location.state.prevpage;
    return (
      <div className="view">
        <Header3 />
        <div className="container">
          <div className="row justify-content-center just-container-insid pb-5">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={{
                          pathname: prevpage,
                          state: {
                            prevpage: prevpage,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          <span
                            style={{
                              display: this.state.updateDisplay,
                            }}
                          >
                            {" "}
                            Create
                          </span>
                          <span
                            style={{
                              display: this.state.spinning,
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        CREATE AN EVENT AND START SELLING TICKETS RIGHT AWAY
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  What kind of an event will this be?
                  <br />
                  <Select
                    value={data.find((obj) => obj.value === eventtype)}
                    placeholder="Select event type"
                    options={data}
                    onChange={this.handleChange}
                    width={"100%"}
                    className="text-left mt-2"
                  />
                  <br />
                  Hosting hosting province
                  <br />
                  <Select
                    value={province.find((obj) => obj.value === provinces)}
                    placeholder="Select event type"
                    options={province}
                    onChange={this.handleProvinces}
                    width={"100%"}
                    className="text-left mt-2"
                  />
                  <br />
                  Event name
                  <br />
                  <input
                    type="text"
                    placeholder="Event name"
                    value={this.state.eventname}
                    onChange={this.handleEventname}
                    className="edit-input"
                  />
                  <br /> <br />
                  <label>Venue</label>
                  <br />
                  {/* <GooglePlacesAutocomplete
                    apiKey="AIzaSyCwSxFxWtGR10TG5BzL-Z4Ug-fFADI7vtM"
                    styles={{
                      textInputContainer: {
                        backgroundColor: "grey",
                      },
                      textInput: {
                        height: 38,
                        color: "#5d5d5d",
                        fontSize: 16,
                      },
                    }}
                    textInputProps={{
                      onChange: (text) => {
                        this.handleVenue(text);
                      },
                    }}
                    selectProps={{
                      onChange: (data, details = null) =>
                        this.setState({ venue: data["value"]["description"] }),
                      fetchDetails: true,
                      placeholder: "Enter address or zip code",
                      // defaultInputValue: this.state.venue,
                    }}
                  /> */}
                  <input
                    type="text"
                    placeholder="Venue"
                    value={this.state.venue}
                    onChange={this.handleVenue}
                    className="edit-input"
                  />
                  <br /><br />
                  Theme
                  <br />
                  <input
                    type="text"
                    placeholder="Theme"
                    value={this.state.theme}
                    onChange={this.handleTheme}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Number of people expected to attend
                  <br />
                  <input
                    type="text"
                    placeholder="Number of expected to attend"
                    value={this.state.expectednum}
                    onChange={this.handleExpected}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Date
                  <br />
                  <input
                    type="date"
                    placeholder="Set date"
                    value={this.state.event_date}
                    onChange={this.handleDate}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  End date
                  <br />
                  <input
                    type="date"
                    placeholder="End date"
                    value={this.state.finish_date}
                    onChange={this.handleEnddate}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Time
                  <br />
                  <input
                    type="time"
                    placeholder="Set time"
                    value={this.state.time}
                    onChange={this.handleTime}
                    className="edit-input w-100"
                  />
                  <br />
                  <br />
                  End time
                  <br />
                  <input
                    type="time"
                    placeholder="End time"
                    value={this.state.endtime}
                    onChange={this.handleEndtime}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Description
                  <br />
                  <textarea
                    placeholder="Description"
                    value={this.state.description}
                    onChange={this.handleDescription}
                    className="edit-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
          onclick={() => this.close()}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Addevent;
