import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";

import Header from "./components/Header";

// export default withRouter (Redirect);

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Privacy policy - Karamale";
  }

  render() {
    if (localStorage.getItem("customername") !== null) {
      return <Redirect to="/customerdashboard" />;
      //   return exit(1);
    } else {
    }
    return (
      <div className="view">
        <Header />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-10 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12">
                    <span className="">
                      <h3>Privacy policy.</h3>
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-selec">
                    <div className="col-12">
                      <div className="item-name">
                        <h5>Introduction:</h5> This website
                        <p>
                          (https://karamale.com) is owned and operated by{" "}
                          <b>Karamale Pty Ltd.</b> The Website is designed to
                          enable event organisers to sell tickets for their
                          events to the general public (Customers).
                        </p>
                        <p>
                          <b>Eligibility:</b> The website is open to event
                          organizers who are at least 18 years old and have the
                          legal right to sell tickets to their events.
                        </p>
                        <p>
                          <b>User Account:</b> The event organizer is
                          responsible for creating a user account and keeping
                          their account information up-to-date.
                        </p>
                        <p>
                          <b>Listing and Selling of Tickets:</b>
                          The event organizer is responsible for listing the
                          event and selling tickets through the website. The
                          event organizer agrees to sell tickets at the price
                          and with the restrictions listed on the website.
                        </p>
                        <p>
                          <b>Use of the Website:</b> Organizers may use the
                          Website to sell tickets to their events (Events). The
                          Website is a platform for Organizers to sell tickets
                          to their Events, and the Organizers are solely
                          responsible for the Events, including, but not limited
                          to, the content, quality, safety, and legality of the
                          Events.
                        </p>
                        <p>
                          <b>Event Information:</b> Organizers must provide
                          accurate and complete information about their Events,
                          including, but not limited to, the date, poster,
                          location, and description of the Event.{" "}
                          <b>Karamale Pty Ltd</b> reserves the right to remove
                          any Event that does not comply with these terms and
                          conditions.
                        </p>
                        <p>
                          <b>Payment and Processing Fees:</b>
                          <ul>
                            <li>
                              The Organiser agrees to pay Karamale Pty Ltd a
                              Budget/Campaign fee to prepare (activate) the
                              event to go live, Karamale Pty Ltd will charge a
                              percentage from this budget every time a ticket is
                              sold through the website.
                            </li>
                            <li>
                              The Organiser acknowledges and agrees that
                              Karamale Pty Ltd is not responsible for any fees
                              or charges incurred by the Customer in connection
                              with the purchase of tickets through the Website,
                              including but not limited to, transaction fees,
                              credit card fees, and other fees.
                            </li>
                            <li>
                              Karamale Pty Ltd will process payments for ticket
                              sales and will remit the ticket price to the event
                              organizer. The event organizer agrees to provide
                              accurate information for payment processing.
                            </li>
                          </ul>
                        </p>
                        <b>Liability and Indemnification:</b>
                        <ul>
                          <li>
                            The Organiser acknowledges and agrees that Karamale
                            Pty Ltd is not responsible for any loss or damage
                            resulting from the use of the Website, including but
                            not limited to, the sale of tickets for Events, the
                            purchase of tickets by Customers, and the attendance
                            of Customers at Events.
                          </li>
                          <li>
                            The Organiser agrees to indemnify and hold harmless
                            Karamale Pty Ltd and its affiliates, employees,
                            agents, and representatives from any and all claims,
                            damages, losses, and expenses (including reasonable
                            legal fees) arising out of the Organiser's use of
                            the Website, including but not limited to, the sale
                            of tickets for Events and the attendance of
                            Customers at Events.
                          </li>
                        </ul>
                        <b>Physical tickets:</b>
                        <ul>
                          <li>
                            Physical tickets are also subjected to
                            Budget/Campaign fee, the number of tickets to be
                            printed are heavily depended on the budget
                            (https://karamale.com/fees) fed to the website.
                          </li>
                          <li>
                            The system generates and prepares tickets for
                            printing based on not only the information about the
                            event but also on the budget
                            (https://karamale.com/fees) allocated by the
                            organizer.
                          </li>
                          <li>
                            As an organiser; you can either collect the tickets
                            or request a delivery in which a delivery fee might
                            be applicable.
                          </li>
                          <li>
                            The organiser acknowledges that ones the tickets are
                            printed out; the information on the tickets will
                            stick and cannot be changed, <b>Karamale Pty Ltd</b>{" "}
                            cannot be held accountable for any incorrect
                            information shown on the tickets, therefore it is
                            important to provide accurate information about the
                            event before requesting a print out
                          </li>
                        </ul>
                        <p>
                          <b>Data collection:</b>
                          The data that is collected through the purchase of
                          tickets, account creation, ticket scanning together
                          with other data endpoints is owned by Karamale Pty
                          Ltd, and the company reserve the right to use the data
                          in any way it sees fit for the betterment of the
                          service, this may include but not limited to marketing
                          upcoming events to the existing network, requesting
                          feedback from ticket buyers: such as rating the
                          service and user experience, or rating events they
                          attended.
                        </p>
                        <p>
                          <b>Taxes:</b> The event organizer is responsible for
                          paying any taxes on ticket sales, including sales tax
                          and any other applicable taxes.
                        </p>
                        <p>
                          <b>Cancellation and Refunds:</b> The event organizer
                          agrees to provide refunds in accordance with the event
                          cancellation and refund policy. The event organizer is
                          responsible for processing refunds and resolving any
                          disputes with ticket buyers.
                        </p>
                        <p>
                          <b>Listing Content:</b> The event organizer agrees to
                          provide accurate and complete information about their
                          event, including event date, time, location, and
                          ticket price.
                        </p>
                        <p>
                          <b>Proprietary Rights:</b> The event organizer
                          acknowledges that the website and its content are
                          protected by copyright, trademark, and other
                          proprietary rights.
                        </p>
                        <p>
                          <b>Termination:</b> The Organiser acknowledges and
                          agrees that <b>Karamale Pty Ltd</b> has the right to
                          terminate the Organiser's use of the Website at any
                          time, without notice and for any reason, including but
                          not limited to, a breach of these Terms.
                        </p>
                        <p>
                          <b>Entire Agreement:</b> These Terms constitute the
                          entire agreement between the Organiser and{" "}
                          <b>Karamale Pty Ltd</b> regarding the use of the
                          Website and supersedes all prior understandings,
                          communications, and agreements between the parties.
                        </p>
                        <p>
                          <b>Disclaimer of Warranties:</b>{" "}
                          <b>Karamale Pty Ltd</b> provides the service "as is"
                          and makes no representations or warranties of any
                          kind, express or implied, as to the operation of the
                          service or the information, content, materials, or
                          products included on the service.
                        </p>
                        <p>
                          <b>Limitation of liability:</b>{" "}
                          <b>Karamale Pty Ltd</b>
                          will not be liable for any damages of any kind arising
                          from the use of the service, including, but not
                          limited to, direct, indirect, incidental, punitive,
                          and consequential damages.
                        </p>
                        <p>
                          <b>Dispute Resolution:</b> Any disputes arising from
                          the use of the website will be resolved through
                          communication between the event organiser and
                          <b>Karamale Pty Ltd</b>, failing to reach a
                          resolution, disputes may be resolved through
                          arbitration in accordance with the rules of the South
                          Africa Arbitration Association.
                        </p>
                        <p>
                          <b>
                            Changes to Terms and Conditions: Karamale Pty Ltd
                          </b>{" "}
                          reserves the right to modify these terms and
                          conditions at any time without prior notice. The event
                          organizer is responsible for regularly reviewing these
                          terms and conditions.
                        </p>
                        <p>
                          <b>Acceptance of Terms:</b> By using <b>Karamale</b>,
                          the event organizer agrees to be bound by these terms
                          and conditions.
                        </p>
                        <p>
                          <b>Contact Information:</b> If Organizers have any
                          questions or concerns about these terms and
                          conditions, they may contact the Website at
                          [info@karamale.com].
                        </p>
                        <b>Visit the website:</b> https://karamale.com <br />
                        <b>Learn more about Karamale:</b>{" "}
                        https://karamale.com/aboutkaramale <br />
                        <b>Learn how the budget works:</b>{" "}
                        https://karamale.com/fees <br />
                        <b>Use our calculator for your budget:</b>{" "}
                        https://karamale.com/budget
                        <br />
                        <br />
                        <label style={{ fontSize: 12, color: "grey" }}>
                          <b>Footnote:</b> Karamale prides itself in all the
                          innovations it worked on and continues to work on, our
                          mission is to conveniently connect organisers with
                          ticket buyers through the power of technology. We
                          welcome feedback from all spheres of the industry, and
                          we invite you to share with us ideas on how we can
                          make Karamale a ticket software for change, after all;
                          you know the industry better than we do; therefore
                          having a festival and an exchange of ideas will assist
                          us in ushering new technology that will transform the
                          industry for ages to come.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Privacy;
