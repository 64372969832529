import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import Header2 from "./components/Header2";
import { v4 as uuidv4 } from "uuid";
import TopBarProgress from "react-topbar-progress-indicator";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Likes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      likes: [],
      email: "",
      phone: "",
      customername: "",
      loadBarProgress: true,
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    document.title = "Likes - " + customername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
        customerid
    ).then((response) => {
      this.setState({
        email: response.data.email,
        phone: response.data.phone,
        customername: response.data.customername,
      });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=LIKES&subquery=GETLIKES&customerid=" +
        customerid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ likes: [] });
        } else {
          this.setState({ likes: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  likes = () => {
    let activeString = "";
    const customerid = localStorage.getItem("customerid");
    const customername = localStorage.getItem("customername");
    const accounttype = localStorage.getItem("accounttype");
    let event = "";

    var newDate = new Date();
    let date = newDate.getDate();
    if (date < 10) {
      date = "0" + date;
    } else {
      date = date;
    }
    let month = newDate.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    var year = newDate.getFullYear();
    var fulldate = year + "-" + month + "-" + date;
    let assignClass = "";

    return (
      <div>
        {this.state.likes.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.likes.map((item, index) => {
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            {
              item.eventname.length > 20
                ? (event = item.eventname.substring(0, 20) + "...")
                : (event = item.eventname);
            }

            {
              item.campaignstatus == "running"
                ? (assignClass = "item-select")
                : (assignClass = "disabledBar");
            }

            const posterUri =
              "https://karamale.com/apps/kscanner/posters/" +
              item.businessid +
              "/" +
              item.eventid +
              "/" +
              item.poster;

            const poster =
              "https://karamale.com/apps/kscanner/posters/" +
              item.businessid +
              "/" +
              item.eventid +
              "/" +
              item.poster;

            return (
              <div className={"col-lg-12 p-0"}>
                <div className={assignClass + " w-100 pr-0 pl-0 pb-2 pt-3"}>
                  <Link
                    to={{
                      pathname: "/event",
                      search:
                        "q=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&pageid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&eventid=" +
                        item.eventid +
                        "&eventDate=" +
                        item.event_day +
                        "&fulldate=" +
                        fulldate +
                        "&customerid=" +
                        localStorage.getItem("customerid") +
                        "&posterUri=" +
                        posterUri +
                        "&accountid=" +
                        localStorage.getItem("customerid") +
                        "&accounttype=" +
                        localStorage.getItem("accounttype") +
                        "&customername=" +
                        this.state.customername +
                        "&email=" +
                        this.state.email +
                        "&phone=" +
                        this.state.phone +
                        "&venue=" +
                        item.event_location +
                        "&description=" +
                        item.event_des +
                        "&businessid=" +
                        item.businessid +
                        "&poster=" +
                        item.poster +
                        "&eventname=" +
                        item.eventname +
                        "&eventday=" +
                        item.eventday +
                        "&starttime=" +
                        item.start_time +
                        "&endtime=" +
                        item.finish_time +
                        "&previousPage=likes&campaignStatus=" +
                        item.campaignstatus +
                        "&category=",
                      state: {
                        eventDate: item.event_day,
                        fulldate: fulldate,
                        customerid: customerid,
                        posterUri: posterUri,
                        accountid: customerid,
                        accounttype: accounttype,
                        customername: this.state.customername,
                        email: this.state.email,
                        phone: this.state.phone,
                        venue: item.event_location,
                        description: item.event_des,
                        businessid: item.businessid,
                        eventid: item.eventid,
                        poster: item.poster,
                        eventname: item.eventname,
                        eventday: item.eventday,
                        starttime: item.start_time,
                        endtime: item.finish_time,
                        previousPage: "likes",
                        campaignStatus: item.campaignstatus,
                        category: "",
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-lg-2 col-3 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      </div>
                      <div
                        className="col-lg-10 col-9 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <label
                          className="item-names"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {event} <br />
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            {item.eventday}
                          </label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header2 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-4 pl-0">
                      <Link to="/customerdashboard" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-3 pr-3 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">LIKES</label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.likes()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Likes;
