import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      failed: "",
      success: "",
      error: "",
      successError: "",
      errorDisplay: "none",
    };

    // this.handleEmail = this.handleEmail.bind(this);
    // this.handleForm = this.handleForm.bind(this);
  }
  componentDidMount() {
    document.title = "Forgot password - Can't log in";
  }
  handleEmail = (event) => {
    this.setState({ email: event.target.value, errorDisplay: "none" });
  };

  handleForm = () => {
    const email = this.state.email;
    if (email.trim() === "") {
      this.setState({
        error: "Enter email or phone number",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=RECOVERPASSWORD&email=" +
          email
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({
            error: "Email address or phone provided does not exist",
            errorDisplay: "block",
            successError: "error-login",
          });
        } else {
          this.setState({
            error: "Reset code sent to " + response.data.email,
            errorDisplay: "block",
            successError: "success-login",
          });
        }
      });
    }
  };
  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center w-100 login-screen-container">
            <div className="col-lg-5 col-12 login-container-adjust">
              <div className="log-title text-center">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width="170" />
                </Link>
              </div>
              <div className="BoxDashboardContainer w-100">
                <input
                  type="text"
                  name="email"
                  required
                  placeholder="Email address or phone number"
                  onChange={this.handleEmail}
                  value={this.state.email}
                />
                <div
                  className=""
                  style={{
                    padding: 10,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: "red",
                  }}
                >
                  {this.state.failed}
                </div>
                <div
                  className=""
                  style={{
                    padding: 10,
                    paddingLeft: 0,
                    paddingTop: 0,
                    color: "green",
                  }}
                >
                  {this.state.success}
                </div>
                <button onClick={() => this.handleForm()}>Submit</button>
                <Link to="/login">
                  <div className="forgotPasswordText text-center">Log In</div>
                </Link>

                <div className="horizontal-line"></div>

                <Link to="/codepage">
                  <div className="forgotPasswordText text-center">
                    I have the <span>Reset code</span>
                  </div>
                </Link>
              </div>

              <div className="text-footer text-center">
                Request a <span>Reset code</span> to reset password
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Password;
