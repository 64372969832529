import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Axios from "axios";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class Customer extends React.Component {
  state = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    pass1: "",
    pass2: "",
    response: "",
    error: "",
    errorDisplay: "none",
    spinning: "none",
    updateDisplay: "block",
  };

  componentDidMount() {
    document.title = "Customer registration";
  }

  fname = (event) => {
    this.setState({
      fname: event.target.value,
      errorDisplay: "none",
    });
  };

  lname = (event) => {
    this.setState({
      lname: event.target.value,
      errorDisplay: "none",
    });
  };

  email = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  phone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  pass1 = (event) => {
    this.setState({
      pass1: event.target.value,
      errorDisplay: "none",
    });
  };

  pass2 = (event) => {
    this.setState({
      pass2: event.target.value,
      errorDisplay: "none",
    });
  };

  onSubmitForm = () => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 4000);
    this.setState({ updateDisplay: "none", spinning: "block" });
    const fname = this.state.fname;
    const lname = this.state.lname;
    const email = this.state.email;
    const phone = this.state.phone;
    const pass1 = this.state.pass1;
    const pass2 = this.state.pass2;
    const customername = fname + " " + lname;

    if (fname.trim() === "") {
      this.setState({
        error: "Enter your first name",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (lname.trim() === "") {
      this.setState({
        error: "Enter your last name",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (email.trim() === "") {
      this.setState({
        error: "Enter your email address",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (!validator.isEmail(email)) {
      this.setState({
        error: "Your email address is invalid.",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (phone.trim() === "") {
      this.setState({
        error: "Enter your phone number",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (
      isPossiblePhoneNumber(phone.replace(" ", "")) === false ||
      isValidPhoneNumber(phone.replace(" ", "")) === false
    ) {
      this.setState({
        error: "Your phone number is invalid",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1.length < 4) {
      this.setState({
        error: "Pincode must at least have 4 digits",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1 !== pass2) {
      this.setState({
        error: "Make sure both your pincodes match",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else {
      const phon = phone.replace(" ", "");
      let phoneNumber = phon.substring(3, 15);
      const digit = phoneNumber.substring(0, 1);
      if (digit == 0) {
        phoneNumber = phoneNumber;
      } else {
        phoneNumber = "0" + phoneNumber;
      }
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=VERIFYACCOUNT&customername=" +
          customername +
          "&phone=" +
          phoneNumber +
          "&email=" +
          email +
          "&password=" +
          pass1 +
          "&accounttype=customer&platform=web"
      )
        .then((response) => {
          if (response.data.val == 1) {
            localStorage.setItem("verificationAccount", "CUSTOMER");
            this.props.history.push({
              pathname: "/verification",
              state: {
                fname: this.state.fname,
                lname: this.state.lname,
                email: this.state.email,
                accounttype: "customer",
                password: this.state.pass1,
                phone: this.state.phone,
              },
            });
          } else {
            this.setState({
              error:
                "The email address or phone number you provided is already registered",
              errorDisplay: "block",
              spinning: "none",
              updateDisplay: "block",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center login-screen-container w-100">
            <div className="col-lg-5 col-12 align-self-center login-container-adjust pt-5">
              <div className="log-title text-center pt-0">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width="170" />
                </Link>
              </div>
              <div className=" BoxDashboardContainer">
                <div className="col-12 text-left p-0 mb-3">
                  <span>Become a customer and buy tickets with ease</span>
                </div>
                <input
                  required
                  type="text"
                  name="fname"
                  placeholder="First name"
                  value={this.state.fname}
                  onChange={this.fname}
                />

                <input
                  required
                  type="text"
                  name="lname"
                  placeholder="Last name"
                  value={this.state.lname}
                  onChange={this.lname}
                />

                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.email}
                />

                <PhoneInput
                  // autoFormat={false}
                  disableDropdown={true}
                  enableSearch={true}
                  showDropdown={false}
                  country={"za"}
                  value={this.state.phone}
                  onChange={(phone) => this.setState({ phone: "+" + phone })}
                  inputStyle={{
                    width: "100%",
                    background: "#f0f2f5",
                    height: "2.7em",
                  }}
                  containerStyle={{ marginBottom: 15 }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                />

                {/* <input
                  required
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  value={this.state.phone}
                  onChange={this.phone}
                /> */}
                <input
                  required
                  type="password"
                  placeholder="Pincode"
                  value={this.state.pass1}
                  onChange={this.pass1}
                />

                <input
                  required
                  type="password"
                  placeholder="Retype pincode"
                  value={this.state.pass2}
                  onChange={this.pass2}
                />

                <div className="col-12 response textMedium">
                  {this.state.response}
                </div>

                <button onClick={() => this.onSubmitForm()}>
                  <span
                    style={{
                      display: this.state.updateDisplay,
                    }}
                  >
                    Sign up
                  </span>
                  <span
                    style={{
                      display: this.state.spinning,
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      // css={override}
                      size={20}
                    />
                  </span>
                </button>
                <Link to="/login">
                  <div className="forgotPasswordText text-center">Log In</div>
                </Link>
              </div>

              <div className="text-footer text-center pb-4">
                Buy a <span>ticket</span> while watching{" "}
                <span>
                  <Link
                    to={{ pathname: "https://netflix.com" }}
                    target="_blank"
                  >
                    <img src="https://karamale.com/netflix1.png" width={70} />
                  </Link>
                </span>
                <br />
                That's how <span>convenient</span> we are.
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Customer;
