import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import Header3 from "./components/Header3";
import TopBarProgress from "react-topbar-progress-indicator";

class Boosters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      query: "",
      loadBarProgress: true,
    };
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    document.title = "Boosters - " + businessname;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PROMOTERS&subquery=GETPROMOTERS&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  searchQuery = (text) => {
    let value = text;
    // this.setState({ query: value });
    this.setState({ query: text.target.value });

    let query = text.target.value.split(" ").join("+");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const accountid = localStorage.getItem("businessid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PROMOTERS&subquery=SEARCHPROMOTERS&search=" +
        query +
        "&accountid=" +
        accountid +
        "&accounttype=" +
        accounttype
    )
      .then((response) => {
        // alert(response.data.val);
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data });
        }
      })
      .catch((error) => console.log(error));
  };

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");

    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="#7fbf7f" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="#ff6666" />
                  ));
            }
            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-1 pl-1 pb-2 pt-3">
                  <Link
                    to={{
                      pathname: "/editbooster",
                      state: {
                        promoterid: item.promoterid,
                        accountid: item.businessid,
                        accounttype: accounttype,
                        fname: item.fname,
                        lname: item.lname,
                        phone: item.phone,
                        email: item.email,
                        credit: item.amount,
                        prevpage: "boosters",
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-12" style={{ cursor: "pointer" }}>
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            cursor: "pointer",
                          }}
                          className="small-details"
                        >
                          {activeString} &nbsp; {item.fname} {item.lname}
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          ></label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header3 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container ">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-6 pl-0">
                      <Link to="/dashboard" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-6">
                      <input
                        type="text"
                        placeholder="Search booster"
                        value={this.state.query}
                        onChange={this.searchQuery}
                        className="search-ticket-input"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title w-100 pl-0 pr-0">
                        BOOSTERS
                        <Link to="addbooster" className="link-btn">
                          <label class="float-right small-details add-booster">
                            Add Booster{" "}
                          </label>
                        </Link>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Boosters;
