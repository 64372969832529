import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import { BsCircle, BsCheckCircle } from "react-icons/bs";
import Header2 from "./components/Header2";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      count: 0,
      uncheck1: "block",
      uncheck2: "block",
      uncheck3: "block",
      check1: "none",
      check2: "none",
      check3: "none",
      activeClass1: "item-select",
      activeClass2: "item-select",
      activeClass3: "item-select",
      status1: "unchecked",
      status2: "unchecked",
      status3: "unchecked",
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    document.title = customername;
  }

  feedback = (event) => {
    this.setState({
      feedback: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    const accountid = localStorage.getItem("customerid");
    const accounttype = localStorage.getItem("accounttype");
    const feedback = this.state.feedback;

    if (feedback.trim() === "") {
      this.setState({
        error: "Enter your feedback",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=FEEDBACK&accountid=" +
          accountid +
          "&accounttype=" +
          accounttype +
          "&feedback=" +
          feedback
      )
        .then((response) => {
          this.setState({
            error: "We've received your feedback. Thank you!",
            errorDisplay: "block",
            successError: "success-login",
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  check1 = () => {
    let count = this.state.count;
    let classAsign,
      block,
      none = "";
    if (this.state.status1 == "unchecked") {
      classAsign = "item-select-active";
      block = "block";
      none = "none";
      this.setState({ status1: "checked", count: count + 1 });
    } else {
      classAsign = "item-select";
      block = "none";
      none = "block";
      this.setState({ status1: "unchecked", count: count - 1 });
    }
    this.setState({
      uncheck1: none,
      check1: block,
      activeClass1: classAsign,
    });
  };

  check2 = () => {
    let count = this.state.count;
    let classAsign,
      block,
      none = "";
    if (this.state.status2 == "unchecked") {
      classAsign = "item-select-active";
      block = "block";
      none = "none";
      this.setState({ status2: "checked", count: count + 1 });
    } else {
      classAsign = "item-select";
      block = "none";
      none = "block";
      this.setState({ status2: "unchecked", count: count - 1 });
    }
    this.setState({
      uncheck2: none,
      check2: block,
      activeClass2: classAsign,
    });
  };

  check3 = () => {
    let count = this.state.count;
    let classAsign,
      block,
      none = "";
    if (this.state.status3 == "unchecked") {
      classAsign = "item-select-active";
      block = "block";
      none = "none";
      this.setState({ status3: "checked", count: count + 1 });
    } else {
      classAsign = "item-select";
      block = "none";
      none = "block";
      this.setState({ status3: "unchecked", count: count - 1 });
    }
    this.setState({
      uncheck3: none,
      check3: block,
      activeClass3: classAsign,
    });
  };

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    return (
      <div className="view">
        <Header2 />
        <div className="container pb-5">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-3 pl-0">
                      <Link to="/customerdashboard" className="link-btn">
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-9 text-right">
                      <label className="back-arrow">
                        <div className="save-btn" onClick={() => this.update()}>
                          Send
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title">
                        SHARE YOUR FEEDBACK, IDEAS AND MORE...
                        <br />
                        <label className="note-line">
                          Let's build <span>Karamale</span> together, and make
                          it SA's number one
                        </label>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider"></div>
                </div>
                <div className="col-lg-12 details-body pb-3">
                  <span>Select whichever applies</span>
                  <span className="float-right small-details text-size">
                    {this.state.count}/7
                  </span>
                  <br />
                  <br />
                  <div
                    className={
                      this.state.activeClass1 + " mb-2 w-100 pt-3 pb-3"
                    }
                    style={{ display: "flex" }}
                    onClick={() => this.check1()}
                  >
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <BsCircle
                        size={25}
                        color={"#3399cc"}
                        style={{ display: this.state.uncheck1 }}
                      />

                      <BsCheckCircle
                        size={30}
                        color={"#66b266"}
                        style={{ display: this.state.check1 }}
                      />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-10">
                      <label className="item-names">Broken link</label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.activeClass2 + " mb-2 w-100 pt-3 pb-3"
                    }
                    style={{ display: "flex" }}
                    onClick={() => this.check2()}
                  >
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <BsCircle
                        size={25}
                        color={"#3399cc"}
                        style={{ display: this.state.uncheck2 }}
                      />

                      <BsCheckCircle
                        size={30}
                        color={"#66b266"}
                        style={{ display: this.state.check2 }}
                      />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-10">
                      <label className="item-names">
                        Showing irrelevant results on the search
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.activeClass3 + " mb-2 w-100 pt-3 pb-3"
                    }
                    style={{ display: "flex" }}
                    onClick={() => this.check3()}
                  >
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <BsCircle
                        size={25}
                        color={"#3399cc"}
                        style={{ display: this.state.uncheck3 }}
                      />

                      <BsCheckCircle
                        size={30}
                        color={"#66b266"}
                        style={{ display: this.state.check3 }}
                      />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-10">
                      <label className="item-names">
                        Website is not user friendly
                      </label>
                    </div>
                  </div>
                  What's your mind?
                  <br />
                  <textarea
                    placeholder="Type here..."
                    onChange={this.feedback}
                    className="edit-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Report;
