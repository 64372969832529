import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { AiOutlineQrcode } from "react-icons/ai";
import { RiQrCodeLine } from "react-icons/ri";
import Header2 from "./components/Header2";

class Previewtickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      previews: [],
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    const eventid = this.props.location.state.eventid;
    document.title = customername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PREVIEWTICKETS&customerid=" +
        customerid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ previews: [] });
        } else {
          this.setState({ previews: response.data });
        }
      })
      .catch((error) => console.log(error));
  }

  previews = () => {
    let activeString = "";
    const customerid = localStorage.getItem("customerid");
    const customername = localStorage.getItem("customername");
    const eventid = this.props.location.state.eventid;
    const transactiondate = this.props.location.state.transactiondate;
    const venue = this.props.location.state.venue;
    const eventname = this.props.location.state.eventname;
    const eventtime = this.props.location.state.eventtime;
    const eventday = this.props.location.state.eventday;
    const yearcreated = this.props.location.state.yearcreated;
    const theme = this.props.location.state.theme;
    const admissions = this.props.location.state.admissions;
    const totalcost = this.props.location.state.totalcost;
    let category = "";

    return (
      <div className="pb-2">
        {this.state.previews.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.previews.map((item, index) => {
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            {
              item.category == "general"
                ? (category = "General")
                : (category = item.category.toUpperCase());
            }

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-2">
                  <Link
                    to={{
                      pathname: "/zoom",
                      state: {
                        customername: customername,
                        customerid: customerid,
                        eventid: eventid,
                        transactiondate: transactiondate,
                        venue: venue,
                        eventname: eventname,
                        eventtime: eventtime,
                        eventday: eventday,
                        yearcreated: yearcreated,
                        theme: theme,
                        admissions: admissions,
                        totalcost: totalcost,
                        ticketnumber: item.ticketnumber,
                      },
                    }}
                    className="row"
                    style={{
                      display: "flex",
                      width: "100%",
                      color: "#333",
                    }}
                  >
                    <div className="col-lg-2 col-4 col-sm-3">
                      <AiOutlineQrcode size={90} />
                    </div>
                    <div
                      className="col-lg-9 col-8 col-sm-9"
                      style={{ cursor: "pointer" }}
                    >
                      <label
                        className="item-names"
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        <label className="admin-cate">
                          <span>Ticket number:</span> {item.ticketnumber} <br />
                          <span>Type:</span> {category}
                          <br />
                          <span>Admission:</span> {item.quantity}
                          <br />
                          <span>Price:</span> R{item.price} as per one adm
                          <br />
                        </label>
                      </label>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const eventname = this.props.location.state.eventname;
    return (
      <div className="view">
        <Header2 />
        <div className="container pb-5">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-4 pl-0">
                      <Link to="/tickets" className="link-btn">
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-8 text-right">
                      <label className="back-arrow">
                        <IoIosInformationCircleOutline
                          size={40}
                          color={"#fff"}
                        />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <span className="note-info">{eventname}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        CLICK TO PREPARE FOR SCANNING
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.previews()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Previewtickets;
