import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Select from "react-select";
import { IoIosArrowBack } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Header from "./components/Header5";

class Bdetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      businessname: localStorage.getItem("businessname"),
      eventname: "",
      eventdate: "",
      eventid: "",
      poster: "",
      theme: "",
      venue: "",
      expectednum: "",
      enddate: "",
      time: "",
      endtime: "",
      event_date: "",
      finish_date: "",
      description: "",
      spinning: "none",
      updateDisplay: "block",
      eventtype: "",
      provinces: "",
    };
  }

  componentDidMount() {
    document.title = "Save banking details - Karamale ";
  }

  handleEventname = (event) => {
    this.setState({
      eventname: event.target.value,
      errorDisplay: "none",
    });
  };

  handleVenue = (event) => {
    this.setState({
      venue: event.target.value,
      errorDisplay: "none",
    });
  };

  handleTheme = (event) => {
    this.setState({
      theme: event.target.value,
      errorDisplay: "none",
    });
  };

  handleChange = (e) => {
    this.setState({ eventtype: e.value, errorDisplay: "none" });
    if (e.value == "absa") {
      this.setState({ eventname: "632005" });
    } else if (e.value == "fnb") {
      this.setState({ eventname: "250655" });
    } else if (e.value == "nedbank") {
      this.setState({ eventname: "198765" });
    } else if (e.value == "standard bank") {
      this.setState({ eventname: "051001" });
    } else if (e.value == "capitec") {
      this.setState({ eventname: "470010" });
    } else if (e.value == "tymebank") {
      this.setState({ eventname: "678910" });
    } else if (e.value == "discovery bank") {
      this.setState({ eventname: "679000" });
    }
  };

  handleProvinces = (e) => {
    this.setState({ provinces: e.value, errorDisplay: "none" });
  };

  update = () => {
    const partnerid = localStorage.getItem("partnerid");
    const eventname = this.state.eventname;
    const theme = this.state.theme;
    const eventtype = this.state.eventtype;
    const province = this.state.provinces;
    this.setState({ updateDisplay: "none", spinning: "block" });

    if (eventtype === "") {
      this.setState({
        error: "Select your bank",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (province === "") {
      this.setState({
        error: "Select account type",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (this.state.eventname.trim() === "") {
      this.setState({
        error:
          "Branch code required. NB: Branch code is automatically entered when you select a bank",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (theme.trim() === "") {
      this.setState({
        error: "Enter your account number",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (theme.length < 9) {
      this.setState({
        error: "Account number entered is invalid",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=SAVEBANKINGDEAILS&partnerid=" +
          partnerid +
          "&branchcode=" +
          eventname +
          "&accountnumber=" +
          theme +
          "&bankname=" +
          eventtype +
          "&accounttype=" +
          province
      )
        .then((response) => {
          if (response.data.val == 1) {
            this.setState({
              error: "Banking details updated",
              errorDisplay: "block",
              successError: "success-login",
              updateDisplay: "block",
              spinning: "none",
            });
          } else {
            this.setState({
              error: "Banking details added",
              errorDisplay: "block",
              successError: "success-login",
              updateDisplay: "block",
              spinning: "none",
            });
          }
          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    if (localStorage.getItem("partnerid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const businessname = localStorage.getItem("businessname");
    const { eventtype, provinces } = this.state;

    const banks = [
      { label: "Absa", value: "absa" },
      { label: "FNB", value: "fnb" },
      { label: "Nedbank", value: "nedbank" },
      { label: "Standark Bank", value: "standard bank" },
      { label: "Capitec", value: "capitec" },
      { label: "TymeBank", value: "tymebank" },
      { label: "Discovery Bank", value: "discovery bank" },
    ];

    const accounts = [
      { label: "Savings", value: "savings" },
      { label: "Current", value: "current" },
    ];

    const prevpage = "in";
    return (
      <div className="view">
        <Header />
        <div className="container">
          <div className="row justify-content-center just-container-insid pb-5">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={{
                          pathname: prevpage,
                          state: {
                            prevpage: prevpage,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow">
                        <div className="save-btn" onClick={() => this.update()}>
                          <span
                            style={{
                              display: this.state.updateDisplay,
                            }}
                          >
                            {" "}
                            Save
                          </span>
                          <span
                            style={{
                              display: this.state.spinning,
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="row">
                      <div className="col-12 pl-0 pr-0">
                        <label className="ticket-title pl-0 pr-0 w-100">
                          ADD BANKING DETAILS
                          <Link to="/in">
                            <label
                              className="color-blue float-right font-normal"
                              style={{ textTransform: "none" }}
                            >
                              Maybe later
                            </label>
                          </Link>
                        </label>
                      </div>
                      <div className="col-12 pl-0 pr-0">
                        <label className="small-details">
                          <span>NB:</span> Karamale will use these banking
                          details to pay you.
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  Which bank do you use?
                  <br />
                  <Select
                    value={banks.find((obj) => obj.value === eventtype)}
                    placeholder="Select bank"
                    options={banks}
                    onChange={this.handleChange}
                    width={"100%"}
                    className="text-left mt-2"
                  />
                  <br />
                  Branch code
                  <br />
                  <input
                    readOnly={true}
                    type="text"
                    placeholder="Branch code"
                    value={this.state.eventname}
                    onChange={this.handleEventname}
                    className="edit-input"
                  />
                  <br /> <br />
                  Account type
                  <br />
                  <Select
                    value={accounts.find((obj) => obj.value === provinces)}
                    placeholder="Select account"
                    options={accounts}
                    onChange={this.handleProvinces}
                    width={"100%"}
                    className="text-left mt-2"
                  />
                  <br />
                  Account number (Please double check)
                  <br />
                  <input
                    type="text"
                    placeholder="Enter account number"
                    value={this.state.theme}
                    onChange={this.handleTheme}
                    className="edit-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Bdetails;
