import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import Header from "./components/Header";

class Boosterlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      businessname: this.props.location.state.businessname,
      email: "",
    };
  }

  componentDidMount() {
    const businessname = this.props.location.state.businessname;
    const businessid = localStorage.getItem("businessid");
    document.title = businessname;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
        businessid
    )
      .then((response) => {
        //   this.setState({
        //     phone: response.data.password,
        //     email: response.data.email,
        //   });
      })
      .catch(function (error) {
        alert(error);
      });
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword = (event) => {
    this.setState({
      password: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    const businessid = this.props.location.state.businessid;
    const businessname = this.props.location.state.businessname;
    const businessphone = this.props.location.state.phone;
    const businessemail = this.props.location.state.email;

    const password = this.state.password;
    const email = this.state.email;

    let os = "Windows";
    if (email.trim() === "") {
      this.setState({
        error: "Email address is required",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (password.trim() === "") {
      this.setState({
        error: "Enter password",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=BOOSTERLOGIN&os=" +
          os +
          "&businessphone=" +
          businessphone +
          "&businessid=" +
          businessid +
          "&businessname=" +
          businessname +
          "&businessemail=" +
          businessemail +
          "&email=" +
          email +
          "&password=" +
          password
      )
        .then((response) => {
          if (response.data.val == 1) {
            localStorage.setItem("boosterid", response.data.boosterid);
            localStorage.setItem("boostername", response.data.boostername);
            localStorage.setItem("boosteremail", response.data.boosteremail);
            localStorage.setItem("boosterphone", response.data.boosterphone);
            localStorage.setItem("activation", response.data.activation);
            localStorage.setItem("clientid", businessid);
            localStorage.setItem("balance", response.data.balance);
            localStorage.setItem("credit", response.data.credit);
            localStorage.setItem("businessname", businessname);
            localStorage.setItem("businessid", businessid);

            this.props.history.push({
              pathname: "Boosterdashboard",
              state: {
                balance: response.data.balance,
                credit: response.data.credit,
                boosteremail: response.data.boosteremail,
                boosterphone: response.data.boosterphone,
                activation: response.data.activation,
                businessid: businessid,
                businessname: businessname,
                boosterid: response.data.boosterid,
                boostername: response.data.boostername,
              },
            });
          } else {
            this.setState({
              error: "Incorrect login details",
              errorDisplay: "block",
              successError: "error-login",
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    let { accountname, dashboard } = "";

    return (
      <div className="view">
        <Header />

        <div className="container">
          <div className="row justify-content-center just-container-inside">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to="/promoter" className="link-btn">
                        <label className="back-arrow pl-0 pr-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow pl-0 pr-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          Login
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-4 pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        {this.props.location.state.businessname}{" "}
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  Email
                  <br />
                  <input
                    type="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.handleEmail}
                    className="edit-input"
                  />
                  <br /> <br />
                  Password
                  <br />
                  <input
                    type="password"
                    placeholder="Password..."
                    value={this.state.phone}
                    onChange={this.handlePassword}
                    className="edit-input"
                  />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Boosterlogin;
