import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import QrReader from "react-qr-scanner";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosPodium,
  IoMdPeople,
  IoIosPeople,
  IoIosAdd,
  IoIosAddCircleOutline,
  IoIosRefresh,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdPhonePortrait,
  IoLogoAndroid,
  IoLogoWindows,
  IoLogoChrome,
  IoLogoApple,
  IoIosBrowsers,
  IoIosSettings,
} from "react-icons/io";
import {
  BsCalendar,
  BsPeople,
  BsBoundingBoxCircles,
  BsPerson,
  BsLock,
  BsPen,
  BsApp,
  BsAppIndicator,
  BsArrowRight,
  BsGraphUp,
  BsSearch,
} from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineScan } from "react-icons/ai";
import { RiCalendarEventLine } from "react-icons/ri";
import { FaEdge, FaSafari, FaFirefox, FaLinux } from "react-icons/fa";

import Header from "./components/HeaderCampaign";
import Switch from "react-switch";
// import HorizontalScroller from "react-horizontal-scroll-container";
import TopBarProgress from "react-topbar-progress-indicator";

class Campaignmanager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      details: [],
      events: [],
      queryResults: [],
      customerlist: [],
      platformlist: [],
      numberOfclientes: 0,
      numberOfbooster: 0,
      numberOfboosterDeactivated: 0,
      delay: 100,
      result: "No result",
      loadBarProgress: true,
      refreshingText: false,
      loadingEvents: false,
      loadCustomers: false,
      loadingPlatforms: false,
      newcampaignbutton: "new-campaign-button",
      customers: "new-campaign-button",
      platforms: "new-campaign-button",
      tick: 0,
      count: 0,
      loadIndi: false,
      loadIndiBlank: true,
      allselected: 0,
      campaignCount: 0,
      loadSearchBox: false,
      searchValue: "",
      loadSearchresults: false,
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    document.title = "Campaign Manager - " + businessname;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=UPDATEALLTRANSFER&campaignid=&businessid=" +
        businessid
    )
      .then((response) => {})
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          campaignCount: (
            <label className="campaigncounts">
              {response.data.campaignCount}
            </label>
          ),
        });
      } else {
        this.setState({
          campaignCount: "",
        });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNS&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({
            details: response.data,

            loadBarProgress: false,
          });
        }
      })
      .catch((error) => console.log(error));

    // fetch all events
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MYEVENTS&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype +
        "&thumbnail=yes"
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ events: [] });
        } else {
          this.setState({ events: response.data });
        }
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETSELECTCAMPAIGNS&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ count: 0 });
        } else {
          this.setState({
            count: response.data.selectedCount,
            eventid: response.data.eventid,
            eventname: response.data.eventname,
            campaignname: response.data.campaignname,
            poster: response.data.poster,
            eventdate: response.data.eventday,
            enddate: response.data.enddate,
            event_date: response.data.event_day,
            finish_date: response.data.finish_day,
            theme: response.data.theme,
            venue: response.data.venue,
            expectednum: response.data.expectednum,
            time: response.data.time,
            endtime: response.data.endtime,
          });
        }
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=ALLSELECTCAMPAIGNS&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ allselected: 0 });
        } else {
          this.setState({ allselected: 1 });
        }
      })
      .catch((error) => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.allselected !== prevState.allselected ||
      this.state.count !== prevState.count ||
      this.state.campaignCount !== prevState.campaignCount
    ) {
      // alert(this.state.count);
      const businessname = localStorage.getItem("businessname");
      const accounttype = localStorage.getItem("accounttype");
      const businessid = localStorage.getItem("businessid");
      document.title = "Campaign Manager - " + businessname;

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
          businessid
      ).then((response) => {
        if (response.data.val == 1) {
          this.setState({
            campaignCount: (
              <label className="campaigncounts">
                {response.data.campaignCount}
              </label>
            ),
          });
        } else {
          this.setState({
            campaignCount: "",
          });
        }
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNS&businessid=" +
          businessid
      )
        .then((response) => {
          if (response.data.val == 0) {
            this.setState({ details: [] });
          } else {
            this.setState({ details: response.data, loadBarProgress: false });
          }
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=ALLSELECTCAMPAIGNS&businessid=" +
          businessid
      )
        .then((response) => {
          if (response.data.val == 0) {
            this.setState({ allselected: 0 });
          } else {
            this.setState({ allselected: 1 });
          }
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETSELECTCAMPAIGNS&businessid=" +
          businessid
      )
        .then((response) => {
          if (response.data.val == 0) {
            this.setState({ count: 0 });
          } else {
            this.setState({
              count: response.data.selectedCount,
              eventid: response.data.eventid,
              campaignname: response.data.campaignname,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  platformlist = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");

    return (
      <div>
        {this.state.platformlist.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              Devices not available, meaning the event under this campaign has
              not been accessed{" "}
            </label>
          </div>
        ) : (
          <div>
            <div className={"col-lg-12 p-0"}>
              <div className="item-select w-100 pr-0 pl-0 pb-3 pt-3">
                <div className="w-100">
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col-6" style={{ cursor: "pointer" }}>
                      <span>
                        <IoIosSettings size={20} />
                        &nbsp;Devices/OS
                      </span>
                    </div>

                    <div className="col-6" style={{ cursor: "pointer" }}>
                      <span>
                        <IoIosBrowsers size={20} /> &nbsp;Agents
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-lg-12 p-0"}>
              {this.state.platformlist.map((item, index) => {
                return (
                  <div className="item-select w-100 pr-0 pl-0 pb-1 pt-3">
                    <div className="w-100">
                      <div className="row" style={{ display: "flex" }}>
                        <div className="col-6" style={{ cursor: "pointer" }}>
                          <label
                            className="item-names w-100 small-details"
                            style={{
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <label>
                              {item.device == "Android" ? (
                                <IoLogoAndroid size={20} color={"#66b266"} />
                              ) : item.device == "Windows" ? (
                                <IoLogoWindows size={20} color={"#069"} />
                              ) : item.device == "iPhone" ||
                                item.device == "MacIntel" ? (
                                <IoLogoApple size={20} color={"#c6c6c6"} />
                              ) : (
                                <FaLinux size={20} color={"#333"} />
                              )}
                              &nbsp;{" "}
                              {item.device == "MacIntel" ? "Mac" : item.device}{" "}
                              ({item.platformCount})
                            </label>
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            ></label>
                          </label>
                        </div>

                        <div className="col-6" style={{ cursor: "pointer" }}>
                          <label
                            className="item-names w-100 small-details"
                            style={{
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <label>
                              {item.useragent == "Edge" ? (
                                <FaEdge size={20} color={"#069"} />
                              ) : item.useragent == "Chrome" ? (
                                <IoLogoChrome size={20} color={"red"} />
                              ) : item.useragent == "Firefox" ? (
                                <FaFirefox size={20} color={"#ffa500"} />
                              ) : item.useragent == "Safari" ? (
                                <FaSafari size={20} color={"#3399cc"} />
                              ) : (
                                <FaLinux size={20} color={"#333"} />
                              )}
                              &nbsp; {item.useragent}
                            </label>
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            ></label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  customerlist = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");

    return (
      <div>
        {this.state.customerlist.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No one bought tickets under <br />
              this campaign as yet{" "}
            </label>
          </div>
        ) : (
          this.state.customerlist.map((item, index) => {
            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-3">
                  <Link
                    to={{
                      pathname: "/customerprofile",
                      state: {
                        accountid: localStorage.getItem("businessid"),
                        accounttype: accounttype,
                        clientid: item.clientid,
                        phone: item.phone,
                        clientename: item.fname + " " + item.lname,
                        email: item.email,
                        prevpage: "customers",
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-12" style={{ cursor: "pointer" }}>
                        <label
                          className="item-names w-100 small-details"
                          style={{
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          <span>
                            {item.fname} {item.lname}
                          </span>
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          ></label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  events = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let string = "";

    return (
      <div>
        {this.state.events.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              You have no events{" "}
            </label>
          </div>
        ) : (
          this.state.events.map((item, index) => {
            let tempEventname = item.eventname;
            const passEventname = tempEventname;
            const campaignstatus = item.campaignstatus;
            let budget = item.budget;
            let ticketset = item.ticketid;
            let markticketset = "";
            let inCheckmark = "";
            let thumbnail = item.thumbnail;
            let pausebtn = "";
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            event = item.eventname;

            if (
              item.thumbnail.trim() === "" ||
              item.thumbnail.trim() === "none"
            ) {
              poster = "https://karamale.com/apps/kscanner/placeholder.png";
              nb = (
                <label
                  className="float-right"
                  style={{
                    color: "red",
                    float: "right",
                    fontWeight: 400,
                  }}
                >
                  <i>Complete event setup!</i>
                </label>
              );
            } else {
              nb = "";
              poster =
                "https://karamale.com/apps/kscanner/posters/" +
                item.businessid +
                "/" +
                item.eventid +
                "/" +
                item.thumbnail;
            }

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-3">
                  {item.event_day < curDate ? (
                    <div className="w-100">
                      <div className="row" style={{ display: "flex" }}>
                        <div className="col-12" style={{ cursor: "pointer" }}>
                          <label
                            className="item-names w-100"
                            style={{
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <label style={{ color: "#828282" }}> {event}</label>
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            ></label>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={{
                        pathname: "/campaign",
                        state: {
                          eventid: item.eventid,
                          eventname: item.eventname,
                          poster: poster,
                          eventdate: item.eventday,
                          enddate: item.enddate,
                          event_date: item.event_day,
                          finish_date: item.finish_day,
                          theme: item.theme,
                          venue: item.venue,
                          expectednum: item.expected,
                          time: item.time,
                          endtime: item.endtime,
                          prevpage: "campaignmanager",
                          currentpage: "campaignmanager",
                        },
                      }}
                      className="w-100"
                    >
                      <div className="row" style={{ display: "flex" }}>
                        <div className="col-12" style={{ cursor: "pointer" }}>
                          <label
                            className="item-names w-100"
                            style={{
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <span>
                              {event} {nb}
                            </span>
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            >
                              {/* {item.eventday} */}
                            </label>
                          </label>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  refresh = () => {
    this.setState({ loadBarProgress: true, refreshingText: true });
    setTimeout(() => {
      this.setState({ loadBarProgress: false, refreshingText: false });
    }, 2000);

    this.props.history.push("/campaignmanager");
  };

  newCampaign = () => {
    this.setState({
      loadCustomers: false,
      customers: "new-campaign-button",
      platforms: "new-campaign-button",
      loadingPlatforms: false,
    });
    if (this.state.loadingEvents == true) {
      this.setState({
        loadingEvents: false,
        newcampaignbutton: "new-campaign-button",
      });
    } else {
      this.setState({
        loadingEvents: true,
        newcampaignbutton: "new-campaign-button-active",
      });
    }
  };

  Platforms = () => {
    const businessid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");
    const eventid = this.state.eventid;
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PLATFORMS&businessid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ platformlist: [] });
        } else {
          this.setState({ platformlist: response.data });
        }
      })
      .catch((error) => console.log(error));

    if (this.state.count == 1) {
      this.setState({
        loadingEvents: false,
        newcampaignbutton: "new-campaign-button",
        customers: "new-campaign-button",
        loadCustomers: false,
      });
      if (this.state.loadingPlatforms == true) {
        this.setState({
          loadingPlatforms: false,
          platforms: "new-campaign-button",
        });
      } else {
        this.setState({
          loadingPlatforms: true,
          platforms: "new-campaign-button-active",
        });
      }
    }
  };

  Customers = () => {
    const businessid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");
    const eventid = this.state.eventid;
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CLIENTELE&subquery=GETCLIENTELE&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ customerlist: [] });
        } else {
          this.setState({ customerlist: response.data });
        }
      })
      .catch((error) => console.log(error));

    if (this.state.count == 1) {
      this.setState({
        loadingEvents: false,
        newcampaignbutton: "new-campaign-button",
        platforms: "new-campaign-button",
        loadingPlatforms: false,
      });
      if (this.state.loadCustomers == true) {
        this.setState({
          loadCustomers: false,
          customers: "new-campaign-button",
        });
      } else {
        this.setState({
          loadCustomers: true,
          customers: "new-campaign-button-active",
        });
      }
    }
  };

  handleOnChange = (eventid) => {
    this.setState({ loadBarProgress: true });
    setTimeout(() => {
      this.setState({ loadBarProgress: false });
    }, 2000);

    this.props.history.push("/campaignmanager");
    const businessid = localStorage.getItem("businessid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=RESUMECAMPAIGN&eventid=" +
        eventid +
        "&businessid=" +
        businessid
    )
      .then((response) => {
        // alert(response.data.campaignCount);
        this.setState({ campaignCount: response.data.campaignCount });
      })
      .catch((error) => console.log(error));
  };

  handleTick = (
    eventid,
    eventname,
    poster,
    eventdate,
    enddate,
    event_date,
    finish_date,
    theme,
    venue,
    expectednum,
    time,
    endtime
  ) => {
    setTimeout(() => {
      this.setState({ loadBarProgress: false });
    }, 2000);

    this.setState({
      loadingEvents: false,
      loadCustomers: false,
      loadingPlatforms: false,
    });

    this.props.history.push("/campaignmanager");
    const businessid = localStorage.getItem("businessid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=UPDATESELECT&eventid=" +
        eventid +
        "&accountid=" +
        businessid
    )
      .then((response) => {
        // alert(response.data.counts);
        this.setState({ count: response.data.counts });
        if (response.data.val == 1) {
          this.setState({
            loadCustomers: false,
            customers: "new-campaign-button",
            platforms: "new-campaign-button",
            loadBarProgress: true,
            eventid: eventid,
            eventname: eventname,
            poster: poster,
            eventdate: eventdate,
            enddate: enddate,
            event_date: event_date,
            finish_date: finish_date,
            theme: theme,
            venue: venue,
            expectednum: expectednum,
            time: time,
            endtime: endtime,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  handleSelectAll = () => {
    const businessid = localStorage.getItem("businessid");
    this.setState({ loadBarProgress: true, loadCustomers: false });

    setTimeout(() => {
      this.setState({ loadBarProgress: false });
    }, 2000);

    this.props.history.push("/campaignmanager");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=UPDATESELECT&businessid=" +
        businessid
    )
      .then((response) => {
        this.setState({ count: response.counts });
        if (response.data.val == 0) {
          this.setState({
            loadIndi: false,
            loadIndiBlank: true,
            allselected: 0,
          });
        } else {
          this.setState({
            loadIndi: true,
            loadIndiBlank: false,
            allselected: 1,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let { string, campaignname, boolean, remainingBalance, campaignid } = "";

    const { loadIndi, loadIndiBlank, loadSearchBox, loadSearchresults } =
      this.state;

    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};

    const year = currentDate.getFullYear();
    const fulldate = year + "-" + month + "-" + date;

    return (
      <div className=" vertical-scroll">
        <table width="200%" border={0}>
          <tr className="td-bottom-line">
            <td width={"5%"} className="text-center">
              <span>On/Off</span>
            </td>
            <td width={"5%"} className="text-center">
              {" "}
              <label
                style={{ cursor: "pointer" }}
                onClick={() => this.handleSelectAll()}
              >
                {this.state.allselected == 0 ? (
                  <BsApp size={30} color={"silver"} />
                ) : (
                  <BsAppIndicator size={30} color={"#66b266"} />
                )}
              </label>
            </td>
            <td width={"5%"} className="text-center">
              <span></span>
            </td>
            <td width={"10%"}>
              <span>Campaigns</span>
              <br />
              <label className="small-details font-size-12">
                name of the campaign associated with the event
              </label>
            </td>
            <td width={"10%"}>
              <span>Event association</span>
              <br />
              <label className="small-details font-size-12">
                name of the event represented by this campaign
              </label>
            </td>

            <td width={"7%"}>
              <span>Status</span>
              <br />
              <label className="small-details font-size-12">
                shows if this campaign
                <br /> is active or inactive
              </label>
            </td>

            <td width={"10%"}>
              <span>Running period</span>
              <br />
              <label className="small-details font-size-12">
                the duration that this
                <br /> campaign is running
              </label>
            </td>

            <td width={"10%"}>
              <span>Days remaining</span>
              <br />
              <label className="small-details font-size-12">
                the days remaining before
                <br /> this campaign stops running
              </label>
            </td>

            <td width={"10%"}>
              <span>Keywords</span>
              <br />
              <label className="small-details font-size-12">
                relative words (useful when searching for this event)
              </label>
            </td>

            <td width={"7%"}>
              <span>Bid margins</span>
              <br />
              <label className="small-details font-size-12">
                the ranks between <br />
                your current budget vs <br />
                the estimated budget.
              </label>
            </td>
            <td width={"7%"}>
              <span>Budget</span>
              <br />
              <label className="small-details font-size-12">
                amount you paid <br />
                upfront to sell tickets
              </label>
            </td>
            <td width={"9%"}>
              <span>Amount spent</span>
              <br />
              <label className="small-details font-size-12">
                amount you've spent <br />
                from your budget
              </label>
            </td>
            <td width={"7%"}>
              <span>Balance</span>
              <br />
              <label className="small-details font-size-12">
                the remaining amount from
                <br /> your budget after expenditure
              </label>
            </td>
            <td width={"7%"}>
              <span>Overall revenue</span>
              <br />
              <label className="small-details font-size-12">
                income generated <br />
                from all ticket sales
              </label>
            </td>
            <td width={"7%"}>
              <span>Digital revenue</span>
              <br />
              <label className="small-details font-size-12">
                income generated <br />
                only from digital ticket sales
              </label>
            </td>
            <td width={"7%"}>
              <span>Physical revenue</span>
              <br />
              <label className="small-details font-size-12">
                income generated <br />
                only from physical ticket sales
              </label>
            </td>
            <td width={"8%"}>
              <span>Unique buyers</span>
              <br />
              <label className="small-details font-size-12">
                first time ticket buyers
                <br /> to this event
              </label>
            </td>
            <td width={"8%"}>
              <span>Admissions</span>
              <br />
              <label className="small-details font-size-12">
                number of admissions <br />
                administered through ticket sales
              </label>
            </td>
            <td width={"5%"}>
              <span>Reach</span>
              <br />
              <label className="small-details font-size-12">
                unique audience
                <br /> that has seen your <br />
                event
              </label>
            </td>
            <td width={"7%"}>
              <span>Impressions</span>
              <br />
              <label className="small-details font-size-12">
                the number of times <br />
                people saw your event. <br />
                (this includes returning audience)
              </label>
            </td>

            <td width={"9%"}>
              <span>Cost per sale</span>
              <br />
              <label className="small-details font-size-12">
                equals the amount Karamale <br />
                charges you from every sale.
                <br />
                (this amount is deducted
                <br /> from your already paid budget)
              </label>
            </td>

            <td width={"10%"}>
              <span>Event date</span>
              <br />
              <label className="small-details font-size-12">
                the date in which this event <br />
                will be hapeening
              </label>
            </td>

            <td width={"10%"}>
              <span>Starts</span>
              <br />
              <label className="small-details font-size-12">
                the date in which <br />
                this campaign started running
              </label>
            </td>
            <td width={"10%"} className="hide-border">
              <span>Ends</span>
              <br />
              <label className="small-details font-size-12">
                the date in which <br />
                this campaign will stop running
              </label>
            </td>
          </tr>

          {this.state.details.length < 1 ? (
            <tr>
              <td colSpan={10}>
                {" "}
                <div>
                  <label
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: 13.5,
                      color: "#5B7083",
                    }}
                  >
                    There are no campaigns
                    <br />
                    <label
                      className="new-campaign"
                      onClick={() => this.newCampaign()}
                    >
                      Create new campaign
                    </label>
                  </label>
                </div>
              </td>
            </tr>
          ) : (
            this.state.details.map((item, index) => {
              let tempEventname = item.eventname;
              const passEventname = tempEventname;
              const campaignstatus = item.campaignstatus;
              let budget = item.budget;
              let ticketset = item.ticketid;
              let markticketset = "";
              let inCheckmark = "";
              let thumbnail = item.thumbnail;
              let pausebtn = "";
              remainingBalance = item.balance;
              campaignid = item.campaignid;
              {
                item.activation == 1
                  ? (activeString = (
                      <IoIosCheckmarkCircleOutline size={18} color="green" />
                    ))
                  : (activeString = (
                      <IoIosRemoveCircleOutline size={18} color="red" />
                    ));
              }

              {
                item.campaignname.length > 25
                  ? (campaignname = item.campaignname.substring(0, 25) + "...")
                  : (campaignname = item.campaignname);
              }

              if (
                item.thumbnail.trim() === "" ||
                item.thumbnail.trim() === "none"
              ) {
                poster = "https://karamale.com/apps/kscanner/placeholder.png";
                nb = (
                  <label
                    data-tip="Complete event setup"
                    className="float-right"
                    style={{ color: "red", float: "right", fontWeight: 400 }}
                  >
                    <ReactTooltip />
                    <BiEditAlt />
                  </label>
                );
              } else {
                nb = "";
                poster =
                  "https://karamale.com/apps/kscanner/posters/" +
                  item.businessid +
                  "/" +
                  item.eventid +
                  "/" +
                  item.thumbnail;
              }

              if (item.campaignstatus == "running") {
                boolean = true;
              } else {
                boolean = false;
              }
              return (
                <tr className="">
                  <td className="text-center">
                    {" "}
                    <label className="widgets">
                      {boolean}
                      <Switch
                        onChange={() => this.handleOnChange(item.eventid)}
                        checked={boolean}
                      />
                    </label>
                  </td>
                  <td className="text-center">
                    <label
                      className=""
                      onClick={() =>
                        this.handleTick(
                          item.eventid,
                          item.eventname,
                          item.poster,
                          item.eventday,
                          item.enddate,
                          item.event_day,
                          item.finish_day,
                          item.theme,
                          item.venue,
                          item.expected,
                          item.time,
                          item.endtime
                        )
                      }
                    >
                      {item.selected == 0 ? (
                        <BsApp size={30} color={"silver"} />
                      ) : (
                        <BsAppIndicator size={30} color={"#66b266"} />
                      )}
                    </label>
                  </td>
                  <td className="text-center">
                    <label
                      style={{
                        backgroundImage: `url(` + poster + `)`,
                        width: 49,
                        height: 49,
                        borderRadius: 100,
                        backgroundSize: "100%",
                      }}
                    ></label>
                  </td>
                  <td>
                    <label
                      className="item-names w-100"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      {campaignname}
                    </label>
                  </td>

                  <td>
                    <label
                      className="item-names w-100"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      {item.eventname}
                    </label>
                  </td>
                  <td>
                    {item.campaignstatus == "running" ? (
                      <label style={{ color: "#4ca64c" }}>Active</label>
                    ) : (
                      <label style={{ color: "silver" }}>Off</label>
                    )}
                  </td>

                  <td>{item.getPeriod} days</td>
                  <td>
                    {item.daysRemaining}{" "}
                    {item.daysRemaining == "---"
                      ? ""
                      : item.daysRemaining == 1
                      ? "day"
                      : "days"}
                  </td>
                  <td>{item.keywords}</td>
                  <td>
                    {item.budget < 350 ? (
                      <label style={{ color: "silver" }}>Lowest</label>
                    ) : item.budget <= 450 ? (
                      <label style={{ color: "silver" }}>Average</label>
                    ) : item.budget <= 999 ? (
                      <label style={{ color: "#4ca64c" }}>High</label>
                    ) : (
                      <label style={{ color: "#4ca64c" }}>Higher</label>
                    )}
                  </td>
                  <td> ZAR {item.budget}</td>
                  <td> ZAR {item.spent}</td>
                  <td className="cell-relative">
                    {" "}
                    ZAR {item.balance}{" "}
                    <sup>
                      {item.transferred == 1 ? (
                        <label className="fontsize-12 color-red">
                          (Transferred)
                        </label>
                      ) : (
                        ""
                      )}
                    </sup>
                    <br />
                    {item.balanceRaw > 0 && item.transferred == 0 ? (
                      <div>
                        <label
                          className="color-blue fontsize-12"
                          onClick={() =>
                            this.handleYesTransfer(
                              item.campaignid,
                              item.balance
                            )
                          }
                        >
                          Transfer balance{" "}
                          {item.markedtransfer == 0 ? (
                            <IoIosArrowDown size={15} />
                          ) : (
                            <IoIosArrowUp size={15} />
                          )}
                        </label>{" "}
                        <br />
                        {item.markedtransfer == 1 ? (
                          <label>
                            <input
                              className={"search-new-event"}
                              autoFocus={true}
                              placeholder="Search new event"
                              value={this.state.searchValue}
                              onChange={this.handleSearch}
                              autoComplete={"off"}
                            />
                            {loadSearchresults && (
                              <label className="sub-results-tranfer">
                                {this.state.queryResults.length < 1 ? (
                                  <div className="no-match-found text-center pl-3 pr-3">
                                    <span className="small-details font-size">
                                      Either you have no upcoming events <br />
                                      or there was no match for your <br />
                                      search
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <label className="w-100">
                                      <label className="pl-2 pr-2pt-2">
                                        <span className="fontsize-12">
                                          Click to transfer
                                        </span>
                                      </label>
                                      <div className="h-divider w-100 mt-0 mb-0" />
                                    </label>
                                    {this.state.queryResults.map(
                                      (item, index) => {
                                        const posterUri =
                                          "https://karamale.com/apps/kscanner/posters/" +
                                          item.businessid +
                                          "/" +
                                          item.eventid +
                                          "/" +
                                          item.poster;
                                        if (item.indexid >= 1) {
                                          return "";
                                        } else {
                                          return (
                                            <div className="row">
                                              <div
                                                className="col-12 pl-0 pr-0"
                                                key={index}
                                              >
                                                <div className="row">
                                                  <div
                                                    onClick={() =>
                                                      this.handleTransfer(
                                                        this.state.balance,
                                                        this.state.campaignid,
                                                        item.eventid,
                                                        item.spent
                                                      )
                                                    }
                                                    className="eventLinks links-width"
                                                  >
                                                    <div className="search-results-inner">
                                                      <div className="search-containe">
                                                        <BsSearch />
                                                      </div>
                                                      <div className="results-container align-self-center text-left">
                                                        {item.event_name}
                                                        {/* {item.event_name
                                                          .length > 20
                                                          ? (event =
                                                              item.event_name.substr(
                                                                0,
                                                                20
                                                              ) + "...")
                                                          : (event =
                                                              item.event_name)} */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                )}
                              </label>
                            )}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <label
                        className="small-details fontsize-12"
                        onClick={() =>
                          this.handleNotTransfer(item.transferred, item.balance)
                        }
                      >
                        Transfer balance
                      </label>
                    )}
                  </td>
                  <td> ZAR {item.revenue}</td>
                  <td> ZAR {item.digitalRevenue}</td>
                  <td> ZAR {item.physicalRevenue}</td>
                  <td> {item.buyers}</td>

                  <td>{item.admission}</td>
                  <td>{item.eventClicks}</td>
                  <td>{item.impressions}</td>
                  <td className="font-size-12 small-details">
                    <span>Digital:</span> 5%
                    <br />
                    <br />
                    <span>Physical:</span>
                    <br /> R1.0 per ticket download.
                    <br />
                    R3.50 per ticket print.
                  </td>
                  <td>{item.eventday}</td>
                  <td>{item.fromdate}</td>
                  <td>{item.todate}</td>
                </tr>
              );
            })
          )}
        </table>
      </div>
    );
  };

  handleScan(data) {
    this.setState({
      result: data,
    });
  }
  handleError(err) {
    console.error(err);
  }

  handleEdit = () => {
    if (this.state.count == 1) {
      // alert(this.state.eventid);
      this.props.history.push({
        pathname: "/campaign",
        state: {
          eventid: this.state.eventid,
          eventname: this.state.eventname,
          poster: this.state.poster,
          eventdate: this.state.eventdate,
          enddate: this.state.enddate,
          event_date: this.state.event_date,
          finish_date: this.state.finish_date,
          theme: this.state.theme,
          venue: this.state.venue,
          expectednum: this.state.expectednum,
          time: this.state.time,
          endtime: this.state.endtime,
          prevpage: "campaignmanager",
          currentpage: "campaignmanager",
        },
      });
    }
  };

  handleAnalytics = () => {
    if (this.state.count == 1) {
      // alert(this.state.endtime);
      this.props.history.push({
        pathname: "/analytics",
        state: {
          eventid: this.state.eventid,
          eventname: this.state.eventname,
          poster: this.state.poster,
          eventdate: this.state.eventdate,
          enddate: this.state.enddate,
          event_date: this.state.event_date,
          finish_date: this.state.finish_date,
          theme: this.state.theme,
          venue: this.state.venue,
          expectednum: this.state.expectednum,
          time: this.state.time,
          endtime: this.state.endtime,
          prevpage: "campaignmanager",
          currentpage: "campaignmanager",
          back: "campaignmanager",
        },
      });
    }
  };

  handleResume = () => {
    const businessid = localStorage.getItem("businessid");
    if (this.state.count > 1) {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=RESUMECAMPAIGNS&businessid=" +
          businessid
      )
        .then((response) => {})
        .catch((error) => console.log(error));

      this.setState({ loadBarProgress: true });
      setTimeout(() => {
        this.setState({ loadBarProgress: false });
      }, 2000);

      this.props.history.push("/campaignmanager");
    }
  };

  handleYesTransfer = (campaignid, balance) => {
    const businessid = localStorage.getItem("businessid");
    this.setState({
      searchValue: "",
      loadSearchresults: false,
      balance: balance,
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=UPDATETRANSFERCHECK&campaignid=" +
        campaignid +
        "&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 1) {
          this.setState({
            loadBarProgress: true,
            loadSearchBox: true,
            campaignid: campaignid,
          });
        } else {
          this.setState({
            loadBarProgress: true,
            loadSearchBox: false,
          });
        }
      })
      .catch((error) => console.log(error));
    this.props.history.push("/campaignmanager");
  };

  handleTransfer = (balance, campaignid, neweventid, spent) => {
    // alert(neweventid);

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TRANSFERBALANCE&campaignid=" +
        campaignid +
        "&neweventid=" +
        neweventid +
        "&balance=" +
        balance +
        "&spent=" +
        spent
    )
      .then((response) => {
        // alert(response.data.val);
        this.setState({
          loadBarProgress: true,
          loadSearchBox: true,
          campaignid: campaignid,
        });
      })
      .catch((error) => console.log(error));
    this.props.history.push("/campaignmanager");
  };

  handleNotTransfer = (transferredid, balance) => {
    if (transferredid == 1) {
      this.setState({
        error:
          "R" + balance + " has already been transferred to another campaign",
        errorDisplay: "block",
      });
    } else {
      this.setState({
        error: "You can't transfer if your balance is at R0.0",
        errorDisplay: "block",
      });
    }

    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 5000);
  };

  closeError = () => {
    this.setState({ errorDisplay: "none" });
  };

  handleSearch = async (event) => {
    // alert(this.state.campaignid);
    const campaignid = this.state.campaignid;
    const businessid = localStorage.getItem("businessid");
    this.setState({ searchValue: event.target.value });
    const searchVal = event.target.value;
    const formData = new FormData();
    formData.append("search", event.target.value);

    await Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=SEARCHEVENTS&search=" +
        searchVal +
        "&businessid=" +
        businessid +
        "&campaignid=" +
        campaignid
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({ queryResults: [] });
      } else {
        this.setState({ queryResults: response.data });
      }
    });

    if (!searchVal) {
      this.setState({ loadSearchresults: false });
    } else {
      this.setState({ loadSearchresults: true });
    }
  };

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");

    const previewStyle = {
      height: 240,
      width: 320,
      border: "thin solid red",
    };
    const {
      loadBarProgress,
      refreshingText,
      loadingEvents,
      loadCustomers,
      loadingPlatforms,
    } = this.state;
    let activeEdit = "";

    if (this.state.count == 1) {
      activeEdit = "";
    } else {
      activeEdit = "button-disabled";
    }
    return (
      <div className="view">
        <Header />
        {loadBarProgress && <TopBarProgress />}
        <div className="containe pl-1 pr-1">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-12">
              <div className="col-12 mt-5 mb-2">
                <span>
                  {this.state.count == 1
                    ? this.state.campaignname
                    : this.state.count == 0
                    ? "No campaigns selected"
                    : this.state.count + " Campaigns selected"}
                </span>
              </div>
              <div className=" BoxDashboardContainer mb-4 pb-3  pl-3 pr-3">
                <div className="">
                  <div className="row vertical-scroll top-data-taps">
                    <div className="col-lg-2 col-4 col-sm-3 col-md-3 mt-2">
                      <div className="item-list pl-1">
                        <label className="ticket-title  pl-0 pr-0">
                          CAMPAIGNS
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-10 col-8 col-sm-9 col-md-9 text-right  pl-0 pr-0">
                      {refreshingText && (
                        <label className="refreshing-text">Refreshing...</label>
                      )}
                      &nbsp; &nbsp;
                      <label
                        className="refresh-icon"
                        data-tip="Refresh"
                        onClick={() => this.refresh()}
                      >
                        <IoIosRefresh size={20} />
                        <ReactTooltip />
                      </label>
                      {this.state.count == 0 ? (
                        ""
                      ) : (
                        <label className="new-campaign-button button-select">
                          {this.state.count} Selected
                        </label>
                      )}
                      {this.state.allselected >= 1 ? (
                        <label
                          className={
                            this.state.count == 1
                              ? "new-campaign-button button-disabled"
                              : "new-campaign-button"
                          }
                          onClick={() => this.handleResume()}
                        >
                          <BsArrowRight /> Resume/Pause all campaigns
                        </label>
                      ) : (
                        ""
                      )}
                      <label
                        className={this.state.platforms + " " + activeEdit}
                        onClick={() => this.Platforms()}
                      >
                        Devices and Agents
                      </label>
                      <label
                        className={"new-campaign-button " + activeEdit}
                        onClick={() => this.handleAnalytics()}
                      >
                        <BsGraphUp /> Analytics
                      </label>
                      <label
                        className={this.state.customers + " " + activeEdit}
                        onClick={() => this.Customers()}
                      >
                        <BsPeople /> Unique buyers
                      </label>
                      <label
                        className={"new-campaign-button " + activeEdit}
                        onClick={() => this.handleEdit()}
                      >
                        <BsPen /> Edit
                      </label>
                      <label
                        className={this.state.newcampaignbutton}
                        onClick={() => this.newCampaign()}
                      >
                        <IoIosAddCircleOutline /> Create campaign
                      </label>
                    </div>

                    {/* <div className="h-divider w-100" /> */}
                  </div>
                  {loadingPlatforms && (
                    <div className="listplatforms BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3 w-100">
                        <strong>
                          Devices and agents used to access this event
                        </strong>
                        <br />
                        <label className="small-details">
                          {this.state.campaignname}
                        </label>
                        <label className="float-right">
                          <Link
                            to={{
                              pathname: "addevent",
                              state: { prevpage: "dashboard" },
                            }}
                          ></Link>
                        </label>
                      </label>

                      <div className="h-divider w-100" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {this.platformlist()}
                      </div>
                    </div>
                  )}

                  {loadingEvents && (
                    <div className="select-event BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3 w-100 mb-0">
                        <strong>Select event</strong>
                        <label className="float-right">
                          <Link
                            to={{
                              pathname: "addevent",
                              state: { prevpage: "dashboard" },
                            }}
                          >
                            <label className="new-event-btn">
                              Create event
                            </label>
                          </Link>
                        </label>
                      </label>

                      <div className="h-divider w-100 mt-0" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {this.events()}
                      </div>
                    </div>
                  )}
                  {loadCustomers && (
                    <div className="listcustomers BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3">
                        <span>{this.state.campaignname}</span>
                      </label>

                      <div className="h-divider w-100" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {this.customerlist()}
                      </div>
                    </div>
                  )}
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
          onClick={() => this.closeError()}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Campaignmanager;
