import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactTooltip from "react-tooltip";
import logo from "../logo.png";
import "../App.css";
import { Link, NavLink } from "react-router-dom";
import SlideDrawer from "./slideDrawer/SlideDrawer5";
import Backdrop from "./backDrop/Backdrop";
import CloseX from "./CloseX/CloseX";
import {
  BsArrowLeftShort,
  BsPerson,
  BsList,
  BsAppIndicator,
  BsLock,
  BsPen,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";

import { IoIosLogOut } from "react-icons/io";

class Header extends React.Component {
  state = {
    searchValue: "",
    response: [],
    queryResults: [],
    resultsComponent: false,
    open: true,
    openLogo: false,
    arrowIcon: true,
    email: "",
    phone: "",
    customername: "",
    sideDrawerOpen: false,
    showHeader: "",
    campaignCount: 0,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {
    const partnerid = localStorage.getItem("partnerid");
  }

  handleSearchArrow = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
  };

  Appheader = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
  };

  render() {
    let slideDrawer;
    let backDrop;
    let closeX;

    if (this.state.sideDrawerOpen) {
      slideDrawer = <SlideDrawer />;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
      closeX = <CloseX click={this.backDropClickHandler} />;
    }

    const { queryResults } = this.state;
    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    let event = "";

    return (
      <div className="App">
        {slideDrawer}
        {backDrop}
        {closeX}
        <header className="App-header" onClick={this.Appheader}>
          <div className="row">
            <div className="Header-wrapper col-lg-4 col-md-10 col-sm-10 col-10 pl-4">
              <BsArrowLeftShort
                className="textGrey hand-cursor search-arrow"
                style={arrowIconStyle}
                onClick={this.handleSearchArrow}
              />{" "}
              &nbsp;
              <Link to="/in" className="links logo-karamale" style={logoStyle}>
                <img
                  src={logo}
                  alt={logo}
                  className="header-app-logo"
                  align="left"
                />
              </Link>
              <label className="campaignmanager-link">
                <div className="campaign-link" target="_blank">
                  Account type:{" "}
                  <label className="small-details">affiliate</label>
                </div>
              </label>
            </div>

            <div
              className="col-lg-2 col-md-1 col-2 more-col-mobile mobile-top-links pl-0"
              onClick={this.drawerToggleClickHandler}
            >
              <div className="more-icons" data-tip="More">
                <ReactTooltip />
                <BsList />
              </div>
            </div>

            {/* <div className="col-lg-1 space-div"></div> */}

            <div className="menu-row-desktop col-lg-7 col-md-7 p-0">
              <div className="row">
                {/* <div className="col-lg-2 col-md-2 col-2"></div> */}
                <div className="col-lg-2 col-md-2 col-2 home-link mobile-top-links">
                  <NavLink
                    to="/in"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Dashboard"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      {" "}
                      <ReactTooltip />
                      <BsAppIndicator size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  <NavLink
                    to="/recruits"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Recruits"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <FaHandshake size={22} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to={{ pathname: "/profile" }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Profile"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsPerson size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to={{
                      pathname: "/changepassword",
                      state: {
                        credentials: "password",
                      },
                    }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Change password"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsLock size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  <NavLink
                    to="/feedback"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Feedback"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsPen size={22} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-2 mobile-liner"></div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/logout"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Log out"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <IoIosLogOut size={25} />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </header>
        <div className="spacer" />
      </div>
    );
  }
}

export default Header;
