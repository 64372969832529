import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiQrCodeLine } from "react-icons/ri";
import Header2 from "./components/Header2";
import Header from "./components/Header";

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      previews: [],
      eventcategory: [],
    };
  }

  componentDidMount() {
    document.title = "Categories - Karamale";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTCATEGORY&limit="
    ).then((response) => {
      this.setState({
        eventcategory: response.data,
        spinning: "none",
      });
    });
  }

  categories = () => {
    let activeString = "";
    const customerid = localStorage.getItem("customerid");
    const customername = localStorage.getItem("customername");
    let category = "";

    return (
      <div className="pb-2">
        {this.state.eventcategory.map((items, index) => {
          return (
            <Link
              to={{
                pathname: "/category",
                state: {
                  fromHome: true,
                  provinceid: items.provinceid,
                  category: items.category,
                  province: items.province,
                },
              }}
              className="eventLinks"
            >
              <div
                className={"provinces pt-3 pb-3 pl-3 pr-3 cats-more"}
                key={index}
              >
                {items.category}
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  searchQuery = (text) => {
    let value = text.target.value;
    this.setState({ query: value });
    let query = value.split(" ").join("+");
    this.setState({ isLoading: true, loadingMerch: false });
    const customerid = localStorage.getItem("customerid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERTICKETS&subquery=SEARCHTICKETS&search=" +
        query +
        "&customerid=" +
        customerid
    )
      .then((response) => {
        // alert(response.data.val);
        if (response.data.val == 0) {
          this.setState({
            previews: [],
          });
        } else {
          this.setState({
            previews: response.data,
          });
        }
        // alert(response.data.search);
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <div className="view">
        {localStorage.getItem("customername") === null ? (
          <Header />
        ) : (
          <Header2 />
        )}
        <div className="container pb-5">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7">
              <div className=" BoxDashboardContainer pl-0 pr-0 mt-5 mb-4 mrgin-search">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-3 pl-0">
                      <Link to="/" className="link-btn">
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-9 text-right">
                      <label className="back-arrow">
                        <IoIosInformationCircleOutline
                          size={40}
                          color={"#fff"}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        CATEGORIES
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.categories()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Categories;
