import React, { Component } from "react";
import "./SlideDrawer.css";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { IoMdHeartEmpty, IoIosLogOut } from "react-icons/io";
import { BsPen, BsPerson, BsAppIndicator, BsCalendar } from "react-icons/bs";

class SlideDrawer2 extends Component {
  state = {
    products: [],
  };
  componentDidMount() {
    let search = window.location.search;
    let query = new URLSearchParams(search);
    let ticketid = query.get("ticketid");
    let eventid = query.get("eventid");

    Axios.get(
      "/api/select/payment/method?eventid=" + eventid + "&ticketid=" + ticketid
    ).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  render() {
    let drawerClasses = "slide-drawer";
    if (this.props.show) {
      drawerClasses = "slide-drawer open";
    }
    return (
      <div className={drawerClasses}>
        <div className="row">
          <div className="col-lg-12 p-0">
            <NavLink
              to="/customerdashboard"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight ">
                <BsAppIndicator size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Dashboard
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink to="" className="links" exact activeClassName="activeLink">
              <div className="col-lg-12 mobile-nav textLight">
                <BsCalendar size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Events
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to="/likes"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoMdHeartEmpty size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Likes
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/profile"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPerson size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Profile
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/feedback"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPen size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Send feedback
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "logout",
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoIosLogOut size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;log out
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideDrawer2;
