import React, { useState, useEffect } from "react";
import "./App.css";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Header3 from "./components/Header3";
import ClipLoader from "react-spinners/ClipLoader";

function Smsmarketingcancel(props) {
  useEffect(() => {
    const textid = localStorage.getItem("textid");
    const businessid = localStorage.getItem("businessid");
    const audience = localStorage.getItem("audience");

    document.title = "Marketing";

    axios
      .get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETTEXTMESSAGE&textid=" +
          textid +
          "&businessid=" +
          businessid
      )
      .then((response) => {
        setText(response.data.text);
        setSpinner(false);
      });
  });

  const [text, setText] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [statusProgress, setStatusProgress] = useState(
    <div>
      <label className="color-red font-size-15 w-100 text-center">
        <IoIosCloseCircleOutline size={25} color={"red"} />
        <br />
        Cancelled
      </label>
      <label className="w-100 mb-0 text-center">
        <Link to="/dashboard">
          <label className="color-blue text-center">
            Return to the dashboard
          </label>
        </Link>
      </label>
    </div>
  );

  if (localStorage.getItem("businessid") === null) {
    return <Redirect to="/login" />;
  } else {
    if (localStorage.getItem("textid") === null) {
      return <Redirect to="/sms" />;
    }
  }

  return (
    <div className="view">
      <Header3 />

      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-7 mt-5 pl-0 pr-0">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-lg-12 col-10 col-sm-11">
                    <label className=" pt-2 color-green w-100">
                      {statusProgress}
                    </label>
                  </div>
                </div>

                <div className="h-divider w-100 mt-0"></div>
                <div className="col-12 text-left p-3 pl-4">
                  <span>Text message</span>
                </div>
                <div className={"item-select-active"}>
                  <div className="link-btn w-100 menu-links">
                    <div className="col-12">
                      <label className="item-names">
                        {text}
                        {spinner && <ClipLoader size={20} color={"grey"} />}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Smsmarketingcancel;
