import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import {
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
  IoMdEye,
  IoIosAttach,
} from "react-icons/io";
import Header3 from "./components/Header3";
import ClipLoader from "react-spinners/ClipLoader";

function Uploaddata(props) {
  useEffect(() => {
    document.title = "Marketing campaign";
  });

  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [count, setCount] = useState(0);
  const [countColor, setCountColor] = useState("countColor");
  const [optionOne, setOptionOne] = useState("item-select-active");
  const [optionTwo, setOptionTwo] = useState("item-select");

  const [offRadioOne, setOffRadioOne] = useState(false);
  const [onRadioOne, setOnRadioOne] = useState(true);

  const [offRadioTwo, setOffRadioTwo] = useState(true);
  const [onRadioTwo, setOnRadioTwo] = useState(false);

  const [marketingType, setMarketingType] = useState("SMS");

  const [recipients, setRecipients] = useState(
    localStorage.getItem("audience")
  );

  const [alias, setAlias] = useState(localStorage.getItem("alias"));

  const [attachment, setAttachment] = useState("");
  const [attachmentLabel, setAttachmentLabel] = useState("Upload the file");

  const [removeImage, setRemoveImage] = useState("");
  const [showAttach, setShowAttach] = useState(true);

  const [presAttachment, setPreAttachment] = useState("");
  const [attachmentName, setAttachmentName] = useState("");
  const [type, setType] = useState("");
  const [path, setPath] = useState("");

  const [loadLabel, setLoadLabel] = useState(true);
  const [loadCliper, setLoadCliper] = useState(false);

  const [errorDisplay, setErrorDisplay] = useState("none");
  const [error, setError] = useState("");

  const [active, setActive] = useState(1);

  const [responsebg, setResponseBG] = useState("error-login");
  //   alert(alias);

  async function next() {
    setLoadLabel(false);
    setLoadCliper(true);

    setErrorDisplay("none");
    setActive(1);

    const businessid = localStorage.getItem("businessid");

    let data = new FormData();
    data.append("file", attachment);
    data.append("businessid", businessid);
    data.append("subqueryone", marketingType); // SMS OR EMAIL
    data.append("subquerytwo", alias); // EXISTINGAUDIENCE, DATABASE OR NEWAUDIENCE
    data.append("query", "UPLOADDATA");

    if (attachment == "") {
      setErrorDisplay("block");
      setError("Upload file");
      setResponseBG("error-login");
      setLoadLabel(true);
      setLoadCliper(false);
    } else {
      await axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        if (response.data.val == 0) {
          setErrorDisplay("block");
          setError("Invalid file");
          setResponseBG("error-login");
        } else {
          if (marketingType == "EMAIL") {
            setLoadLabel(true);
            setLoadCliper(false);
          } else {
            setLoadLabel(true);
            setLoadCliper(false);
          }

          localStorage.setItem("alias", "NEWAUDIENCE");
          localStorage.setItem("type", marketingType);

          props.history.push({
            pathname: "marketing",
          });
        }

        setLoadLabel(true);
        setLoadCliper(false);

        //   setActive(0);
      });

      setTimeout(() => {
        setErrorDisplay("none");
      }, 4000);
    }
  }

  function handleText(event) {
    setText(event.target.value);
    setCount(event.target.value.length);
    if (event.target.value.length > 130) {
      setCountColor("countColor-error");
    } else {
      setCountColor("countColor");
    }
  }

  function handleSubject(event) {
    setSubject(event.target.value);
  }

  function email() {
    setOptionOne("item-select");
    setOptionTwo("item-select-active");

    setOffRadioOne(true);
    setOnRadioOne(false);

    setOffRadioTwo(false);
    setOnRadioTwo(true);
    setMarketingType("EMAIL");

    setErrorDisplay("none");
    setActive(1);
  }

  function sms() {
    setOptionTwo("item-select");
    setOptionOne("item-select-active");

    setOffRadioTwo(true);
    setOnRadioTwo(false);

    setOffRadioOne(false);
    setOnRadioOne(true);
    setMarketingType("SMS");

    setErrorDisplay("none");
    setActive(1);
  }

  function removeAttachment() {
    setAttachmentLabel("Upload the file");
    setAttachment("");
    setErrorDisplay("none");
    setActive(1);
  }

  function selectFile(event) {
    const img = event.target.files[0];
    setAttachment(event.target.files[0]);
    setPreAttachment(URL.createObjectURL(event.target.files[0]));
    setAttachmentName(img.name);
    setType(img.type);
    setPath(URL.createObjectURL(event.target.files[0]));

    setAttachmentLabel("Re-upload the file");
    setRemoveImage(
      <label className="mt-3 color-red hover-text" onClick={removeAttachment}>
        <i>Remove attachment</i>
      </label>
    );

    setErrorDisplay("none");
    setActive(1);
  }

  if (localStorage.getItem("businessname") === null) {
    return <Redirect to="/login" />;
  } else {
  }
  const businessname = localStorage.getItem("businessname");
  const businessid = localStorage.getItem("businessid");
  const accounttype = localStorage.getItem("accounttype");
  //   alert(recipients.replace(",", ""));
  const total = recipients * 0.9;

  return (
    <div className="view">
      <Header3 />
      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-7 mt-5  pl-2 pr-2">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-lg-12 col-10 col-sm-11">
                    <label className=" pt-2">
                      <span>Marketing</span>
                    </label>

                    <label class="back-arrow float-right">
                      {attachment == "" ? (
                        <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                          <span>Upoad</span>
                        </div>
                      ) : (
                        <div
                          class="save-btn btn-radius-3"
                          onClick={next.bind(this)}
                        >
                          {loadLabel && <span>Upload</span>}
                          {loadCliper && (
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              size={20}
                            />
                          )}
                        </div>
                      )}
                    </label>
                  </div>
                </div>

                <div className="h-divider w-100"></div>
                <div className="row w-100">
                  <div className="col-12 text-left p-3">
                    <span>Which data are you uploading for?</span>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <div className={optionOne} onClick={sms}>
                      <div className="link-btn w-100 menu-links">
                        <div className="col-lg-2 col-2 col-sm-2 text-left">
                          {onRadioOne && (
                            <IoIosRadioButtonOn size={28} color={"#66b266"} />
                          )}
                          {offRadioOne && (
                            <IoIosRadioButtonOff size={28} color={"#b2b200"} />
                          )}
                        </div>
                        <div className="col-lg-10 col-10 col-sm-10">
                          <label className="item-names w-100">
                            SMS marketing
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <div className={optionTwo} onClick={email}>
                      <div className="link-btn w-100 menu-links">
                        <div className="col-lg-2 col-2 col-sm-2 text-left">
                          {onRadioTwo && (
                            <IoIosRadioButtonOn size={28} color={"#66b266"} />
                          )}
                          {offRadioTwo && (
                            <IoIosRadioButtonOff size={28} color={"#b2b200"} />
                          )}
                        </div>

                        <div className="col-lg-10 col-10 col-sm-10">
                          <label className="item-names w-100">
                            Email marketing
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-left p-3">
                  <span>Upload your own data</span>
                  <br />
                  <br />
                  <label className="w-100 mb-0">
                    [ Follow the below steps ]
                  </label>
                </div>

                <div className="col-12 text-left pt-0 pb-0">
                  {marketingType === "SMS" ? (
                    <Link
                      to={{
                        pathname:
                          "https://karamale.com/apps/kscanner/structure/sms_structure.csv",
                      }}
                      target={"_top"}
                    >
                      <span className="color-blue font-normal">
                        1. Download sms structure
                      </span>
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname:
                          "https://karamale.com/apps/kscanner/structure/email_structure.csv",
                      }}
                      target={"_top"}
                    >
                      <span className="color-blue font-normal">
                        1. Download email structure
                      </span>
                    </Link>
                  )}
                  <br />
                  <br />
                  <label className="color-grey">2. Fill in data</label>
                  <br />
                  <br />
                  <label className="color-blue" htmlFor="image">
                    3. {attachmentLabel}
                  </label>
                  <br />
                  {attachmentName}

                  <input
                    name="file"
                    type="file"
                    onChange={selectFile}
                    id="image"
                    className="hide-file-input-type"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={responsebg + " text-center"}
        style={{ display: errorDisplay }}
      >
        {error}
      </div>
    </div>
  );
}

export default Uploaddata;
