import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import {
  IoIosArrowBack,
  IoIosAddCircleOutline,
  IoMdCheckmark,
} from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import Header3 from "./components/Header3";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import TopBarProgress from "react-topbar-progress-indicator";

class Addmodels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      eventtype: "",
      name: "",
      age: null,
      addPoster: false,
      settings: false,
      hideUploadBtn: "none",
      spinning: "none",
      updateDisplay: "block",
      activeBox: "box-upload",
      image: null,
      path: "",
      type: "image/jpeg",
      preimage: null,
      height: null,
      width: null,
      uploadsymbol: true,
      gender: "",
      bio: "",
      modelid: localStorage.getItem("modelid"),
      photo: null,
      dtcreated: null,
      loadBarProgress: false,
      dataFound: 0,
      free: "item-select-active",
      costly: "item-select",
      offRadioTwo: false,
      onRadioTwo: true,
      offRadioThree: true,
      onRadioThree: false,
      points: null,
      price: null,
      warningLabel: "",
      configure: "Configure",
      note: (
        <label className="mb-0">
          <span>NB:</span> If price is set to free, people will vote free of
          charge.
        </label>
      ),
    };
  }

  componentDidMount() {
    document.title = "Add models - Karamale";
    localStorage.setItem("costs", "free");
    const eventid = localStorage.getItem("eventid");
    const businessid = localStorage.getItem("businessid");
    const modelid = this.state.modelid;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CONFIGURATIONDETAILS&eventid=" +
        eventid +
        "&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({
          warningLabel: (
            <label>
              &nbsp;
              <BsExclamationCircle className="color-red" size={15} />
            </label>
          ),
        });
      } else {
        this.setState({
          warningLabel: (
            <label>
              <IoMdCheckmark className="color-green" size={20} />
            </label>
          ),
        });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MODELDETAILS&modelid=" +
        modelid
    ).then((response) => {
      //   alert(response.data.val);
      if (response.data.val == 1) {
        this.setState({
          photo: response.data.photo,
          name: response.data.model,
          age: response.data.age,
          gender: response.data.gender,
          bio: response.data.bio,
          dtcreated: response.data.dtcreated,
          dataFound: 1,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    document.title = "Add models - Karamale";
    localStorage.setItem("costs", "free");
    const eventid = localStorage.getItem("eventid");
    const businessid = localStorage.getItem("businessid");
    const modelid = this.state.modelid;
    if (prevState.warningLabel !== this.state.warningLabel) {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CONFIGURATIONDETAILS&eventid=" +
          eventid +
          "&businessid=" +
          businessid
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({
            warningLabel: (
              <label>
                &nbsp;
                <BsExclamationCircle className="color-red" size={15} />
              </label>
            ),
          });
        } else {
          this.setState({
            configure: "Adjust points",
            warningLabel: (
              <label>
                <IoMdCheckmark className="color-green" size={20} />
              </label>
            ),
          });
        }
      });
    }
  }

  saveSettings = () => {
    const amount = this.state.price;
    const points = this.state.points;
    const eventid = localStorage.getItem("eventid");
    const businessid = localStorage.getItem("businessid");
    if (amount === null || points === null) {
      this.setState({
        error: "Both amount and points must be set",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      this.setState({ loadBarProgress: true });
      //   alert(points);

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=INSERTPOINTS&eventid=" +
          eventid +
          "&businessid=" +
          businessid +
          "&amount=" +
          amount +
          "&points=" +
          points
      ).then((response) => {
        // alert(response.data.val);
        this.setState({
          loadBarProgress: false,
          error: "Points successfully saved. You can add more or update.",
          errorDisplay: "block",
          successError: "success-login",
          settings: false,
          configure: "Adjust points",
        });
      });

      this.props.history.push({
        pathname: "/addmodels",
      });
    }

    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 5000);
  };

  bio = (event) => {
    this.setState({
      bio: event.target.value,
      errorDisplay: "none",
    });
  };

  selectImage = (event) => {
    const img = event.target.files[0];

    this.setState({
      image: URL.createObjectURL(event.target.files[0]),
      preimage: URL.createObjectURL(event.target.files[0]),
      imgname: img,
      type: img.type,
      path: URL.createObjectURL(event.target.files[0]),
      activeBox: "box-upload-dis",
      hideUploadBtn: "block",
      height: 300,
      width: 400,
      uploadsymbol: false,
    });
  };

  uploadPicture = async () => {
    const eventid = localStorage.getItem("eventid");
    const accountid = localStorage.getItem("businessid");
    const type = this.state.type;
    const path = this.state.path;
    const fileName = this.state.imgname;
    const fname = this.state.name;
    const age = this.state.age;
    const gender = this.state.gender;
    const bio = this.state.bio;
    let data = new FormData();

    this.setState({
      updateDisplay: "none",
      spinning: "block",
    });

    // alert(path);

    // data.append("file", { type: type, uri: path, name: path.split("/").pop() });
    data.append("file", this.state.imgname);
    data.append("eventid", eventid);
    data.append("businessid", accountid);
    data.append("fname", fname);
    data.append("age", age);
    data.append("gender", gender);
    data.append("bio", bio);
    data.append("modelid", localStorage.getItem("modelid"));
    data.append("query", "UPLOADMODELPICTURE");

    if (this.state.image === null && this.state.modelid < 1) {
      this.setState({
        error: "Please select a picture",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (fname === "") {
      this.setState({
        error: "Enter the model's name",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (age === "") {
      this.setState({
        error: "Enter the model's age",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (age < 18) {
      this.setState({
        error: "Age entered is invalid",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (gender === "") {
      this.setState({
        error: "Enter the model's gender",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (bio === "") {
      this.setState({
        error: "Write a small bio about this model",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      this.setState({ loadBarProgress: true });
      await Axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          // alert(response.data.filename);
          this.setState({
            error: "Successful!",
            errorDisplay: "block",
            successError: "success-login",
            updateDisplay: "block",
            spinning: "none",
            addPoster: false,
            checkposter: "yes",
            loadBarProgress: false,
            addPoster: false,
          });
        })
        .catch((error) => {
          console.log("image upload error: ", error);
        });

      setTimeout(() => {
        this.setState({ errorDisplay: "none" });
      }, 4000);

      this.props.history.push({
        pathname: "/models",
      });
    }
  };

  handleChange = (e) => {
    this.setState({ gender: e.value, errorDisplay: "none" });
  };

  handlePoints = (e) => {
    this.setState({ points: e.value });
  };

  handlePrice = (e) => {
    if (e.value > 0) {
      this.setState({
        note: (
          <label className="mb-0">
            <span>NB:</span> Adding an amount is good if you are raising funds
          </label>
        ),
      });
    } else {
      this.setState({
        note: (
          <label className="mb-0">
            <span>NB:</span> If price is set to free, people will vote free of
            charge.
          </label>
        ),
      });
    }
    this.setState({
      price: e.value,
    });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
      errorDisplay: "none",
    });
  };

  handleAge = (event) => {
    this.setState({
      age: event.target.value,
      errorDisplay: "none",
    });
  };

  addPoster = () => {
    this.setState({ addPoster: true, errorDisplay: "none" });
  };

  settings = () => {
    this.setState({ settings: true, errorDisplay: "none" });
  };

  closeModal = () => {
    this.setState({
      addPoster: false,
      settings: false,
    });
  };

  free = () => {
    if (this.state.offRadioTwo == true) {
      this.setState({
        offRadioTwo: false,
        onRadioTwo: true,
        free: "item-select-active",

        offRadioThree: true,
        onRadioThree: false,
        costly: "item-select",
      });
    }
    // else {
    //   this.setState({
    //     offRadioTwo: true,
    //     onRadioTwo: false,
    //     free: "item-select",
    //   });
    // }

    localStorage.setItem("costs", "free");
  };

  costly = () => {
    if (this.state.offRadioThree == true) {
      this.setState({
        offRadioThree: false,
        onRadioThree: true,
        costly: "item-select-active",

        offRadioTwo: true,
        onRadioTwo: false,
        free: "item-select",
      });
    }
    // else {
    //   this.setState({
    //     offRadioThree: true,
    //     onRadioThree: false,
    //     costly: "item-select",
    //   });
    // }

    localStorage.setItem("costs", "payment");
  };

  render() {
    const {
      gender,
      uploadsymbol,
      loadBarProgress,
      onRadioTwo,
      offRadioTwo,
      onRadioThree,
      offRadioThree,
    } = this.state;
    let { photo } = "";
    photo =
      "https://karamale.com/apps/kscanner/models/" +
      localStorage.getItem("businessid") +
      "/" +
      localStorage.getItem("eventid") +
      "/" +
      this.state.photo;
    const data = [
      { label: "Female", value: "female" },
      { label: "Male", value: "male" },
    ];

    const points = [
      { label: "5 Points", value: 5 },
      { label: "10 Points", value: 10 },
      { label: "20 Points", value: 20 },
      { label: "35 Points", value: 35 },
    ];

    const price = [
      { label: "Free", value: 0 },
      { label: "R 25", value: 25 },
      { label: "R 35", value: 35 },
      { label: "R 60", value: 60 },
      { label: "R 100", value: 100 },
    ];

    let { accountname, dashboard } = "";
    if (localStorage.getItem("customername") === null) {
      if (localStorage.getItem("businessname") === null) {
        if (localStorage.getItem("partnername") === null) {
          return <Redirect to="/login" />;
        } else {
          accountname = localStorage.getItem("partnername");
          dashboard = "in";
        }
      } else {
        accountname = localStorage.getItem("businessname");
        dashboard = "dashboard";
      }
    } else {
      accountname = localStorage.getItem("customername");
      dashboard = "customerdashboard";
    }
    const customername = accountname;
    return (
      <div className="view">
        <Header3 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <Modal show={this.state.settings} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row w-100 mb-4 justify-content-end align-self-center">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div className="save-btn" onClick={this.saveSettings}>
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        Save
                      </span>

                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <label className="back-arrow text-center">
                    <div
                      className="cancel-btn text-center"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-0 pr-0 pt-0 pb-0">
                    <div className="h-divider w-100 mb-0"></div>
                  </div>
                  <div className="col-12 mt-4 mb-0 justify-content-center p-0 w-100">
                    <label className="float-left mb-0">
                      <span>Configure points</span>
                    </label>

                    {/* <div
                      className={this.state.free + " border-1 w-100 mt-5"}
                      onClick={this.free}
                    >
                      <div className="link-btn w-100 menu-links row justify-content-center align-items-center">
                        <div className="col-lg-1 col-2 text-center">
                          {onRadioTwo && (
                            <IoMdCheckmark size={28} color={"#66b266"} />
                          )}
                          {offRadioTwo && (
                            <IoIosRadioButtonOff size={28} color={"#ffc04c"} />
                          )}
                        </div>

                        <div className="col-lg-11 col-10">
                          <label className="item-names mb-0">Free</label>
                          <br />
                          <label className="mb-0 small-details font-size-12">
                            People will cast votes free of charge
                          </label>
                        </div>
                      </div>
                    </div> */}

                    {/* <div
                      className={this.state.costly + " border-1 w-100 mt-3"}
                      onClick={this.costly}
                    >
                      <div className="link-btn w-100 menu-links row justify-content-center align-items-center">
                        <div className="col-lg-1 col-2 text-center">
                          {onRadioThree && (
                            <IoMdCheckmark size={28} color={"#66b266"} />
                          )}
                          {offRadioThree && (
                            <IoIosRadioButtonOff size={28} color={"#39c"} />
                          )}
                        </div>

                        <div className="col-lg-11 col-10">
                          <label className="item-names mb-0">Add payment</label>
                          <br />
                          <label className="mb-0 small-details font-size-12">
                            Only recommended if you want to raise funds
                          </label>
                        </div>
                      </div>
                    </div>
                   */}
                    <br />

                    <div className="row align-items-center">
                      <div className="col-lg-5 p-0">
                        <Select
                          value={price.find((obj) => obj.value === price)}
                          placeholder="Set amount"
                          options={price}
                          onChange={this.handlePrice}
                          width={"100%"}
                          className="text-left mt-2"
                        />
                      </div>
                      <div className="col-lg-2 text-center pt-3 pb-2">for</div>
                      <div className="col-lg-5 p-0">
                        <Select
                          value={points.find((obj) => obj.value === points)}
                          placeholder="Set points"
                          options={points}
                          onChange={this.handlePoints}
                          width={"100%"}
                          className="text-left mt-2"
                        />
                      </div>

                      <div className="col-12 p-0 mt-3">
                        <label className="mb-0 small-details">
                          {this.state.note}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.addPoster} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row w-100 mb-4 justify-content-end align-self-center">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div
                      className="save-btn"
                      style={{ display: this.state.hideUploadBtn }}
                      onClick={this.uploadPicture.bind(this)}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {this.state.dataFound == 0 ? "Add" : "Update"}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>

                  <label className="back-arrow text-center">
                    <div
                      className="cancel-btn text-center"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-4 pr-2 pt-0 pb-0">
                    <div className="h-divider w-100"></div>
                  </div>

                  <div
                    className="col-12 mt-4 mb-4 justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <label
                      className={this.state.activeBox}
                      style={{
                        backgroundImage: "url(" + this.state.image + ")",
                        backgroundSize: "cover",
                        cursor: "pointer",
                        // backgroundRepeat: "no-repeat",
                        backgroundPosition: "top",
                        maxHeight: 350,
                      }}
                    >
                      {uploadsymbol && <IoIosAddCircleOutline size={20} />}
                      <input
                        name="file"
                        type="file"
                        onChange={this.selectImage}
                        id="image"
                        accept={".jpg,.jpeg,.png"}
                        className="hide-file-input-type"
                      />
                      {/* <img
                        src={this.state.image}
                        width={"100%"}
                        htmlFor="image"
                        className="img-poster"
                      /> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={
                          localStorage.getItem("modelid") > 0
                            ? "models"
                            : "dashboard"
                        }
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div
                          className="save-btn"
                          onClick={() => this.uploadPicture()}
                        >
                          {this.state.dataFound == 0 ? "Add" : "Update"}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2 small-details">
                Prepare a voting link for this model
                <label className="float-right mb-0">
                  <Link to="/models">
                    <label className="color-blue mb-0">Models</label>
                  </Link>
                  &nbsp; - &nbsp;
                  <label className="color-blue mb-0" onClick={this.settings}>
                    {this.state.configure} {this.state.warningLabel}
                  </label>
                </label>
              </div>
              <div className=" BoxDashboardContainer pl-2 pr-2 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        ADD MODEL PROFILE
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body pb-3">
                  <div
                    className="row justify-content-center align-self-center"
                    onClick={() => this.addPoster()}
                  >
                    <div
                      className="box-upload"
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 100,
                        backgroundImage: `url(` + photo + `)`,
                        backgroundSize: "100%",
                      }}
                    >
                      <IoIosAddCircleOutline />
                    </div>
                    <br />
                    <div className="col-12 text-center mt-3">
                      <span>Upload picture</span>
                    </div>
                  </div>
                  <br />
                  Name and surname
                  <br />
                  <input
                    type="text"
                    placeholder="Enter full name"
                    value={this.state.name}
                    onChange={this.handleName}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Age
                  <br />
                  <input
                    type="number"
                    placeholder="Enter age"
                    value={this.state.age}
                    onChange={this.handleAge}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Gender
                  <br />
                  <Select
                    value={data.find((obj) => obj.value === gender)}
                    placeholder="Select event type"
                    options={data}
                    onChange={this.handleChange}
                    width={"100%"}
                    className="text-left mt-2"
                  />
                  <br />
                  Bio
                  <br />
                  <textarea
                    placeholder="A brief description about the model"
                    onChange={this.bio}
                    className="edit-input"
                    value={this.state.bio}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Addmodels;
