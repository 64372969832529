import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Axios from "axios";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class Partner extends React.Component {
  state = {
    business: "",
    email: "",
    phone: "",
    pass1: "",
    pass2: "",
    response: "",
    error: "",
    errorDisplay: "none",
    spinning: "none",
    updateDisplay: "block",
    optionOne: "item-select-active",
    optionTwo: "item-select",
    onRadioOne: true,
    offRadioOne: false,
    offRadioTwo: true,
    onRadioTwo: false,
    entity: "ORG",
    loadNotification: false,
  };

  componentDidMount() {
    document.title = "Sales partner registration";
    localStorage.setItem("entity", "ORG");
  }

  handleBusiness = (event) => {
    this.setState({
      business: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePhone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword = (event) => {
    this.setState({
      pass1: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword2 = (event) => {
    this.setState({
      pass2: event.target.value,
      errorDisplay: "none",
    });
  };

  onSubmitForm = async () => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 4000);
    this.setState({ updateDisplay: "none", spinning: "block" });
    const business = this.state.business;
    const email = this.state.email;
    const phone = this.state.phone;
    const pass1 = this.state.pass1;
    const pass2 = this.state.pass2;

    if (business.trim() === "") {
      this.setState({
        error:
          this.state.entity == "ORG"
            ? "Enter the name of your organization"
            : "Enter your name and surname",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (email.trim() === "") {
      this.setState({
        error: "Enter your email address",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (!validator.isEmail(email)) {
      this.setState({
        error: "Your email address is invalid.",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (phone.trim() === "") {
      this.setState({
        error: "Enter your phone number",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (
      isPossiblePhoneNumber(phone.replace(" ", "")) === false ||
      isValidPhoneNumber(phone.replace(" ", "")) === false
    ) {
      this.setState({
        error: "Your phone number is invalid",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1.length < 4) {
      this.setState({
        error: "Pincode must at least have 4 digits",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1 !== pass2) {
      this.setState({
        error: "Make sure both your passwords match",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else {
      const phon = phone.replace(" ", "");
      let phoneNumber = phon.substring(3, 15);
      const digit = phoneNumber.substring(0, 1);
      if (digit == 0) {
        phoneNumber = phoneNumber;
      } else {
        phoneNumber = "0" + phoneNumber;
      }
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=VERIFYACCOUNT&business=" +
          business +
          "&phone=" +
          phoneNumber +
          "&email=" +
          email +
          "&password=" +
          pass1 +
          "&accounttype=partner&platform=web&partner=no"
      )
        .then((response) => {
          // alert(response.data.val);

          if (response.data.val == 1) {
            localStorage.setItem("verificationAccount", "PARTNER");
            this.props.history.push({
              pathname: "/verification",
              state: {
                business: this.state.business,
                email: this.state.email,
                accounttype: "partner",
                password: this.state.pass1,
                phone: this.state.phone,
              },
            });
          } else {
            this.setState({
              error:
                "The email address or phone number you provided is already registered",
              errorDisplay: "block",
              spinning: "none",
              updateDisplay: "block",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  sms = () => {
    this.setState({
      optionTwo: "item-select",
      optionOne: "item-select-active",
      offRadioTwo: true,
      onRadioTwo: false,
      offRadioOne: false,
      onRadioOne: true,
      errorDisplay: "none",
      active: 1,
      entity: "ORG",
      switch: "Switched to organizational partner",
      //   loadNotification: true,
    });
    localStorage.setItem("entity", "ORG");

    setTimeout(() => {
      this.setState({
        loadNotification: false,
      });
    }, 3000);
  };

  email = () => {
    this.setState({
      optionOne: "item-select",
      optionTwo: "item-select-active",
      offRadioOne: true,
      onRadioOne: false,
      offRadioTwo: false,
      onRadioTwo: true,
      errorDisplay: "none",
      active: 1,
      entity: "INDIVIDUAL",
      switch: "Switched to individual partner",
      //   loadNotification: true,
    });
    localStorage.setItem("entity", "INDIVIDUAL");

    setTimeout(() => {
      this.setState({
        loadNotification: false,
      });
    }, 3000);
  };

  render() {
    const { loadNotification } = this.state;
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center w-100">
            <div className="col-lg-5 col-12 align-self-center pl-0 pr-0">
              <div className="log-title text-center">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width="170" />
                </Link>
              </div>

              <div className=" BoxDashboardContainer">
                <div className="row w-100">
                  <div className="col-12 text-left p-0 mb-3">
                    <span>Become an Affiliate</span>
                  </div>

                  <div className="col-lg-6 col-sm-6 p-0 mb-3">
                    <div
                      className={this.state.optionOne + " w-100"}
                      onClick={this.sms}
                    >
                      <div className="link-btn w-100 menu-links">
                        <div className="col-1 col-lg-2 text-left">
                          {this.state.onRadioOne && (
                            <IoIosRadioButtonOn size={28} color={"#66b266"} />
                          )}
                          {this.state.offRadioOne && (
                            <IoIosRadioButtonOff size={28} color={"#b2b200"} />
                          )}
                        </div>
                        <div className="col-11 col-lg-10">
                          <label className="item-names w-100">
                            Organization
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 p-0 mb-3">
                    <div
                      className={this.state.optionTwo + " w-100"}
                      onClick={this.email}
                    >
                      <div className="link-btn w-100 menu-links w-100">
                        <div className="col-1 col-lg-2 text-left">
                          {this.state.onRadioTwo && (
                            <IoIosRadioButtonOn size={28} color={"#66b266"} />
                          )}
                          {this.state.offRadioTwo && (
                            <IoIosRadioButtonOff size={28} color={"#b2b200"} />
                          )}
                        </div>

                        <div className="col-11 col-lg-10">
                          <label className="item-names w-100">Individual</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  required
                  type="text"
                  name="business"
                  placeholder={
                    this.state.entity == "ORG"
                      ? "Name of your organization"
                      : "Name and surname"
                  }
                  value={this.state.business}
                  onChange={this.handleBusiness}
                />
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleEmail}
                />

                <PhoneInput
                  // autoFormat={false}
                  disableDropdown={true}
                  enableSearch={true}
                  showDropdown={false}
                  country={"za"}
                  value={this.state.phone}
                  onChange={(phone) => this.setState({ phone: "+" + phone })}
                  inputStyle={{
                    width: "100%",
                    background: "#f0f2f5",
                    height: "2.7em",
                  }}
                  containerStyle={{ marginBottom: 15 }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                />

                {/* <input
                  required
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  value={this.state.phone}
                  onChange={this.handlePhone}
                /> */}
                <input
                  required
                  type="password"
                  placeholder="Password"
                  value={this.state.pass1}
                  onChange={this.handlePassword}
                />

                <input
                  required
                  type="password"
                  placeholder="Retype password"
                  value={this.state.pass2}
                  onChange={this.handlePassword2}
                />

                <div className="col-12 response textMedium">
                  {this.state.response}
                </div>

                <button onClick={() => this.onSubmitForm()}>
                  <span
                    style={{
                      display: this.state.updateDisplay,
                    }}
                  >
                    Sign up
                  </span>
                  <span
                    style={{
                      display: this.state.spinning,
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      // css={override}
                      size={20}
                    />
                  </span>
                </button>
                <Link to="/login">
                  <div className="forgotPasswordText text-center">Log In</div>
                </Link>
              </div>

              <div className="text-footer text-center pb-4">
                Become an <span>Affiliate</span> and earn up to 30% in
                commissions.
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
        {loadNotification && (
          <div className="col-lg-3 notification-switch mb-0">
            <div className=" BoxDashboardContainer">
              <div className="col-12 text-center">
                <span>{this.state.switch}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Partner;
