import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiQrCodeLine } from "react-icons/ri";
import Header2 from "./components/Header2";

class Transactiondetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      previews: [],
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    const eventid = this.props.location.state.eventid;
    document.title = customername;
  }

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const eventname = this.props.location.state.eventname;
    const transactiondate = this.props.location.state.transactiondate;
    const admissions = this.props.location.state.admissions;
    const totalcost = this.props.location.state.totalcost;
    const venue = this.props.location.state.venue;
    const eventday = this.props.location.state.eventday;
    const eventtime = this.props.location.state.eventtime;
    const theme = this.props.location.state.theme;
    const yearcreated = this.props.location.state.yearcreated;
    return (
      <div className="view">
        <Header2 />
        <div className="container pb-5">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-4 pl-0">
                      <Link to="/transactions" className="link-btn">
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-8 text-right">
                      <label className="back-arrow">
                        <IoIosInformationCircleOutline
                          size={40}
                          color={"#fff"}
                        />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <span className="note-info">{eventname}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        MY TICKET COSTS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Cost details</span>
                  <br />
                  <br />
                  Purchase date
                  <br />
                  <label className="small-details">{transactiondate}</label>
                  <br /> <br />
                  Admissions
                  <br />
                  <label className="small-details">{admissions}</label>
                  <br /> <br />
                  Total costs
                  <br />
                  <label className="small-details">R {totalcost}</label>
                  <br />
                  <br />
                  <span>Event details</span>
                  <br />
                  <br />
                  Venue
                  <br />
                  <label className="small-details">{venue}</label>
                  <br /> <br />
                  Date
                  <br />
                  <label className="small-details">{eventday}</label>
                  <br /> <br />
                  Time
                  <br />
                  <label className="small-details">{eventtime}</label>
                  <br /> <br />
                  Theme
                  <br />
                  <label className="small-details">{theme}</label>
                  <br /> <br />
                  Year created
                  <br />
                  <label className="small-details">{yearcreated}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Transactiondetails;
