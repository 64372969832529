import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Header3 from "./components/Header3";
import TopBarProgress from "react-topbar-progress-indicator";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import { BsShield, BsShieldLock } from "react-icons/bs";
import { ImMan } from "react-icons/im";
import { FaChild } from "react-icons/fa";
var QRCode = require("qrcode.react");

class Ticketdownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      query: "",
      loadBarProgress: true,
      spinning: "none",
      updateDisplay: "block",
      resumeModal: false,
    };
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    const eventid = localStorage.getItem("eventid");
    document.title = "Ticket print - " + businessname;
    const ticketLimit = localStorage.getItem("ticketLimit");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TICKETREADYTODOWNLOAD&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype +
        "&eventid=" +
        eventid +
        "&ticketLimit=" +
        ticketLimit +
        "&reprint=" +
        localStorage.getItem("ticketPrint")
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [], loadBarProgress: false });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let ticketnumber = "";
    return (
      <div className=" vertical-scroll-mobile p-0">
        <table width="100%" border={0}>
          <tr style={{ background: "#fff", border: "hidden" }}>
            <td className="p-0">
              <div>
                {this.state.details.length < 1 ? (
                  <div>
                    <label
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: 13.5,
                        color: "#5B7083",
                      }}
                    >
                      Your tickets are already printed.
                      <br />
                      If this is not the case, please dial{" "}
                      <span>074 261 2017</span> for assistance.{" "}
                    </label>
                  </div>
                ) : (
                  this.state.details.map((item, index) => {
                    ticketnumber = uuidv4().toUpperCase().substr(0, 7);
                    return (
                      <div className={"col-lg-12 p-0"}>
                        <div className="w-100 pr-1 pl-1 pb-2 pt-3">
                          <div className="w-100">
                            <div className="row" style={{ display: "flex" }}>
                              <div
                                className="col-12"
                                style={{ cursor: "pointer" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <table
                                    width={"100%"}
                                    align={"center"}
                                    cellpadding={0}
                                    cellspacing={0}
                                    style={{ fontFamily: "arial" }}
                                  >
                                    <tr
                                      style={{
                                        background: "rgb(242, 242, 242, 0.9)",
                                      }}
                                    >
                                      <th
                                        rowspan={4}
                                        style={{
                                          color: "#333",
                                          textAlign: "center",
                                          fontWeight: 400,
                                          fontSize: 15,
                                          backgroundImage: `url(
                                    "https://karamale.com/logok.png"
                                  )`,
                                          backgroundSize: "cover",
                                          border: "thin solid #f3f4f4",
                                          paddingLeft: "15",
                                          paddingRight: "15",
                                        }}
                                      >
                                        <label
                                          style={{
                                            fontSize: 15,
                                            marginTop: 13,
                                            padding: 8,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            border: "thin solid #e61d25",
                                            backgroundColor: "transparent",
                                            color: "#e61d25",
                                            borderRadius: 100,
                                          }}
                                        >
                                          Event dates
                                        </label>
                                        <br />
                                        <label style={{ marginTop: 10 }}>
                                          {item.eventdate}{" "}
                                          <span style={{ fontWeight: 400 }}>
                                            {item.eventmonth}, {item.eventyear}
                                          </span>
                                        </label>
                                        <br />

                                        <table
                                          width={"100%"}
                                          style={{
                                            fontSize: 17,
                                            marginTop: 0,
                                            padding: 10,
                                            textAlign: "center",
                                            border: "hidden",
                                          }}
                                          cellspacing={0}
                                          cellpadding={0}
                                          border={0}
                                        >
                                          <tr
                                            style={{
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <td
                                              width={"25%"}
                                              style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                paddingBottom: 5,
                                                backgroundColor: "transparent",
                                              }}
                                              cellspacing={0}
                                              cellpadding={0}
                                            >
                                              <b>Time:</b>{" "}
                                              <span style={{ color: "grey" }}>
                                                {" "}
                                                {item.starttime}
                                              </span>{" "}
                                              <br />
                                            </td>
                                          </tr>
                                          <tr
                                            style={{
                                              border: "hidden",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <td
                                              style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                border: "hidden",

                                                overflowWrap: "break-word",
                                                wordWrap: "break-word",
                                                width: "10%",
                                              }}
                                            >
                                              <b>Venue:</b>
                                              <br />
                                              <label
                                                style={{
                                                  color: "grey",
                                                  paddingTop: 5,
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {item.venue
                                                  .split(",")
                                                  .join("\r\n")}
                                              </label>
                                            </td>
                                          </tr>
                                        </table>

                                        <table
                                          align={"center"}
                                          style={{
                                            width: "100%",
                                            marginTop: 15,
                                            borderRadius: 100,
                                            color: "#fff",
                                            padding: 10,
                                          }}
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                padding: 0,
                                                backgroundColor: "transparent",
                                                border: "hidden",
                                              }}
                                            ></td>
                                          </tr>
                                          <tr
                                            style={{
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <td
                                              style={{
                                                borderTop: "thin solid #dcdcdc",
                                                textAlign: "center",
                                                marginTop: 15,
                                                paddingTop: 15,
                                                border: "hidden",
                                              }}
                                            >
                                              <b
                                                style={{
                                                  color: "#e61d25",
                                                  fontSize: 13,
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Ticket issued by
                                              </b>
                                              <br />
                                              <img
                                                src="https://karamale.com/malelogo.png"
                                                width={100}
                                                style={{
                                                  marginBottom: 5,
                                                  marginTop: 5,
                                                }}
                                              />
                                              <br />
                                              <label
                                                style={{
                                                  color: "#e61d25",
                                                  marginTop: 3,
                                                  fontSize: 12,
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {" "}
                                                <b style={{ color: "#e61d25" }}>
                                                  Inquiries:
                                                </b>{" "}
                                                074 261 2017
                                              </label>
                                            </td>
                                          </tr>
                                        </table>
                                      </th>

                                      <th
                                        colspan={2}
                                        width={"60%"}
                                        style={{
                                          borderBottom: "thin solid #fff",
                                          background: "#e61d25",
                                          fontSize: 20,
                                          color: "#fff",
                                          textAlign: "center",
                                          padding: 10,
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                        }}
                                      >
                                        {localStorage.getItem("eventname")}
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                            fontSize: 15,
                                          }}
                                        >
                                          By {businessname}
                                        </span>
                                      </th>

                                      <th
                                        style={{
                                          background: "#e61d25",
                                          borderLeft: "thin dashed #fff",
                                          borderBottom: "thin solid #fff",
                                          textAlign: "center",
                                        }}
                                      >
                                        <label
                                          style={{
                                            padding: 5,
                                            paddingTop: 5,
                                            height: 30,
                                            width: 30,
                                            background: "#fff",
                                            color: "#e61d25",
                                            textAlign: "center",
                                            fontSize: 12,
                                            fontWeight: 500,
                                            borderRadius: 100,
                                            borderBottom: "thin solid yellow",
                                          }}
                                        >
                                          #{index + 1}
                                        </label>
                                      </th>
                                    </tr>

                                    <tr
                                      style={{
                                        background: "#e61d25",
                                        textAlign: "center",
                                        fontSize: 13,
                                      }}
                                    >
                                      <td
                                        style={{
                                          padding: 0,
                                          borderBottom: "thin solid #fff",
                                          color: "#fff",
                                        }}
                                      >
                                        <b>Order details</b>
                                      </td>
                                      <td
                                        style={{
                                          padding: 0,
                                          borderBottom: "thin solid #fff",
                                          borderLeft: "thin solid #fff",
                                          color: "#fff",
                                        }}
                                      >
                                        <b> Ticket details</b>
                                      </td>
                                      <td
                                        style={{
                                          padding: 20,
                                          color: "#fff",
                                          borderLeft: "thin dashed #fff",
                                        }}
                                      ></td>
                                    </tr>
                                    <tr style={{ background: "#e61d25" }}>
                                      <td
                                        style={{
                                          padding: 0,
                                          borderBottom: "thin solid #fff",
                                          textAlign: "center",
                                          fontSize: 13,
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                        }}
                                      >
                                        <b style={{ color: "#fff" }}>
                                          Issued to
                                        </b>
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                          }}
                                        >
                                          Customer
                                        </span>
                                        <br />
                                        <br />
                                        <b style={{ color: "#fff" }}>
                                          Ticket holder
                                        </b>
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                          }}
                                        >
                                          Customer
                                        </span>
                                      </td>

                                      <td
                                        style={{
                                          padding: 0,
                                          borderBottom: "thin solid #fff",
                                          borderRight: "thin dashed #fff",
                                          borderLeft: "thin solid #fff",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: 13,
                                          paddingLeft: 30,
                                          paddingRight: 30,
                                        }}
                                      >
                                        <b style={{ color: "#fff" }}>Type:</b>{" "}
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                          }}
                                        >
                                          {item.category == "vip"
                                            ? "VIP"
                                            : item.category == "vvip"
                                            ? "VVIP"
                                            : "General"}
                                        </span>
                                        <br />
                                        <br />
                                        <b style={{ color: "#fff" }}>
                                          Price:
                                        </b>{" "}
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                          }}
                                        >
                                          ZAR{" "}
                                          {parseInt(item.age_group) == 1
                                            ? item.kids_price
                                            : item.price}
                                        </span>
                                        <br />
                                        <br />
                                        <b style={{ color: "#fff" }}>
                                          Total:
                                        </b>{" "}
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#fff",
                                          }}
                                        >
                                          ZAR{" "}
                                          {parseInt(item.age_group) == 1
                                            ? item.kids_price
                                            : item.price}
                                        </span>
                                      </td>

                                      <td
                                        width={"15%"}
                                        style={{
                                          padding: 0,
                                          borderLeft: "thin dashed #fff",
                                          borderTop: "thin solid #fff",
                                          textAlign: "center",
                                          color: "#fff",
                                          fontSize: 13,
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                        }}
                                      >
                                        <label className="mb-0 font-size-12 color-white">
                                          <BsShieldLock
                                            size={20}
                                            color={"#fff"}
                                          />{" "}
                                          Highly secured
                                        </label>

                                        <br />
                                        <QRCode
                                          value={ticketnumber}
                                          logoSize={30}
                                          size={110}
                                          className="mt-3 mb-3"
                                        />
                                        <br />
                                        {item.ticketnumber}
                                      </td>
                                    </tr>

                                    <tr
                                      style={{
                                        background: "#e61d25",
                                        textAlign: "center",
                                        fontSize: 13,
                                      }}
                                    >
                                      <td
                                        style={{ padding: 15, color: "#fff" }}
                                      >
                                        <b style={{ color: "#fff" }}>
                                          Admission:
                                        </b>{" "}
                                        1
                                      </td>
                                      <td
                                        style={{
                                          padding: 15,
                                          color: "#fff",
                                          borderRight: "thin dashed #fff",
                                          borderLeft: "thin solid #fff",
                                        }}
                                      >
                                        <b style={{ color: "#fff" }}>Group:</b>{" "}
                                        {item.age_group == 1 ? (
                                          <label className="mb-0">
                                            <FaChild color={"lime"} size={25} />{" "}
                                            Child
                                          </label>
                                        ) : (
                                          <label className="mb-0">
                                            <ImMan color={"yellow"} size={25} />{" "}
                                            Adult
                                          </label>
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          padding: 15,
                                          color: "#fff",
                                          borderLeft: "2 dashed #fff",
                                          textAlign: "center",
                                        }}
                                      >
                                        <b style={{ color: "#fff" }}>Inquiry</b>
                                        <br /> {item.contacts}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  confirm = () => {
    this.setState({ resumeModal: true });
  };

  download = () => {
    this.props.history.push({
      pathname: "/download",
      state: {
        reprint: "no",
      },
    });
  };

  closeModal = () => {
    this.setState({
      resumeModal: false,
    });
  };

  cancel = () => {
    this.setState({
      resumeModal: false,
    });
  };

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1),
    } else {
    }

    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header3 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container ">
          <Modal show={this.state.resumeModal} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4">
              <div className="row justify-content-center align-self-center">
                <div className="col-12 text-center mb-4">
                  <label className="small-details mb-0">
                    <label className="mb-0 small-details font-size-13">
                      <span>ZAR {localStorage.getItem("costs")}</span> will be
                      deducted from your current balance, and your balance is{" "}
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={localStorage.getItem("balance")}
                        prefix="ZAR "
                        decimalSeparator="."
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                      .<br />
                      <br />
                      Are you sure you want to download these tickets?
                    </label>
                  </label>
                </div>

                <div
                  className="save-btn btn-radius-3 mb-4 mr-2"
                  onClick={() => this.download()}
                >
                  <span
                    style={{
                      display: this.state.updateDisplay,
                    }}
                  >
                    Download
                  </span>
                  <span
                    style={{
                      display: this.state.spinning,
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      // css={override}
                      size={20}
                    />
                  </span>
                </div>

                <div
                  className="cancel-btn btn-radius-3 ml-2"
                  onClick={() => this.cancel()}
                  style={{ height: "2.7em" }}
                >
                  Cancel
                </div>
              </div>
            </div>
          </Modal>
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-12 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center align-items-center">
                  <div className="item-list p-0 w-100">
                    <div className="row w-100">
                      <div className="col-lg-5 col-6 pl-3 pt-2 pr-3">
                        <Link to="/dashboard" className="link-btn">
                          <label className="back-arrow mb-0">
                            <span>{localStorage.getItem("eventname")}</span>
                          </label>
                        </Link>
                      </div>

                      <div
                        className="col-lg-7 col-6 justify-content-end pr-3 pl-3"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="save-btn btn-radius-3"
                          onClick={() => this.confirm()}
                        >
                          <span
                            style={{
                              display: this.state.updateDisplay,
                            }}
                          >
                            Download
                          </span>
                          <span
                            style={{
                              display: this.state.spinning,
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="h-divider w-100" />
                      </div>
                      <div className="col-12 pr-3 pl-3">
                        <label className="mb-0 font-size-13">
                          Number of tickets prepared:{" "}
                          <label className="small-details">
                            {localStorage.getItem("ticketPrint")}
                          </label>
                          <br />
                          Estimated revenue after sales:{" "}
                          <label className="small-details">
                            ZAR {localStorage.getItem("estimatedRevenue")}
                          </label>
                          <br />
                          Cost per ticket print:{" "}
                          <label className="small-details">ZAR 1.0</label>
                          <br />
                          Total cost for {localStorage.getItem(
                            "ticketPrint"
                          )}{" "}
                          tickets print:{" "}
                          <label className="small-details">
                            ZAR {localStorage.getItem("costs")}
                          </label>
                          {/* <label className="b-0 small-details">
                            <span>NB:</span> ZAR {localStorage.getItem("costs")}{" "}
                            will be deducted from your budget.
                          </label> */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0 W-100">
                    <div className="col-lg-12 pl-0 pr-0">
                      <label className="ticket-title pl-0 pr-0">
                        {localStorage.getItem("ticketPrint")} TICKETS READY TO
                        DOWNLOAD
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Ticketdownload;
