import React from "react";
import Axios from "axios";
import { FcCheckmark } from "react-icons/fc";
import {
  IoIosCheckmarkCircleOutline,
  IoIosArrowRoundForward,
} from "react-icons/io";
import ReactAudioPlayer from "react-audio-player";
import { Link, NavLink } from "react-router-dom";

class Success extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
    };
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    const email = query.get("email");
    const ticketnumber = query.get("ticketnumber");
    const ticketid = query.get("ticketid");
    const eventid = query.get("eventid");
    const businessid = query.get("businessid");
    const category = query.get("category");
    const phone = query.get("phone");
    const total = query.get("total");
    const admission = query.get("admission");
    const group = query.get("group");
    const price = query.get("price");
    const kids_price = query.get("kids_price");

    const apiUrl =
      "https://karamale.com/apps/kscanner/webservices.php?query=PROCESSTICKETBROWSER&email=" +
      email +
      "&ticketnumber=" +
      ticketnumber +
      "&ticketid=" +
      ticketid +
      "&eventid=" +
      eventid +
      "&businessid=" +
      businessid +
      "&category=" +
      category +
      "&phone=" +
      phone +
      "&total=" +
      total +
      "&admission=" +
      admission +
      "&price=" +
      price +
      "&kids_price=" +
      kids_price +
      "&group=" +
      localStorage.getItem("group");
    Axios.get(apiUrl).then((res) => {
      const products = res.data;
      this.setState({ products, url: apiUrl });
    });
  }

  render() {
    window.onbeforeunload = function () {
      return false;
    };
    function my_onkeydown_handler(event) {
      switch (event.keyCode) {
        case 116: // 'F5'
          event.preventDefault();
          break;

        case 17: // 'Ctrl'
          event.preventDefault();
          break;

        case 82: // 'R'
          event.preventDefault();
          break;
      }
    }
    document.addEventListener("keydown", my_onkeydown_handler);
    return (
      <div className="row background-img">
        <div className="w-100 pl-5 pr-5 shield">
          <div className="success-response">
            <div className="row h-100 justify-content-center">
              <div className="align-self-center text-center adjust-margin">
                <div className="success-message mb-3">
                  <IoIosCheckmarkCircleOutline size={40} /> <br />
                  Successful!
                </div>
                <div className="sub-text-success-message">
                  <div style={{ display: "none" }}>
                    <ReactAudioPlayer
                      src="https://karamale.com/apps/kscanner/success.mp3"
                      autoPlay={true}
                      controls
                    />
                  </div>
                  <Link
                    to={{
                      pathname: "/tickets",
                      // state: {
                      //   customerid: customerid
                      // }
                    }}
                    className="my-tickets-btn"
                  >
                    Go to your ticket <IoIosArrowRoundForward size={25} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Success;
