import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { IoMdPerson, IoMdBriefcase, IoIosArrowRoundDown } from "react-icons/io";
import { FaHandshake } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Footer from "./components/Footer";
import Header from "./components/Header";
class Register extends React.Component {
  state = {
    business: "",
    email: "",
    phone: "",
    password: "",
    response: "",
  };

  componentDidMount() {
    document.title = "Registration - Choose account";
    // alert(localStorage.getItem("affiliateid"));
  }

  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center login-screen-container w-100">
            <div className={"col-lg-3 col-12 mt-3 text-center"}>
              <div className="log-title login-logo text-center">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width={150} />
                </Link>
              </div>
            </div>
            <div className="col-12 text-center">
              {/* <span className="choose-account">CHOOSE ACCOUNT</span> */}
              <br />
              <div className="arrow-pointer">
                <IoIosArrowRoundDown size={40} />
                <br />
                <span className="choose-account">CHOOSE ACCOUNT</span>
              </div>
            </div>
            <div className="col-lg-3 col-12 text-center pl-5 pr-5 pb-4">
              <div className="row justify-content-center">
                <Link
                  to="/customer"
                  className="links logo-karamale"
                  data-tip="TICKET BUYER"
                >
                  <div className="customer">
                    <IoMdPerson size={40} />
                  </div>
                  <ReactTooltip />
                </Link>
              </div>
              <label className="mt-3">Ticket buyer</label>
            </div>

            <div className="col-lg-3 col-12 text-center pl-5 pr-5 pb-4">
              <div className="row justify-content-center">
                <Link
                  to="/business"
                  className="links logo-karamale"
                  data-tip="EVENT ORGANISER"
                >
                  <div className="business">
                    <IoMdBriefcase size={40} />
                  </div>
                  <ReactTooltip />
                </Link>
              </div>
              <label className="mt-3">Event organizer</label>
            </div>

            <div className="col-lg-3 col-12 text-center pl-5 pr-5 pb-4">
              <div className="row justify-content-center">
                <Link
                  to="/partner"
                  className="links logo-karamale"
                  data-tip="BECOME AN AFFILIATE"
                >
                  <div className="partner">
                    <FaHandshake size={40} />
                  </div>
                  <ReactTooltip />
                </Link>
              </div>
              <label className="mt-3">
                Become an <span>affiliate</span>
                <br />
                <Link to="/aboutsalesparnter">
                  <label className="color-blue">Learn more..</label>
                </Link>
              </label>
            </div>

            {/* <div className="col-12 text-center">
                Switch to <span>Karamale</span> ticket platform and{" "}
                <span>Explore</span> endless possibilities
              </div> */}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Register;
