import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "./App.css";
import { AiOutlineSend } from "react-icons/ai";
import Axios from "axios";
import Header3 from "./components/Header3";
import Header from "./components/Header";
class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vvip: "",
      vip: "",
      general: "",
      priceOne: "",
      priceTwo: "",
      priceThree: "",
      calResults: "",
    };

    this.handleVVIP = this.handleVVIP.bind(this);
    this.handleVIP = this.handleVIP.bind(this);
    this.handleGeneral = this.handleGeneral.bind(this);
    this.handlePriceOne = this.handlePriceOne.bind(this);
    this.handlePriceTwo = this.handlePriceTwo.bind(this);
    this.handlePriceThree = this.handlePriceThree.bind(this);
    this.handleCalculation = this.handleCalculation.bind(this);
  }

  handleVVIP(event) {
    this.setState({ vvip: event.target.value });
  }

  handleVIP = (event) => {
    this.setState({ vip: event.target.value });
  };

  handleGeneral = (event) => {
    this.setState({ general: event.target.value });
  };

  handlePriceOne = (event) => {
    this.setState({ priceOne: event.target.value });
  };

  handlePriceTwo = (event) => {
    this.setState({ priceTwo: event.target.value });
  };

  handlePriceThree = (event) => {
    this.setState({ priceThree: event.target.value });
  };

  handleCalculation() {
    const vvip = this.state.vvip;
    const vip = this.state.vip;
    const general = this.state.general;
    const priceone = this.state.priceOne;
    const pricetwo = this.state.priceTwo;
    const pricethree = this.state.priceThree;

    if (vvip !== "" && priceone === "") {
      this.setState({ calResults: "VVIP tickets expects ticket price value" });
    } else if (vvip === "" && priceone !== "") {
      this.setState({
        calResults: "VVIP ticket price expects a value of Number Of Ticket",
      });
    } else if (vip !== "" && pricetwo === "") {
      this.setState({ calResults: "VIP tickets expects ticket price value" });
    } else if (vip === "" && pricetwo !== "") {
      this.setState({
        calResults: "VIP ticket price expects a value of Number Of Ticket",
      });
    } else if (general !== "" && pricethree === "") {
      this.setState({
        calResults: "General tickets expects ticket price value",
      });
    } else if (general === "" && pricethree !== "") {
      this.setState({
        calResults: "General ticket price expects a value of Number Of Ticket",
      });
    } else {
      let { perone, pertwo, perthree } = 0;
      if (parseInt(priceone) > 200) {
        perone = 0.05;
      } else {
        perone = 0.05;
      }
      const calVVIP = priceone * perone;
      const amountOne = calVVIP * vvip;

      if (parseInt(pricetwo) > 200) {
        pertwo = 0.05;
      } else {
        pertwo = 0.05;
      }

      const calVIP = pricetwo * pertwo;
      const amountTwo = calVIP * vip;

      if (parseInt(pricethree) > 200) {
        perthree = 0.05;
      } else {
        perthree = 0.05;
      }

      const calGen = pricethree * perthree;
      const amountThree = calGen * general;

      let total = amountOne + amountTwo + amountThree;
      let totalA = total.toFixed(1);

      this.setState({ calResults: "Your budget will be: ZAR " + totalA });
    }
  }

  render() {
    document.title =
      "Calculate how much you are willing to spend to sell tickets on Karamale";
    let btn = "";

    if (
      localStorage.getItem("businessname") === null &&
      localStorage.getItem("accounttype") === null
    ) {
      btn = (
        <Link to="/business">
          <div className="create-account-btn text-center">
            Ready to sell? Create account
          </div>
        </Link>
      );
    } else {
      btn = (
        <Link to={{ pathname: "addevent", state: { prevpage: "budget" } }}>
          <div className="create-account-btn text-center">Create event</div>
        </Link>
      );
    }
    return (
      <div className="view">
        {localStorage.getItem("businessname") !== null &&
        localStorage.getItem("accounttype") !== null ? (
          <Header3 />
        ) : (
          <Header />
        )}

        <div className="container">
          <div className="row justify-content-center align-self-center">
            <div className="row align-self-center justify-content-center">
              <div className="log-title text-center col-12 mt-4">
                <img src="https://karamale.com/karamale.png" width="170" />
              </div>
              <div
                className="text-footer text-center mb-2 col-12 category-name-heading"
                style={{
                  fontSize: 20,
                  color: "#4d4d4f",
                  textTransform: "none",
                }}
              >
                Want to sell tickets on Karamale? Calculate how much you can
                spend.
              </div>
              <div className="col-lg-5 mb-4  pl-2 pr-2">
                <div className="BoxDashboardContainer w-100 col-12 mb-4 pl-3 pr-3">
                  <div className="row">
                    <div className="col-12 pl-1 pr-1">
                      <span className="">VVIP</span>{" "}
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Number of tickets</label>
                      <br />
                      <input
                        type="number"
                        required
                        placeholder="If applicable"
                        value={this.state.vvip}
                        onChange={this.handleVVIP}
                        className="edit-input"
                      />
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Price per ticket</label>
                      <br />
                      <input
                        type="number"
                        required
                        placeholder="Example: R550"
                        value={this.state.priceOne}
                        onChange={this.handlePriceOne}
                        className="edit-input"
                      />
                    </div>
                    <br />
                    <br />

                    <div className="col-12 pl-1 pr-1">
                      <span className="">VIP</span>{" "}
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Number of tickets</label>
                      <br />
                      <input
                        type="number"
                        name="phone"
                        required
                        placeholder="If applicable"
                        value={this.state.VIP}
                        onChange={this.handleVIP}
                        className="edit-input"
                      />
                    </div>

                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Price per ticket</label>
                      <br />
                      <input
                        type="number"
                        required
                        placeholder="Example: R150"
                        value={this.state.priceTwo}
                        onChange={this.handlePriceTwo}
                        className="edit-input"
                      />
                    </div>

                    <div className="col-12 pl-1 pr-1">
                      <span className="">General</span>{" "}
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Number of tickets</label>
                      <br />
                      <input
                        type="number"
                        name="email"
                        required
                        placeholder="If applicable"
                        value={this.state.general}
                        onChange={this.handleGeneral}
                        className="edit-input"
                      />
                    </div>

                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 mb-4 pl-1 pr-1">
                      <label>Price per ticket</label>
                      <br />
                      <input
                        type="number"
                        required
                        placeholder="Example: R100"
                        value={this.state.priceThree}
                        onChange={this.handlePriceThree}
                        className="edit-input"
                      />
                    </div>
                  </div>
                  <div className="mb-3 msg-success mt-3">
                    {this.state.calResults}
                  </div>

                  <div className="col-12 response textMedium">
                    {this.state.response}
                  </div>
                  <button onClick={this.handleCalculation}>Calculate</button>

                  <div className="horizontal-line"></div>

                  {btn}
                </div>
                <div className=" BoxDashboardContainer col-12 w-100 mb-0 pl-0 pr-0">
                  <div className="row">
                    <div className="col-lg-12">
                      Be in control of your <span className="">Budget</span>.
                      Choose what you spend
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 pl-2 pr-2">
                <div className=" BoxDashboardContainer w-100 col-12 mb-4 pl-1 pr-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <span className="">Digital tickets</span>
                      <br />
                      Budget is calculated at <span className="">5%</span>
                      <br />
                      <br />
                      Tickets are issued to a customer via sms, email and can
                      even be previewed on the App or karamale.com in a qrcode
                      format, this process is immediate, in less than 2 minutes
                      customers will receive their tickets after making a
                      purchase.
                      <br />
                      <br />
                      Use the <span className="">Budget calculator</span> on
                      your left to decide how much you are willing to spend to
                      sell tickets on Karamale.
                      <br />
                      <br />
                      You can increase budget however you see fit, anytime...
                      anywhere.
                      <br />
                      <br />
                      Everytime a customer buys your ticket, Karamale takes its{" "}
                      <span>5%</span> from this budget, budget is the only money
                      you'll ever pay Karamale, whatever money you make from
                      your ticket sales is all yours.
                    </div>
                  </div>
                </div>

                <div className=" BoxDashboardContainer w-100 col-12 mb-4 pl-1 pr-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <span className="">Physical tickets</span>
                      <br />
                      To download tickets on Karamale will only cost you R1 per
                      ticket download, however, if you would like Karamale to
                      download and print them for you; it will cost you R3.5 per
                      ticket.
                      <br />
                      <br />
                      <span className="">NB: </span> All tickets are generated
                      by the software along side qrcodes and each is assigned a
                      unique ticket number among other data type making them
                      highly secured.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Budget;
