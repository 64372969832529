import React, { useState } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import TopBarProgress from "react-topbar-progress-indicator";
import {
  IoIosArrowBack,
  IoIosAddCircleOutline,
  IoIosBicycle,
  IoIosNuclear,
  IoIosTrash,
  IoIosTimer,
  IoMdCheckmarkCircleOutline,
  IoIosAdd,
  IoIosInformationCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoMdPrint,
  IoMdWoman,
  IoIosMan,
} from "react-icons/io";
import { BsImage, BsGraphUp, BsPip } from "react-icons/bs";
import { BiPrinter } from "react-icons/bi";
import Header3 from "./components/Header3";
import {
  IoAnalyticsOutline,
  IoBicycleOutline,
  IoCloudDoneOutline,
  IoCloudDownloadOutline,
  IoFlowerOutline,
  IoImageOutline,
  IoNuclearOutline,
  IoTicketOutline,
  IoTrashOutline,
} from "react-icons/io5";

class Editevent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      showModal: false,
      deleteModal: false,
      controlModal: false,
      addPoster: false,
      postponeEvent: false,
      resumeModal: false,
      category: "vvip",
      price: "0",
      numberOftickets: "0",
      getVVIP: "",
      getVVIPprice: "",
      getVIPprice: "",
      getGENprice: "",
      getVIP: "",
      getGen: "",
      itemVVIP: "item-select-active",
      itemVIP: "item-select",
      itemGen: "item-select",
      currentSelect: "VVIP",
      selectedPrice: 0,
      selectedTicket: 0,
      kidsSelectedPrice: 0,
      kidsSelectedTicket: 0,
      spinning: "none",
      updateDisplay: "block",
      error: "",
      successError: "",
      deletion: "",
      deleted: 0,
      eventPassed: "",
      image: null,
      path: "",
      type: "image/jpeg",
      activeBox: "box-upload",
      preimage: this.props.location.state.poster,
      numberOfcustomers: 0,
      message: "",
      reason: "",
      subtype: "postpone",
      hideUploadBtn: "none",
      checktickets: "no",
      checkcampaign: "no",
      checkposter: "no",
      loadBarProgress: false,
      printed: "",
      ticketCount: 0,
      eventtype: "",
      kids_price: 0,
      kids_tickets: 0,
      kids_vvip_tickets: 0,
      kids_vip_tickets: 0,
      kids_general_tickets: 0,
      kids_vvip_price: 0,
      kids_vip_price: 0,
      kids_general_price: 0,
    };

    this.selectImage = this.selectImage.bind(this);
    // this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    const eventname = this.props.location.state.eventname;
    const eventid = this.props.location.state.eventid;
    document.title = eventname + " - " + businessname;
    let delString = "";

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;

    if (month < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (day < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    let active = "item-names-disabled";
    if (this.state.deleted == 1) {
      active = "item-names-disabled";
    } else {
      active = "item-names";
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    const event_date = this.props.location.state.event_date;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTTYPE&eventid=" +
        eventid
    ).then((response) => {
      // alert(response.data.eventtype);
      this.setState({ eventtype: response.data.eventtype });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=REPRINTABLETICKETS&eventid=" +
        eventid
    ).then((response) => {
      // alert(response.data.ticketCount);
      if (response.data.val == 0 || response.data.ticketCount == 0) {
        this.setState({ printed: "" });
      } else {
        this.setState({
          ticketCount: response.data.ticketCount,
          printed: (
            <div className="reprint-select w-100">
              <div
                className="link-btn w-100 menu-links "
                onClick={
                  event_date >= fulldate &&
                  this.state.deleted == 0 &&
                  this.state.checktickets == "yes" &&
                  this.state.checkposter == "yes" &&
                  this.state.checkcampaign == "yes"
                    ? this.reprint
                    : this.functionControl
                }
              >
                <div className="col-lg-2 col-2 col-sm-1 text-center">
                  <IoCloudDownloadOutline
                    size={30}
                    className={active}
                    color={"#fff"}
                  />
                </div>
                <div className="col-lg-10 col-10 col-sm-10">
                  <label className={active + " w-100 mb-0 color-white"}>
                    Re-download tickets
                    <label className="float-right">
                      {this.state.ticketCount}
                    </label>
                  </label>
                </div>
              </div>
            </div>
          ),
        });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CHECKCAMPAIGN&eventid=" +
        eventid
    ).then((response) => {
      // alert(response.data.image);
      if (response.data.thumbnail == "yes") {
        this.setState({
          preimage:
            "https://karamale.com/apps/kscanner/posters/" +
            response.data.businessid +
            "/" +
            response.data.eventid +
            "/" +
            response.data.image,
        });
      } else {
        this.setState({ preimage: this.state.preimage });
      }
      this.setState({
        checkcampaign: response.data.checkcampaign,
        checkposter: response.data.thumbnail,
      });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CHECKTICKETS&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        checktickets: response.data.tickets,
      });
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTSALESDATA&eventid=" +
        eventid
    )
      .then((response) => {
        this.setState({ numberOfcustomers: response.data.numberOfcustomers });
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CHECKIFEVENTDELETED&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.deletion == 0 && response.data.event_day < fulldate) {
          this.setState({
            deleted: response.data.deletion,
            deletion: (
              <div
                style={{
                  textTransform: "none",
                  fontWeight: 400,
                  color: "#ffae19",
                }}
              >
                <i>
                  This event has passed <IoIosTimer color={"#ffae19"} />
                </i>
              </div>
            ),
          });
        } else if (response.data.deletion == 1) {
          this.setState({
            deleted: response.data.deletion,
            deletion: (
              <div
                style={{ textTransform: "none", fontWeight: 400, color: "red" }}
              >
                <i>
                  This event has been deleted <IoIosTrash color={"red"} />
                </i>
              </div>
            ),
          });
        } else {
          this.setState({
            deleted: response.data.deletion,
            deletion: (
              <div
                style={{
                  textTransform: "none",
                  fontWeight: 400,
                  color: "#4ca64c",
                }}
              >
                <i>
                  This event is scheduled to happen...
                  <IoMdCheckmarkCircleOutline color={"#4ca64c"} />
                </i>
              </div>
            ),
          });
        }
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETVVIPDETAILS&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        this.setState({
          getVVIP: response.data.vvipticketavail,
          getVVIPprice: response.data.vvipprice,
          price: response.data.vvipprice.replace("R", ""),
          kids_price: response.data.kids_vvip_price,
          kids_tickets: response.data.kids_vvip_tickets,
          numberOftickets: response.data.vvipticketavail,
          selectedTicket: response.data.vvipticketavail,
          selectedPrice: response.data.genprice.substring(1),
          kidsSelectedPrice: response.data.kids_price,
          kidsSelectedTicket: response.data.kids_tickets,
        });
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETVIPDETAILS&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        this.setState({
          getVIP: response.data.vipticketavail,
          getVIPprice: response.data.vipprice,
          kids_vip_price: response.data.kids_vip_price,
          kids_vip_tickets: response.data.kids_vip_tickets,
        });
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETGENDETAILS&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        this.setState({
          getGen: response.data.genticketavail,
          getGENprice: response.data.genprice,
          kids_general_price: response.data.kids_general_price,
          kids_general_tickets: response.data.kids_general_tickets,
        });
      })
      .catch((error) => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    // alert(this.state.checktickets);
    if (
      this.state.checktickets !== prevState.checktickets ||
      this.state.checkposter !== prevState.checkposter
    ) {
      const businessname = localStorage.getItem("businessname");
      const accounttype = localStorage.getItem("accounttype");
      const businessid = localStorage.getItem("businessid");
      const eventname = this.props.location.state.eventname;
      const eventid = this.props.location.state.eventid;

      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;

      if (month < 10) {
        month = "0" + month;
      } else {
        month = month;
      }

      if (day < 10) {
        day = "0" + day;
      } else {
        day = day;
      }

      let active = "item-names-disabled";
      if (this.state.deleted == 1) {
        active = "item-names-disabled";
      } else {
        active = "item-names";
      }

      const year = date.getFullYear();
      const fulldate = year + "-" + month + "-" + day;
      const event_date = this.props.location.state.event_date;

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=EVENTTYPE&eventid=" +
          eventid
      ).then((response) => {
        // alert(response.data.eventtype);
        this.setState({ eventtype: response.data.eventtype });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=REPRINTABLETICKETS&eventid=" +
          eventid
      ).then((response) => {
        // alert(response.data.ticketCount);
        if (response.data.val == 0 || response.data.ticketCount == 0) {
          this.setState({ printed: "" });
        } else {
          this.setState({
            ticketCount: response.data.ticketCount,
            printed: (
              <div className="reprint-select w-100">
                <div
                  className="link-btn w-100 menu-links "
                  onClick={
                    event_date >= fulldate &&
                    this.state.deleted == 0 &&
                    this.state.checktickets == "yes" &&
                    this.state.checkposter == "yes" &&
                    this.state.checkcampaign == "yes"
                      ? this.reprint
                      : this.functionControl
                  }
                >
                  <div className="col-lg-2 col-2 col-sm-1 text-center">
                    <IoCloudDownloadOutline
                      size={30}
                      className={active}
                      color={"#fff"}
                    />
                  </div>
                  <div className="col-lg-10 col-10 col-sm-10">
                    <label className={active + " w-100 mb-0 color-white"}>
                      Re-download tickets
                      <label className="float-right">
                        {this.state.ticketCount}
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            ),
          });
        }
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHECKCAMPAIGN&eventid=" +
          eventid
      ).then((response) => {
        // alert(response.data.image);
        if (response.data.thumbnail == "yes") {
          this.setState({
            preimage:
              "https://karamale.com/apps/kscanner/posters/" +
              response.data.businessid +
              "/" +
              response.data.eventid +
              "/" +
              response.data.image,
          });
        } else {
          this.setState({ preimage: this.state.preimage });
        }
        this.setState({
          checkcampaign: response.data.checkcampaign,
          checkposter: response.data.thumbnail,
        });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHECKTICKETS&eventid=" +
          eventid
      ).then((response) => {
        this.setState({
          checktickets: response.data.tickets,
        });
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=EVENTSALESDATA&eventid=" +
          eventid
      )
        .then((response) => {
          this.setState({ numberOfcustomers: response.data.numberOfcustomers });
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHECKIFEVENTDELETED&eventid=" +
          eventid
      )
        .then((response) => {
          if (
            response.data.deletion == 0 &&
            response.data.event_day < fulldate
          ) {
            this.setState({
              deleted: response.data.deletion,
              deletion: (
                <div
                  style={{
                    textTransform: "none",
                    fontWeight: 400,
                    color: "#ffae19",
                  }}
                >
                  <i>
                    This event has passed <IoIosTimer color={"#ffae19"} />
                  </i>
                </div>
              ),
            });
          } else if (response.data.deletion == 1) {
            this.setState({
              deleted: response.data.deletion,
              deletion: (
                <div
                  style={{
                    textTransform: "none",
                    fontWeight: 400,
                    color: "red",
                  }}
                >
                  <i>
                    This event has been deleted <IoIosTrash color={"red"} />
                  </i>
                </div>
              ),
            });
          } else {
            this.setState({
              deleted: response.data.deletion,
              deletion: (
                <div
                  style={{
                    textTransform: "none",
                    fontWeight: 400,
                    color: "#4ca64c",
                  }}
                >
                  <i>
                    This event is scheduled to happen...
                    <IoMdCheckmarkCircleOutline color={"#4ca64c"} />
                  </i>
                </div>
              ),
            });
          }
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETVVIPDETAILS&accountid=" +
          businessid +
          "&eventid=" +
          eventid
      )
        .then((response) => {
          this.setState({
            getVVIP: response.data.vvipticketavail,
            getVVIPprice: response.data.vvipprice,
            price: response.data.vvipprice.replace("R", ""),
            numberOftickets: response.data.vvipticketavail,
            selectedTicket: response.data.vvipticketavail,
            selectedPrice: response.data.vvipprice.substring(1),
            kids_price: response.data.kids_vvip_price,
            kids_tickets: response.data.kids_vvip_tickets,
            kidsSelectedPrice: response.data.kids_vvip_price,
            kidsSelectedTicket: response.data.kids_vvip_tickets,
          });
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETVIPDETAILS&accountid=" +
          businessid +
          "&eventid=" +
          eventid
      )
        .then((response) => {
          this.setState({
            getVIP: response.data.vipticketavail,
            getVIPprice: response.data.vipprice,
            kids_vip_price: response.data.kids_vip_price,
            kids_vip_tickets: response.data.kids_vip_tickets,
          });
        })
        .catch((error) => console.log(error));

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETGENDETAILS&accountid=" +
          businessid +
          "&eventid=" +
          eventid
      )
        .then((response) => {
          this.setState({
            getGen: response.data.genticketavail,
            getGENprice: response.data.genprice,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  reprint = () => {
    const eventid = this.props.location.state.eventid;
    const eventname = this.props.location.state.eventname;
    localStorage.setItem("eventid", eventid);
    localStorage.setItem("eventname", eventname);
    this.props.history.push({
      pathname: "/download",
      state: {
        reprint: "yes",
      },
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      deleteModal: false,
      addPoster: false,
      controlModal: false,
      postponeEvent: false,
      resumeModal: false,
      errorDisplay: "none",
    });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  deleteModal = () => {
    this.setState({ deleteModal: true });
  };

  addPoster = () => {
    this.setState({ addPoster: true });
  };

  activateVVIP = () => {
    this.setState({
      itemVIP: "item-select",
      itemVVIP: "item-select-active",
      itemGen: "item-select",
      currentSelect: "VVIP",
      selectedTicket: this.state.getVVIP,
      selectedPrice: this.state.getVVIPprice,
      kidsSelectedPrice: this.state.kids_price,
      kidsSelectedTicket: this.state.kids_tickets,
    });
  };

  activateGen = () => {
    this.setState({
      itemVIP: "item-select",
      itemVVIP: "item-select",
      itemGen: "item-select-active",
      currentSelect: "General",
      selectedTicket: this.state.getGen,
      selectedPrice: this.state.getGENprice,
      kidsSelectedPrice: this.state.kids_general_price,
      kidsSelectedTicket: this.state.kids_general_tickets,
    });
  };

  activateVIP = () => {
    this.setState({
      itemVIP: "item-select-active",
      itemVVIP: "item-select",
      itemGen: "item-select",
      currentSelect: "VIP",
      selectedTicket: this.state.getVIP,
      selectedPrice: this.state.getVIPprice,
      kidsSelectedPrice: this.state.kids_vip_price,
      kidsSelectedTicket: this.state.kids_vip_tickets,
    });
  };
  handleTickets = (event) => {
    this.setState({
      selectedTicket: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePrice = (event) => {
    this.setState({
      selectedPrice: event.target.value,
      errorDisplay: "none",
    });
  };

  handle_kids_tickets = (event) => {
    this.setState({
      kidsSelectedTicket: event.target.value,
      errorDisplay: "none",
    });
  };

  handle_kids_price = (event) => {
    this.setState({
      kidsSelectedPrice: event.target.value,
      errorDisplay: "none",
    });
  };

  cancel = () => {
    this.setState({
      showModal: false,
      deleteModal: false,
      controlModal: false,
      resumeModal: false,
      errorDisplay: "none",
    });
  };

  update = async () => {
    this.setState({
      updateDisplay: "none",
      spinning: "block",
      loadBarProgress: true,
    });
    const businessid = localStorage.getItem("businessid");
    const eventname = this.props.location.state.eventname;
    const eventid = this.props.location.state.eventid;

    const price = this.state.selectedPrice;
    const numberOftickets = this.state.selectedTicket;
    const kids_price = this.state.kidsSelectedPrice;
    const kids_tickets = this.state.kidsSelectedTicket;
    const category = this.state.currentSelect.toLowerCase();

    if (
      (numberOftickets <= 0 || numberOftickets === "") &&
      (kids_tickets <= 0 || kids_tickets === "")
    ) {
      this.setState({
        error:
          "At least one group must have tickets available. i.e. Adults or Kids",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (numberOftickets > 0 && (price <= 0 || price === "")) {
      this.setState({
        error: "Ticket price cannot be empty or R0.0. (Adults)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (numberOftickets > 0 && isNaN(price)) {
      this.setState({
        error: "Invalid ticket price, please enter a number. (Adults)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (price > 0 && (numberOftickets <= 0 || numberOftickets === "")) {
      this.setState({
        error: "Indicate how many tickets are to be available. (Adults)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (kids_tickets > 0 && (kids_price <= 0 || kids_price === "")) {
      this.setState({
        error: "Ticket price cannot be empty or R0.0. (Kids)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (kids_tickets > 0 && isNaN(kids_price)) {
      this.setState({
        error: "Invalid ticket price, please enter a number. (Kids)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (kids_price > 0 && (kids_tickets <= 0 || kids_tickets === "")) {
      this.setState({
        error: "Indicate how many tickets are to be available. (Kids)",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      await Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=ADDTICEKTS&accountid=" +
          businessid +
          "&price=" +
          price +
          "&numberOftickets=" +
          numberOftickets +
          "&category=" +
          category +
          "&eventid=" +
          eventid +
          "&kids_price=" +
          kids_price +
          "&kids_tickets=" +
          kids_tickets
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({
            error: response.data.error,
            errorDisplay: "block",
            successError: "error-login",
          });
        } else {
          this.setState({
            error: "Tickets loaded",
            errorDisplay: "block",
            successError: "success-login",
            updateDisplay: "block",
            spinning: "none",
            checktickets: "yes",

            loadBarProgress: false,
            showModal: false,
            deleteModal: false,
            addPoster: false,
            controlModal: false,
            postponeEvent: false,
            resumeModal: false,
          });

          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);

          this.props.history.push({
            pathname: "/editevent",
            state: {
              campaignstatus: this.props.location.state.campaignstatus,
              eventid: this.props.location.state.eventid,
              eventname: this.props.location.state.eventname,
              poster: this.props.location.state.poster,
              eventdate: this.props.location.state.eventdate,
              enddate: this.props.location.state.enddate,
              event_date: this.props.location.state.event_date,
              finish_date: this.props.location.state.finish_date,
              theme: this.props.location.state.theme,
              venue: this.props.location.state.venue,
              expectednum: this.props.location.state.expectednum,
              time: this.props.location.state.time,
              endtime: this.props.location.state.endtime,
              prevpage: this.props.location.state.prevpage,
            },
          });
        }
      });
    }
  };

  delete = () => {
    const eventid = this.props.location.state.eventid;
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=DELETEEVENT&eventid=" +
        eventid
    ).then((response) => {
      if (response.data.val == 1) {
        this.props.history.push("/myevents");
      } else {
        this.setState({
          error:
            "Something went wrong. Is not your fault though, our engineers are working on it",
          errorDisplay: "block",
          successError: "error-login",
          updateDisplay: "block",
          spinning: "none",
        });
      }
    });
  };

  functionControl = () => {
    if (this.state.checkcampaign == "no") {
      this.setState({
        controlModal: true,
        delString: "You have to create a campaign first",
      });
    } else if (this.state.deleted == 1) {
      this.setState({
        controlModal: true,
        delString: "This event has been deleted, it can't be edited",
      });
    } else {
      this.setState({
        controlModal: true,
        delString: "This event has already passed, it can't be edited",
      });
    }
  };

  campaign = () => {
    const eventid = this.props.location.state.eventid;
    const eventname = this.props.location.state.eventname;
    const prevpage = this.props.location.state.prevpage;
    const currentpage = "editevent";
    const eventdate = this.props.location.state.eventdate;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;
    this.props.history.push({
      pathname: "campaign",
      state: {
        eventid: eventid,
        eventname: eventname,
        poster: poster,
        eventdate: eventdate,
        enddate: enddate,
        event_date: event_date,
        finish_date: finish_date,
        theme: theme,
        venue: venue,
        expectednum: expectednum,
        time: time,
        endtime: endtime,
        prevpage: prevpage,
        currentpage: currentpage,
      },
    });
  };

  selectImage = (event) => {
    const img = event.target.files[0];

    // alert(img.name);

    this.setState({
      image: URL.createObjectURL(event.target.files[0]),
      preimage: URL.createObjectURL(event.target.files[0]),
      imgname: img,
      type: img.type,
      path: URL.createObjectURL(event.target.files[0]),
      activeBox: "box-upload-dis",
      hideUploadBtn: "block",
    });
  };

  uploadPoster = async () => {
    const eventid = this.props.location.state.eventid;
    const accountid = localStorage.getItem("businessid");
    const type = this.state.type;
    const path = this.state.path;
    const fileName = this.state.imgname;
    let data = new FormData();

    this.setState({ updateDisplay: "none", spinning: "block" });

    // alert(path);

    // data.append("file", { type: type, uri: path, name: path.split("/").pop() });
    data.append("file", this.state.imgname);
    data.append("eventid", eventid);
    data.append("businessid", accountid);
    data.append("query", "POSTERUPLOAD");

    if (this.state.image === "") {
      this.setState({
        error: "Please select a poster",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      await Axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          // alert(response.data.filename);
          this.setState({
            error: "Successful!",
            errorDisplay: "block",
            successError: "success-login",
            updateDisplay: "block",
            spinning: "none",
            addPoster: false,
            checkposter: "yes",
            loadBarProgress: false,
            showModal: false,
            deleteModal: false,
            addPoster: false,
            controlModal: false,
            postponeEvent: false,
            resumeModal: false,
          });
        })
        .catch((error) => {
          console.log("image upload error: ", error);
        });

      setTimeout(() => {
        this.setState({ errorDisplay: "none" });
      }, 4000);

      this.props.history.push({
        pathname: "/editevent",
        state: {
          campaignstatus: this.props.location.state.campaignstatus,
          eventid: this.props.location.state.eventid,
          eventname: this.props.location.state.eventname,
          poster: this.props.location.state.poster,
          eventdate: this.props.location.state.eventdate,
          enddate: this.props.location.state.enddate,
          event_date: this.props.location.state.event_date,
          finish_date: this.props.location.state.finish_date,
          theme: this.props.location.state.theme,
          venue: this.props.location.state.venue,
          expectednum: this.props.location.state.expectednum,
          time: this.props.location.state.time,
          endtime: this.props.location.state.endtime,
          prevpage: this.props.location.state.prevpage,
        },
      });
    }
  };

  postpone = () => {
    this.setState({ postponeEvent: true, subtype: "postpone" });
  };

  cancelModal = () => {
    this.setState({ postponeEvent: true, subtype: "cancel" });
  };

  handleChange = (e) => {
    this.setState({ reason: e.value });
  };

  handleMessage = (event) => {
    this.setState({
      message: event.target.value,
      errorDisplay: "none",
    });
  };

  postponeEvent = () => {
    const eventid = this.props.location.state.eventid;
    const businessid = localStorage.getItem("businessid");
    const type = this.state.subtype;
    const message = this.state.message;
    const reason = this.state.reason;

    if (reason === "") {
      this.setState({
        error: "Select a reason for the postponement ",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (message.trim() === "") {
      this.setState({
        error: "Write a message ",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=POSTPONE&businessid=" +
          businessid +
          "&eventid=" +
          eventid +
          "&type=" +
          type +
          "&message=" +
          message +
          "&reason=" +
          reason
      ).then((response) => {
        let string = "";
        if (this.state.subtype === "postpone") {
          string = "postponed";
        } else {
          string = "cancelled";
        }
        this.setState({
          error: "Event " + string,
          errorDisplay: "block",
          successError: "success-login",
        });
      });
    }
  };

  resumeModal = () => {
    this.setState({ resumeModal: true });
  };

  resumeCampaign = () => {
    const eventid = this.props.location.state.eventid;
    const accountid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");
    const eventname = this.props.location.state.eventname;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=RESUMECAMPAIGN&eventid=" +
        eventid +
        "&businessid=" +
        accountid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          error: "Successful!",
          errorDisplay: "block",
          successError: "success-login",
        });
      } else {
        this.setState({
          error:
            "Something went wrong, our engineers are working on it. We apologise for the inconvenience",
          errorDisplay: "block",
          successError: "error-login",
        });
      }
    });
  };

  downloadTickets = () => {
    const eventid = this.props.location.state.eventid;
    const eventname = this.props.location.state.eventname;
    const prevpage = this.props.location.state.prevpage;
    const currentpage = "editevent";
    const eventdate = this.props.location.state.eventdate;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;
    localStorage.setItem("eventname", eventname);
    localStorage.setItem("download", "download");
    localStorage.setItem("eventid", eventid);
    this.props.history.push({
      pathname: "/downloadtickets",
      state: {
        eventid: eventid,
        eventname: eventname,
        poster: poster,
        eventdate: eventdate,
        enddate: enddate,
        event_date: event_date,
        finish_date: finish_date,
        theme: theme,
        venue: venue,
        expectednum: expectednum,
        time: time,
        endtime: endtime,
        prevpage: prevpage,
        currentpage: currentpage,
      },
    });
  };

  addModels = () => {
    const eventid = this.props.location.state.eventid;
    localStorage.removeItem("modelid");
    localStorage.setItem("eventid", eventid);
    this.props.history.push({
      pathname: "/addmodels",
    });
  };

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }

    const data = [
      { value: "covid-19 restrictions", label: "Covid-19 restrictions" },
      { value: "venue cancelled", label: "Venue cancelled" },
      { value: "sponsor pulls out", label: "Sponsor pulls out" },
      {
        value: "unfavorable weather conditions",
        label: "Unfavorable weather conditions",
      },
      { value: "missing paperwork", label: "Missing paperwork" },
      { value: "competing events", label: "Competing events" },
      { value: "other", label: "Other" },
    ];

    let active = "item-names-disabled";
    if (this.state.deleted == 1) {
      active = "item-names-disabled";
    } else {
      active = "item-names";
    }

    const prevpage = this.props.location.state.prevpage;
    const currentage = "editevent";
    const eventname = this.props.location.state.eventname;
    const eventdate = this.props.location.state.eventdate;
    const eventid = this.props.location.state.eventid;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;
    const campaignstatus = this.props.location.state.campaignstatus;
    const businessname = localStorage.getItem("businessname");
    let { passEvent, statusString } = "";

    if (campaignstatus === "stop") {
      statusString = "Resume";
    } else {
      statusString = "Pause";
    }

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;

    if (month < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (day < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;

    if (event_date < fulldate) {
      active = "item-names-disabled";
    } else {
      active = active;
    }

    let analyticsActive = "";

    if (
      (event_date <= fulldate || event_date >= fulldate) &&
      this.state.deleted == 0
    ) {
      analyticsActive = "item-names";
    } else {
      analyticsActive = "item-names-disabled";
    }

    const { reason, loadBarProgress } = this.state;

    return (
      <div className="view">
        <Header3 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <Modal show={this.state.resumeModal} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4">
              <div className="row justify-content-center align-self-center">
                <div className="col-12 text-center mb-4">
                  <label className="small-details">
                    {campaignstatus == "stop" ? (
                      <label>
                        Click <span>resume</span> to reinstate the event to a
                        live mode. You can always come back and change this.
                      </label>
                    ) : (
                      <label>
                        Click <span>pause</span> for sleep mode. You can always
                        come back and change this.
                      </label>
                    )}
                  </label>
                </div>

                <div className="col-lg-3 col-3 text-right">
                  <label className="back-arrow">
                    <div
                      className="save-btn"
                      onClick={() => this.resumeCampaign()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {campaignstatus == "stop" ? "Resume" : "Pause"}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>

                <div className="col-lg-3 col-3 text-right">
                  <label className="back-arrow">
                    <div className="cancel-btn" onClick={() => this.cancel()}>
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Cancel
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.postponeEvent} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-0 pr-0 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row text-center mb-4 pr-4 pl-4 justify-content-end align-self-center">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div
                      className="save-btn"
                      onClick={this.postponeEvent.bind(this)}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        Proceed
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>

                  <label className="back-arrow">
                    <div
                      className="cancel-btn"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-0 pr-0 pt-0 pb-0">
                    <div className="h-divider w-100"></div>
                  </div>

                  <div className="col-12 text-left pl-0 pr-0">
                    <Select
                      value={data.find((obj) => obj.value === reason)}
                      placeholder="Reason for this action"
                      options={data}
                      onChange={this.handleChange}
                      width={"100%"}
                      className="text-left"
                    />
                  </div>

                  <div
                    className="col-12 mt-4 mb-2 justify-content-center  pl-0 pr-0"
                    style={{ display: "flex" }}
                  >
                    {/* <select>
                      <option>fdfd</option>
                    </select> */}
                    <textarea
                      placeholder={"Write message (also propose a new date)"}
                      onChange={this.handleMessage}
                    />
                  </div>

                  <div className="col-12 text-center">
                    <label className="small-details">
                      An audience of {this.state.numberOfcustomers}{" "}
                      {this.state.numberOfcustomers == 1 ? "person" : "people"}{" "}
                      will be notified.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.addPoster} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row w-100 mb-4 justify-content-end align-self-center pr-3">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div
                      className="save-btn"
                      style={{ display: this.state.hideUploadBtn }}
                      onClick={this.uploadPoster.bind(this)}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {poster.trim() == "" ? "Add" : "Update"}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>

                  <label className="back-arrow">
                    <div
                      className="cancel-btn"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-4 pr-2 pt-0 pb-0">
                    <div className="h-divider w-100"></div>
                  </div>

                  <div
                    className="col-12 mt-4 mb-4 justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <label
                      className={this.state.activeBox}
                      style={{
                        backgroundImage: "url(" + this.state.image + ")",
                        backgroundSize: "contain",
                        // backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <IoIosAdd size={40} />
                      <input
                        name="file"
                        type="file"
                        onChange={this.selectImage}
                        id="image"
                        accept={".jpg,.jpeg,.png"}
                        className="hide-file-input-type"
                      />
                      {/* <img
                        src={this.state.image}
                        width={"100%"}
                        htmlFor="image"
                        className="img-poster"
                      /> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.controlModal} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4">
              <div className="row justify-content-center align-self-center">
                <div className="col-12 text-center mb-4">
                  <label className="small-details">
                    {this.state.checktickets == "no" ? (
                      <label>
                        {" "}
                        You first have to{" "}
                        <label
                          onClick={this.openModal}
                          className={"color-blue"}
                        >
                          {" "}
                          load tickets
                        </label>{" "}
                        before you can do anything.
                      </label>
                    ) : this.state.checkposter == "no" ? (
                      <label>
                        {" "}
                        Now that tickets are loaded,{" "}
                        <label
                          onClick={this.addPoster}
                          className={"color-blue"}
                        >
                          {" "}
                          upload a poster
                        </label>{" "}
                        and then create a campaign
                      </label>
                    ) : (
                      this.state.delString
                    )}
                  </label>
                </div>

                <div className="col-lg-3 col-3 text-right">
                  <label className="back-arrow">
                    <div className="cancel-btn" onClick={() => this.cancel()}>
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.deleteModal} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="col-12 text-center mb-4">
                  <label className="small-details">
                    Are you sure you want to delete this event? This action
                    cannot be undone.
                  </label>
                </div>

                <div className="col-lg-6 col-6 text-right">
                  <label className="back-arrow">
                    <div className="save-btn" onClick={() => this.delete()}>
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Delete
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>

                <div className="col-lg-6 col-6 text-left">
                  <label className="back-arrow">
                    <div className="cancel-btn" onClick={() => this.cancel()}>
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Cancel
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={this.state.showModal} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-3 pr-3 modalBGcolor">
              <div className="row justify-content-end align-self-center pr-3">
                <label className="back-arrow" style={{ marginRight: 15 }}>
                  <div className="save-btn" onClick={() => this.update()}>
                    <span
                      style={{
                        display: this.state.updateDisplay,
                      }}
                    >
                      {" "}
                      Load
                    </span>
                    <span
                      style={{
                        display: this.state.spinning,
                      }}
                    >
                      <ClipLoader
                        color={"#fff"}
                        loading={true}
                        // css={override}
                        size={20}
                      />
                    </span>
                  </div>
                </label>

                <label className="back-arrow">
                  <div className="cancel-btn" onClick={() => this.cancel()}>
                    <span
                      style={{
                        display: this.state.updateDisplay,
                      }}
                    >
                      {" "}
                      Cancel
                    </span>
                    <span
                      style={{
                        display: this.state.spinning,
                      }}
                    >
                      <ClipLoader
                        color={"#fff"}
                        loading={true}
                        // css={override}
                        size={20}
                      />
                    </span>
                  </div>
                </label>
              </div>
              <div class="h-divider w-100"></div>
              <div
                className={this.state.itemVVIP + " w-100 mb-2"}
                onClick={() => this.activateVVIP()}
              >
                <div
                  to={{
                    pathname: "logout",
                  }}
                  className="link-btn w-100 menu-links "
                >
                  <div className="col-12">
                    <label className="item-names small-details">
                      <span className="color-black">VVIP</span> <br />
                      Loaded: {this.state.getVVIP}, Price:{" "}
                      {this.state.getVVIPprice} - <span>Adults</span>
                      <br />
                      Loaded: {this.state.kids_tickets}, Price: R
                      {this.state.kids_price} - <span>Kids</span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={this.state.itemVIP + " w-100 mb-2"}
                onClick={() => this.activateVIP()}
              >
                <div
                  to={{
                    pathname: "logout",
                  }}
                  className="link-btn w-100 menu-links "
                >
                  <div className="col-12">
                    <label className="item-names small-details">
                      <span className="color-black">VIP</span> <br />
                      Loaded: {this.state.getVIP}, Price:{" "}
                      {this.state.getVIPprice} - <span>Adults</span>
                      <br />
                      Loaded: {this.state.kids_vip_tickets}, Price: R
                      {this.state.kids_vip_price} - <span>Kids</span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={this.state.itemGen + " w-100 mb-2"}
                onClick={() => this.activateGen()}
              >
                <div
                  to={{
                    pathname: "logout",
                  }}
                  className="link-btn w-100 menu-links "
                >
                  <div className="col-12">
                    <label className="item-names small-details">
                      <span className="color-black">General</span> <br />
                      Loaded: {this.state.getGen}, Price:{" "}
                      {this.state.getGENprice} - <span>Adults</span>
                      <br />
                      Loaded: {this.state.kids_general_tickets}, Price: R
                      {this.state.kids_general_price} - <span>Kids</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="h-divider w-100"></div>
              <div className="col-12 mt-4 mb-4 p-0">
                <span>Category: </span> {this.state.currentSelect}
                <br />
                <br />
                <h5 className="w-100">Adults</h5>
                <div className="row">
                  <div className="col-6 pl-0">
                    Set ticket price
                    <br />
                    <input
                      type="text"
                      placeholder="Price"
                      value={
                        this.state.selectedPrice
                          ? this.state.selectedPrice.replace("R", "")
                          : this.state.selectedPrice
                      }
                      onChange={this.handlePrice}
                      className="edit-input"
                    />
                  </div>
                  <div className="col-6 pr-0">
                    How many tickets?
                    <br />
                    <input
                      type="text"
                      placeholder="Load tickets"
                      value={this.state.selectedTicket}
                      onChange={this.handleTickets}
                      className="edit-input"
                    />
                  </div>
                </div>
                <br />
                <h5 className="w-100">Kids</h5>
                <div className="row">
                  <div className="col-6 pl-0">
                    Set ticket price
                    <br />
                    <input
                      type="text"
                      placeholder="Set ticket price"
                      value={
                        this.state.kidsSelectedPrice
                          ? this.state.kidsSelectedPrice.replace("R", "")
                          : this.state.kidsSelectedPrice
                      }
                      onChange={this.handle_kids_price}
                      className="edit-input"
                    />
                  </div>
                  <div className="col-6 pr-0">
                    How many tickets?
                    <br />
                    <input
                      type="text"
                      placeholder="Load tickets"
                      value={this.state.kidsSelectedTicket}
                      onChange={this.handle_kids_tickets}
                      className="edit-input"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  value={this.state.currentSelect.toLowerCase()}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </Modal>
          <div className="row justify-content-center just-container-insid mt-4">
            <div className="col-lg-11 col-12 pl-2 pr-2">
              <label className={"category-name-heading"}>
                {this.state.checkcampaign == "yes" ? (
                  <Link
                    to="/campaignmanager"
                    className="link-color font-normal"
                  >
                    Go to campaign manager
                  </Link>
                ) : (
                  ""
                )}
              </label>
            </div>
            <div className="col-lg-7 padding-added pl-2 pr-2">
              <div className=" BoxDashboardContainer mt-0 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-6 pl-0">
                      <Link to={prevpage} className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <Link
                          to={{
                            pathname: "updateeventdetails",
                            state: {
                              prevpage: prevpage,
                              currentage: "editevent",
                              eventname: eventname,
                              eventdate: eventdate,
                              eventid: eventid,
                              poster: poster,
                              theme: theme,
                              venue: venue,
                              expectednum: expectednum,
                              enddate: enddate,
                              time: time,
                              endtime: endtime,
                              event_date: event_date,
                              finish_date: finish_date,
                            },
                          }}
                          className="link-btn"
                        >
                          <div className="edit-btn">Edit</div>
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title">
                        {this.state.deletion}
                        {eventname}
                        <br />
                        <label class="small-details">{eventdate}</label>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>

                <div className="col-lg-12 details-body mb-3">
                  <img
                    src={this.state.preimage}
                    width={"100%"}
                    className="img-poster"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 p-0">
              {/* <div className="col-lg-12 tickets-assigned"> */}
              <div className="col-lg-12  pl-2 pr-2">
                <div className=" BoxDashboardContainer mt-0 mb-0 pb-3 pl-3 pr-3">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0">
                        <label
                          className="ticket-title"
                          style={{
                            width: 150,
                            paddingTop: 7,
                          }}
                        >
                          ACTION
                        </label>
                        <label
                          className="resume-btn"
                          onClick={() => this.resumeModal()}
                        >
                          {statusString}
                        </label>
                      </div>
                    </div>

                    <div className="h-divider w-100"></div>
                  </div>

                  <div className="item-select w-100 mb-2">
                    <Link
                      to={{
                        pathname: "analytics",
                        state: {
                          campaignstatus: campaignstatus,
                          eventid: eventid,
                          eventname: eventname,
                          poster: poster,
                          eventdate: eventdate,
                          enddate: enddate,
                          event_date: event_date,
                          finish_date: finish_date,
                          theme: theme,
                          venue: venue,
                          expectednum: expectednum,
                          time: time,
                          endtime: endtime,
                          prevpage: prevpage,
                          back: "editevent",
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoAnalyticsOutline
                          size={30}
                          className={analyticsActive}
                        />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-11">
                        <label className={analyticsActive + " w-100 mb-0"}>
                          Analytics
                          <label
                            className="float-right"
                            style={{
                              color: "rgb(102, 178, 102)",
                              fontSize: 15,
                            }}
                          >
                            {/* <i>New</i> */}
                          </label>
                        </label>
                      </div>
                    </Link>
                  </div>

                  <div
                    className="item-select w-100 mb-2 menu-links"
                    onClick={
                      event_date >= fulldate && this.state.deleted == 0
                        ? this.openModal
                        : this.functionControl
                    }
                  >
                    <div className="link-btn w-100 menu-links ">
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoTicketOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active + " w-100 mb-0"}>
                          Load tickets{" "}
                          {this.state.checktickets == "no" ? (
                            <label
                              className="float-right"
                              data-tip={"Please load tickets"}
                            >
                              {" "}
                              <IoIosInformationCircleOutline
                                size={20}
                                color={"red"}
                              />
                              <ReactTooltip />
                            </label>
                          ) : (
                            <label className="float-right">
                              {" "}
                              <IoIosCheckmarkCircleOutline
                                size={20}
                                color={"#66b266"}
                              />
                            </label>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="item-select w-100 mb-2">
                    <div
                      className="link-btn w-100 menu-links "
                      onClick={
                        event_date >= fulldate && this.state.deleted == 0
                          ? this.addPoster
                          : this.functionControl
                      }
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoImageOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active + " w-100 mb-0"}>
                          Add poster
                          {this.state.checkposter == "no" ? (
                            <label
                              className="float-right"
                              data-tip={"Add poster to this event"}
                            >
                              {" "}
                              <IoIosInformationCircleOutline
                                size={20}
                                color={"red"}
                              />
                              <ReactTooltip />
                            </label>
                          ) : (
                            <label className="float-right">
                              {" "}
                              <IoIosCheckmarkCircleOutline
                                size={20}
                                color={"#66b266"}
                              />
                            </label>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="item-select w-100 mb-2">
                    <div
                      className="link-btn w-100 menu-links "
                      onClick={
                        event_date >= fulldate &&
                        this.state.deleted == 0 &&
                        this.state.checktickets == "yes" &&
                        this.state.checkposter == "yes"
                          ? this.campaign
                          : this.functionControl
                      }
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoFlowerOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active + " w-100 mb-0"}>
                          Create campaign
                          {this.state.checkcampaign == "no" ? (
                            <label
                              className="float-right"
                              data-tip={
                                "Create a campaign to activate this event"
                              }
                            >
                              {" "}
                              <IoIosInformationCircleOutline
                                size={20}
                                color={"red"}
                              />
                              <ReactTooltip />
                            </label>
                          ) : (
                            <label className="float-right">
                              {" "}
                              <IoIosCheckmarkCircleOutline
                                size={20}
                                color={"#66b266"}
                              />
                            </label>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* {this.state.eventtype == "beauty pageant" ? (
                    <div className="item-select w-100 mb-2">
                      <div
                        className="link-btn w-100 menu-links "
                        onClick={
                          event_date >= fulldate &&
                          this.state.deleted == 0 &&
                          this.state.checktickets == "yes" &&
                          this.state.checkposter == "yes" &&
                          this.state.checkcampaign == "yes"
                            ? this.addModels
                            : this.functionControl
                        }
                      >
                        <div className="col-lg-2 col-2 col-sm-1 text-center">
                          <div className="row">
                            <div className="col-6 p-0">
                              <IoMdWoman size={17} className={active} />
                            </div>
                            <div className="col-6 p-0">
                              <IoIosMan size={15} className={active} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-10 col-10 col-sm-10">
                          <label className={active + " w-100 mb-0"}>
                            Add models
                            <label className="float-right color-green">
                              <i>New</i>
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  <div className="item-select w-100 mb-2">
                    <div
                      className="link-btn w-100 menu-links "
                      onClick={
                        event_date >= fulldate &&
                        this.state.deleted == 0 &&
                        this.state.checktickets == "yes" &&
                        this.state.checkposter == "yes" &&
                        this.state.checkcampaign == "yes"
                          ? this.downloadTickets
                          : this.functionControl
                      }
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        {this.state.ticketCount > 0 ? (
                          <IoCloudDoneOutline size={30} className={active} />
                        ) : (
                          <IoCloudDownloadOutline
                            size={30}
                            className={active}
                          />
                        )}
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active + " w-100 mb-0"}>
                          Download tickets
                          <span
                            className={
                              this.state.ticketCount > 0
                                ? "float-right"
                                : "d-none"
                            }
                          >
                            <IoIosCheckmarkCircleOutline
                              size={20}
                              color={"#66b266"}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.state.printed}
                </div>
              </div>
              {/* <div className="col-lg-12 tickets-assigned"> */}
              <div className="col-lg-12  pl-2 pr-2">
                <div className=" BoxDashboardContainer mt-4 mb-4 pb-3 ">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0">
                        <label className="ticket-title">MORE ACTION</label>
                      </div>
                    </div>
                  </div>

                  <div className="h-divider w-100" />
                  <div className="item-select w-100 mb-2">
                    <div
                      className="link-btn w-100 menu-links "
                      onClick={
                        event_date >= fulldate && this.state.deleted == 0
                          ? this.postpone
                          : this.functionControl
                      }
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoBicycleOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active}>Postpone event</label>
                      </div>
                    </div>
                  </div>

                  <div className="item-select w-100 mb-2">
                    <div
                      className="link-btn w-100 menu-links "
                      onClick={
                        event_date >= fulldate && this.state.deleted == 0
                          ? this.cancelModal
                          : this.functionControl
                      }
                    >
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoNuclearOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active}>Cancel event</label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="item-select w-100 mb-2"
                    onClick={
                      event_date >= fulldate && this.state.deleted == 0
                        ? this.deleteModal
                        : this.functionControl
                    }
                    // onClick={() => this.deleteModal()}
                  >
                    <div className="link-btn w-100 menu-links ">
                      <div className="col-lg-2 col-2 col-sm-1 text-center">
                        <IoTrashOutline size={30} className={active} />
                      </div>
                      <div className="col-lg-10 col-10 col-sm-10">
                        <label className={active}>Delete event</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
      </div>
    );
  }
}

export default Editevent;
