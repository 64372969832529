import React, { Component } from "react";
import "./SlideDrawer.css";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { BsAppIndicator, BsBattery } from "react-icons/bs";
import { BiCartAlt } from "react-icons/bi";
import { IoIosLogOut } from "react-icons/io";

class SlideDrawer extends Component {
  state = {
    products: [],
  };
  componentDidMount() {
    let search = window.location.search;
    let query = new URLSearchParams(search);
    let ticketid = query.get("ticketid");
    let eventid = query.get("eventid");

    Axios.get(
      "/api/select/payment/method?eventid=" + eventid + "&ticketid=" + ticketid
    ).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  render() {
    let drawerClasses = "slide-drawer";
    if (this.props.show) {
      drawerClasses = "slide-drawer open";
    }

    const boosterid = localStorage.getItem("boosterid");
    const boostername = localStorage.getItem("boostername");
    const boosteremail = localStorage.getItem("boosteremail");
    const boosterphone = localStorage.getItem("boosterphone");
    const activation = localStorage.getItem("activation");
    const balance = localStorage.getItem("balance");
    const credit = localStorage.getItem("credit");
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");

    return (
      <div className={drawerClasses}>
        <div className="row">
          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "/Boosterdashboard",
                state: {
                  activation: activation,
                  boosteremail: boosteremail,
                  boosterphone: boosterphone,
                  businessid: businessid,
                  businessname: businessname,
                  boostername: boostername,
                  boosterid: boosterid,
                  credit: credit,
                  balance: balance,
                },
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight ">
                <BsAppIndicator size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Dashboard
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "/selltickets",
                state: {
                  activation: activation,
                  boosteremail: boosteremail,
                  boosterphone: boosterphone,
                  businessid: businessid,
                  businessname: businessname,
                  boostername: boostername,
                  boosterid: boosterid,
                  credit: credit,
                  balance: balance,
                },
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BiCartAlt size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Sell tickets
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "/Boosterprofile",
                state: {
                  activation: activation,
                  boosteremail: boosteremail,
                  boosterphone: boosterphone,
                  businessid: businessid,
                  businessname: businessname,
                  boostername: boostername,
                  boosterid: boosterid,
                  credit: credit,
                  balance: balance,
                },
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPerson size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Profile
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "/accountstatus",
                state: {
                  activation: activation,
                  boosteremail: boosteremail,
                  boosterphone: boosterphone,
                  businessid: businessid,
                  businessname: businessname,
                  boostername: boostername,
                  boosterid: boosterid,
                  credit: credit,
                  balance: balance,
                },
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsBattery size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Account status
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "logout",
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoIosLogOut size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;log out
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideDrawer;
