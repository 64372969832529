import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsPlus } from "react-icons/bs";
import { BsDash } from "react-icons/bs";
import ReactTooltip from "react-tooltip";

import NumericInput from "react-numeric-input";

class Eft extends Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const ticketid = query.get("ticketid");
    const apiUrl =
      "/api/select/payment/method?eventid=" +
      eventid +
      "&ticketid=" +
      ticketid;

    Axios.get(apiUrl).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      products: [],
      isChecked: false,
      isBtnDisabled: true,
      add: "",
      minus: "",
      admissionValue: 1,
      total: this.props.location.state.price,
      price: this.props.location.state.price,
      btnClasses: ["btnDisabled w-100"],
      fullname: "",
      selectedFile: "",
      ticketnumber: Math.random().toString(36).substr(2, 7).toUpperCase(),
      phone: "",
      email: "",
      key: Math.random().toString(36).substr(2, 100).toUpperCase(),
    };
  }

  handleChange = this.handleChange.bind(this);
  handleEmail = this.handleEmail.bind(this);
  handlePhone = this.handlePhone.bind(this);
  handleAdmission = this.handleAdmission.bind(this);
  handleTotal = this.handleTotal.bind(this);
  handleTicketnumber = this.handleTicketnumber.bind(this);
  handleFile = this.handleFile.bind(this);
  //   handleSubmit = this.handleSubmit.bind(this);

  handleTicketnumber(event) {
    this.setState({ ticketnumber: event.target.value });
  }

  handleTotal(event) {
    this.setState({ total: event.target.value });
  }

  handleAdmission(event) {
    this.setState({ admissionValue: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
    const livePhone = event.target.value;
    const formData = new FormData();
    formData.append("phone", livePhone);

    const url = "/getUserEmail";
    Axios.post(url, formData).then((res) => {
      this.setState({ email: res.data[0].email });
      this.setState({ fullname: res.data[0].fname + " " + res.data[0].lname });
      console.log(res.data[0].email);
    });
  }

  handleEmail(event) {
    this.setState({ email: event.target.value });
    const liveEmail = event.target.value;
    const formData = new FormData();
    formData.append("email", liveEmail);

    const url = "/getUserPhone";
    Axios.post(url, formData).then((res) => {
      this.setState({ phone: res.data[0].phone });
      this.setState({ fullname: res.data[0].fname + " " + res.data[0].lname });
      console.log(res.data[0].phone);
    });
  }

  handleChange(event) {
    this.setState({ fullname: event.target.value });
  }

  checkHandleClicked = () => {
    this.setState((prevState) => ({
      isChecked: !this.state.isChecked,
      isBtnDisabled: prevState.false,
      btnClasses: ["karamale-btn-square karamale-btn w-100"],
    }));
  };

  addHandle = () => {
    const pricetag = this.props.location.state.price;
    const admissionValue = this.state.admissionValue;
    if (admissionValue == 15) {
      this.setState({ maxMessage: "You've reached the maximum" });
    } else {
      if (admissionValue > 0) {
        this.setState({ maxMessage: "" });
      }
      this.setState((prevState) => ({
        admissionValue: prevState.admissionValue + 1,
        total: prevState.total + pricetag,
      }));
    }
  };

  minusHandle = () => {
    const pricetag = this.props.location.state.price;
    const admissionValue = this.state.admissionValue;
    if (admissionValue == 1) {
      this.setState({ maxMessage: "You've reached the minimum" });
    } else {
      if (admissionValue < 16) {
        this.setState({ maxMessage: "" });
      }
      this.setState((prevState) => ({
        admissionValue: prevState.admissionValue - 1,
        total: prevState.total - pricetag,
      }));
    }
  };

  handleSubmit = async (event) => {
    // event.preventDefault();
    // const data = new FormData(event.target);
    const Formdata = new FormData();
    Formdata.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    Formdata.append("eventid", this.props.location.state.eventid);
    Formdata.append("businessid", this.props.location.state.businessid);
    Formdata.append("ticketid", this.props.location.state.ticketid);
    Formdata.append("ticketref", this.props.location.state.ticketref);
    Formdata.append("total", this.state.total);
    Formdata.append("price", this.state.price);
    Formdata.append("admissionValue", this.state.admissionValue);
    Formdata.append("phone", this.state.phone);
    Formdata.append("email", this.state.email);
    Formdata.append("fullname", this.state.fullname);
    Formdata.append("ticketnumber", this.state.ticketnumber);
    Formdata.append("attachmentName", this.state.selectedFile.name);

    try {
      const res = await Axios.post(
        "/api/insert/ticket/buyer/details/attachment",
        Formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
        alert("There was a problem with the server");
      } else {
        console.log(err.response.data.msg);
      }
    }

    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
  };

  handleFile(event) {
    console.log(event.target.files);
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  render() {
    const { products } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const ticketid = query.get("ticketid");
    const q = query.get("q");
    // const ticketnumber = Math.random().toString(36).substr(2, 7).toUpperCase();
    // const command = query.get("command");

    return (
      <form action="/successeft" onSubmit={this.handleSubmit}>
        <div className="container">
          {products.map((events) => (
            <div className="row mt-4 mb-5">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-4">
                <div className="backbtn mb-3 event-title">
                  <Link
                    to={
                      "/buy?q=" +
                      q +
                      "&eventid=" +
                      eventid +
                      "&ticketid=" +
                      ticketid
                    }
                    className="backArrow"
                    data-tip="Back"
                  >
                    <ReactTooltip />
                    <BsArrowLeft />
                  </Link>{" "}
                  &nbsp;&nbsp;&nbsp; {events.event_name}
                </div>
                <div className="nainer">
                  <div className="">
                    <img
                      src={
                        "http://localhost/karamalenew/src/posters/" +
                        events.businessId +
                        "/" +
                        events.thumbnail
                      }
                      alt={events.thumbnail}
                      width="100%"
                    />
                  </div>
                  <div className="col-lg-12 p-3">
                    <div className="eventName">
                      <strong className="textWeight">
                        {events.event_name}
                      </strong>
                      <br />
                      {events.eventDate}
                      <span className="time">Time: {events.start_time}</span>
                      <br />
                      <br />
                      <span className="boldText">Description</span>
                      <br />
                      {events.event_des}
                      <br />
                      <br />
                      <span className="boldText">Venue</span>
                      <br />
                      {events.event_location} <br />
                      <br />
                      <span className="boldText">Theme</span>
                      <br />
                      {events.event_theme}
                    </div>
                  </div>
                </div>
                <div className="nainer mt-4">
                  <div className="col-lg-12 pt-2 pb-2 greyText">
                    <span className="boldText">Business Info</span>
                    <br />
                    <strong className="textWeight">Company </strong>{" "}
                    {events.business_name} <br />
                    <strong className="textWeight">Primary number </strong>{" "}
                    {events.cell_number} <br />
                    <strong className="textWeight">Seconday number</strong>{" "}
                    {events.tel} <br />
                    <strong className="textWeight">Email </strong>{" "}
                    {events.email} <br /> <br />
                    <span className="boldText">Address</span> <br />
                    {events.address}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="nainer margins">
                  <div className="ticket-title">{events.ticket_cat} Ticket</div>
                  <div className="pl-3 pr-3 pt-2">
                    <div className="upperCasedText">
                      <span className="capitalizedText textLight">
                        Adults: R{events.price}
                        <br />
                        Tickets Available: {events.avail_tickets}
                        <br />
                        <br />
                        <b>Banking details</b>
                        <br />
                        <b>Bank:</b> {events.bank_name.toUpperCase()}
                        <br />
                        <b>Account Number:</b> {events.account_number}
                        <br />
                        <b>Account Holder:</b> {events.account_holder}
                        <br />
                        <b>Branch Code:</b> {events.branch_code}
                        <br />
                        <b>Reference Number:</b> {this.state.ticketnumber}
                        <br />
                        <br />
                        <label className="normalCase">
                          Proof of payment required
                          <sup className="text-red">*</sup>
                        </label>
                        <br />
                        <div className="row mt-3">
                          <div className="col-lg-12 form-components ">
                            <input
                              required
                              type="hidden"
                              name="key"
                              value={this.state.key}
                            />
                            <input
                              required
                              type="hidden"
                              name="ticketnumber"
                              value={this.state.ticketnumber}
                              onChange={this.handleTicketnumber}
                            />
                            <input
                              required
                              type="text"
                              name="fullname"
                              placeholder="Name and Surname"
                              value={this.state.fullname}
                              onChange={this.handleChange}
                            />
                            <input
                              required
                              type="email"
                              name="email"
                              placeholder="Email address"
                              value={this.state.email}
                              onChange={this.handleEmail}
                            />
                            <input
                              required
                              type="text"
                              name="phone"
                              placeholder="Phone number"
                              value={this.state.phone}
                              onChange={this.handlePhone}
                            />

                            <input
                              required
                              type="file"
                              onChange={this.handleFile}
                              id="file"
                            />

                            <label className="textLight w-100">
                              Add Admission{" "}
                              <span className="float-right textLight text-15">
                                Total: R
                                <input
                                  type="text"
                                  readOnly
                                  value={this.state.total}
                                  onChange={this.handleTotal}
                                  className="totalAmount"
                                />
                              </span>
                            </label>
                            <label className="max-message">
                              {this.state.maxMessage}
                            </label>
                            <br />
                            <div className="row mb-3">
                              <div className="col-4 pr-1">
                                <button
                                  className="minus"
                                  type="button"
                                  onClick={this.minusHandle}
                                >
                                  <BsDash />
                                </button>
                              </div>
                              <div className="col-4 pr-2 pl-2">
                                <input
                                  type="text"
                                  name="admission"
                                  className="admissionInput"
                                  placeholder="0"
                                  readOnly
                                  min={1}
                                  max={3}
                                  value={this.state.admissionValue}
                                  onChange={this.handleAdmission}
                                />
                              </div>
                              <div className="col-4 pl-1">
                                <button
                                  className="add"
                                  type="button"
                                  onClick={this.addHandle}
                                >
                                  <BsPlus />
                                </button>
                                <input type="hidden" value={this.state.price} />
                                <input
                                  type="hidden"
                                  value={this.props.location.state.businessid}
                                  name="businessid"
                                />
                                <input
                                  type="hidden"
                                  value={this.props.location.state.ticketid}
                                  name="ticketid"
                                />
                                <input
                                  type="hidden"
                                  value={this.props.location.state.eventid}
                                  name="eventid"
                                />
                                <input
                                  type="hidden"
                                  value={this.props.location.state.ticketref}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-2 col-2 text-left">
                                <input
                                  type="checkbox"
                                  onChange={this.state.isChecked}
                                  className="checkbox"
                                  name="agreement"
                                  onClick={this.checkHandleClicked}
                                />
                              </div>
                              <div className="col-lg-10 col-10 terms text-left">
                                I've read and agreed to the{" "}
                                <Link
                                  to={{
                                    pathname: "/terms-and-conditions",
                                  }}
                                >
                                  terms and conditions
                                </Link>
                              </div>
                            </div>

                            <button
                              disabled={this.state.isBtnDisabled}
                              className={this.state.btnClasses}
                            >
                              <BsArrowRight /> &nbsp; Proceed to buy
                            </button>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </form>
    );
  }
}

export default Eft;
