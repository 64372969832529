import React, { Component } from "react";
import "./App.css";
import { Link, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import Buttons from "./Buttons";
import { IoIosArrowBack, IoIosHappy } from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import { BiPurchaseTagAlt } from "react-icons/bi";
import ClipLoader from "react-spinners/BeatLoader";
import Header4 from "./components/Header4";

import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Eventdescription extends Component {
  state = {
    error: null,
    products: [],
    tickets: [],
    fetchingTickets: true,
  };

  componentDidMount() {
    // const eventname = this.props.location.state.eventname;
    // const eventid = this.props.location.state.eventid;

    const query = new URLSearchParams(this.props.location.search);

    if (
      query.has("eventid") === true &&
      query.has("eventname") === true &&
      query.has("boostername") === true &&
      query.has("posterUri") === true &&
      query.has("venue") === true &&
      query.has("description") === true &&
      query.has("businessid") === true &&
      query.has("eventday") === true &&
      query.has("starttime") === true &&
      query.has("endtime") === true &&
      query.has("boosterid") === true &&
      query.has("activation") === true &&
      query.has("boosteremail") === true &&
      query.has("boosterphone") === true &&
      query.has("boostername") === true &&
      query.has("businessname") === true &&
      query.has("balance") === true &&
      query.has("credit") === true
    ) {
    } else {
      this.props.history.push("");
    }

    const eventid = query.get("eventid");
    document.title = query.get("eventname");
    // console.log("error");
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TICKETS&eventid=" +
        eventid
    ).then((response) => {
      this.setState({
        tickets: response.data,
        fetchingTickets: false,
      });
    });
  }

  tickets = () => {
    const query = new URLSearchParams(this.props.location.search);

    const eventid = query.get("eventid");
    const accountid = query.get("accountid");
    const eventDate = query.get("eventDate");
    const customerid = query.get("customerid");
    const posterUri = query.get("posterUri");
    const accounttype = query.get("accounttype");
    const customername = query.get("customername");
    const email = query.get("email");
    const phone = query.get("phone");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const poster = query.get("poster");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");
    const previousPage = query.get("previousPage");
    const campaignStatus = query.get("campaignStatus");
    const category = query.get("category");
    const province = query.get("province");
    const credit = query.get("credit");

    let { hide, warningdiv, soldOut, soldOutLabel } = "";
    const date = new Date();
    let day = date.getDate();
    if (day >= 10) {
      day = day;
    } else {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;

    if (month >= 10) {
      month = month;
    } else {
      month = "0" + month;
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;

    return (
      <div className="row justify-content-center">
        {this.state.tickets.map((item, index) => {
          let ticket = "";

          if (item.ticket_cat == "vvip") {
            ticket = "VVIP";
          } else if (item.ticket_cat == "vip") {
            ticket = "VIP";
          } else {
            ticket = "General";
          }

          {
            eventday >= fulldate ? (hide = "flex") : (hide = "none");
          }

          {
            eventDate >= fulldate
              ? (warningdiv = "none")
              : (warningdiv = "flex");
          }
          return (
            <div className="col-4 col-lg-12 pl-1 pr-1">
              {parseInt(item.avail_tickets) <= 0 &&
              parseInt(item.kids_tickets) <= 0 ? (
                <div className="col-12 col-lg-12 p-0">
                  <label className="ticket-btn-disabled">
                    {ticket} <span className="ticket-price">Sold out </span>
                  </label>
                </div>
              ) : (
                <div className="col-12 col-lg-12 p-0">
                  <Link
                    style={{ display: hide, textDecoration: "none" }}
                    to={{
                      pathname: "/customerpay",
                      search:
                        "&q=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&pageid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&boostername=" +
                        localStorage.getItem("boostername") +
                        "&q=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&pageid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&fulldate=" +
                        fulldate +
                        "&eventDate=" +
                        eventDate +
                        "&price=" +
                        item.price +
                        "&kids_price=" +
                        item.kids_price +
                        "&ticketid=" +
                        item.id +
                        "&ticketref=" +
                        item.ticketref +
                        "&command=1" +
                        customerid +
                        "&posterUri=" +
                        posterUri +
                        "&customername=" +
                        customername +
                        "&email=" +
                        email +
                        "&phone=" +
                        phone +
                        "&accountid=" +
                        accountid +
                        "&accounttype=" +
                        accounttype +
                        "&price=" +
                        item.price +
                        "&eventtype=" +
                        category +
                        "&province=" +
                        province +
                        "&venue=" +
                        venue +
                        "&description=" +
                        description +
                        "&businessid=" +
                        businessid +
                        "&eventid=" +
                        eventid +
                        "&poster=" +
                        poster +
                        "&eventname=" +
                        eventname +
                        "&eventday=" +
                        eventday +
                        "&starttime=" +
                        starttime +
                        "&endtime=" +
                        endtime +
                        "&ticketid=" +
                        item.id +
                        "&eventid=" +
                        eventid +
                        "&previousPage=" +
                        previousPage +
                        "&prevPage=event&campaignStatus=" +
                        campaignStatus +
                        "&category=" +
                        ticket +
                        "&credit=" +
                        credit +
                        "&boosterid=" +
                        localStorage.getItem("boosterid") +
                        "&activation=" +
                        query.get("activation") +
                        "&boosteremail=" +
                        query.get("boosteremail") +
                        "&boosteremail=" +
                        query.get("boosteremail") +
                        "&boosterphone=" +
                        query.get("boosterphone") +
                        "&businessname=" +
                        query.get("businessname") +
                        "&balance=" +
                        query.get("balance") +
                        "&sold=" +
                        item.sold +
                        "&avail_tickets=" +
                        item.avail_tickets +
                        "&kids_sold=" +
                        item.kids_sold +
                        "&kids_tickets=" +
                        item.kids_tickets,

                      state: {
                        fromHome: true,
                        fulldate: fulldate,
                        eventDate: eventDate,
                        price: item.price,
                        ticketid: item.ticketid,
                        ticketref: item.ticketref,
                        command: 1,
                        customerid: customerid,
                        posterUri: posterUri,
                        customername: customername,
                        email: email,
                        phone: phone,
                        accountid: accountid,
                        accounttype: accounttype,
                        price: item.price,
                        category: ticket,
                        venue: venue,
                        description: description,
                        businessid: businessid,
                        eventid: eventid,
                        poster: poster,
                        eventname: eventname,
                        eventday: eventday,
                        starttime: starttime,
                        endtime: endtime,
                        ticketid: item.id,
                        eventid: eventid,
                        previousPage: previousPage,
                        prevPage: "event",
                        campaignStatus: campaignStatus,
                      },
                    }}
                  >
                    <label className="desktop-buttons-purchase-options">
                      <label className="ticket-btn mb-0">
                        <span>{ticket} - </span>
                        <span class="break">
                          <br />
                        </span>
                        {/* <span className="ticket-price mobile-break"> */}
                        <label className=" mobile-break mb-0">
                          {item.price <= 0 ? (
                            ""
                          ) : (
                            <label className="mb-0">
                              Adults: R{item.price}
                            </label>
                          )}
                          {item.kids_price <= 0 ? (
                            ""
                          ) : (
                            <label className="mb-0">
                              , Kids: R{item.kids_price}
                            </label>
                          )}
                        </label>
                      </label>
                    </label>

                    <label className="ticket-btn-container small-details font-size-12">
                      <label className="ticket-btn">
                        {ticket}
                        <span class="break">
                          <br />
                        </span>
                      </label>
                      {item.price <= 0 ? (
                        ""
                      ) : (
                        <label className="mb-0">Adults: R{item.price}</label>
                      )}
                      <br />
                      {item.kids_price <= 0 ? (
                        ""
                      ) : (
                        <label className="mb-0">Kids: R{item.kids_price}</label>
                      )}
                    </label>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("boosterid") === null) {
      return <Redirect to="/" />;
      //   return exit(1);
    } else {
    }
    const query = new URLSearchParams(this.props.location.search);

    const { error, products, fetchingTickets } = this.state;
    const q = query.get("q");
    const queryid = query.get("id");
    const date = new Date();

    let day = date.getDate();
    if (day >= 10) {
      day = day;
    } else {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;

    if (month >= 10) {
      month = month;
    } else {
      month = "0" + month;
    }

    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;

    const eventid = query.get("eventid");
    const accountid = query.get("accountid");
    const eventDate = query.get("eventDate");
    const posterUri = query.get("posterUri");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");

    const boosterid = localStorage.getItem("boosterid");
    const boosteremail = query.get("boosteremail");
    const boosterphone = query.get("boosterphone");
    const activation = query.get("activation");
    const businessname = query.get("businessname");
    const boostername = query.get("boostername");
    const credit = query.get("credit");
    const balance = query.get("balance");
    let backpage = "";

    let string = "";
    {
      eventday >= fulldate
        ? (string = "")
        : (string = (
            <label className="warning-event">
              This event has already taken place.
              <br />
              You can no longer buy tickets {eventday}
            </label>
          ));
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="view pt-4">
          <Header4 />

          <div className="container">
            <div className="row mt-4 mb-0 w-100">
              {eventDate > fulldate ? (
                <div className="row justify-content-center align-self-center desktop-width">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-4 pl-0 pr-0">
                    <div className="content-detail">
                      <div className="">
                        <img
                          src={posterUri}
                          width="100%"
                          className="img-poster"
                        />
                      </div>

                      <div className="col-lg-12 p-0 mt-4 pb-5">
                        <div className="pb-5">
                          <span className="bolddiv">Description</span>

                          <br />
                          <label className="mb-0 whiteSpace">
                            {description}
                          </label>
                          <br />
                          <br />
                          <span className="bolddiv">Venue</span>
                          <br />
                          {venue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center align-self-center desktop-width">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-4 pl-0 pr-0">
                    <div className="content-detail">
                      <div className="">
                        <img
                          src={posterUri}
                          width="100%"
                          className="img-poster"
                        />
                      </div>

                      <div className="col-lg-12 p-0 mt-4 pb-5">
                        <div className="pb-5">
                          <span className="bolddiv">Description</span>

                          <br />
                          <label className="mb-0 whiteSpace">
                            {description}
                          </label>
                          <br />
                          <br />
                          <span className="bolddiv">Venue</span>
                          <br />
                          {venue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tickets">
            <div>
              <div>
                {this.tickets()} {string}
                {fetchingTickets && (
                  <label className="mb-0 w-100 text-center small-details">
                    <ClipLoader color={"#39c"} />
                    <br /> Just a moment...
                  </label>
                )}
              </div>
            </div>
          </div>

          <div
            className="event-name justify-content-center"
            style={{ display: "flex" }}
          >
            <div
              className="backbtn event-title justify-content-center"
              style={{ display: "flex" }}
            >
              <div className="pt-1 pr-2">
                <Link
                  to={{
                    pathname: "/selltickets",
                    state: {
                      activation: activation,
                      boosteremail: boosteremail,
                      boosterphone: boosterphone,
                      businessid: businessid,
                      businessname: businessname,
                      boostername: boostername,
                      boosterid: boosterid,
                      credit: credit,
                      balance: balance,
                    },
                  }}
                  className="backArrow"
                  data-tip="Back"
                  data-effect="solid"
                  data-arrow-color="transparent"
                  data-type="dark"
                  data-place="top"
                >
                  <ReactTooltip />
                  <IoIosArrowBack size={40} className="Back-arrow-blue" />
                </Link>
              </div>
              <div className="text-center">
                <label className="mb-0">{eventname}</label>
                <br />
                <span style={{ fontSize: 13, color: "grey", fontWeight: 400 }}>
                  {eventDate} @ {starttime}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Eventdescription);
