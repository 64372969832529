import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";

class Codepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass1: "",
      pass2: "",
      code: "",
      failed: "",
      success: "",
      error: "",
      successError: "",
      errorDisplay: "none",
    };

    // this.handleEmail = this.handleEmail.bind(this);
    // this.handleForm = this.handleForm.bind(this);
  }
  handleEmail = (event) => {
    this.setState({ email: event.target.value, errorDisplay: "none" });
  };

  handlePass1 = (event) => {
    this.setState({ pass1: event.target.value, errorDisplay: "none" });
  };

  handlePass2 = (event) => {
    this.setState({ pass2: event.target.value, errorDisplay: "none" });
  };

  handleCode = (event) => {
    this.setState({ code: event.target.value, errorDisplay: "none" });
  };
  handleForm = () => {
    const email = this.state.email;
    const pass1 = this.state.pass1;
    const pass2 = this.state.pass2;
    const code = this.state.code;
    if (pass1.trim() === "") {
      this.setState({
        error: "Enter your new password",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (pass2.trim() === "") {
      this.setState({
        error: "Re type your new password",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (pass1 !== pass2) {
      this.setState({
        error: "Make sure both your passwords match",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (email.trim() === "") {
      this.setState({
        error: "Enter email address",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (code.trim() === "") {
      this.setState({
        error: "Enter the ''Reset code''",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=UPDATEPASSCODE&email=" +
          email +
          "&passcodeone=" +
          pass1 +
          "&temppassword=" +
          code
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({
            error: "Reset code is incorrect",
            errorDisplay: "block",
            successError: "error-login",
          });
        } else if (response.data.val == 5) {
          this.setState({
            error:
              "The ''Reset code'' and email address entered are not aligned",
            errorDisplay: "block",
            successError: "error-login",
          });
        } else {
          this.setState({
            error:
              "Reset successful. Your new password is ready for use. Click log in",
            errorDisplay: "block",
            successError: "success-login",
          });
        }
      });
    }
  };
  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center w-100 login-screen-container">
            <div className="col-lg-5 col-12 login-container-adjust">
              <div className="log-title text-center">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width="170" />
                </Link>
              </div>
              <div className=" BoxDashboardContainer">
                <input
                  type="password"
                  name="pass1"
                  required
                  placeholder="Type your new password"
                  onChange={this.handlePass1}
                  value={this.state.pass1}
                />

                <input
                  type="password"
                  name="pass2"
                  required
                  placeholder="Re-type your new password"
                  onChange={this.handlePass2}
                  value={this.state.pass2}
                />

                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email address"
                  onChange={this.handleEmail}
                  value={this.state.email}
                />

                <input
                  type="text"
                  name="code"
                  required
                  placeholder="Enter Reset code"
                  onChange={this.handleCode}
                  value={this.state.code}
                />
                <div
                  className=""
                  style={{
                    padding: 10,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: "red",
                  }}
                >
                  {this.state.failed}
                </div>
                <div
                  className=""
                  style={{
                    padding: 10,
                    paddingLeft: 0,
                    paddingTop: 0,
                    color: "green",
                  }}
                >
                  {this.state.success}
                </div>
                <button onClick={() => this.handleForm()}>Save</button>
                <Link to="/login">
                  <div className="forgotPasswordText text-center">Log in</div>
                </Link>
              </div>

              <div className="text-footer text-center">
                Set new <span>password</span> and resume where you left off
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Codepage;
