import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosArrowBack,
  IoIosCheckmarkCircleOutline,
  IoIosRadioButtonOff,
} from "react-icons/io";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiEditLine } from "react-icons/ri";
import ClipLoader from "react-spinners/ClipLoader";
import Header3 from "./components/Header3";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignname: "",
      keywords: "",
      startdate: "",
      enddate: "",
      error: "",
      estBudget: 10,
      currentBudget: 0,
      budgetString: "Set budget",
      successError: "",
      errorDisplay: "none",
      description: "Loading...",
      spinning: "none",
      updateDisplay: "block",
      btnLabel: "Add payment",
      nb: "",
      optionOne: "item-select-active",
      optionTwo: "item-select",
      optionThree: "item-select",
      checkedOne: true,
      uncheckedOne: false,
      checkedTwo: false,
      uncheckedTwo: true,
      checkedThree: false,
      uncheckedThree: true,
      textColorOne: "color-blue",
      textColorTwo: "color-black",
      textColorThree: "color-black",
      quotationTextThree: "color-grey",
      quotationTextOne: "color-blue",
      quotationTextTwo: "color-grey",
      note: (
        <label className="mt-3 mb-0 color-grey">
          A digital ticket is a virtual product which your customers will be
          able to buy online, transactions are only processed online; no
          physical money; all transactions are electronic.
        </label>
      ),
      cal_budget: 0,
      ifSet: false,
    };
  }

  componentDidMount() {
    const eventid = this.props.location.state.eventid;
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    const eventname = this.props.location.state.eventname;
    document.title = eventname + " - Campaign ";
    localStorage.setItem("ticket_type", "digital");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNESTIMATE&eventid=" +
        eventid +
        "&accountid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          estBudget: response.data.estBudget,
          currentBudget: "Current budget: R" + response.data.currentBudget,
          budgetString: "Increase budget",
          nb: "",
          campaignname: response.data.campaign_name,
          keywords: response.data.keywords,
          startdate: response.data.campaign_start_date,
          enddate: response.data.duration,
          btnLabel: "Add Payment",
          genPrice: response.data.generalPrice,
          genTickets: response.data.generalTickets,
          vipPrice: response.data.vipPrice,
          vipTickets: response.data.vipTickets,
          vvipPrice: response.data.vvipPrice,
          vvipTickets: response.data.vvipTickets,
          ifSet: false,
        });
      } else {
        this.setState({
          estBudget: response.data.estBudget,
          currentBudget: "Current budget: R" + response.data.currentBudget,
          budgetString: "Estimated budget",
          nb: (
            <label className="mb-0 mt-2 color-grey">
              This budget is based on the{" "}
              <label className="mb-0 color-orange">ticket prices</label> and the
              number of tickets you fed the system.{" "}
              <Link to="/budget" className="color-blue">
                Learn more...
              </Link>
              <br />
              <br />
              <label className="mb-0 color-orange">
                <strong>NB:</strong> Ticket price only applies to digital
                tickets.
              </label>
            </label>
          ),
          campaignname: "",
          keywords: "",
          startdate: "",
          enddate: "",
          btnLabel: "Add Payment",
          genPrice: response.data.generalPrice,
          genTickets: response.data.generalTickets,
          vipPrice: response.data.vipPrice,
          vipTickets: response.data.vipTickets,
          vvipPrice: response.data.vvipPrice,
          vvipTickets: response.data.vvipTickets,
          ifSet: true,
        });
      }
    });
  }

  handleCampaigname = (event) => {
    this.setState({
      campaignname: event.target.value,
      errorDisplay: "none",
    });
  };

  handleKeywords = (event) => {
    this.setState({
      keywords: event.target.value,
      errorDisplay: "none",
    });
  };

  handleDate = (event) => {
    this.setState({
      startdate: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEnddate = (event) => {
    this.setState({
      enddate: event.target.value,
      errorDisplay: "none",
    });
  };

  handleBudget = (event) => {
    this.setState({
      estBudget: event.target.value,
      errorDisplay: "none",
    });
  };

  handleSubmit = this.handleSubmit.bind(this);

  handleSubmit(aform_reference) {
    const data = {
      eventid: this.props.location.state.eventid,
      businessid: localStorage.getItem("businessid"),
      campaignname: this.state.campaignname,
      keywords: this.state.keywords,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      estBudget: this.state.estBudget,
    };
  }

  addPayment = () => {
    const eventid = this.props.location.state.eventid;
    const businessid = localStorage.getItem("businessid");
    const budget = this.state.estBudget;

    if (this.state.campaignname.trim() == "") {
      this.setState({
        errorDisplay: "block",
        error: "Enter the campaign name.",
        successError: "error-login",
      });
    } else if (this.state.keywords.trim() == "") {
      this.setState({
        errorDisplay: "block",
        error:
          "Enter keywords relative to the event, and use commas to separate.",
        successError: "error-login",
      });
    } else if (this.state.startdate.trim() == "") {
      this.setState({
        errorDisplay: "block",
        error:
          "Set date from which you would like this campaign to run. We do however recommend today's date.",
        successError: "error-login",
      });
    } else if (this.state.enddate.trim() == "") {
      this.setState({
        errorDisplay: "block",
        error:
          "Set date to which this campaign will stop running. We recommend that you set it to be the same date as the event .",
        successError: "error-login",
      });
    } else if (parseInt(this.state.estBudget) < 10) {
      this.setState({
        errorDisplay: "block",
        error: "Budget should at least be R10 minimum.",
        successError: "error-login",
      });
    } else {
      this.setState({
        btnLabel: "Connecting to the api...",
        errorDisplay: "none",
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=TEMPEPORARYBUDGET&eventid=" +
          eventid +
          "&businessid=" +
          businessid +
          "&budget=" +
          budget
      ).then((response) => {
        //   do something...
      });
    }
  };

  radioOne = () => {
    localStorage.setItem("ticket_type", "digital");
    let { vipTickets, vvipTickets, genTickets, vipPrice, vvipPrice, genPrice } =
      this.state;
    vipTickets = this.state.vipTickets;
    vvipTickets = this.state.vvipTickets;
    genTickets = this.state.genTickets;

    vipPrice = this.state.vipPrice;
    vvipPrice = this.state.vvipPrice;
    genPrice = this.state.genPrice;
    if (vipTickets !== null) {
      vipTickets = this.state.vipTickets;
      vipPrice = this.state.vipPrice;
    } else {
      vipTickets = 0;
      vipPrice = 0;
    }

    if (vvipTickets !== null) {
      vvipTickets = this.state.vvipTickets;
      vvipPrice = this.state.vvipPrice;
    } else {
      vvipTickets = 0;
      vipPrice = 0;
    }

    if (genTickets !== null) {
      genTickets = this.state.genTickets;
      genPrice = this.state.genPrice;
    } else {
      genTickets = 0;
      genPrice = 0;
    }
    const priceVip = parseInt(vipPrice) * 0.05 * parseInt(vipTickets);
    const priceVVip = parseInt(vvipPrice) * 0.05 * parseInt(vvipTickets);
    const priceGen = parseInt(genPrice) * 0.05 * parseInt(genTickets);
    const ticket_math = priceVip + priceVVip + priceGen;
    this.setState({
      estBudget: ticket_math,
      uncheckedOne: false,
      checkedOne: true,
      optionOne: "item-select-active",
      textColorOne: "color-blue",
      quotationTextOne: "color-blue",

      uncheckedTwo: true,
      checkedTwo: false,
      optionTwo: "item-select",
      textColorTwo: "color-black",
      quotationTextTwo: "color-grey",
      note: (
        <label className="mt-3 mb-0 color-grey">
          A digital ticket is a virtual product which your customers will be
          able to buy online, transactions are only processed online; no
          physical money; all transactions are electronic.
        </label>
      ),

      uncheckedThree: true,
      checkedThree: false,
      optionThree: "item-select",
      textColorThree: "color-black",
      quotationTextThree: "color-grey",
    });
  };

  radioTwo = () => {
    localStorage.setItem("ticket_type", "download");
    let { vipTickets, vvipTickets, genTickets } = this.state;
    vipTickets = this.state.vipTickets;
    vvipTickets = this.state.vvipTickets;
    genTickets = this.state.genTickets;
    if (vipTickets !== null) {
      vipTickets = this.state.vipTickets;
    } else {
      vipTickets = 0;
    }

    if (vvipTickets !== null) {
      vvipTickets = this.state.vvipTickets;
    } else {
      vvipTickets = 0;
    }

    if (genTickets !== null) {
      genTickets = this.state.genTickets;
    } else {
      genTickets = 0;
    }

    // alert(genTickets);
    const ticket_math =
      parseInt(vipTickets) + parseInt(vvipTickets) + parseInt(genTickets);
    this.setState({
      estBudget: 1 * parseInt(ticket_math),
      uncheckedTwo: false,
      checkedTwo: true,
      optionTwo: "item-select-active",
      textColorTwo: "color-blue",
      quotationTextTwo: "color-blue",

      uncheckedOne: true,
      checkedOne: false,
      optionOne: "item-select",
      textColorOne: "color-black",
      quotationTextOne: "color-grey",
      note: (
        <label className="mt-3 mb-0 color-grey">
          As a event organiser, you are able to generate, download tickets and
          print them yourself, Karamale will charge you R1.00 per ticket
          download, this amount will be deducted from your budget.{" "}
          <strong>NB:</strong> All tickets are equally secured whether virtual
          or physical.
        </label>
      ),

      uncheckedThree: true,
      checkedThree: false,
      optionThree: "item-select",
      textColorThree: "color-black",
      quotationTextThree: "color-grey",
    });
  };

  radioThree = () => {
    localStorage.setItem("ticket_type", "physical");
    let { vipTickets, vvipTickets, genTickets } = this.state;
    vipTickets = this.state.vipTickets;
    vvipTickets = this.state.vvipTickets;
    genTickets = this.state.genTickets;

    if (vipTickets !== null) {
      vipTickets = this.state.vipTickets;
    } else {
      vipTickets = 0;
    }

    if (vvipTickets !== null) {
      vvipTickets = this.state.vvipTickets;
    } else {
      vvipTickets = 0;
    }

    if (genTickets !== null) {
      genTickets = this.state.genTickets;
    } else {
      genTickets = 0;
    }

    const cal_vip_cost = vipTickets * 3.5;
    const cal_vvip_cost = vvipTickets * 3.5;
    const cal_general_cost = genTickets * 3.5;

    const ticket_math = cal_vip_cost + cal_vvip_cost + cal_general_cost;
    this.setState({
      estBudget: ticket_math,
      uncheckedThree: false,
      checkedThree: true,
      optionThree: "item-select-active",
      textColorThree: "color-blue",
      quotationTextThree: "color-blue",

      uncheckedOne: true,
      checkedOne: false,
      optionOne: "item-select",
      textColorOne: "color-black",
      quotationTextOne: "color-grey",

      uncheckedTwo: true,
      checkedTwo: false,
      optionTwo: "item-select",
      textColorTwo: "color-black",
      quotationTextTwo: "color-grey",
      note: (
        <label className="mt-3 mb-0 color-grey">
          Physical tickets are tangible products, Karamale is responsbile for
          printing these tickets on your behalf, you will be charged R3.50 per
          ticket.
          <br />
          <br />
          <label className="mb-0 color-orange">
            <strong>NB: </strong> Make sure event details are correct as
            Karamale will not be held responsible for any typo ones the tickets
            are printed.
          </label>
        </label>
      ),
    });
  };
  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const {
      uncheckedOne,
      checkedOne,
      checkedTwo,
      uncheckedTwo,
      checkedThree,
      uncheckedThree,
      ifSet,
    } = this.state;
    const customername = localStorage.getItem("customername");
    const dtregistered = this.props.location.state.dtregistered;
    const businessid = localStorage.getItem("businessid");
    const prevpage = this.props.location.state.prevpage;
    const backpage = this.props.location.state.currentpage;
    const eventname = this.props.location.state.eventname;
    const eventid = this.props.location.state.eventid;
    const businessname = this.state.businessname;
    const venue = this.props.location.state.venue;
    const theme = this.props.location.state.theme;
    const expectednum = this.props.location.state.expectednum;
    const eventdate = this.props.location.state.eventdate;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const description = this.props.location.state.description;
    const finish_date = this.props.location.state.finish_date;
    const event_date = this.props.location.state.event_date;
    const poster = this.props.location.state.poster;
    const currentpage = this.props.location.state.currentpage;
    return (
      <div className="view">
        <Header3 />
        <form
          action="https://www.payfast.co.za/eng/process"
          name="form_a54ad269093bbef584e1bd2cfe93c8cf"
          // onsubmit="return click_a54ad269093bbef584e1bd2cfe93c8cf( this )"
          onSubmit={this.handleSubmit}
        >
          <div className="containe">
            <div className="row justify-content-center just-container-insid pb-5">
              <div
                class=" text-center mt-5 col-12 category-name-heading"
                style={{
                  fontSize: 15,
                  color: "rgb(77, 77, 79)",
                  textTransform: "none",
                  marginBottom: -5,
                }}
              >
                Not sure how much to spend?{" "}
                <Link to="/budget" style={{ color: "#3399cc" }}>
                  Use our calculator to determine your budget
                </Link>
              </div>
              <div className="col-lg-6">
                <div className=" BoxDashboardContainer mt-3 mb-4 mrgin-search pl-0 pr-0">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-4 col-4 pl-0">
                        <Link
                          to={{
                            pathname: backpage,
                            state: {
                              eventid: eventid,
                              eventname: eventname,
                              poster: poster,
                              eventdate: eventdate,
                              theme: theme,
                              venue: venue,
                              expectednum: expectednum,
                              enddate: enddate,
                              time: time,
                              endtime: endtime,
                              prevpage: prevpage,
                              event_date: event_date,
                              finish_date: finish_date,
                            },
                          }}
                          className="link-btn"
                        >
                          <label className="back-arrow mb-0">
                            <IoIosArrowBack size={40} />
                            Back
                          </label>
                        </Link>
                      </div>

                      <div className="col-lg-8 col-8 text-right">
                        <label className="back-arrow mb-0">
                          <button
                            className="save-btn width-200"
                            onClick={() => this.addPayment()}
                          >
                            <span
                              style={{
                                display: this.state.updateDisplay,
                              }}
                            >
                              {" "}
                              {this.state.btnLabel}
                            </span>
                            <span
                              style={{
                                display: this.state.spinning,
                              }}
                            >
                              <ClipLoader
                                color={"#fff"}
                                loading={true}
                                // css={override}
                                size={20}
                              />
                            </span>
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" BoxDashboardContainer  pl-2 pr-2">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-lg-12 pl-0">
                        <label className="ticket-title pl-0 pr-0 mb-0">
                          CREATE A CAMPAIGN
                          <br />
                          <label className="small-details normal-casing">
                            {eventname}
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className="h-divider w-100"></div>
                  </div>
                  <div className="col-lg-12 details-body">
                    <span>Details</span>
                    <br />
                    <br />
                    Campaign name
                    <br />
                    <input
                      type="text"
                      placeholder="Enter campaign name"
                      value={this.state.campaignname}
                      onChange={this.handleCampaigname}
                      className="edit-input"
                      required
                    />
                    <br /> <br />
                    Enter keyworks
                    <br />
                    <input
                      type="text"
                      placeholder="Ener keywords: Separate by comma"
                      value={this.state.keywords}
                      onChange={this.handleKeywords}
                      className="edit-input"
                      required
                    />
                    <br /> <br />
                    Set starting date
                    <br />
                    <input
                      type="date"
                      placeholder="Set starting date"
                      value={this.state.startdate}
                      onChange={this.handleDate}
                      className="edit-input w-100"
                      required
                    />
                    <br />
                    <br />
                    Set ending date
                    <br />
                    <input
                      type="date"
                      placeholder="Set ending date"
                      value={this.state.enddate}
                      onChange={this.handleEnddate}
                      className="edit-input w-100"
                      required
                    />
                    <br />
                    <br />
                    {ifSet && (
                      <label className="mb-0">
                        {" "}
                        Click the below options to see how much you can spend.
                        Pay attention to the <b>Estimated budget</b> below.
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-4 col-12 pl-0 pr-0 mt-3 text-center">
                            <div
                              className={
                                this.state.optionOne + " mb-2 width-100"
                              }
                              onClick={this.radioOne}
                            >
                              <div className="link-btn w-100 menu-links text-left border-1">
                                <label
                                  className={
                                    this.state.textColorOne +
                                    " mb-0 cursor-pointer"
                                  }
                                >
                                  Digital
                                </label>
                                &nbsp;&nbsp;
                                {this.state.checkedOne && (
                                  <IoIosCheckmarkCircleOutline
                                    size={25}
                                    className="color-blue"
                                  />
                                )}
                                {this.state.uncheckedOne && (
                                  <IoIosRadioButtonOff
                                    size={25}
                                    className="color-black"
                                  />
                                )}
                              </div>
                            </div>
                            <label
                              className={this.state.quotationTextOne + " mb-0"}
                            >
                              5% per sale
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-4 col-12 pl-0 pr-0 mt-3 text-center">
                            <div
                              className={
                                this.state.optionTwo + " mb-2 width-100"
                              }
                              onClick={this.radioTwo}
                            >
                              <div className="link-btn w-100 menu-links text-left border-1">
                                <label
                                  className={
                                    this.state.textColorTwo +
                                    " mb-0 cursor-pointer"
                                  }
                                >
                                  Download
                                </label>
                                &nbsp;&nbsp;
                                {this.state.checkedTwo && (
                                  <IoIosCheckmarkCircleOutline
                                    size={25}
                                    className="color-blue"
                                  />
                                )}
                                {this.state.uncheckedTwo && (
                                  <IoIosRadioButtonOff
                                    size={25}
                                    className="color-black"
                                  />
                                )}
                              </div>
                            </div>
                            <label
                              className={this.state.quotationTextTwo + " mb-0"}
                            >
                              R1.00
                            </label>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-4 col-12 pl-0 pr-0 mt-3 text-center">
                            <div
                              className={
                                this.state.optionThree + " mb-2 width-100"
                              }
                              onClick={this.radioThree}
                            >
                              <div className="link-btn w-100 menu-links text-left border-1">
                                <label
                                  className={
                                    this.state.textColorThree +
                                    " mb-0 cursor-pointer"
                                  }
                                >
                                  Physical
                                </label>
                                &nbsp;&nbsp;
                                {this.state.checkedThree && (
                                  <IoIosCheckmarkCircleOutline
                                    size={25}
                                    className="color-blue"
                                  />
                                )}
                                {this.state.uncheckedThree && (
                                  <IoIosRadioButtonOff
                                    size={25}
                                    className="color-black"
                                  />
                                )}
                              </div>
                            </div>
                            <label
                              className={
                                this.state.quotationTextThree + " mb-0"
                              }
                            >
                              R3.50
                            </label>
                          </div>
                          <div className="col-12 p-0">{this.state.note}</div>
                        </div>
                        <br />
                        <br />
                      </label>
                    )}
                    {this.state.budgetString}
                    <label className="small-details float-right mb-0">
                      {this.state.currentBudget}
                    </label>
                    <br />
                    <input
                      type="number"
                      placeholder={this.state.budgetString}
                      value={this.state.estBudget}
                      onChange={this.handleBudget}
                      className="edit-input"
                      required
                      min={10}
                    />
                    {this.state.nb}
                    {/* transfer data to third party for payment processing */}
                    <input type="hidden" value={businessid} />
                    <input type="hidden" value={eventid} />
                    <input type="hidden" name="cmd" value="_paynow" />
                    <input type="hidden" name="receiver" value="15455113" />
                    <input
                      type="hidden"
                      name="item_name"
                      value={"Campaign for: " + this.state.campaignname}
                    />
                    <input
                      type="hidden"
                      name="amount"
                      id="amount"
                      value={this.state.estBudget}
                    />
                    <input
                      type="hidden"
                      name="item_description"
                      value="Campaign launch"
                    />
                    <input
                      type="hidden"
                      name="return_url"
                      id="return_url_"
                      value={
                        "https://karamale.com/campaignsuccess?buid=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&cancellationid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&campaignname=" +
                        this.state.campaignname.split(" ").join("+") +
                        "&keywords=" +
                        this.state.keywords.split(" ").join("+") +
                        "&startdate=" +
                        this.state.startdate +
                        "&enddate=" +
                        this.state.enddate +
                        "&budget=" +
                        this.state.estBudget +
                        "&businessid=" +
                        businessid +
                        "&eventid=" +
                        eventid +
                        "&eventname=" +
                        eventname.split(" ").join("+") +
                        "&poster=" +
                        poster +
                        "&event_date=" +
                        event_date +
                        "&finish_date=" +
                        finish_date +
                        "&theme=" +
                        theme.split(" ").join("+") +
                        "&venue=" +
                        venue.split(" ").join("+") +
                        "&expectednum=" +
                        expectednum +
                        "&time=" +
                        time +
                        "&endtime=" +
                        endtime +
                        "&prevpage=" +
                        prevpage.split(" ").join("+") +
                        "&currentpage=" +
                        currentpage.split(" ").join("+") +
                        "&eventdate=" +
                        eventdate.split(" ").join("+") +
                        "&eventenddate=" +
                        enddate.split(" ").join("+")
                      }
                    />
                    <input
                      type="hidden"
                      name="cancel_url"
                      id="cancel_url_"
                      value={
                        "https://karamale.com/campaigncancelled?buid=" +
                        uuid6 +
                        "&queryid=" +
                        uuid5 +
                        "&cancellationid=" +
                        uuid4 +
                        "&uid=" +
                        uuid3 +
                        "&qKey=" +
                        uuid1 +
                        "&key=" +
                        uuid2 +
                        "&uuid=" +
                        uuid +
                        "&campaignname=" +
                        this.state.campaignname.split(" ").join("+")
                      }
                    />
                    <input
                      type="hidden"
                      name="custom_amount"
                      id="custom_amounts"
                      value={0}
                      class="pricing"
                    />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={this.state.successError}
            style={{ display: this.state.errorDisplay, textAlign: "center" }}
          >
            {this.state.error}
          </div>
          {/* <Footer /> */}
        </form>
      </div>
    );
  }
}

export default Campaign;
