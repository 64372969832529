import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import {
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
  IoMdEye,
  IoIosAttach,
} from "react-icons/io";
import Header3 from "./components/Header3";
import ClipLoader from "react-spinners/ClipLoader";

function Marketing(props) {
  useEffect(() => {
    document.title = "Marketing campaign";

    const businessid = localStorage.getItem("businessid");

    if (localStorage.getItem("alias") == "NEWAUDIENCE") {
      axios
        .get(
          "https://karamale.com/apps/kscanner/webservices.php?query=AUDIENCE&businessid=" +
            businessid
        )
        .then((response) => {
          setRecipients(response.data.audience);
        });
    }
  });

  //   this.selectImage = this.selectImage.bind(this);

  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [count, setCount] = useState(0);
  const [countColor, setCountColor] = useState("countColor");
  const [optionOne, setOptionOne] = useState(
    localStorage.getItem("type") == "SMS" ? "item-select-active" : "item-select"
  );
  const [optionTwo, setOptionTwo] = useState(
    localStorage.getItem("type") == "EMAIL"
      ? "item-select-active"
      : "item-select"
  );

  const [offRadioOne, setOffRadioOne] = useState(
    localStorage.getItem("type") == "SMS" ? false : true
  );
  const [onRadioOne, setOnRadioOne] = useState(
    localStorage.getItem("type") == "SMS" ? true : false
  );

  const [offRadioTwo, setOffRadioTwo] = useState(
    localStorage.getItem("type") == "EMAIL" ? false : true
  );
  const [onRadioTwo, setOnRadioTwo] = useState(
    localStorage.getItem("type") == "EMAIL" ? true : false
  );

  const [marketingType, setMarketingType] = useState(
    localStorage.getItem("type")
  );

  const [recipients, setRecipients] = useState(
    localStorage.getItem("audience")
  );

  const [alias, setAlias] = useState(localStorage.getItem("alias"));

  const [attachment, setAttachment] = useState("");
  const [attachmentLabel, setAttachmentLabel] = useState("Add attachment");

  const [removeImage, setRemoveImage] = useState("");
  const [showAttach, setShowAttach] = useState(true);

  const [presAttachment, setPreAttachment] = useState("");
  const [attachmentName, setAttachmentName] = useState("");
  const [type, setType] = useState("");
  const [path, setPath] = useState("");

  const [loadLabel, setLoadLabel] = useState(true);
  const [loadCliper, setLoadCliper] = useState(false);

  const [errorDisplay, setErrorDisplay] = useState("none");
  const [error, setError] = useState("");

  const [active, setActive] = useState(1);

  const [responsebg, setResponseBG] = useState("error-login");
  //   alert(alias);

  // alert(recipients);

  async function next() {
    setLoadLabel(false);
    setLoadCliper(true);

    setErrorDisplay("none");
    setActive(1);

    const businessid = localStorage.getItem("businessid");

    const cost = recipients.replace(",", "") * 0.9;

    let data = new FormData();
    data.append("file", attachment);
    data.append("businessid", businessid);
    data.append("text", text); // Text from the texarea
    data.append("subject", subject); // Subject from the input type
    data.append("subqueryone", marketingType); // SMS OR EMAIL
    data.append("subquerytwo", alias); // EXISTINGAUDIENCE, DATABASE OR NEWAUDIENCE
    data.append("query", "MARKETING"); // MARKETING
    data.append("recipients_count", recipients.replace(",", ""));
    data.append("cost", cost);

    if (marketingType === "EMAIL" && subject.trim() === "") {
      setErrorDisplay("block");
      setError("Enter email subject");
      setResponseBG("error-login");
      setLoadLabel(true);
      setLoadCliper(false);
    } else {
      await axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        // alert(response.data.val);
        //   email marketing
        if (marketingType === "EMAIL") {
          if (response.data.val == 400) {
            setErrorDisplay("block");
            setError(
              "Something went wrong, not your fault, our engineers are working on it"
            );
            setResponseBG("error-login");
          } else if (response.data.val == 0) {
            setErrorDisplay("block");
            setError("Invalid file detected (" + type + ")");
            setResponseBG("error-login");
          } else {
            // alert(response.data.val);
            setErrorDisplay("block");
            setError(
              recipients +
                " recipients received this email. Marketing successful!"
            );
            setResponseBG("success-login");
          }
        } else {
          // sms marketing
          //   alert(response.data.textid);
          localStorage.setItem("textid", response.data.textid);
          const cost = recipients.replace(",", "") * 0.9;
          props.history.push({
            pathname: "/smscampaign",
            state: { textid: response.data.textid, cost: cost, text: text },
          });
        }
        setLoadLabel(true);
        setLoadCliper(false);

        //   setActive(0);
      });

      setTimeout(() => {
        setErrorDisplay("none");
      }, 4000);
    }
  }

  function handleText(event) {
    setText(event.target.value);
    setCount(event.target.value.length);
    if (event.target.value.length > 130) {
      setCountColor("countColor-error");
    } else {
      setCountColor("countColor");
    }
  }

  function handleSubject(event) {
    setSubject(event.target.value);
  }

  function email() {
    setOptionOne("item-select");
    setOptionTwo("item-select-active");

    setOffRadioOne(true);
    setOnRadioOne(false);

    setOffRadioTwo(false);
    setOnRadioTwo(true);
    setMarketingType("EMAIL");

    setShowAttach(false);
    setAttachmentLabel("Add attachment");
    setAttachment("");
    setErrorDisplay("none");
    setActive(1);
  }

  function sms() {
    setOptionTwo("item-select");
    setOptionOne("item-select-active");

    setOffRadioTwo(true);
    setOnRadioTwo(false);

    setOffRadioOne(false);
    setOnRadioOne(true);
    setMarketingType("SMS");

    setErrorDisplay("none");
    setActive(1);
  }

  function removeAttachment() {
    setShowAttach(false);
    setAttachmentLabel("Add attachment");
    setAttachment("");
    setErrorDisplay("none");
    setActive(1);
  }

  function selectImage(event) {
    const img = event.target.files[0];
    setAttachment(event.target.files[0]);
    setPreAttachment(URL.createObjectURL(event.target.files[0]));
    setAttachmentName(img.name);
    setType(img.type);
    setPath(URL.createObjectURL(event.target.files[0]));
    setShowAttach(true);

    setAttachmentLabel("Change attachment");
    setRemoveImage(
      <label className="mt-3 color-red hover-text" onClick={removeAttachment}>
        <i>Remove attachment</i>
      </label>
    );

    setErrorDisplay("none");
    setActive(1);
  }

  if (localStorage.getItem("businessname") === null) {
    return <Redirect to="/login" />;
  } else {
    if (localStorage.getItem("alias") === null) {
      return <Redirect to="/sms" />;
    }
  }
  const businessname = localStorage.getItem("businessname");
  const businessid = localStorage.getItem("businessid");
  const accounttype = localStorage.getItem("accounttype");
  //   alert(recipients.replace(",", ""));
  const total =
    recipients < 1000 ? recipients * 0.9 : recipients.replace(",", "") * 0.9;
  return (
    <div className="view">
      <Header3 />
      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-6 mt-5  pl-2 pr-2">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-12">
                    <label className=" pt-2">
                      <span>Marketing</span>
                    </label>
                    {(text.trim() === "" || count > 130) &&
                    marketingType === "SMS" ? (
                      <label class="back-arrow float-right">
                        <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                          <span>
                            {marketingType == "SMS" ? "Proceed" : "Send"}
                          </span>
                        </div>
                      </label>
                    ) : (
                      <label class="back-arrow float-right">
                        {text.trim() === "" || active === 0 ? (
                          <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                            <span>
                              {marketingType == "SMS" ? "Proceed" : "Send"}
                            </span>
                          </div>
                        ) : (
                          <div
                            class="save-btn btn-radius-3"
                            onClick={next.bind(this)}
                          >
                            {loadLabel && (
                              <span>
                                {marketingType == "SMS" ? "Proceed" : "Send"}
                              </span>
                            )}
                            {loadCliper && (
                              <ClipLoader
                                color={"#fff"}
                                loading={true}
                                size={20}
                              />
                            )}
                          </div>
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="h-divider w-100"></div>
                <div className="row w-100">
                  <div className="col-12 text-left p-3">
                    <span>Choose marketing type</span>
                  </div>
                  {localStorage.getItem("type") == "EMAIL" &&
                  localStorage.getItem("alias") == "NEWAUDIENCE" ? (
                    ""
                  ) : (
                    <div className="col-lg-6 col-sm-6 pr-0">
                      <div className={optionOne} onClick={sms}>
                        <div className="link-btn w-100 menu-links">
                          <div className="col-1 col-lg-2 text-left">
                            {onRadioOne && (
                              <IoIosRadioButtonOn size={28} color={"#66b266"} />
                            )}
                            {offRadioOne && (
                              <IoIosRadioButtonOff
                                size={28}
                                color={"#b2b200"}
                              />
                            )}
                          </div>
                          <div className="col-11 col-lg-10">
                            <label className="item-names w-100">
                              SMS marketing{" "}
                              <label className="float-right color-green">
                                <i>Paid</i>
                              </label>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {localStorage.getItem("type") == "SMS" &&
                  localStorage.getItem("alias") == "NEWAUDIENCE" ? (
                    ""
                  ) : (
                    <div className="col-lg-6 col-sm-6 pr-0">
                      <div className={optionTwo} onClick={email}>
                        <div className="link-btn w-100 menu-links">
                          <div className="col-1 col-lg-2 text-left">
                            {onRadioTwo && (
                              <IoIosRadioButtonOn size={28} color={"#66b266"} />
                            )}
                            {offRadioTwo && (
                              <IoIosRadioButtonOff
                                size={28}
                                color={"#b2b200"}
                              />
                            )}
                          </div>

                          <div className="col-11 col-lg-10">
                            <label className="item-names w-100">
                              Email marketing{" "}
                              <label className="float-right color-green">
                                <i>Free</i>
                              </label>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 text-left p-3">
                  <span>
                    {marketingType === "SMS"
                      ? "Text limited to 130 characters, the other 30 is for personalized names"
                      : "Character limit deactivated"}
                  </span>
                </div>

                <div className="col-12 text-left pt-0 pb-0">
                  {marketingType === "SMS" ? (
                    ""
                  ) : (
                    <input
                      type="text"
                      placeholder={"Email subject"}
                      onChange={handleSubject}
                      value={subject}
                      className="edit-input mb-3"
                    />
                  )}
                  <textarea
                    placeholder={"Type your text"}
                    onChange={handleText}
                    value={text}
                    className="color-black"
                  />
                  {marketingType === "EMAIL" ? (
                    <label className="color-blue" htmlFor="image">
                      <IoIosAttach size={20} /> {attachmentLabel}
                    </label>
                  ) : (
                    <label className="color-light-grey">
                      <IoIosAttach size={20} /> Add attachment
                    </label>
                  )}
                  <label className="float-right">
                    {marketingType === "EMAIL" ? (
                      <label className="w-100">
                        <label>No character limit</label>
                      </label>
                    ) : (
                      <label>
                        <label className={countColor}>{count}</label> / 130
                      </label>
                    )}
                  </label>
                </div>
                <div className="col-12">
                  <span>
                    {recipients}{" "}
                    {recipients == 1 ? " recipient" : " recipients"} will
                    receive the {marketingType === "SMS" ? "text" : "email"}
                  </span>
                  <label className="w-100">
                    {marketingType === "SMS" ? (
                      <label className="w-100">
                        <label className="color-grey">
                          R0.90 per recipient
                        </label>{" "}
                        <label className="float-right">
                          <span>Total</span>:{" "}
                          <label className="color-grey">
                            ZAR {total.toFixed(1)}
                          </label>
                        </label>
                      </label>
                    ) : (
                      <label className="w-100">
                        <label className="color-grey">
                          Email marketing is free
                        </label>{" "}
                        <label className="float-right">
                          <span>Total</span>:{" "}
                          <label className="color-grey">ZAR 0.0</label>
                        </label>
                      </label>
                    )}
                  </label>
                  <input
                    name="file"
                    type="file"
                    onChange={selectImage}
                    id="image"
                    className="hide-file-input-type"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-5  pl-2 pr-2">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-lg-2 col-2 col-sm-1 text-center pt-1">
                    <IoMdEye size={30} />
                  </div>
                  <div className="col-lg-10 col-10 col-sm-11">
                    <label className=" pt-2">
                      <span>Preview</span>
                    </label>
                  </div>
                </div>

                <div className="h-divider w-100"></div>
                <div className="col-12 text-left pb-0">
                  <label>
                    {text.trim() === "" ? (
                      <label className="color-grey">
                        Your text will appear here the moment you start typing
                      </label>
                    ) : (
                      <label>
                        <label className="w-100 preview-text">
                          Hi, [Name and surname],
                          <br />
                          {text}
                        </label>
                      </label>
                    )}
                    {marketingType === "EMAIL" ? (
                      <label>
                        {showAttach && (
                          <label>
                            {type == "application/pdf" ? (
                              <label className="w-100">
                                <label className="w-100">
                                  <img
                                    src={
                                      "https://karamale.com/apps/kscanner/icons/pdf.png"
                                    }
                                    width={100}
                                    className="mr-3"
                                    alt="Attachment"
                                  />
                                </label>

                                {attachmentName}
                                <br />
                                {removeImage}
                              </label>
                            ) : type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                              <label className="w-100">
                                <label className="w-100">
                                  <img
                                    src={
                                      "https://karamale.com/apps/kscanner/icons/word.png"
                                    }
                                    width={100}
                                    className="mr-3"
                                    alt="Attachment"
                                  />
                                </label>

                                {attachmentName}
                                <br />
                                {removeImage}
                              </label>
                            ) : type == "application/vnd.ms-excel" ? (
                              <label className="w-100">
                                <label className="w-100">
                                  <img
                                    src={
                                      "https://karamale.com/apps/kscanner/icons/excel.png"
                                    }
                                    width={100}
                                    className="mr-3"
                                    alt="Attachment"
                                  />
                                </label>

                                {attachmentName}
                                <br />
                                {removeImage}
                              </label>
                            ) : type == "image/png" || type == "image/jpeg" ? (
                              <label className="w-100">
                                <img
                                  src={presAttachment}
                                  className="w-100"
                                  alt="Attachment"
                                />
                                {removeImage}
                              </label>
                            ) : type == "audio/mpeg" ? (
                              <label className="w-100">
                                {type.trim() !== "" ? (
                                  <label>
                                    <label className="w-100">
                                      <img
                                        src={
                                          "https://karamale.com/apps/kscanner/icons/mp3.png"
                                        }
                                        width={100}
                                        className="mr-3"
                                        alt="Attachment"
                                      />
                                    </label>
                                    {attachmentName}
                                    <br />
                                    {removeImage}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </label>
                            ) : type == "video/mp4" ? (
                              <label className="w-100">
                                {type.trim() !== "" ? (
                                  <label>
                                    <label className="w-100">
                                      <img
                                        src={
                                          "https://karamale.com/apps/kscanner/icons/mp4.png"
                                        }
                                        width={100}
                                        className="mr-3"
                                        alt="Attachment"
                                      />
                                    </label>
                                    {attachmentName}
                                    <br />
                                    {removeImage}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </label>
                            ) : (
                              <label className="w-100">
                                {type.trim() !== "" ? (
                                  <label>
                                    <label className="w-100">
                                      <img
                                        src={
                                          "https://karamale.com/apps/kscanner/icons/file.png"
                                        }
                                        width={100}
                                        className="mr-3"
                                        alt="Attachment"
                                      />
                                    </label>
                                    {attachmentName}
                                    <br />
                                    {removeImage}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </label>
                            )}
                          </label>
                        )}
                      </label>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={responsebg + " text-center"}
        style={{ display: errorDisplay }}
      >
        {error}
      </div>
    </div>
  );
}

export default Marketing;
