import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import HyperModal from "react-hyper-modal";
import Footer from "./components/Footer";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { Chart } from "react-charts";
import { Pie, Bar, Line, Doughnut } from "react-chartjs-2";
// import Modal from "./react-bootstrap/Modal";
import {
  IoIosArrowBack,
  IoMdAnalytics,
  IoIosAddCircleOutline,
  IoMdPhotos,
  IoIosCash,
  IoIosBicycle,
  IoIosNuclear,
  IoIosTrash,
  IoIosTimer,
  IoMdCheckmarkCircleOutline,
  IoIosAdd,
  IoIosInformationCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowRoundUp,
  IoIosArrowRoundDown,
} from "react-icons/io";
import {
  BsBarChart,
  BsImageAlt,
  BsImage,
  BsGraphUp,
  BsPip,
} from "react-icons/bs";
import Header3 from "./components/Header3";

function Analytics(props) {
  const [general, setGeneral] = useState("");
  const [vip, setVip] = useState("");
  const [vvip, setVvip] = useState("");

  const [salesG, setSalesG] = useState(0);
  const [salesV, setSalesV] = useState(0);
  const [salesVV, setSalesVV] = useState(0);

  const [viptickets, setViptickets] = useState(0);
  const [generaltickets, setGeneraltickets] = useState(0);
  const [vviptickets, setVviptickets] = useState(0);

  const [getPercVIP, setPercVIP] = useState(0);
  const [getPercVVIP, setPercVVIP] = useState(0);
  const [getPercGEN, setPercGEN] = useState(0);

  const [getPercentageVIP, setPercentageVIP] = useState("0%");
  const [getPercentageVVIP, setPercentageVVIP] = useState("0%");
  const [getPercentageGEN, setPercentageGEN] = useState("0%");

  const [totalSales, setTotalSales] = useState(0);
  const [totalSold, setTotalSold] = useState(0);

  const [yesterdayincome, setYesterdayincome] = useState(0);
  const [todayincome, setTodayincome] = useState(0);
  const [overallincome, setOverallincome] = useState(0);

  const [yesterdaysales, setYesterdaysales] = useState(0);
  const [todaysales, setTodaysales] = useState(0);
  const [overallsales, setOverallsales] = useState(0);

  const [overallindicators, setOverallindicators] = useState(0);
  const [todayPerformance, setTodayPerformance] = useState(0);
  const [yesterdayPerformance, setYesterdayPerformance] = useState(0);

  const [perc, setPerc] = useState(0);
  const [ticketsAvailable, setTicketsAvailable] = useState(0);
  const [percentage, setPercentage] = useState("0%");

  const [currency, setCurrency] = useState("R");
  React.useEffect(() => {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    const eventname = props.location.state.eventname;
    const eventid = props.location.state.eventid;
    document.title = "Analytics - " + eventname + " - " + businessname;

    // get sales data for general tickets
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETSALESDATAGENERAL&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setGeneral(response.data.general);
        setSalesG(response.data.salesg);
        setGeneraltickets(response.data.generaltickets);
        setPercentageGEN(response.data.getPercentage);
        setPercGEN(response.data.getPerc);
      })
      .catch((error) => console.log(error));

    // get sales data for vip tickets
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETSALESDATAVIP&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setVip(response.data.vip);
        setSalesV(response.data.salesv);
        setViptickets(response.data.viptickets);
        setPercentageVIP(response.data.getPercentage);
        setPercVIP(response.data.getPerc);
      })
      .catch((error) => console.log(error));

    // get sales data for vvip tickets
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETSALESDATAVVIP&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setVvip(response.data.vvip);
        setSalesVV(response.data.salesvv);
        setVviptickets(response.data.vviptickets);
        setPercentageVVIP(response.data.getPercentage);
        setPercVVIP(response.data.getPerc);
      })
      .catch((error) => console.log(error));

    // get total sales
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETTOTALSALES&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setTotalSales(response.data.sales);
      })
      .catch((error) => console.log(error));

    // get total sold
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETTOTALSOLD&accountid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setTotalSold(response.data.sold);
        setPerc(response.data.perc);
        setTicketsAvailable(response.data.ticketsAvailable);
        setPercentage(response.data.percentage);
      })
      .catch((error) => console.log(error));

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=FINANCIALS&businessid=" +
        businessid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        setTodayincome(response.data.todayincome);
        setYesterdayincome(response.data.yesterdayincome);
        setOverallincome(response.data.overallincome);

        setTodaysales(response.data.todaysales);
        setYesterdaysales(response.data.yesterdaysales);
        setOverallsales(response.data.overallsales);

        setOverallindicators(response.data.overallindicators);
        setYesterdayPerformance(response.data.yesterdayPerformance);
        setTodayPerformance(response.data.todayPerformance);

        // this.setState({ overallsales: response.data.overallsales, yesterdaysales: response.data.yesterdaysales, todaysales: response.data.todaysales, yesterdayincome: response.data.yesterdayincome, todayincome: response.data.todayincome, yesterdayPerformance: response.data.yesterdayPerformance, todayPerformance: response.data.todayPerformance, overallincome: response.data.overallincome, overallindicators: response.data.overallindicators});
      })
      .catch((error) => console.log(error));
  });

  const sales = parseInt(salesG);

  const data = {
    labels: ["VVIP", "VIP", "General"],
    datasets: [
      {
        label: "Revenue",
        data: [vvip, vip, general],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataSales = {
    labels: ["VVIP", "VIP", "General"],
    datasets: [
      {
        label: "Sales",
        data: [salesVV, salesV, salesG],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataLine = {
    labels: ["VVIP", "VIP", "General"],
    datasets: [
      {
        label: "Sales",
        data: [salesVV, salesV, salesG],
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const dataDou = {
    labels: ["VVIP %", "VIP %", "General %"],
    datasets: [
      {
        label: "Sales",
        data: [
          getPercentageVVIP.replace("%", ""),
          getPercentageVIP.replace("%", ""),
          getPercentageGEN.replace("%", ""),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const prevpage = props.location.state.prevpage;
  const currentage = "analytics";
  const eventname = props.location.state.eventname;
  const eventdate = props.location.state.eventdate;
  const eventid = props.location.state.eventid;
  const poster = props.location.state.poster;
  const theme = props.location.state.theme;
  const venue = props.location.state.venue;
  const expectednum = props.location.state.expectednum;
  const enddate = props.location.state.enddate;
  const time = props.location.state.time;
  const endtime = props.location.state.endtime;
  const event_date = props.location.state.event_date;
  const finish_date = props.location.state.finish_date;
  const campaignstatus = props.location.state.campaignstatus;
  const back = props.location.state.back;
  const businessname = localStorage.getItem("businessname");

  let {
    float,
    floatGen,
    status,
    standingVIP,
    standingGEN,
    standingVVIP,
    yesterdaymoneyColor,
    todaymoneyColor,
    yesterdayGrowth,
    todayGrowth,
    allincome,
    declineString,
    declineColor,
    declineIndicator,
    moreData,
  } = "";

  if (perc < 25) {
    status = <label style={{ color: "#ff6666" }}>Poor</label>;
  } else if (perc < 50) {
    status = <label style={{ color: "#f4a201" }}>Average</label>;
  } else if (perc < 75) {
    status = <label style={{ color: "#468514" }}>Good</label>;
  } else {
    status = <label style={{ color: "#66b266" }}>Excellent!</label>;
  }

  if (
    parseFloat(yesterdayPerformance) <= 0 &&
    parseFloat(todayPerformance) <= 0
  ) {
    yesterdaymoneyColor = "#ff6666";
    todaymoneyColor = "#ff6666";
    declineString = "No sales between yesterday and today";
    declineColor = "#ff6666";
    declineIndicator = "";
    allincome = "-- --";
    yesterdayGrowth = "";
    todayGrowth = "";
  } else if (parseFloat(yesterdayPerformance) > parseFloat(todayPerformance)) {
    yesterdaymoneyColor = "#66b266";
    todaymoneyColor = "#ff6666";
    declineString = "In comparison to yesterday, today's sales declined by";
    declineColor = "#ff6666";
    declineIndicator = (
      <label>
        <IoIosArrowRoundDown size={30} color="#ff6666" />
      </label>
    );
    allincome =
      (parseFloat(yesterdayPerformance) - parseFloat(todayPerformance)).toFixed(
        1
      ) + "%";
    yesterdayGrowth = (
      <label>
        <IoIosArrowRoundUp size={20} color="#66b266" />
      </label>
    );
    todayGrowth = (
      <label>
        <IoIosArrowRoundDown size={20} color="#ff6666" />
      </label>
    );
  } else {
    todaymoneyColor = "#66b266";
    yesterdaymoneyColor = "#ff6666";
    declineString = "In comparison to yesterday, today's sales increased by";
    declineIndicator = (
      <label>
        <IoIosArrowRoundUp size={30} color="#66b266" />
      </label>
    );
    declineColor = "#66b266";
    allincome =
      (parseFloat(todayPerformance) - parseFloat(yesterdayPerformance)).toFixed(
        1
      ) + "%";

    yesterdayGrowth = (
      <label>
        <IoIosArrowRoundDown size={20} color="#ff6666" />
      </label>
    );
    todayGrowth = (
      <label>
        <IoIosArrowRoundUp size={20} color="#66b266" />
      </label>
    );
  }

  if (getPercVIP < 25) {
    standingVIP = <label style={{ color: "#ff6666" }}>Poor</label>;
  } else if (getPercVIP < 50) {
    standingVIP = <label style={{ color: "#f4a201" }}>Average</label>;
  } else if (getPercVIP < 75) {
    standingVIP = <label style={{ color: "#468514" }}>Good </label>;
  } else {
    standingVIP = <label style={{ color: "#66b266" }}>Excellent! </label>;
  }

  if (getPercGEN < 25) {
    standingGEN = <label style={{ color: "#ff6666" }}>Poor</label>;
  } else if (getPercGEN < 50) {
    standingGEN = <label style={{ color: "#f4a201" }}>Average</label>;
  } else if (getPercGEN < 75) {
    standingGEN = <label style={{ color: "#468514" }}>Good </label>;
  } else {
    standingGEN = <label style={{ color: "#66b266" }}>Excellent! </label>;
  }

  if (getPercVVIP < 25) {
    standingVVIP = <label style={{ color: "#ff6666" }}>Poor</label>;
  } else if (getPercVVIP < 50) {
    standingVVIP = <label style={{ color: "#f4a201" }}>Average</label>;
  } else if (getPercVVIP < 75) {
    standingVVIP = <label style={{ color: "#468514" }}>Good </label>;
  } else {
    standingVVIP = <label style={{ color: "#66b266" }}>Excellent! </label>;
  }

  if (getPercentageVIP == "100.0%") {
    float = "right";
  } else {
    float = "none";
  }

  if (getPercentageGEN == "100.0%") {
    floatGen = "right";
  } else {
    floatGen = "none";
  }

  return (
    <div className="view">
      {localStorage.getItem("businessid") === null ? (
        <Redirect to="/login" />
      ) : (
        ""
      )}
      <Header3 />
      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-12 padding-added">
            <div className="row">
              <div className="col-lg-4 col-5 pl-2 pr-2">
                <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-12 pl-0">
                        <Link
                          to={{
                            pathname: back,
                            state: {
                              campaignstatus: campaignstatus,
                              eventid: eventid,
                              eventname: eventname,
                              poster: poster,
                              eventdate: eventdate,
                              enddate: enddate,
                              event_date: event_date,
                              finish_date: finish_date,
                              theme: theme,
                              venue: venue,
                              expectednum: expectednum,
                              time: time,
                              endtime: endtime,
                              prevpage: prevpage,
                            },
                          }}
                          className="link-btn"
                        >
                          <label className="back-arrow">
                            <IoIosArrowBack size={40} />
                            Back
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-7  pl-2 pr-2">
                <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search  pl-0 pr-0">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0">
                      <div className="col-12 text-right">
                        <label className="back-arrow">
                          <div className="link-btn">
                            <div
                              className="edit-btn graph-heading"
                              style={{
                                fontSize: 17,
                                color: "rgb(77, 77, 79)",
                                fontWeight: 700,
                              }}
                            >
                              Analytics:
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12  pl-2 pr-2">
                <div className=" BoxDashboardContainer mb-4 pl-0 pr-0">
                  <div className="row align-self-center justify-content-center">
                    <div className="item-list p-0 w-100">
                      <div className="col-lg-12 pl-0">
                        <label className="ticket-title">
                          {eventname}
                          <br />
                          <label class="small-details">{eventdate}</label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 pl-2 pr-2">
                <div className=" BoxDashboardContainer mb-4 flex justify-content-center pl-3 pr-3">
                  <div className="col-12 details-body mb-3 justify-content-center align-self-center row pl-2 pr-2 flext">
                    <div className="col-12 pl-0 pr-0">
                      <label class="graph-titles">Sales</label>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <Line data={dataLine} options={options} width={250} />
                      </div>
                      <div className="col-12 pl-0 pr-0 text-center mt-4">
                        <div className="row">
                          <div className="col-lg-3 col-4">
                            <span>
                              Generals
                              <br />
                              <label className="fontNormal small-details">
                                {salesG}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-4">
                            <span>
                              VIP
                              <br />
                              <label className="fontNormal small-details">
                                {salesV}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-4">
                            <span>
                              VVIP
                              <br />
                              <label className="fontNormal small-details">
                                {salesVV}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-12">
                            <span>
                              Total
                              <br />
                              <label className="fontNormal small-details">
                                {totalSold}
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 pl-2 pr-2">
                <div className=" BoxDashboardContainer mb-4 justify-content-center flex">
                  <div className="col-12 details-body mb-3 justify-content-center align-self-center row  pl-2 pr-2 flex">
                    <div className="col-12 pl-0 pr-0">
                      <label class=" graph-titles">Revenue</label>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <Pie data={data} width={250} />
                      </div>
                      <div className="col-12 pl-0 pr-0 text-center mt-4">
                        <div className="row">
                          <div className="col-lg-3 col-4">
                            <span>
                              Generals
                              <br />
                              <label className="fontNormal small-details">
                                R{general}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-4">
                            <span>
                              VIP
                              <br />
                              <label className="fontNormal small-details">
                                R{vip}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-4">
                            <span>
                              VVIP
                              <br />
                              <label className="fontNormal small-details">
                                R{vvip}
                              </label>
                            </span>
                          </div>

                          <div className="col-lg-3 col-12">
                            <span>
                              Total
                              <br />
                              <label className="fontNormal small-details">
                                R{totalSales}
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 pl-2 pr-2">
                <div className=" BoxDashboardContainer flex justify-content-center mb-4 pl-2 pr-2">
                  <div className="col-12 details-body mb-3 justify-content-center align-self-center row flex">
                    <div className="col-12 pl-0 pr-0">
                      <label class="graph-titles">Sales progress</label>
                    </div>

                    <div className="col-12 pl-0 pr-0">
                      <label>Overall</label>
                      <label className="float-right">
                        Performance: {status}
                      </label>
                      <br />

                      <label className="small-details w-100">
                        <span>Sales:</span> {totalSold}/{ticketsAvailable}
                        <span className="float-right">
                          Income:{" "}
                          <label className="fontNormal">R{totalSales}</label>
                        </span>
                      </label>
                      <div
                        className=""
                        style={{
                          border: "thin solid rgba(75, 192, 192, 1)",
                          height: 30,
                          width: "100%",
                        }}
                      >
                        <label
                          style={{
                            width: percentage,
                            backgroundColor: "rgba(75, 192, 192, 0.2)",
                            height: 29,
                            marginBottom: 0,
                            textAlign: "right",
                            paddingTop: 3,
                          }}
                        >
                          {percentage}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 pl-0 pr-0 mt-4">
                      <label>VIP</label>
                      <label className="float-right">
                        Performance: {standingVIP}
                      </label>
                      <br />

                      <label className="small-details w-100">
                        <span>Sales:</span> {salesV}/{viptickets}
                        <span className="float-right">
                          Income: <label className="fontNormal">R{vip}</label>
                        </span>
                      </label>
                      <div
                        className=""
                        style={{
                          border: "thin solid #3399cc",
                          height: 30,
                          width: "100%",
                        }}
                      >
                        <label
                          style={{
                            width: getPercentageVIP,
                            backgroundColor: "rgba(54, 162, 235, 0.2)",
                            height: 29,
                            marginBottom: 0,
                            textAlign: "right",
                            paddingTop: 3,
                          }}
                        >
                          {getPercentageVIP}
                        </label>
                      </div>
                    </div>

                    <div className="col-12  pl-0 pr-0 mt-4">
                      <label>General</label>
                      <label className="float-right">
                        Performance: {standingGEN}
                      </label>
                      <br />

                      <label className="small-details w-100">
                        <span>Sales:</span> {salesG}/{generaltickets}
                        <span className="float-right">
                          Income:{" "}
                          <label className="fontNormal">R{general}</label>
                        </span>
                      </label>

                      <div
                        className=""
                        style={{
                          border: "thin solid darkOrange",
                          height: 30,
                          width: "100%",
                        }}
                      >
                        <label
                          style={{
                            width: getPercentageGEN,
                            backgroundColor: "rgba(255, 206, 86, 0.2)",
                            height: 29,
                            marginBottom: 0,
                            textAlign: "right",
                            paddingTop: 3,
                          }}
                        >
                          {getPercentageGEN}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 pl-0 pr-0 mt-4">
                      <label>VVIP</label>
                      <label className="float-right">
                        Performance: {standingVVIP}
                      </label>
                      <br />

                      <label className="small-details w-100">
                        <span>Sales:</span> {salesVV}/{vviptickets}
                        <span className="float-right">
                          Income: <label className="fontNormal">R{vvip}</label>
                        </span>
                      </label>

                      <div
                        className=""
                        style={{
                          border: "thin solid red",
                          height: 30,
                          width: "100%",
                        }}
                      >
                        <label
                          style={{
                            width: getPercentageVVIP,
                            backgroundColor: "rgba(255, 99, 132, 0.2)",
                            height: 29,
                            marginBottom: 0,
                            textAlign: "right",
                            paddingTop: 3,
                          }}
                        >
                          {getPercentageVVIP}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 pl-2 pr-2">
                <div className=" BoxDashboardContainer flex justify-content-center mb-4 pl-2 pr-2">
                  <div className="col-12 details-body mb-3 justify-content-center align-self-center row  flex">
                    <div className="col-12 pl-0 pr-0">
                      <label class=" graph-titles">
                        Yesterday and Today's sales progress
                      </label>
                    </div>
                    <div className="col-12  pl-0 pr-0">
                      <span>Revenue</span>
                      <br />
                      <div className="row">
                        <div className="col-lg-4 col-4 text-left">
                          <label className="small-details">Overall</label>
                          <br />
                          <label style={{ color: todaymoneyColor }}>
                            R{overallincome}
                          </label>
                        </div>

                        <div className="col-lg-4 col-4 text-center">
                          <label className="small-details">Yesterday</label>
                          <br />
                          <label style={{ color: yesterdaymoneyColor }}>
                            R{yesterdayincome}
                          </label>
                        </div>

                        <div className="col-lg-4 col-4 text-right">
                          <label className="small-details">Today</label>
                          <br />
                          <label style={{ color: todaymoneyColor }}>
                            R{todayincome}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 pl-0 pr-0">
                      <div className="h-divider w-100"></div>
                    </div>
                    <div className="col-12  pl-0 pr-0">
                      <span>Sales</span>
                      <br />
                      <div className="row">
                        <div className="col-lg-4 col-4 text-left">
                          <label className="small-details">Overall</label>
                          <br />
                          {todaysales > yesterdaysales ? (
                            <label style={{ color: "rgb(102, 178, 102)" }}>
                              {overallsales}
                            </label>
                          ) : (
                            <label style={{ color: "rgb(255, 102, 102)" }}>
                              {overallsales}
                            </label>
                          )}
                        </div>

                        <div className="col-lg-4 col-4 text-center">
                          <label className="small-details">Yesterday</label>
                          <br />
                          {yesterdaysales > todaysales ? (
                            <label style={{ color: "rgb(102, 178, 102)" }}>
                              {yesterdaysales}
                            </label>
                          ) : (
                            <label style={{ color: "rgb(255, 102, 102)" }}>
                              {yesterdaysales}
                            </label>
                          )}
                        </div>

                        <div className="col-lg-4 col-4 text-right">
                          <label className="small-details">Today</label>
                          <br />
                          {todaysales > yesterdaysales ? (
                            <label style={{ color: "rgb(102, 178, 102)" }}>
                              {todaysales}
                            </label>
                          ) : (
                            <label style={{ color: "red" }}>{todaysales}</label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 pl-0 pr-0">
                      <div className="h-divider w-100"></div>
                    </div>
                    <div className="col-12  pl-0 pr-0">
                      <span>Indicators</span>
                      <br />
                      <div className="row">
                        <div className="col-lg-4 col-4 text-left">
                          <label className="small-details">
                            <IoIosArrowRoundUp color={"#66b266"} size={20} />
                            <IoIosArrowRoundDown color={"#ff6666"} size={20} />
                            Overall
                          </label>
                          <br />
                          <label style={{ color: todaymoneyColor }}>
                            {overallindicators}%
                          </label>
                        </div>

                        <div className="col-lg-4 col-4 text-center">
                          <label className="small-details">Yesterday</label>
                          <br />
                          <label style={{ color: yesterdaymoneyColor }}>
                            {yesterdayPerformance}%
                          </label>
                        </div>

                        <div className="col-lg-4 col-4 text-right">
                          <label className="small-details">Today</label>
                          <br />
                          <label style={{ color: todaymoneyColor }}>
                            {todayPerformance}%
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 pl-0 pr-0">
                      <div className="h-divider w-100"></div>
                    </div>
                    <div className="col-12  pl-0 pr-0">
                      <div className="row">
                        <div className="col-12 text-center">
                          <span className="">{declineString}</span>
                          <br />
                          <label
                            style={{
                              width: "100%",
                              color: declineColor,
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: 25,
                            }}
                          >
                            {declineIndicator} {allincome}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
