import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import {
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosRefresh,
} from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";

import Header from "./components/Header5";
import TopBarProgress from "react-topbar-progress-indicator";

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      details: [],
      delay: 100,
      loadBarProgress: true,
      refreshingText: false,
      loadIndi: false,
      loadIndiBlank: true,
      campaignCount: 0,
      newcampaignbutton: "new-campaign-button",
      wallet: "0.0",
    };
  }

  componentDidMount() {
    const businessid = localStorage.getItem("recruitid");
    const eventid = localStorage.getItem("eventid");
    document.title =
      localStorage.getItem("partnername") +
      " - " +
      localStorage.getItem("recruitname") +
      " - My wallet ";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=WALLET&businessid=" +
        businessid +
        "&eventid=" +
        eventid
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({ wallet: "0.0" });
      } else {
        this.setState({ wallet: response.data.payout });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
        businessid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          campaignCount: (
            <label className="campaigncounts">
              {response.data.campaignCount}
            </label>
          ),
        });
      } else {
        this.setState({
          campaignCount: "",
        });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNS&businessid=" +
        businessid +
        "&recruit=yes"
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [], loadBarProgress: false });
        } else {
          this.setState({
            details: response.data,

            loadBarProgress: false,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.campaignCount !== prevState.campaignCount) {
      const businessid = localStorage.getItem("recruitid");
      const eventid = localStorage.getItem("eventid");

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=WALLET&businessid=" +
          businessid +
          "&eventid=" +
          eventid
      ).then((response) => {
        if (response.data.val == 0) {
          this.setState({ wallet: "0.0" });
        } else {
          this.setState({ wallet: response.data.payout });
        }
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNSTATSUSS&businessid=" +
          businessid
      ).then((response) => {
        if (response.data.val == 1) {
          this.setState({
            campaignCount: (
              <label className="campaigncounts">
                {response.data.campaignCount}
              </label>
            ),
          });
        } else {
          this.setState({
            campaignCount: "",
          });
        }
      });

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CAMPAIGNS&businessid=" +
          businessid +
          "&recruit=yes"
      )
        .then((response) => {
          if (response.data.val == 0) {
            this.setState({ details: [] });
          } else {
            this.setState({
              details: response.data,

              loadBarProgress: false,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  refresh = () => {
    this.setState({ loadBarProgress: true, refreshingText: true });
    setTimeout(() => {
      this.setState({ loadBarProgress: false, refreshingText: false });
    }, 2000);

    this.props.history.push("/wallet");
  };

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let {
      string,
      campaignname,
      boolean,
      remainingBalance,
      campaignid,
      payout,
    } = "";

    const { loadIndi, loadIndiBlank, loadSearchBox, loadSearchresults } =
      this.state;

    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};

    const year = currentDate.getFullYear();
    const fulldate = year + "-" + month + "-" + date;

    return (
      <div className="">
        <div className=" vertical-scroll">
          <table width="100%" border={0}>
            <tr className="td-bottom-line">
              <td width={"5%"} className="text-center">
                <span></span>
              </td>
              <td width={"10%"}>
                <span>Campaign</span>
                <br />
                <label className="small-details font-size-12">
                  name of the campaign
                </label>
              </td>
              <td width={"10%"}>
                <span>Event </span>
                <br />
                <label className="small-details font-size-12">
                  name of the event associated with this event
                </label>
              </td>

              <td width={"7%"}>
                <span>Status</span>
                <br />
                <label className="small-details font-size-12">
                  shows if this campaign is active or inactive
                </label>
              </td>

              <td width={"10%"}>
                <span>Running period</span>
                <br />
                <label className="small-details font-size-12">
                  duration that this campaign is running
                </label>
              </td>

              <td width={"10%"}>
                <span>Days remaining</span>
                <br />
                <label className="small-details font-size-12">
                  days remaining before this campaign stops running
                </label>
              </td>

              <td width={"10%"}>
                <span>Keywords</span>
                <br />
                <label className="small-details font-size-12">
                  relative words (useful when searching for this event)
                </label>
              </td>

              <td width={"10%"}>
                <span>Event date</span>
                <br />
                <label className="small-details font-size-12">
                  date this event will be happening
                </label>
              </td>

              <td width={"10%"}>
                <span>Starts</span>
                <br />

                <label className="small-details font-size-12">
                  date this campaign started running
                </label>
              </td>
              <td width={"10%"} className="hide-border">
                <span>Ends</span>
                <br />
                <label className="small-details font-size-12">
                  date this campaign will stop running
                </label>
              </td>

              <td width={"8%"}>
                <span>Admissions</span>
                <br />
                <label className="small-details font-size-12">
                  number of sales the recruit made so far
                </label>
              </td>

              <td width={"10%"}>
                <span>Expenditure</span>
                <br />
                <label className="small-details font-size-12">
                  amount the recruit spent on Karamale
                </label>
              </td>

              <td width={"8%"}>
                <span>Your rate</span>
                <br />
                <label className="small-details font-size-12">
                  the percentage Karamale
                  <br /> pays you from the recruit's expenditure
                </label>
              </td>

              <td width={"8%"}>
                <span>Payout</span>
                <br />
                <label className="small-details font-size-12">
                  money Karamale will pay you
                </label>
              </td>
            </tr>

            {this.state.details.length < 1 ? (
              <tr>
                <td colSpan={1} style={{ borderRight: "hidden" }}>
                  {" "}
                  <div>
                    <label
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: 13.5,
                        color: "#5B7083",
                      }}
                    >
                      This event has no campaign as yet. <br />
                      Soon as your recruit create a campaign, you'll see all the
                      details here. <br />
                      Karamale will notify you when your recruit creates a
                      campaign.
                    </label>
                  </div>
                </td>
              </tr>
            ) : (
              this.state.details.map((item, index) => {
                payout = item.spent * 0.3;
                let tempEventname = item.eventname;
                const passEventname = tempEventname;
                const campaignstatus = item.campaignstatus;
                let budget = item.budget;
                let ticketset = item.ticketid;
                let markticketset = "";
                let inCheckmark = "";
                let thumbnail = item.thumbnail;
                let pausebtn = "";
                remainingBalance = item.balance;
                campaignid = item.campaignid;
                {
                  item.activation == 1
                    ? (activeString = (
                        <IoIosCheckmarkCircleOutline size={18} color="green" />
                      ))
                    : (activeString = (
                        <IoIosRemoveCircleOutline size={18} color="red" />
                      ));
                }

                {
                  item.campaignname.length > 25
                    ? (campaignname =
                        item.campaignname.substring(0, 25) + "...")
                    : (campaignname = item.campaignname);
                }

                if (
                  item.thumbnail.trim() === "" ||
                  item.thumbnail.trim() === "none"
                ) {
                  poster = "https://karamale.com/apps/kscanner/placeholder.png";
                  nb = (
                    <label
                      data-tip="Complete event setup"
                      className="float-right"
                      style={{ color: "red", float: "right", fontWeight: 400 }}
                    >
                      <ReactTooltip />
                      <BiEditAlt />
                    </label>
                  );
                } else {
                  nb = "";
                  poster =
                    "https://karamale.com/apps/kscanner/posters/" +
                    item.businessid +
                    "/" +
                    item.eventid +
                    "/" +
                    item.thumbnail;
                }

                if (item.campaignstatus == "running") {
                  boolean = true;
                } else {
                  boolean = false;
                }
                return (
                  <tr className="">
                    <td className="text-center">
                      <label
                        style={{
                          backgroundImage: `url(` + poster + `)`,
                          width: 49,
                          height: 49,
                          borderRadius: 100,
                          backgroundSize: "100%",
                        }}
                      ></label>
                    </td>
                    <td>
                      <label
                        className="item-names w-100"
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {campaignname}
                      </label>
                    </td>

                    <td>
                      <label
                        className="item-names w-100"
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {item.eventname}
                      </label>
                    </td>

                    <td>
                      {item.campaignstatus == "running" ? (
                        <label style={{ color: "#4ca64c" }}>Active</label>
                      ) : (
                        <label style={{ color: "silver" }}>Off</label>
                      )}
                    </td>

                    <td>{item.getPeriod} days</td>
                    <td>
                      {item.daysRemaining}{" "}
                      {item.daysRemaining == "---"
                        ? ""
                        : item.daysRemaining == 1
                        ? "day"
                        : "days"}
                    </td>
                    <td>{item.keywords}</td>
                    <td>{item.eventday}</td>
                    <td>{item.fromdate}</td>
                    <td>{item.todate}</td>
                    <td>{item.admission}</td>
                    <td> ZAR {item.spent}</td>
                    <td>30%</td>
                    <td>ZAR {payout.toFixed(1)}</td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
        <label className="mt-3 small-details mb-0">
          Scroll to the right for more options.
        </label>
      </div>
    );
  };

  handleEdit = () => {
    if (this.state.count == 1) {
      // alert(this.state.eventid);
      this.props.history.push({
        pathname: "/campaign",
        state: {
          eventid: this.state.eventid,
          eventname: this.state.eventname,
          poster: this.state.poster,
          eventdate: this.state.eventdate,
          enddate: this.state.enddate,
          event_date: this.state.event_date,
          finish_date: this.state.finish_date,
          theme: this.state.theme,
          venue: this.state.venue,
          expectednum: this.state.expectednum,
          time: this.state.time,
          endtime: this.state.endtime,
          prevpage: "campaignmanager",
          currentpage: "campaignmanager",
        },
      });
    }
  };

  handleAnalytics = () => {
    if (this.state.count == 1) {
      // alert(this.state.endtime);
      this.props.history.push({
        pathname: "/analytics",
        state: {
          eventid: this.state.eventid,
          eventname: this.state.eventname,
          poster: this.state.poster,
          eventdate: this.state.eventdate,
          enddate: this.state.enddate,
          event_date: this.state.event_date,
          finish_date: this.state.finish_date,
          theme: this.state.theme,
          venue: this.state.venue,
          expectednum: this.state.expectednum,
          time: this.state.time,
          endtime: this.state.endtime,
          prevpage: "campaignmanager",
          currentpage: "campaignmanager",
          back: "campaignmanager",
        },
      });
    }
  };

  handleResume = () => {
    const businessid = localStorage.getItem("businessid");
    if (this.state.count > 1) {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=RESUMECAMPAIGNS&businessid=" +
          businessid
      )
        .then((response) => {})
        .catch((error) => console.log(error));

      this.setState({ loadBarProgress: true });
      setTimeout(() => {
        this.setState({ loadBarProgress: false });
      }, 2000);

      this.props.history.push("/campaignmanager");
    }
  };

  handleYesTransfer = (campaignid, balance) => {
    const businessid = localStorage.getItem("businessid");
    this.setState({
      searchValue: "",
      loadSearchresults: false,
      balance: balance,
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=UPDATETRANSFERCHECK&campaignid=" +
        campaignid +
        "&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 1) {
          this.setState({
            loadBarProgress: true,
            loadSearchBox: true,
            campaignid: campaignid,
          });
        } else {
          this.setState({
            loadBarProgress: true,
            loadSearchBox: false,
          });
        }
      })
      .catch((error) => console.log(error));
    this.props.history.push("/campaignmanager");
  };

  handleTransfer = (balance, campaignid, neweventid, spent) => {
    // alert(neweventid);

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=TRANSFERBALANCE&campaignid=" +
        campaignid +
        "&neweventid=" +
        neweventid +
        "&balance=" +
        balance +
        "&spent=" +
        spent
    )
      .then((response) => {
        // alert(response.data.val);
        this.setState({
          loadBarProgress: true,
          loadSearchBox: true,
          campaignid: campaignid,
        });
      })
      .catch((error) => console.log(error));
    this.props.history.push("/campaignmanager");
  };

  handleNotTransfer = (transferredid, balance) => {
    if (transferredid == 1) {
      this.setState({
        error:
          "R" + balance + " has already been transferred to another campaign",
        errorDisplay: "block",
      });
    } else {
      this.setState({
        error: "You can't transfer if your balance is at R0.0",
        errorDisplay: "block",
      });
    }

    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 5000);
  };

  closeError = () => {
    this.setState({ errorDisplay: "none" });
  };

  handleSearch = async (event) => {
    // alert(this.state.campaignid);
    const campaignid = this.state.campaignid;
    const businessid = localStorage.getItem("businessid");
    this.setState({ searchValue: event.target.value });
    const searchVal = event.target.value;
    const formData = new FormData();
    formData.append("search", event.target.value);

    await Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=SEARCHEVENTS&search=" +
        searchVal +
        "&businessid=" +
        businessid +
        "&campaignid=" +
        campaignid
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({ queryResults: [] });
      } else {
        this.setState({ queryResults: response.data });
      }
    });

    if (!searchVal) {
      this.setState({ loadSearchresults: false });
    } else {
      this.setState({ loadSearchresults: true });
    }
  };

  newCampaign = () => {
    this.setState({
      loadCustomers: false,
      customers: "new-campaign-button",
      platforms: "new-campaign-button",
      loadingPlatforms: false,
    });
    if (this.state.loadingEvents == true) {
      this.setState({
        loadingEvents: false,
        newcampaignbutton: "new-campaign-button",
      });
    } else {
      this.setState({
        loadingEvents: true,
        newcampaignbutton: "new-campaign-button-active",
      });
    }
  };

  render() {
    if (localStorage.getItem("partnerid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
      if (
        !localStorage.getItem("recruitid") ||
        !localStorage.getItem("eventid")
      )
        return <Redirect to="/recruits" />;
    }
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");

    const previewStyle = {
      height: 240,
      width: 320,
      border: "thin solid red",
    };
    const {
      loadBarProgress,
      refreshingText,
      loadingEvents,
      loadCustomers,
      loadingPlatforms,
    } = this.state;
    let activeEdit = "";

    if (this.state.count == 1) {
      activeEdit = "";
    } else {
      activeEdit = "button-disabled";
    }
    return (
      <div className="view">
        <Header />
        {loadBarProgress && <TopBarProgress />}
        <div className="containe pl-0 pr-0">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-12 pl-2 pr-2">
              <div className="col-12 mt-5 mb-2 pl-0 pr-0">
                <span>{localStorage.getItem("recruitname")}</span>
                <label className="break-to-next-line mb-0">
                  <br />
                </label>
                <span className="float-right-mobile">
                  Your wallet:{" "}
                  <label className="small-details">
                    {this.state.wallet < 15 ? (
                      <label className="mb-0 color-red">
                        ZAR {this.state.wallet}
                      </label>
                    ) : (
                      <label className="mb-0 color-green">
                        ZAR {this.state.wallet}
                      </label>
                    )}
                  </label>
                </span>
              </div>
              <div className=" BoxDashboardContainer mb-4 pb-3 pl-3 pr-3 pt-0">
                <div className="">
                  <div className="row vertical-scroll top-data-taps pt-0">
                    <table width="100%" border={0}>
                      <tr className="background-color-white border-hidden">
                        <td width={"80%"} style={{ border: "hidden" }}>
                          <div className="col-lg-2 col-4 col-sm-3 col-md-3 mt-0 pl-0">
                            <div className="item-names pl-1">
                              <label className="ticket-title  pl-0 pr-0 mt-0 mb-0">
                                <label className="small-details mt-0 mb-0">
                                  {localStorage.getItem("eventname")}
                                </label>
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-12 text-right  pl-0 pr-0">
                            {refreshingText && (
                              <label className="refreshing-text">
                                Refreshing...
                              </label>
                            )}
                            &nbsp; &nbsp;
                            <label
                              className="refresh-icon mb-0 mt-0"
                              data-tip="Refresh"
                              onClick={() => this.refresh()}
                            >
                              <IoIosRefresh size={20} />
                              <ReactTooltip />
                            </label>
                            {/* <label
                        className={this.state.newcampaignbutton}
                        onClick={() => this.newCampaign()}
                      >
                        Ticket category
                      </label> */}
                          </div>

                          {/* <div className="h-divider w-100"></div> */}
                        </td>
                      </tr>
                    </table>
                  </div>
                  {loadingPlatforms && (
                    <div className="listplatforms BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3 w-100">
                        <strong>
                          Devices and agents used to access this event
                        </strong>
                        <br />
                        <label className="small-details">
                          {this.state.campaignname}
                        </label>
                        <label className="float-right">
                          <Link
                            to={{
                              pathname: "addevent",
                              state: { prevpage: "dashboard" },
                            }}
                          ></Link>
                        </label>
                      </label>

                      <div className="h-divider w-100" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {this.platformlist()}
                      </div>
                    </div>
                  )}

                  {loadingEvents && (
                    <div className="select-event BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3 w-100">
                        <strong>Current tickets</strong>
                      </label>

                      <div className="h-divider w-100 mt-0" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {/* {this.events()} */}
                        jk
                      </div>
                    </div>
                  )}
                  {loadCustomers && (
                    <div className="listcustomers BoxDashboardContainer text-left pl-0 pr-0">
                      <label className="pl-3 pr-3">
                        <span>{this.state.campaignname}</span>
                      </label>

                      <div className="h-divider w-100" />
                      <div className="listcustomers-header pl-3 pr-3 pb-2">
                        {this.customerlist()}
                      </div>
                    </div>
                  )}
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
          onClick={() => this.closeError()}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Wallet;
