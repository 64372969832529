import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { IoIosAdd } from "react-icons/io";
import Header3 from "./components/Header3";
import ClipLoader from "react-spinners/ClipLoader";

function Smscampaign(props) {
  useEffect(() => {
    const textid = localStorage.getItem("textid");
    const businessid = localStorage.getItem("businessid");

    document.title = "Finalize marketing";
  });

  const [loadLabel, setLoadLabel] = useState(true);
  const [loadCliper, setLoadCliper] = useState(false);

  function next() {
    setLoadLabel(false);
    setLoadCliper(true);
  }

  if (localStorage.getItem("businessid") === null) {
    return <Redirect to="/login" />;
  } else {
    if (localStorage.getItem("textid") === null) {
      return <Redirect to="/sms" />;
    }
  }

  return (
    <div className="view">
      <Header3 />
      <form
        action="https://www.payfast.co.za/eng/process"
        name="form_a54ad269093bbef584e1bd2cfe93c8cf"
      >
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-top-header">
                    <div className="col-lg-12 col-10 col-sm-11">
                      <label className=" pt-2">
                        <span>Finalize your marketing</span>
                      </label>
                      <label class="back-arrow float-right">
                        {props.location.state.cost < 5 ? (
                          <div class="ticket-btn-disabled with-100 text-center mb-0 font-size-15 btn-border-color-grey">
                            <span>
                              <span>Pay R{props.location.state.cost}</span>
                            </span>
                          </div>
                        ) : (
                          <button
                            class="save-btn btn-radius-3 width-150"
                            onClick={next}
                          >
                            {loadLabel && (
                              <span>
                                Pay R{props.location.state.cost.toFixed(1)}
                              </span>
                            )}

                            {loadCliper && (
                              <ClipLoader
                                color={"#fff"}
                                loading={true}
                                size={20}
                              />
                            )}
                          </button>
                        )}
                      </label>
                    </div>
                  </div>
                  {props.location.state.cost < 5 ? (
                    <div className="col-12 color-red">
                      We are unable to process payments than are less than R5
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="h-divider w-100"></div>
                  <div className="col-12 text-left p-3 pl-4">
                    <span>Text message</span>
                  </div>

                  <div className={"item-select-active"}>
                    <div className="link-btn w-100 menu-links">
                      <div className="col-12">
                        <label className="item-names">
                          {props.location.state.text}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="hidden" name="cmd" value="_paynow" />
        <input type="hidden" name="receiver" value="15455113" />
        <input type="hidden" name="item_name" value={"SMS MARKETING"} />
        <input
          type="hidden"
          name="amount"
          id="amount"
          value={props.location.state.cost.toFixed(1)}
        />
        <input type="hidden" name="item_description" value="SMS MARKETING" />
        <input
          type="hidden"
          name="return_url"
          id="return_url_"
          value={"https://karamale.com/smsmarketingsuccessful"}
        />
        <input
          type="hidden"
          name="cancel_url"
          id="cancel_url_"
          value={"https://karamale.com/smsmarketingcancel"}
        />
        <input
          type="hidden"
          name="custom_amount"
          id="custom_amounts"
          value={0}
          class="pricing"
        />
      </form>
    </div>
  );
}

export default Smscampaign;
