import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import {
  IoIosArrowBack,
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import Header5 from "./components/Header5";
import TopBarProgress from "react-topbar-progress-indicator";
import ReactTooltip from "react-tooltip";

class Recruits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      query: "",
      loadBarProgress: true,
      notFound: (
        <label className="mb-0">
          You have no recruits at the moment.
          <br />
          <label className="mb-0 small-details">
            Recruits will only appear when they've created at least one event.
          </label>
        </label>
      ),
    };
  }

  componentDidMount() {
    const partnername = localStorage.getItem("partnername");
    const accounttype = localStorage.getItem("accounttype");
    const partnerid = localStorage.getItem("partnerid");
    document.title = "Recruits - " + partnername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=RECRUITS&subquery=FETCHRECRUITS&partnerid=" +
        partnerid +
        "&accounttype=" +
        accounttype
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [], loadBarProgress: false });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  searchQuery = (text) => {
    this.setState({ query: text.target.value });

    let query = text.target.value.split(" ").join("+");
    const accounttype = localStorage.getItem("accounttype");
    const accountid = localStorage.getItem("partnerid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=RECRUITS&subquery=SEARCHRECRUITS&search=" +
        query +
        "&partnerid=" +
        accountid +
        "&accounttype=" +
        accounttype
    )
      .then((response) => {
        // alert(response.data.search);
        if (response.data.val == 0) {
          this.setState({
            details: [],
            notFound:
              "No match found for your search... '" +
              query.split("+").join(" ").substr(0, 20) +
              "'",
          });
        } else {
          this.setState({ details: response.data });
        }
      })
      .catch((error) => console.log(error));
  };

  wallet = (recruitid, recruitname, recruitemail, eventid, eventname) => {
    // alert(eventid);
    this.props.history.push({
      pathname: "wallet",
    });

    localStorage.setItem("recruitid", recruitid);
    localStorage.setItem("recruitname", recruitname);
    localStorage.setItem("recruitemail", recruitemail);
    localStorage.setItem("eventid", eventid);
    localStorage.setItem("eventname", eventname);
  };

  details = () => {
    const accounttype = localStorage.getItem("accounttype");
    let eventname = "";
    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              {this.state.notFound}{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            {
              item.eventname.length > 25
                ? (eventname = item.eventname.substring(0, 25) + "...")
                : (eventname = item.eventname);
            }
            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-1 pl-1 pb-2 pt-3">
                  <div
                    className="w-100"
                    onClick={() =>
                      this.wallet(
                        item.recruitid,
                        item.recruit,
                        item.email,
                        item.eventid,
                        item.eventname
                      )
                    }
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-12" style={{ cursor: "pointer" }}>
                        <label
                          className="item-names w-100"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          className="color-black"
                        >
                          <span>{item.recruit}</span>
                          <br />
                          <label className="small-details w-100 mb-0">
                            {item.eventname == "no" ? (
                              <label className="color-semi-black">
                                This recruit hasn't launched an event, we'll
                                notify you when they do.
                              </label>
                            ) : (
                              eventname
                            )}
                            <label
                              className="float-right mb-0"
                              style={{ marginTop: -10 }}
                            >
                              {item.eventStatus == "active" ? (
                                <label
                                  className="color-green mb-0"
                                  data-tip="Active"
                                >
                                  <IoIosCheckmarkCircleOutline size={20} />
                                  <ReactTooltip />
                                </label>
                              ) : (
                                <label className="mb-0">
                                  {item.eventname == "no" ? (
                                    <label
                                      className="color-semi-black mb-0"
                                      data-tip="No event for this recruit at moment"
                                    >
                                      <BsExclamationCircle size={17} />
                                      <ReactTooltip />
                                    </label>
                                  ) : (
                                    <label
                                      className="color-red mb-0"
                                      data-tip="Inactive"
                                    >
                                      <IoIosCloseCircleOutline size={20} />
                                      <ReactTooltip />
                                    </label>
                                  )}
                                </label>
                              )}
                            </label>
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("partnerid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header5 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container ">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-4 pl-0">
                      <Link to="/in" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-8">
                      <input
                        type="text"
                        placeholder="Search recruits"
                        value={this.state.query}
                        onChange={this.searchQuery}
                        className="search-ticket-input"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5  pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title  pl-0 pr-0 w-100">
                        Recruits (Karamale clients)
                        <br />
                        <label
                          className="small-details mb-0"
                          style={{ textTransform: "none" }}
                        >
                          <span>NB:</span> Other recruits may not appear because
                          they haven't created an event
                        </label>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Recruits;
