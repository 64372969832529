import React from "react";
import "./App.css";
import { Link, useHistory, withRouter } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

// export default withRouter (Redirect);

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
    };
  }

  componentDidMount() {
    document.title = "Karamale - Successfully logged out";
    localStorage.clear();
    this.forceUpdate();
  }

  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center login-screen-container w-100">
            <div className="align-self-center">
              {/* <div className="log-title text-center">
                <img src="https://karamale.com/karamale.png" width="170" />
              </div> */}

              <div className="text-footer text-center successfully-out">
                <IoIosCheckmarkCircleOutline size={40} />
                <br />
                <br />
                Successfully logged out
                <br />
                <br />
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Logout;
