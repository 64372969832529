import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { BsPlus, BsDash, BsCircle, BsCheckCircle } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { IoIosArrowBack } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import Header2 from "./components/Header2";
import Header from "./components/Header";
const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Finalize extends Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    document.title = "Karamale | " + query.get("eventname");
    localStorage.setItem("group", "adults");
    let { eventid, eventname, ticketid } = 0;
    if (
      query.has("eventid") === true &&
      query.has("eventname") === true &&
      query.has("eventDate") === true &&
      query.has("fulldate") === true &&
      query.has("customerid") === true &&
      query.has("posterUri") === true &&
      query.has("accountid") === true &&
      query.has("accounttype") === true &&
      query.has("customername") === true &&
      query.has("email") === true &&
      query.has("phone") === true &&
      query.has("venue") === true &&
      query.has("description") === true &&
      query.has("businessid") === true &&
      query.has("poster") === true &&
      query.has("eventname") === true &&
      query.has("eventday") === true &&
      query.has("starttime") === true &&
      query.has("endtime") === true &&
      query.has("previousPage") === true &&
      query.has("campaignStatus") === true &&
      query.has("ticketid") === true &&
      query.has("ticketref") === true &&
      query.has("command") === true &&
      query.has("eventtype") === true
    ) {
    } else {
      this.props.history.push("");
    }
    const avail_tickets = query.get("avail_tickets");
    const kids_tickets = query.get("kids_tickets");

    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({ admissionValue: 0, total: 0 });
    }

    if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({ admissionValue: 0, total: 0 });
    }
  }

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      error: "",
      products: [],
      isChecked: false,
      isBtnDisabled: true,
      add: null,
      minus: null,
      admissionValue: 1,
      total: query.get("price"),
      price: query.get("price"),
      kidsPrice: query.get("kids_price"),

      btnClasses: ["btnDisabled w-100"],
      fullname: null,
      response: "",
      maxMessage: "",
      ticketnumber: Math.random().toString(36).substr(2, 7).toUpperCase(),
      phone: "",
      email: "",
      key: Math.random().toString(36).substr(1, 100).toUpperCase(),
      checkedOne: true,
      uncheckedOne: false,
      checkedTwo: false,
      uncheckedTwo: true,
      activeOne: "item-select-active",
      activeTwo: "item-select",
    };
  }

  handleChange = this.handleChange.bind(this);
  handleEmail = this.handleEmail.bind(this);
  handlePhone = this.handlePhone.bind(this);
  handleAdmission = this.handleAdmission.bind(this);
  handleTotal = this.handleTotal.bind(this);
  handleSubmit = this.handleSubmit.bind(this);

  handleTotal(event) {
    this.setState({ total: event.target.value });
  }

  handleAdmission(event) {
    this.setState({ admissionValue: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });

    const livePhone = event.target.value;
    const formData = new FormData();
    formData.append("phone", livePhone);

    const url = "/getUserEmail";
    Axios.post(url, formData).then((res) => {
      this.setState({ email: res.data[0].email });
      this.setState({ fullname: res.data[0].fname + " " + res.data[0].lname });
      console.log(res.data[0].email);
    });
  }

  handleEmail(event) {
    this.setState({ email: event.target.value });
    const liveEmail = event.target.value;
    const formData = new FormData();
    formData.append("email", liveEmail);

    const url = "/getUserPhone";
    Axios.post(url, formData).then((res) => {
      this.setState({ phone: res.data[0].phone });
      this.setState({ fullname: res.data[0].fname + " " + res.data[0].lname });
      console.log(res.data[0].phone);
    });
  }

  handleChange(event) {
    this.setState({ fullname: event.target.value });
  }

  checkHandleClicked = () => {
    this.setState((prevState) => ({
      isChecked: !this.state.isChecked,
      isBtnDisabled: this.state.isChecked ? true : false,
      btnClasses: this.state.isChecked
        ? ["btnDisabled w-100"]
        : ["karamale-btn-square karamale-btn w-100"],
    }));
  };

  addHandle = () => {
    // const pricetag = this.props.location.state.price;
    const admissionValue = this.state.admissionValue;
    const query = new URLSearchParams(this.props.location.search);

    const eventid = query.get("eventid");
    const businessid = query.get("businessid");
    const ticketid = query.get("ticketid");
    let pricetag = query.get("price");
    const avail_tickets = query.get("avail_tickets");
    const kids_tickets = query.get("kids_tickets");
    let ticketsAvailable = avail_tickets;

    if (localStorage.getItem("group") == "kids") {
      pricetag = query.get("kids_price");
      ticketsAvailable = kids_tickets;
    } else {
      pricetag = query.get("price");
      ticketsAvailable = avail_tickets;
    }

    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({
        maxMessage:
          "Tickets for " + localStorage.getItem("group") + " are sold out",
      });
    } else if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({
        maxMessage:
          "Tickets for " + localStorage.getItem("group") + " are sold out",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHECKAVAILABLETICKETS&eventid=" +
          eventid +
          "&ticketid=" +
          ticketid
      ).then((response) => {
        let category = "";
        if (response.data.category == "general") {
          category = "General";
        } else {
          category = response.data.category.toUpperCase();
        }
        if (admissionValue == parseInt(ticketsAvailable)) {
          let plural,
            pastTense = "";

          if (ticketsAvailable == 1) {
            plural = "ticket";
            pastTense = "was";
          } else {
            plural = "tickets";
            pastTense = "are";
          }
          this.setState({
            maxMessage:
              "There " +
              " " +
              pastTense +
              " " +
              ticketsAvailable +
              " " +
              category +
              " " +
              plural +
              " left",
          });
        } else {
          if (admissionValue == 15) {
            this.setState({
              maxMessage:
                "You've reached the maximum of 15 " + ticketsAvailable,
            });
          } else {
            if (admissionValue > 0) {
              this.setState({ maxMessage: "" });
            }

            this.setState((prevState) => ({
              admissionValue: prevState.admissionValue + 1,
              total: parseInt(prevState.total) + parseInt(pricetag),
            }));
          }
        }
      });
    }
  };

  minusHandle = () => {
    const query = new URLSearchParams(this.props.location.search);
    let pricetag = query.get("price");
    if (localStorage.getItem("group") == "kids") {
      pricetag = query.get("kids_price");
    } else {
      pricetag = query.get("price");
    }

    const admissionValue = this.state.admissionValue;
    if (admissionValue == 1 || admissionValue <= 0) {
      this.setState({ maxMessage: "You've reached the minimum" });
    } else {
      if (admissionValue < 16) {
        this.setState({ maxMessage: "" });
      }
      this.setState((prevState) => ({
        admissionValue: prevState.admissionValue - 1,
        total: prevState.total - pricetag,
      }));
    }
  };

  handleSubmit(aform_reference) {
    // event.preventDefault();
    // const data = new FormData(event.target);
    const query = new URLSearchParams(this.props.location.search);
    const data = {
      eventid: query.get("eventid"),
      businessid: query.get("businessid"),
      ticketid: query.get("ticketid"),
      ticketref: this.state.ticketnumber,
      total: this.state.total,
      price: this.state.price,
      admissionValue: this.state.admissionValue,
      phone: this.state.phone,
      email: this.state.email,
      fullname: this.state.fullname,
    };
    // Axios.post("/api/insert/ticket/buyer/details", data)
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));

    // var aform = aform_reference;
    // aform["amounts"].value =
    //   Math.round(aform["amounts"].value * Math.pow(10, 2)) / Math.pow(10, 2);
    // aform["custom_amount"].value = aform["custom_amount"].value.replace(
    //   /^\s+|\s+$/g,
    //   ""
    // );
    // if (
    //   !aform["custom_amount"].value ||
    //   0 === aform["custom_amount"].value.length ||
    //   /^\s*$/.test(aform["custom_amount"].value)
    // ) {
    //   alert("An amount is required");
    //   return false;
    // }
    // aform["amounts"].value =
    //   Math.round(aform["custom_amount"].value * Math.pow(10, 2)) /
    //   Math.pow(10, 2);
  }

  // click_a54ad269093bbef584e1bd2cfe93c8cf(aform_reference) {
  //   var aform = aform_reference;
  //   aform["amounts"].value =
  //     Math.round(aform["amounts"].value * Math.pow(10, 2)) / Math.pow(10, 2);
  //   aform["custom_amount"].value = aform["custom_amount"].value.replace(
  //     /^\s+|\s+$/g,
  //     ""
  //   );
  //   if (
  //     !aform["custom_amount"].value ||
  //     0 === aform["custom_amount"].value.length ||
  //     /^\s*$/.test(aform["custom_amount"].value)
  //   ) {
  //     alert("An amount is required");
  //     return false;
  //   }
  //   aform["amounts"].value =
  //     Math.round(aform["custom_amount"].value * Math.pow(10, 2)) /
  //     Math.pow(10, 2);
  // }

  adults = () => {
    const query = new URLSearchParams(this.props.location.search);
    localStorage.setItem("group", "adults");
    const avail_tickets = query.get("avail_tickets");
    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({ admissionValue: 0, total: 0 });
    } else {
      this.setState({ admissionValue: 1, total: this.state.price });
    }

    this.setState({
      activeOne: "item-select-active",
      activeTwo: "item-select",
      checkedOne: true,
      uncheckedOne: false,
      checkedTwo: false,
      uncheckedTwo: true,
      maxMessage: "",
    });
  };

  kids = () => {
    const query = new URLSearchParams(this.props.location.search);
    localStorage.setItem("group", "kids");
    const kids_tickets = query.get("kids_tickets");
    if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({ admissionValue: 0, total: 0 });
    } else {
      this.setState({ admissionValue: 1, total: this.state.kidsPrice });
    }
    this.setState({
      activeOne: "item-select",
      activeTwo: "item-select-active",
      checkedOne: false,
      uncheckedOne: true,
      checkedTwo: true,
      uncheckedTwo: false,
      maxMessage: "",
    });
  };

  render() {
    const {
      products,
      checkedOne,
      uncheckedOne,
      checkedTwo,
      uncheckedTwo,
      activeOne,
      activeTwo,
    } = this.state;
    const query = new URLSearchParams(this.props.location.search);

    const accountid = query.get("accountid");
    const eventid = query.get("eventid");
    const eventDate = query.get("eventDate");
    // const fulldate = query.get("fulldate");
    const customerid = query.get("customerid");
    const posterUri = query.get("posterUri");
    const accounttype = query.get("accounttype");
    const customername = query.get("customername");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const poster = query.get("poster");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");
    const price = query.get("price");
    const kids_price = query.get("kids_price");
    const eventtype = query.get("eventtype");
    const province = query.get("province");
    const category = query.get("category");
    const previousPage = query.get("previousPage");
    const prevPage = query.get("prevPage");

    const sold = query.get("sold");
    const avail_tickets = query.get("avail_tickets");
    const kids_sold = query.get("kids_sold");
    const kids_tickets = query.get("kids_tickets");

    const campaignStatus = query.get("campaignStatus");
    const email = query.get("email");
    const phone = query.get("phone");

    let soldOutAdults,
      soldOutKids = "";
    if (parseInt(avail_tickets) === 0) {
      soldOutAdults = (
        <label className="mb-0 color-red font-size-12 font-normal w-100 text-center">
          Sold out
        </label>
      );
    } else {
      soldOutAdults = (
        <label className="mb-0 color-green font-size-12 font-normal w-100 text-center">
          You can still buy
        </label>
      );
    }

    if (parseInt(kids_tickets) === 0) {
      soldOutKids = (
        <label className="mb-0 color-red font-size-12 font-normal w-100 text-center">
          Sold out
        </label>
      );
    } else {
      soldOutKids = (
        <label className="mb-0 color-green font-size-12 font-normal w-100 text-center">
          You can still buy
        </label>
      );
    }

    return (
      <form
        action="https://www.payfast.co.za/eng/process"
        name="form_a54ad269093bbef584e1bd2cfe93c8cf"
        // onsubmit="return click_a54ad269093bbef584e1bd2cfe93c8cf( this )"
        onSubmit={this.handleSubmit}
      >
        <div className="view pt-4">
          {localStorage.getItem("customername") === null ? (
            <Header />
          ) : (
            <Header2 />
          )}
          {/* {loadBarProgress && <TopBarProgress />} */}
          <div className="container">
            <div className="row mt-4 mb-0 w-100">
              <div className="row justify-content-center align-self-center desktop-width">
                <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-5">
                  <div className="content-detail">
                    <div className="">
                      <img
                        src={posterUri}
                        alt={poster}
                        width="100%"
                        className="img-poster"
                      />
                    </div>
                    <div className="col-lg-12 p-0 mt-4 pb-4">
                      <div className="pb-5">
                        <span className="bolddiv">Description</span>

                        <br />
                        <label className="mb-0 whiteSpace">{description}</label>
                        <br />
                        <br />
                        <span className="bolddiv">Venue</span>
                        <br />
                        {venue}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  localStorage.getItem("customername") === null
                    ? "d-none"
                    : "col-lg-3 col-md-12 col-sm-12 col-12"
                }
              >
                <div className="tickets pl-1 pr-1">
                  <div className="ticket-title">
                    {category} <br />
                    <div className="row w-100 mt-2 align-items-center">
                      {price <= 0 ? (
                        ""
                      ) : (
                        <div className="col-6 pl-0 pr-1">
                          <div
                            className={activeOne + " p-2 w-100 border-1"}
                            onClick={this.adults}
                          >
                            <label className="small-details mb-0 font-normal text-12">
                              {checkedOne && <BsCheckCircle size={15} />}
                              {uncheckedOne && <BsCircle size={15} />}
                              &nbsp;&nbsp;Adults:
                              <label className="m-0">&nbsp;R {price}</label>
                            </label>
                          </div>
                          {soldOutAdults}
                        </div>
                      )}

                      {kids_price <= 0 ? (
                        ""
                      ) : (
                        <div className="col-6 pl-1 pr-0">
                          <div
                            className={activeTwo + " p-2 w-100 border-1"}
                            onClick={this.kids}
                          >
                            <label className="small-details mb-0 font-normal text-12">
                              {checkedTwo && <BsCheckCircle size={15} />}
                              {uncheckedTwo && <BsCircle size={15} />}
                              &nbsp;&nbsp;Kids:
                              <label className="m-0">&nbsp;R{kids_price}</label>
                            </label>
                          </div>
                          {soldOutKids}
                        </div>
                      )}
                    </div>
                    <div className="h-divider w-100" />
                  </div>
                  <div className="pl-0 pr-0 pt-0">
                    <div className="upperCasedText">
                      <span className="capitalizedText textLight">
                        <div className="row">
                          <div className="col-lg-12 form-components ">
                            <label className="noteB normal-casing">
                              <span className="buy-text">NB:</span>{" "}
                              <Link to="/profile" className="click-here">
                                Change personal details{" "}
                              </Link>
                            </label>
                            <br />
                            <span className="buy-text">Name</span>
                            <br />
                            <label class="small-details">{customername}</label>
                            <br />
                            <span className="buy-text">Email</span>
                            <br />
                            <label class="small-details lower-casing">
                              {email}
                            </label>
                            <br />
                            <span className="buy-text">Phone</span>
                            <br />
                            <label class="small-details">{phone}</label>
                            <br />
                            <br />
                            <label className="textLight w-100">
                              Add Admission
                              <span className="float-right textLight text-15">
                                Total: R
                                <input
                                  type="text"
                                  readOnly
                                  value={this.state.total}
                                  onChange={this.handleTotal}
                                  className="totalAmount"
                                />
                              </span>
                            </label>
                            <label className="max-message">
                              {this.state.maxMessage}
                            </label>
                            <br />
                            <div className="row mb-3">
                              <div className="col-4 pr-2 pl-0">
                                <button
                                  className="minus"
                                  type="button"
                                  onClick={this.minusHandle}
                                >
                                  <BsDash />
                                </button>
                              </div>
                              <div className="col-4 pr-2 pl-2">
                                <input
                                  type="text"
                                  name="admission"
                                  className="admissionInput"
                                  placeholder="0"
                                  readOnly
                                  value={this.state.admissionValue}
                                  onChange={this.handleAdmission}
                                />
                              </div>
                              <div className="col-4 pr-0 pl-2">
                                <button
                                  className="add"
                                  id="add"
                                  type="button"
                                  onClick={this.addHandle}
                                >
                                  <BsPlus />
                                </button>
                                <input
                                  type="hidden"
                                  value={
                                    localStorage.getItem("group") == "kids"
                                      ? this.state.kidsPrice
                                      : this.state.price
                                  }
                                />
                                <input
                                  type="hidden"
                                  value={query.get("businessid")}
                                />
                                <input
                                  type="hidden"
                                  value={query.get("ticketid")}
                                />
                                <input
                                  type="hidden"
                                  value={query.get("eventid")}
                                />
                                <input
                                  type="hidden"
                                  value={this.state.ticketnumber}
                                />

                                <input
                                  type="hidden"
                                  name="cmd"
                                  value="_paynow"
                                />

                                <input
                                  type="hidden"
                                  name="receiver"
                                  value="15455113"
                                />

                                <input
                                  type="hidden"
                                  name="item_name"
                                  value={eventname}
                                />

                                <input
                                  type="hidden"
                                  name="amount"
                                  id="amount"
                                  value={this.state.total}
                                />

                                <input
                                  type="hidden"
                                  name="item_description"
                                  value=""
                                />

                                <input
                                  type="hidden"
                                  name="return_url"
                                  id="return_url_"
                                  value={
                                    "https://karamale.com/success?buid=" +
                                    uuid6 +
                                    "&queryid=" +
                                    uuid5 +
                                    "&cancellationid=" +
                                    uuid4 +
                                    "&uid=" +
                                    uuid3 +
                                    "&qKey=" +
                                    uuid1 +
                                    "&key=" +
                                    uuid2 +
                                    "&uuid=" +
                                    uuid +
                                    "&category=" +
                                    category +
                                    "&phone=" +
                                    phone +
                                    "&total=" +
                                    this.state.total +
                                    "&admission=" +
                                    this.state.admissionValue +
                                    "&price=" +
                                    this.state.price +
                                    "&kids_price=" +
                                    this.state.kidsPrice +
                                    "&key=" +
                                    this.state.key +
                                    "&email=" +
                                    email +
                                    "&ticketnumber=" +
                                    this.state.ticketnumber +
                                    "&ticketid=" +
                                    query.get("ticketid") +
                                    "&eventid=" +
                                    query.get("eventid") +
                                    "&businessid=" +
                                    query.get("businessid") +
                                    "&method=card&group=" +
                                    localStorage.getItem("group")
                                  }
                                />

                                <input
                                  type="hidden"
                                  name="cancel_url"
                                  id="cancel_url_"
                                  value={
                                    "https://karamale.com/cancelled?buid=" +
                                    uuid6 +
                                    "&queryid=" +
                                    uuid5 +
                                    "&cancellationid=" +
                                    uuid4 +
                                    "&uid=" +
                                    uuid3 +
                                    "&qKey=" +
                                    uuid1 +
                                    "&key=" +
                                    uuid2 +
                                    "&uuid=" +
                                    uuid +
                                    "&email=" +
                                    this.state.email +
                                    "&phaseValue=1" +
                                    "&businessId=" +
                                    query.get("businessid") +
                                    "&ticketId=" +
                                    query.get("ticketid") +
                                    "&ticketnumber=" +
                                    this.state.ticketnumber +
                                    "&eventId=" +
                                    query.get("eventid")
                                  }
                                />

                                <input
                                  type="hidden"
                                  name="custom_amount"
                                  id="custom_amounts"
                                  value={0}
                                  class="pricing"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-2 text-left cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={this.state.isChecked}
                                  className="checkbox"
                                  name="agreement"
                                  onClick={this.checkHandleClicked}
                                  id="agreement"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                className="col-lg-10 col-10 terms text-left align-self-center"
                                style={{
                                  display: "flex",
                                  textTransform: "none",
                                }}
                              >
                                <label
                                  for="agreement"
                                  className="cursor-pointer link-btn"
                                >
                                  I've read and agreed to the terms and
                                  conditions
                                </label>
                              </div>
                            </div>
                            <button
                              disabled={this.state.isBtnDisabled}
                              className={this.state.btnClasses}
                            >
                              Proceed to buy
                            </button>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="event-name justify-content-center"
            style={{ display: "flex" }}
          >
            <div
              className="backbtn event-title justify-content-center"
              style={{ display: "flex" }}
            >
              <div className="pt-1 pr-2">
                <Link
                  to={{
                    pathname: "/" + prevPage,
                    search:
                      "q=" +
                      uuid6 +
                      "&queryid=" +
                      uuid5 +
                      "&pageid=" +
                      uuid4 +
                      "&uid=" +
                      uuid3 +
                      "&qKey=" +
                      uuid1 +
                      "&key=" +
                      uuid2 +
                      "&uuid=" +
                      uuid +
                      "&eventid=" +
                      query.get("eventid") +
                      "&eventDate=" +
                      query.get("eventDate") +
                      "&fulldate=" +
                      query.get("fulldate") +
                      "&customerid=" +
                      query.get("customerid") +
                      "&posterUri=" +
                      query.get("posterUri") +
                      "&accountid=" +
                      query.get("accountid") +
                      "&accounttype=" +
                      query.get("accounttype") +
                      "&customername=" +
                      query.get("customername") +
                      "&email=" +
                      query.get("email") +
                      "&phone=" +
                      query.get("phone") +
                      "&venue=" +
                      query.get("venue") +
                      "&description=" +
                      query.get("description") +
                      "&businessid=" +
                      query.get("businessid") +
                      "&poster=" +
                      query.get("poster") +
                      "&eventname=" +
                      query.get("eventname") +
                      "&eventday=" +
                      query.get("eventday") +
                      "&starttime=" +
                      query.get("starttime") +
                      "&endtime=" +
                      query.get("endtime") +
                      "&previousPage=" +
                      previousPage +
                      "&campaignStatus=" +
                      campaignStatus +
                      "&category=" +
                      eventtype +
                      "&province=" +
                      province,
                    state: {
                      fromHome: true,
                      eventid: query.get("eventid"),
                      eventDate: query.get("eventDate"),
                      // fulldate: query.get("fulldate"),
                      customerid: query.get("customerid"),
                      posterUri: query.get("posterUri"),
                      accountid: query.get("accountid"),
                      accounttype: query.get("accounttype"),
                      customername: query.get("customername"),
                      email: query.get("email"),
                      phone: query.get("phone"),
                      venue: query.get("venue"),
                      description: query.get("description"),
                      businessid: query.get("businessid"),
                      poster: query.get("poster"),
                      eventname: query.get("eventname"),
                      eventday: query.get("eventday"),
                      starttime: query.get("starttime"),
                      endtime: query.get("endtime"),
                      previousPage: previousPage,
                      campaignStatus: campaignStatus,
                      category: eventtype,
                      province: province,
                    },
                  }}
                  className="backArrow"
                  data-tip="Back"
                  data-effect="solid"
                  data-arrow-color="transparent"
                  data-type="dark"
                  data-place="top"
                >
                  <ReactTooltip />
                  <IoIosArrowBack size={40} className="Back-arrow-blue" />
                </Link>
              </div>
              <div className="" style={{ textAlign: "center" }}>
                {eventname}
                <br />
                <span style={{ fontSize: 13, color: "grey", fontWeight: 400 }}>
                  {eventday} @ {starttime}
                </span>
                {localStorage.getItem("customername") === null ? (
                  <span>
                    <br />
                    <label>
                      <Link to="/login">Login</Link>{" "}
                      <span style={{ textTransform: "lowercase" }}>or</span>{" "}
                      <Link to="/register">Register</Link>{" "}
                      <span style={{ textTransform: "lowercase" }}>
                        to purchase tickets
                      </span>
                    </label>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="blanket"></div>
        </div>
      </form>
    );
  }
}

export default Finalize;
