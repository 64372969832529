import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Axios from "axios";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class Business extends React.Component {
  state = {
    business: "",
    email: "",
    phone: "",
    pass1: "",
    pass2: "",
    response: "",
    error: "",
    errorDisplay: "none",
    spinning: "none",
    updateDisplay: "block",
  };

  componentDidMount() {
    document.title = "Business registration";
  }

  handleBusiness = (event) => {
    this.setState({
      business: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePhone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword = (event) => {
    this.setState({
      pass1: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword2 = (event) => {
    this.setState({
      pass2: event.target.value,
      errorDisplay: "none",
    });
  };

  onSubmitForm = async () => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 4000);
    this.setState({ updateDisplay: "none", spinning: "block" });
    const business = this.state.business;
    const email = this.state.email;
    const phone = this.state.phone;
    const pass1 = this.state.pass1;
    const pass2 = this.state.pass2;

    if (business.trim() === "") {
      this.setState({
        error: "Enter the name of your organisation",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (email.trim() === "") {
      this.setState({
        error: "Enter your email address",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (!validator.isEmail(email)) {
      this.setState({
        error: "Your email address is invalid.",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (phone.trim() === "") {
      this.setState({
        error: "Enter your phone number",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (
      isPossiblePhoneNumber(phone.replace(" ", "")) === false ||
      isValidPhoneNumber(phone.replace(" ", "")) === false
    ) {
      this.setState({
        error: "Your phone number is invalid",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1.length < 4) {
      this.setState({
        error: "Passcode must at least have 4 digits",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else if (pass1 !== pass2) {
      this.setState({
        error: "Make sure both your passcodes match",
        errorDisplay: "block",
        spinning: "none",
        updateDisplay: "block",
      });
    } else {
      const phon = phone.replace(" ", "");
      let phoneNumber = phon.substring(3, 15);
      const digit = phoneNumber.substring(0, 1);
      if (digit == 0) {
        phoneNumber = phoneNumber;
      } else {
        phoneNumber = "0" + phoneNumber;
      }
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=VERIFYACCOUNT&business=" +
          business +
          "&phone=" +
          phoneNumber +
          "&email=" +
          email +
          "&password=" +
          pass1 +
          "&accounttype=business&platform=web"
      )
        .then((response) => {
          if (response.data.val == 1) {
            localStorage.setItem("verificationAccount", "BUSINESS");
            this.props.history.push({
              pathname: "/verification",
              state: {
                business: this.state.business,
                email: this.state.email,
                accounttype: "business",
                password: this.state.pass1,
                phone: this.state.phone,
              },
            });
          } else {
            this.setState({
              error:
                "The email address or phone number you provided is already registered",
              errorDisplay: "block",
              spinning: "none",
              updateDisplay: "block",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  render() {
    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center login-screen-container w-100">
            <div className="col-lg-5 col-12 align-self-center login-container-adjust pt-5">
              <div className="log-title text-center pt-0">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" width="170" />
                </Link>
              </div>
              <div className=" BoxDashboardContainer">
                <div className="col-12 text-left p-0 mb-3">
                  <span>Become an event organizer</span>
                </div>
                <input
                  required
                  type="text"
                  name="business"
                  placeholder="Organisation name"
                  value={this.state.business}
                  onChange={this.handleBusiness}
                />
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleEmail}
                />

                <PhoneInput
                  // autoFormat={false}
                  disableDropdown={true}
                  enableSearch={true}
                  showDropdown={false}
                  country={"za"}
                  value={this.state.phone}
                  onChange={(phone) => this.setState({ phone: "+" + phone })}
                  inputStyle={{
                    width: "100%",
                    background: "#f0f2f5",
                    height: "2.7em",
                  }}
                  containerStyle={{ marginBottom: 15 }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                />

                {/* <input
                  required
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  value={this.state.phone}
                  onChange={this.handlePhone}
                /> */}
                <input
                  required
                  type="password"
                  placeholder="Passcode"
                  value={this.state.pass1}
                  onChange={this.handlePassword}
                />

                <input
                  required
                  type="password"
                  placeholder="Retype passcode"
                  value={this.state.pass2}
                  onChange={this.handlePassword2}
                />

                <div className="col-12 response textMedium">
                  {this.state.response}
                </div>
                <div className="">
                  <label className="text-12">
                    <strong>Note:</strong> By signing up you agree to the&nbsp;
                    <Link to="/terms_and_conditions.pdf" target={"_blank"}>
                      Terms
                    </Link>
                    &nbsp; and{" "}
                    <Link to="/terms_and_conditions.pdf" target={"_blank"}>
                      Conditions
                    </Link>
                  </label>
                </div>
                <button onClick={() => this.onSubmitForm()}>
                  <span
                    style={{
                      display: this.state.updateDisplay,
                    }}
                  >
                    Sign up
                  </span>
                  <span
                    style={{
                      display: this.state.spinning,
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      // css={override}
                      size={20}
                    />
                  </span>
                </button>
                <Link to="/login">
                  <div className="forgotPasswordText text-center">Log In</div>
                </Link>
              </div>

              <div className="text-footer text-center pb-4">
                Your <span>audience</span> is waiting... Start{" "}
                <span>selling tickets</span> today!
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Business;
