import React from "react";
import "./App.css";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosPodium,
} from "react-icons/io";

// export default withRouter (Redirect);

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "About us - Karamale";
  }

  render() {
    if (localStorage.getItem("customername") !== null) {
      return <Redirect to="/customerdashboard" />;
      //   return exit(1);
    } else {
    }
    return (
      <div className="view">
        <Header />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="col-lg-12 col-10 col-sm-11">
                    <span className="">
                      About us: Who are we and what do we do?
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-selet">
                    <div className="col-12">
                      <label className="item-names">
                        <b>About us</b>
                        <br />
                        Karamale is a ticket company, an entity that provides
                        ticket services to entertainment gatherings of any kind.
                        Karamale came into the scene at the beginning of 2019
                        with a hope of reinventing the ticketing space.
                        <br />
                        <br />
                        <b>Vision</b>
                        <br />
                        Our vision is to become Africa's number choice when it
                        comes to ticketing services.
                        <br />
                        <br />
                        <b>Mission</b>
                        <br />
                        Add value to the entertainment space by engaging with
                        the captains of the industry to try and understand how
                        Karamale can best provide a better experience in the
                        ticketing line.
                        <br />
                        <br />
                        <b>Tickets</b>
                        <br />
                        At the moment, Karamale does provide for both physical
                        or electronic tickets, however, physical tickets will be
                        done away with as we are moving to fully electronic
                        ticketing.. <br />
                        Karamale got you...
                        <ol>
                          <li>
                            <strong>Physical:</strong> These are tickets that
                            Karamale prints for you to sell to your customers
                          </li>
                          <li>
                            <strong>Electronic:</strong> The term speaks for
                            itself. Electronic tickets are issued via sms and
                            email, but for convenience, the buyer can download
                            the Karamale App and view their tickets.
                          </li>
                        </ol>
                        <b>NB:</b> All tickets are generated with a unique{" "}
                        <b>ticket number</b> which is embedded in a qr code,
                        this is to detect duplicates.
                        <br />
                        <br />
                        <b>Connect</b>
                        <br />
                        All tickets are connected to Karamale servers, our
                        online services monitors all tickets and scanning. Our
                        mobile scanning app gives you the power to easily scan
                        tickets and detect the counterfeit tickets.
                        <br />
                        <br />
                        <b>Promoters/Boosters</b>
                        <br />
                        As an organizer, you have the ability to create accounts
                        for your promoters, there's no limit to how many
                        accounts you can create. Each of your promoters will
                        have unique login credentials, this is to allow them to
                        sell tickets on your behalf, and every transaction made
                        on the behalf of the organizer by the promoter will be
                        recorded for the organizer to see.
                        <br />
                        <br />
                        As an organizer, you'll be able to monitor your
                        promoters' sales performance and revenue by just using
                        the App or website.
                        <br />
                        <br />
                        <b>Scanning</b>
                        <br />
                        It is all in the Karamale App, use the app to scan
                        tickets. The security behind the app makes it impossible
                        for those with fake tickets to gain access. Fake tickets
                        will be detected, communicated to the person scanning
                        and access will be denied by the scanner.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default About;
