import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "./components/Header";

// export default withRouter (Redirect);

class AccountDeletion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      reason: "",
      errorMsg: "d-none",
      successMsg: "d-none",
      form_1: "d-block",
      form_2: "d-none",
      message: "",
      disabled: false,
      code: "",
    };
  }

  componentDidMount() {
    document.title = "Account Deletion - Karamale";
  }

  render() {
    if (localStorage.getItem("customername") !== null) {
      return <Redirect to="/customerdashboard" />;
      //   return exit(1);
    } else {
    }

    const handleCode = (event) => {
      let value = event.target.value;
      this.setState({ code: value });
    };

    const handleEmail = (event) => {
      let value = event.target.value;
      this.setState({ email: value });
    };

    const handlePassword = (event) => {
      let value = event.target.value;
      this.setState({ password: value });
    };

    const handleReason = (event) => {
      let value = event.target.value;
      this.setState({ reason: value });
    };

    const submitRequest = (event) => {
      event.preventDefault();
      this.setState({
        disabled: true,
      });

      const formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("reason", this.state.reason);
      formData.append("password", this.state.password);
      formData.append("query", "REQUEST_ACCOUNT_DELETION");
      console.log(this.state.email);

      axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log(response.data.code + "fdfdfdf");
          if (response.data.code == 401) {
            console.log(response.data.status + " submitted...");
            if (response.data.status == "complete") {
              this.setState({
                disabled: false,
                // form_2: "d-block",
                // form_1: "d-none",
                errorMsg: "d-block",
                message: response.data.message,
              });
              console.log(response.data);
              console.log("submitted...");
            } else {
              this.setState({
                form_2: "d-none",
                form_1: "d-block",
                message: response.data.message,
                errorMsg: "d-block",
                disabled: false,
              });
            }

            // console.log(response.data.code);
          } else {
            this.setState({
              disabled: false,
              form_2: "d-block",
              form_1: "d-none",
              errorMsg: "d-none",
            });
            console.log(response.data);
          }
        })
        .catch(function (response) {
          this.setState({
            errorMsg: "d-block",
            form_1: "d-block",
            message: "Something went wrong, please try again.",
            disabled: false,
          });
          console.log(response);
        });
    };

    const submitCode = (event) => {
      event.preventDefault();
      this.setState({
        disabled: true,
      });
      const formData = new FormData();

      formData.append("code", this.state.code);
      formData.append("email", this.state.email);
      formData.append("query", "REQUEST_ACCOUNT_DELETION");
      formData.append("subQuery", "CONFIRM_CODE");
      axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.code == 401) {
            this.setState({
              //   form_2: "d-none",
              //   form_1: "d-block",
              message: response.data.message,
              errorMsg: "d-block",
              disabled: false,
            });

            console.log(response.data.code);
          } else {
            this.setState({
              //   form_2: "d-block",
              //   form_1: "d-none",
              successMsg: "d-block",
              errorMsg: "d-none",
              message: response.data.message,
            });
            console.log(response.data);
          }
        })
        .catch(function (response) {
          this.setState({
            errorMsg: "d-block",
            successMsg: "d-none",
            // form_1: "d-block",
            message: "Something went wrong, please try again.",
            disabled: false,
          });
          console.log(response);
        });
    };
    return (
      <div className="view">
        <Header />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-10 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-start">
                  <div className="col-12">
                    <span className="">
                      <h3>Request for account deletion.</h3>
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>
                  <div className={this.state.form_1 + " col-5"}>
                    <form onSubmit={submitRequest}>
                      <label htmlFor="email" className="text-bold">
                        Enter your email address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-100 mb-3"
                        onChange={handleEmail}
                        required
                        //   value={this.state.email}
                      />
                      <br />
                      <label htmlFor="password" className="text-bold">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="w-100 mb-3"
                        onChange={handlePassword}
                        required
                      />
                      <br />
                      <label htmlFor="reason" className="text-bold">
                        Reason
                      </label>
                      <textarea
                        id="reason"
                        placeholder="State your reason"
                        onChange={handleReason}
                        required
                      />
                      <br />

                      <div
                        className={
                          this.state.errorMsg + " alert alert-warning p-2"
                        }
                      >
                        {this.state.message}
                      </div>
                      <button
                        className="btn btn-dark text-bold"
                        disabled={this.state.disabled}
                      >
                        Submit request
                      </button>
                    </form>
                  </div>
                  <div className={this.state.form_2 + " col-5"}>
                    <form onSubmit={submitCode}>
                      <label
                        htmlFor="code"
                        className="text-bold"
                        style={{ lineHeight: 1 }}
                      >
                        Enter code
                        <br />
                        <span style={{ fontSize: 12, color: "grey" }}>
                          Code sent to {this.state.email}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="code"
                        className="w-100 mb-3"
                        onChange={handleCode}
                        required
                        //   value={this.state.email}
                      />

                      <br />

                      <div
                        className={
                          this.state.successMsg + " alert alert-success p-2"
                        }
                      >
                        {this.state.message}
                      </div>

                      <div
                        className={
                          this.state.errorMsg + " alert alert-warning p-2"
                        }
                      >
                        {this.state.message}
                      </div>
                      <button
                        className="btn btn-dark text-bold"
                        disabled={this.state.disabled}
                      >
                        Complete request
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default AccountDeletion;
