import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";

class National extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
    };
  }

  componentDidMount() {
    document.title = "Karamale - National ranking ";
    Axios.get("/api/select/ranking/national").then(
      (res) => {
        const products = res.data;
        this.setState({ products });
      }
    );
  }

  render() {
    const { error, products } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="row mt-4">
          {products.map(
            (ranks) =>
              !!ranks.poster && (
                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div
                    className="nainer define-ranking-box"
                    style={{
                      backgroundImage:
                        "url(http://localhost/karamalenew/src/posters/" +
                        ranks.businessid +
                        "/" +
                        ranks.poster +
                        ")",
                    }}
                  >
                    <div className="rank">{ranks.quantity}</div>
                    <div className="row h-100">
                      <div className="col-12 align-self-end label-shadow-ranking">
                        <div className="row h-100">
                          <div className="col-12 pl-3 pr-3 align-self-center textLight case-sentence">
                            {ranks.event_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      );
    }
  }
}

export default National;
