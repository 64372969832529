import React from "react";
import Axios from "axios";
import { FcCheckmark } from "react-icons/fc";
import {
  IoIosCheckmarkCircleOutline,
  IoIosArrowRoundForward,
} from "react-icons/io";
import ReactAudioPlayer from "react-audio-player";
import { Link, NavLink } from "react-router-dom";

class Campaignsuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
    };
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    const campaignname = query.get("campaignname");
    const keywords = query.get("keywords");
    const startdate = query.get("startdate");
    const eventid = query.get("eventid");
    const businessid = query.get("businessid");
    const enddate = query.get("enddate");
    const budget = query.get("budget");

    const apiUrl =
      "https://karamale.com/apps/kscanner/webservices.php?query=ADDCAMPAIGN&campaign=" +
      campaignname +
      "&keywords=" +
      keywords +
      "&startdate=" +
      startdate +
      "&eventid=" +
      eventid +
      "&businessid=" +
      businessid +
      "&enddate=" +
      enddate +
      "&budget=" +
      budget;
    Axios.get(apiUrl).then((res) => {
      const products = res.data;
      this.setState({ products, url: apiUrl });
    });
  }

  render() {
    window.onbeforeunload = function () {
      return false;
    };
    function my_onkeydown_handler(event) {
      switch (event.keyCode) {
        case 116: // 'F5'
          event.preventDefault();
          break;

        case 17: // 'Ctrl'
          event.preventDefault();
          break;

        case 82: // 'R'
          event.preventDefault();
          break;
      }
    }
    document.addEventListener("keydown", my_onkeydown_handler);

    const query = new URLSearchParams(this.props.location.search);
    const eventid = query.get("eventid");
    const eventname = query.get("eventname");
    const poster = query.get("poster");
    const event_date = query.get("event_date");
    const finish_date = query.get("finish_date");
    const theme = query.get("theme");
    const venue = query.get("venue");
    const expectednum = query.get("expectednum");
    const time = query.get("time");
    const endtime = query.get("endtime");
    const prevpage = query.get("prevpage");
    const currentpage = query.get("currentpage");
    const eventdate = query.get("eventdate");
    const eventenddate = query.get("eventenddate");

    const businessid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    return (
      <div className="row background-img">
        <div className="w-100 pl-5 pr-5 shield">
          <div className="success-response">
            <div className="row h-100 justify-content-center">
              <div className="align-self-center text-center adjust-margin">
                <div className="success-message mb-3">
                  <IoIosCheckmarkCircleOutline size={40} /> <br />
                  Successful!
                </div>
                <div className="sub-text-success-message">
                  <div style={{ display: "none" }}>
                    <ReactAudioPlayer
                      src="https://karamale.com/apps/kscanner/success.mp3"
                      autoPlay={true}
                      controls
                    />
                  </div>
                  <br />
                  <Link
                    to={{
                      pathname: "/editevent",
                      state: {
                        eventid: eventid,
                        eventname: eventname,
                        poster: poster,
                        eventdate: eventdate,
                        enddate: eventenddate,
                        event_date: event_date,
                        finish_date: finish_date,
                        theme: theme,
                        venue: venue,
                        expectednum: expectednum,
                        time: time,
                        endtime: endtime,
                        prevpage: prevpage,
                        currentpage: currentpage,
                      },
                    }}
                    className="my-tickets-btn"
                  >
                    Back to the event <IoIosArrowRoundForward size={25} />
                  </Link>

                  <Link
                    to={{
                      pathname: "/dashboard",
                      state: {
                        accounttype: accounttype,
                        accountid: businessid,
                        businessname: businessname,
                      },
                    }}
                    className="save-btn ml-4 pl-3 pr-3"
                    style={{
                      borderRadius: 5,
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                  >
                    Go to Dashboard <IoIosArrowRoundForward size={25} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Campaignsuccess;
