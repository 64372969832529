import React, { useState, useEffect } from "react";
import "./App.css";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Header3 from "./components/Header3";
import ClipLoader from "react-spinners/ClipLoader";

function Smsmarketingsuccessful(props) {
  useEffect(() => {
    const textid = localStorage.getItem("textid");
    const businessid = localStorage.getItem("businessid");
    const audience = localStorage.getItem("audience");

    document.title = "Marketing";

    axios
      .get(
        "https://karamale.com/apps/kscanner/webservices.php?query=GETTEXTMESSAGE&textid=" +
          textid +
          "&businessid=" +
          businessid
      )
      .then((response) => {
        setText(response.data.text);
        setSpinner(false);
      });

    const data = new FormData();
    data.append("audience", audience);
    data.append("businessid", businessid);
    data.append("textid", textid);
    data.append("alias", localStorage.getItem("alias"));
    data.append("query", "PROCESSSMSMARKETING");
    axios({
      url: "https://karamale.com/apps/kscanner/webservices.php",
      method: "POST",
      data,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      setStatusProgress(
        <label className="w-100">
          {" "}
          <label className="color-green font-size-15 w-100 text-center">
            <IoIosCheckmarkCircleOutline size={25} color={"#329932"} />
            <br />
            Successful!
          </label>
          <label className="w-100 mb-0 text-center">
            <Link to="/dashboard">
              <label className="color-blue text-center">
                Return to the dashboard
              </label>
            </Link>
          </label>
        </label>
      );
    });
  });

  const [text, setText] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [statusProgress, setStatusProgress] = useState(
    <label className="color-green font-size-15 w-100 text-center">
      <ClipLoader size={20} color={"#329932"} />
      <br />
      Processing...
    </label>
  );

  if (localStorage.getItem("businessid") === null) {
    return <Redirect to="/login" />;
  } else {
    if (localStorage.getItem("textid") === null) {
      return <Redirect to="/sms" />;
    }
  }

  return (
    <div className="view">
      <Header3 />

      <div className="container">
        <div className="row justify-content-center just-container-insid">
          <div className="col-lg-7 mt-5 pl-0 pr-0">
            <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
              <div className="row align-self-center justify-content-center">
                <div className="item-top-header">
                  <div className="col-lg-12 col-10 col-sm-11">
                    <label className=" pt-2 color-green w-100">
                      {statusProgress}
                    </label>
                  </div>
                </div>

                <div className="h-divider w-100 mt-0"></div>
                <div className="col-12 text-left p-3 pl-4">
                  <span>Text message</span>
                </div>
                <div className={"item-select-active"}>
                  <div className="link-btn w-100 menu-links">
                    <div className="col-12">
                      <label className="item-names">
                        {text}
                        {spinner && <ClipLoader size={20} color={"grey"} />}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Smsmarketingsuccessful;
