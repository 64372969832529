import React from "react";
import Axios from "axios";
import { FcCheckmark } from "react-icons/fc";

class Success extends React.Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get("email");
    const ticketnumber = query.get("ticketnumber");
    const ticketid = query.get("ticketid");
    const eventid = query.get("eventid");
    const businessid = query.get("businessID");
    const apiUrl =
      "/api/update/paid/table?email=" +
      email +
      "&ticketnumber=" +
      ticketnumber +
      "&ticketid=" +
      ticketid +
      "&eventid=" +
      eventid +
      "&businessid=" +
      businessid;

    Axios.get(apiUrl).then((res) => {
      const products = res.data;
      this.setState({ products });
    });

    const emailApiUrl =
      "/sendEmailAttachment?businessid=" +
      businessid +
      "&ticketnumber=" +
      ticketnumber +
      "&email=" +
      email +
      "&ticketid=" +
      ticketid +
      "&eventid=" +
      eventid;
    Axios.get(emailApiUrl).then((res) => {
      const feedback = res.data;
    });
  }
  render() {
    return (
      <div className="row background-img">
        <div className="w-100 pl-5 pr-5 shield">
          <div className="success-response">
            <div className="row h-100 justify-content-center">
              <div className="align-self-center text-center adjust-margin">
                <div className="success-message">
                  <FcCheckmark /> <br />
                  Success
                </div>
                <div className="sub-text-success-message">
                  <strong>Karamale</strong> is processing your ticket and will
                  be issued to you soon as it is approved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Success;
