import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import Header from "./components/Header";
import TopBarProgress from "react-topbar-progress-indicator";
import ClipLoader from "react-spinners/ClipLoader";
class Promoter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      query: "",
      loadBarProgress: true,
    };
  }

  componentDidMount() {
    document.title = "Log in as Booster";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MERCHANTS&subquery=GETMERCHANTS"
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  searchQuery = (text) => {
    let value = text;
    // this.setState({ query: value });
    this.setState({ query: text.target.value });

    let query = text.target.value.split(" ").join("+");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MERCHANTS&subquery=SEARCHMERCHANTS&search=" +
        query
    )
      .then((response) => {
        // alert(response.data.val);
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data });
        }
      })
      .catch((error) => console.log(error));
  };

  details = () => {
    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              <ClipLoader
                color={"red"}
                loading={true}
                // css={override}
                size={20}
              />
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-1 pl-1 pb-2 pt-3">
                  <Link
                    to={{
                      pathname: "/boosterlogin",
                      state: {
                        email: item.email,
                        phone: item.cell_number,
                        businessid: item.id,
                        businessname: item.business_name.toLowerCase(),
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-12" style={{ cursor: "pointer" }}>
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            cursor: "pointer",
                          }}
                          className="small-details"
                        >
                          {item.business_name}
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          ></label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    const { loadBarProgress, loadProvinces, loadCategories } = this.state;
    return (
      <div className="view">
        <Header />
        {loadBarProgress && <TopBarProgress />}
        <div className="row align-self-center justify-content-center">
          <div className="col-lg-5">
            <div className=" BoxDashboardContainer w-100 col-12 mt-5 mb-4 mrgin-search">
              <div className="row align-self-center justify-content-center">
                <div className="item-list p-0">
                  <div className="col-lg-5 col-4 pl-0 pr-0">
                    <Link to="/" className="link-btn">
                      <label className="back-arrow pl-0 pr-0">
                        <IoIosArrowBack size={40} />
                        Back
                      </label>
                    </Link>
                  </div>

                  <div className="col-lg-7 col-8 pl-0 pr-0">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={this.state.query}
                      onChange={this.searchQuery}
                      className="search-ticket-input"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=" BoxDashboardContainer mb-5">
              <div className="row align-self-center justify-content-center">
                <div className="item-list p-0">
                  <div className="col-lg-12 pl-0 pr-0">
                    <label className="ticket-title pl-0 pr-0">
                      SELECT YOUR ORGANIZATION
                    </label>
                  </div>
                </div>

                <div className="h-divider w-100"></div>
              </div>
              {this.details()}
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Promoter;
