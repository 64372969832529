import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import { BiCalendarEvent } from "react-icons/bi";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosPodium,
  IoIosQrScanner,
} from "react-icons/io";
import {
  IoTicketOutline,
  IoCalendarOutline,
  IoCalendarClearOutline,
} from "react-icons/io5";
import { RiCalendarEventLine } from "react-icons/ri";
import {
  BsLock,
  BsHouseDoor,
  BsArrowLeftShort,
  BsPlusCircle,
  BsPerson,
  BsBarChart,
  BsInfoCircle,
  BsList,
  BsSearch,
  BsPeople,
  BsCalendar,
  BsAppIndicator,
  BsBoundingBoxCircles,
  BsPen,
  BsHeart,
} from "react-icons/bs";
import Header2 from "./components/Header2";

class Customerdashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      tickets: [],
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    document.title = customername + " - Customer dashboard";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERTICKETS&subquery=GETTICKETS&customerid=" +
        customerid +
        "&customerdashboard=yes"
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ tickets: [] });
        } else {
          this.setState({ tickets: response.data });
        }
      })
      .catch((error) => console.log(error));
  }

  tickets = () => {
    let activeString = "";
    const customerid = localStorage.getItem("customerid");
    const customername = localStorage.getItem("customername");
    let event = "";

    return (
      <div>
        {this.state.tickets.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.tickets.map((item, index) => {
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            {
              item.eventname.length > 23
                ? (event = item.eventname.substring(0, 23) + "...")
                : (event = item.eventname);
            }

            let poster =
              "https://karamale.com/apps/kscanner/posters/" +
              item.businessid +
              "/" +
              item.eventid +
              "/" +
              item.thumbnail;

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100">
                  <Link
                    to={{
                      pathname: "/previewtickets",
                      state: {
                        customername: customername,
                        customerid: customerid,
                        eventid: item.eventid,
                        transactiondate: item.transactiondate,
                        venue: item.venue,
                        eventname: item.eventname,
                        eventtime: item.eventtime,
                        eventday: item.eventday,
                        yearcreated: item.yearcreated,
                        theme: item.theme,
                        admissions: item.admissions,
                        totalcost: item.totalcost,
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      {/* <div className="col-lg-3 col-2 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      </div> */}
                      <div
                        className="col-lg-12 col-12 col-sm-12"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                            marginRight: 10,
                          }}
                          className="float-left"
                        />
                        <label
                          className="item-names"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {event} <br />
                          <label
                            style={{
                              color: "grey",
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            {item.eventday}
                          </label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}

        {this.state.tickets.length < 3 ? (
          ""
        ) : (
          <div className="item-select w-100">
            <Link
              to={{
                pathname: "/tickets",
              }}
              className="w-100"
            >
              <div className="row" style={{ display: "flex" }}>
                {/* <div className="col-lg-3 col-2 col-sm-2 text-center">
                  <div
                    style={{
                      backgroundImage: `url(https://karamale.com/apps/kscanner/placeholder.png)`,
                      width: 55,
                      height: 55,
                      borderRadius: 100,
                      backgroundSize: "100%",
                    }}
                  ></div>
                </div> */}
                <div
                  className="col-lg-12 col-12 col-sm-12"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      backgroundImage: `url(https://karamale.com/apps/kscanner/placeholder.png)`,
                      width: 55,
                      height: 55,
                      borderRadius: 100,
                      backgroundSize: "100%",
                      marginRight: 10,
                    }}
                    className="float-left"
                  />
                  <label
                    className="item-names"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "#333",
                    }}
                  >
                    More... <br />
                    <label
                      style={{
                        color: "grey",
                        fontWeight: "normal",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      See all available tickets
                    </label>
                  </label>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  };

  searchQuery = (text) => {
    let value = text.target.value;
    this.setState({ query: value });
    let query = value.split(" ").join("+");
    this.setState({ isLoading: true, loadingMerch: false });
    const customerid = localStorage.getItem("customerid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERTICKETS&subquery=SEARCHTICKETS&search=" +
        query +
        "&customerid=" +
        customerid +
        "&customerdashboard=yes"
    )
      .then((response) => {
        // alert(response.data.val);
        if (response.data.val == 0) {
          this.setState({
            tickets: [],
          });
        } else {
          this.setState({
            tickets: response.data,
          });
        }
        // alert(response.data.search);
      })
      .catch((error) => console.log(error));
  };

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    const accounttype = localStorage.getItem("accounttype");
    return (
      <div className="view">
        <Header2 />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5 pl-2 pr-2">
              <div className=" BoxDashboardContainer mb-5 pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-user">
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <IoIosContact size={30} className="profile-icon" />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-11">
                      <label className="custername-profile">
                        {customername.toUpperCase()}
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-select">
                    <Link to="/" className="link-btn w-100 menu-links">
                      <div className="col-lg-1 col-2 col-sm-1">
                        <IoCalendarOutline size={30} color={"#ff6666"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Events</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link to="/tickets" className="link-btn w-100 menu-links">
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoTicketOutline size={30} color={"#e122ad"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Tickets</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to="/transactions"
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoCalendarClearOutline size={30} color={"#87ceeb"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">My events</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link to="/likes" className="link-btn w-100 menu-links">
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsHeart size={30} color={"#ffa500"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Likes</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "profile",
                        state: {
                          customerid: customerid,
                          customername: customername,
                          accountid: customerid,
                          accounttype: accounttype,
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPerson size={30} color={"#3399cc"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Profile</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "changepassword",
                        state: {
                          credentials: "pincode",
                        },
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsLock size={30} color={"#006699"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Change pincode</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "feedback",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPen size={30} color={"#ffc62b"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">
                          Send us feedback and more...
                        </label>
                      </div>
                    </Link>
                  </div>

                  {/* <div className="item-select">
                    <Link
                      to={{
                        pathname: "report",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosPodium size={30} color={"#66b266"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Send report</label>
                      </div>
                    </Link>
                  </div> */}

                  <div className="item-select mb-2">
                    <Link
                      to={{
                        pathname: "logout",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosLogOut size={30} color={"red"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-10">
                        <label className="item-names">Log out</label>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 tickets-assigned">
              <div className=" BoxDashboardContainer mt-5 mb-4 pb-3 pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12">
                      <input
                        type="text"
                        placeholder="Search tickets by event name"
                        value={this.state.query}
                        onChange={this.searchQuery}
                        className="search-ticket-input"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        RECENT TICKETS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.tickets()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Customerdashboard;
