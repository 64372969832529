import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { IoIosArrowBack, IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAdd } from "react-icons/io";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Header from "./components/Header";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import TopBarProgress from "react-topbar-progress-indicator";

class Modelprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      eventtype: "",
      name: "",
      age: null,
      addPoster: false,
      vote: false,
      hideUploadBtn: "none",
      spinning: "none",
      updateDisplay: "block",
      activeBox: "box-upload",
      image: null,
      path: "",
      type: "image/jpeg",
      preimage: null,
      height: null,
      width: null,
      uploadsymbol: true,
      gender: "",
      bio: "",
      modelid: localStorage.getItem("modelid"),
      photo: null,
      dtcreated: null,
      loadBarProgress: false,
      dataFound: 0,
      getPoints: [],
    };
  }

  componentDidMount() {
    const modelid = this.state.modelid;

    let businessid,
      eventid = null;

    if (localStorage.getItem("org_uid") && localStorage.getItem("e_uid")) {
      businessid = localStorage.getItem("org_uid");
      eventid = localStorage.getItem("e_uid");
    } else {
      businessid = localStorage.getItem("businessid");
      eventid = localStorage.getItem("eventid");
    }

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETPOINTS&eventid=" +
        eventid +
        "&businessid=" +
        businessid
    ).then((response) => {
      // alert(response.data.val);
      if (response.data.val == 0) {
        this.setState({ getPoints: [] });
      } else {
        this.setState({ getPoints: response.data });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MODELDETAILS&modelid=" +
        modelid
    ).then((response) => {
      //   alert(response.data.val);
      if (response.data.val == 1) {
        this.setState({
          photo: response.data.photo,
          name: response.data.model,
          age: response.data.age,
          gender: response.data.gender,
          bio: response.data.bio,
          dtcreated: response.data.dtcreated,
          dataFound: 1,
        });
      }
    });
  }

  pointListing = () => {
    return (
      <div>
        {this.state.getPoints.length ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.getPoints.map((item, index) => {
            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-3">
                  <div onClick={() => this.models(item.id)} className="w-100">
                    <div
                      className="row justify-content-center align-items-center"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="col-lg-12 col-9 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            cursor: "pointer",
                            fontSize: 17,
                          }}
                        >
                          <span>{item.amount}</span>
                          <br />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  bio = (event) => {
    this.setState({
      bio: event.target.value,
      errorDisplay: "none",
    });
  };

  selectImage = (event) => {
    const img = event.target.files[0];

    this.setState({
      image: URL.createObjectURL(event.target.files[0]),
      preimage: URL.createObjectURL(event.target.files[0]),
      imgname: img,
      type: img.type,
      path: URL.createObjectURL(event.target.files[0]),
      activeBox: "box-upload-dis",
      hideUploadBtn: "block",
      height: 300,
      width: 400,
      uploadsymbol: false,
    });
  };

  uploadPicture = async () => {
    const eventid = localStorage.getItem("eventid");
    const accountid = localStorage.getItem("businessid");
    const type = this.state.type;
    const path = this.state.path;
    const fileName = this.state.imgname;
    const fname = this.state.name;
    const age = this.state.age;
    const gender = this.state.gender;
    const bio = this.state.bio;
    let data = new FormData();

    this.setState({
      updateDisplay: "none",
      spinning: "block",
    });

    // alert(path);

    // data.append("file", { type: type, uri: path, name: path.split("/").pop() });
    data.append("file", this.state.imgname);
    data.append("eventid", eventid);
    data.append("businessid", accountid);
    data.append("fname", fname);
    data.append("age", age);
    data.append("gender", gender);
    data.append("bio", bio);
    data.append("modelid", localStorage.getItem("modelid"));
    data.append("query", "UPLOADMODELPICTURE");

    if (this.state.image === null) {
      this.setState({
        error: "Please select a picture",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (fname === "") {
      this.setState({
        error: "Enter the model's name",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (age === "") {
      this.setState({
        error: "Enter the model's age",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (age < 18) {
      this.setState({
        error: "Age entered is invalid",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (gender === "") {
      this.setState({
        error: "Enter the model's gender",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (bio === "") {
      this.setState({
        error: "Write a small bio about this model",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      this.setState({ loadBarProgress: true });
      await Axios({
        url: "https://karamale.com/apps/kscanner/webservices.php",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          // alert(response.data.filename);
          this.setState({
            error: "Successful!",
            errorDisplay: "block",
            successError: "success-login",
            updateDisplay: "block",
            spinning: "none",
            addPoster: false,
            checkposter: "yes",
            loadBarProgress: false,
            addPoster: false,
          });
        })
        .catch((error) => {
          console.log("image upload error: ", error);
        });

      setTimeout(() => {
        this.setState({ errorDisplay: "none" });
      }, 4000);

      this.props.history.push({
        pathname: "/models",
      });
    }
  };

  handleChange = (e) => {
    this.setState({ gender: e.value, errorDisplay: "none" });
    // alert(e.value);
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
      errorDisplay: "none",
    });
  };

  handleAge = (event) => {
    this.setState({
      age: event.target.value,
      errorDisplay: "none",
    });
  };

  addPoster = () => {
    this.setState({ addPoster: true, errorDisplay: "none" });
  };

  closeModal = () => {
    this.setState({
      addPoster: false,
      vote: false,
    });
  };

  models = (modelid) => {
    if (localStorage.getItem("businessid")) {
      localStorage.setItem("modelid", modelid);
      this.props.history.push({ pathname: "/addmodels" });
    } else {
      //   alert("modelid");
    }
  };

  vote = (modelid) => {
    this.setState({ vote: true, errorDisplay: "none" });
  };

  render() {
    const { gender, uploadsymbol, loadBarProgress } = this.state;
    let businessid,
      eventid = null;
    if (localStorage.getItem("org_uid") && localStorage.getItem("e_uid")) {
      businessid = localStorage.getItem("org_uid");
      eventid = localStorage.getItem("e_uid");
    } else {
      businessid = localStorage.getItem("businessid");
      eventid = localStorage.getItem("eventid");
    }
    document.title = this.state.name;
    let { photo } = "";
    photo =
      "https://karamale.com/apps/kscanner/models/" +
      businessid +
      "/" +
      eventid +
      "/" +
      this.state.photo;
    const data = [
      { label: "Female", value: "female" },
      { label: "Male", value: "male" },
    ];

    let { accountname, dashboard } = "";
    if (
      localStorage.getItem("businessid") !== null ||
      localStorage.getItem("org_uid") !== null
    ) {
    } else {
      return <Redirect to="/login" />;
    }
    const customername = accountname;
    return (
      <div className="view">
        {localStorage.getItem("businessname") === null ? (
          <Header />
        ) : (
          <Header3 />
        )}
        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <Modal show={this.state.vote} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row w-100 mb-4 justify-content-end align-self-center">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div
                      className="save-btn"
                      style={{ display: this.state.hideUploadBtn }}
                      onClick={this.uploadPicture.bind(this)}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {this.state.dataFound == 0 ? "Add" : "Update"}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>

                  <label className="back-arrow text-center">
                    <div
                      className="cancel-btn text-center"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-0 pr-0 pt-0 pb-0">
                    <div className="h-divider w-100 mb-0"></div>
                  </div>

                  <div className="col-12 mt-4 mb-0 justify-content-center p-0">
                    {this.pointListing()}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.addPoster} onHide={this.closeModal}>
            <div className="col-12 pt-4 pb-2 pl-4 pr-4 modalBGcolor">
              <div className="row justify-content-center align-self-center">
                <div className="row w-100 mb-4 justify-content-end align-self-center">
                  <label className="back-arrow" style={{ marginRight: 15 }}>
                    <div
                      className="save-btn"
                      style={{ display: this.state.hideUploadBtn }}
                      onClick={this.uploadPicture.bind(this)}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {this.state.dataFound == 0 ? "Add" : "Update"}
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>

                  <label className="back-arrow text-center">
                    <div
                      className="cancel-btn text-center"
                      onClick={() => this.closeModal()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Close
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                  <div className="col-12 pl-0 pr-0 pt-0 pb-0">
                    <div className="h-divider w-100 mb-0"></div>
                  </div>

                  <div className="col-12 mt-4 mb-0 justify-content-center p-0">
                    <img src={photo} width={"100%"} className="radius-5" />
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={
                          localStorage.getItem("modelid") > 0
                            ? "models"
                            : "dashboard"
                        }
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right pt-2">
                      {localStorage.getItem("org_uid") &&
                      localStorage.getItem("e_uid") ? (
                        <label
                          className="float-right color-blue mb-0 font-normal"
                          onClick={() =>
                            this.vote(localStorage.getItem("modelid"))
                          }
                        >
                          Vote
                        </label>
                      ) : (
                        <label
                          className="float-right color-blue mb-0 font-normal"
                          onClick={() =>
                            this.models(localStorage.getItem("modelid"))
                          }
                        >
                          Edit
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        MODEL PROFILE
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body pb-3">
                  <div
                    className="row justify-content-center align-self-center"
                    onClick={() => this.addPoster()}
                  >
                    <div
                      className=""
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 100,
                        backgroundImage: `url(` + photo + `)`,
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                    ></div>
                    <div className="col-12 text-center mt-3 mb-0">
                      <span>{this.state.name}</span>
                    </div>
                    <br />
                    <div className="col-12 text-center mt-3"></div>
                  </div>
                  {localStorage.getItem("org_uid") &&
                  localStorage.getItem("e_uid") ? (
                    ""
                  ) : (
                    <div className="col-12 text-center mt-0 mb-0">
                      <label className="small-details">
                        {" "}
                        {this.state.gender == "female"
                          ? "Her url: "
                          : "His url:"}{" "}
                        <Link
                          to={{
                            pathname:
                              "https://karamale.com/vote?model=" +
                              this.state.name.replace(" ", "+") +
                              "&m_uid=" +
                              this.state.modelid +
                              "&org_uid=" +
                              localStorage.getItem("businessid"),
                          }}
                          target="_blank"
                        >
                          <label className="color-blue">
                            https://karamale.com/vote?model=
                            {this.state.name.replace(" ", "+")}&m_uid=
                            {this.state.modelid}&org_uid=
                            {localStorage.getItem("businessid")}
                          </label>
                        </Link>
                      </label>
                    </div>
                  )}
                  <div className="h-divider w-100" />
                  Model name
                  <br />
                  <label className="small-details">{this.state.name}</label>
                  <br />
                  <br />
                  Age
                  <br />
                  <label className="small-details">{this.state.age}</label>
                  <br />
                  <br />
                  Gender
                  <br />
                  <label className="small-details">{gender}</label>
                  <br />
                  <br />
                  Bio
                  <br />
                  <label className="small-details">{this.state.bio}</label>
                  <br />
                  <br />
                  Profile created
                  <br />
                  <label className="small-details">
                    {this.state.dtcreated}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Modelprofile;
