import React from "react";
import { BsX } from "react-icons/bs";
import {
  IoIosCloseCircleOutline,
  IoIosArrowRoundForward,
} from "react-icons/io";
import ReactAudioPlayer from "react-audio-player";
import Header2 from "./components/Header2";
import { Link, NavLink } from "react-router-dom";
class Campaigncancelled extends React.Component {
  render() {
    const businessid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const query = new URLSearchParams(this.props.location.search);
    const campaignname = query.get("campaignname");
    return (
      <div className="row background-img">
        <div className="w-100 pl-5 pr-5 shield">
          <div className="success-response">
            <div className="row h-100 justify-content-center">
              <div className="align-self-center text-center adjust-margin">
                <div className="cancelled-message">
                  <div style={{ display: "none" }}>
                    <ReactAudioPlayer
                      src="https://karamale.com/apps/kscanner/fail.mp3"
                      autoPlay={true}
                      controls
                    />
                  </div>
                  <IoIosCloseCircleOutline size={40} /> <br />
                  Transaction cancelled for <br />
                  <b>{campaignname}</b>
                </div>
                <br />
                <br />
                <Link
                  to={{
                    pathname: "/dashboard",
                    state: {
                      accounttype: accounttype,
                      accountid: businessid,
                      businessname: businessname,
                    },
                  }}
                  className="my-tickets-btn"
                >
                  Go to Dashboard <IoIosArrowRoundForward size={25} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Campaigncancelled;
