import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";
import { NavLink } from "react-router-dom";
import { BsChevronCompactRight } from "react-icons/bs";

class Ranknav extends Component {
  render() {
    return (
      <div>
        <div className="col-12">
          {" "}
          <span className="filter">Filter by</span>
        </div>

        <div className="row padding">
          <div className=" col-lg-12 col-md-12 col-sm-12 col-4 p-0">
            <NavLink
              to="/national"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="header-tabs col-12 nav-links mobile-position">
                <BsChevronCompactRight className="arrowIcons" /> National
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-4 p-0">
            <NavLink
              to="/provincial"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="header-tabs col-12 nav-links mobile-position">
                <BsChevronCompactRight className="arrowIcons" /> Provincial
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-4 p-0">
            <NavLink
              to="/regional"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="header-tabs col-12 nav-links mobile-position">
                <BsChevronCompactRight className="arrowIcons" /> Regional
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Ranknav;
