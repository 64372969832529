import React from "react";
import { BsX } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ReactAudioPlayer from "react-audio-player";
class Cancelled extends React.Component {
  render() {
    return (
      <div className="row background-img">
        <div className="w-100 pl-5 pr-5 shield">
          <div className="success-response">
            <div className="row h-100 justify-content-center">
              <div className="align-self-center text-center adjust-margin">
                <div className="cancelled-message">
                  <div style={{ display: "none" }}>
                    <ReactAudioPlayer
                      src="https://karamale.com/apps/kscanner/fail.mp3"
                      autoPlay={true}
                      controls
                    />
                  </div>
                  <IoIosCloseCircleOutline size={40} /> <br />
                  Transaction cancelled
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cancelled;
