import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import Header3 from "./components/Header3";
import TopBarProgress from "react-topbar-progress-indicator";

class Myevents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      loadBarProgress: true,
    };
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    document.title = "My Events / " + businessname;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MYEVENTS&accountid=" +
        businessid +
        "&accounttype=" +
        accounttype
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let string = "";

    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            let tempEventname = item.eventname;
            const passEventname = tempEventname;
            const campaignstatus = item.campaignstatus;
            let budget = item.budget;
            let ticketset = item.ticketid;
            let markticketset = "";
            let inCheckmark = "";
            let thumbnail = item.thumbnail;
            let pausebtn = "";
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            event = item.eventname;

            if (
              item.thumbnail.trim() === "" ||
              item.thumbnail.trim() === "none"
            ) {
              poster = "https://karamale.com/apps/kscanner/placeholder.png";
              nb = (
                <label
                  className="float-right"
                  style={{
                    color: "red",
                    float: "right",
                    fontWeight: 400,
                  }}
                >
                  <i>Complete event setup!</i>
                </label>
              );
            } else {
              nb = "";
              poster =
                "https://karamale.com/apps/kscanner/posters/" +
                item.businessid +
                "/" +
                item.eventid +
                "/" +
                item.thumbnail;
            }

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-3">
                  <Link
                    to={{
                      pathname: "/editevent",
                      state: {
                        campaignstatus: item.campaignstatus,
                        eventid: item.eventid,
                        eventname: item.eventname,
                        poster: poster,
                        eventdate: item.eventday,
                        enddate: item.enddate,
                        event_date: item.event_day,
                        finish_date: item.finish_day,
                        theme: item.theme,
                        venue: item.venue,
                        expectednum: item.expected,
                        time: item.time,
                        endtime: item.endtime,
                        prevpage: "myevents",
                      },
                    }}
                    className="w-100"
                  >
                    <div
                      className="row justify-content-center align-items-center"
                      style={{ display: "flex" }}
                    >
                      <div className="col-lg-2 col-3 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      </div>
                      <div
                        className="col-lg-10 col-9 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {event} {nb}
                          <br />
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            {item.eventday}
                          </label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("businessid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header3 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container ">
          <div className="row justify-content-center just-container-inside">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-6 pl-0">
                      <Link to="/dashboard" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <Link
                          to={{
                            pathname: "addevent",
                            state: { prevpage: "myevents" },
                          }}
                          className="link-btn"
                        >
                          <div className="edit-btn">Add event</div>
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        MY EVENTS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Myevents;
