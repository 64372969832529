import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";

class Editbooster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      pass1: "",
      pass2: "",
      fname: this.props.location.state.fname,
      lname: this.props.location.state.lname,
      phone: this.props.location.state.phone,
      email: this.props.location.state.email,
      credit: this.props.location.state.credit,
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
    };
  }

  componentDidMount() {
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    document.title = "Edit Booster profile - " + businessname;
  }

  handleFname = (event) => {
    this.setState({
      fname: event.target.value,
      errorDisplay: "none",
    });
  };

  handleLname = (event) => {
    this.setState({
      lname: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePhone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePass1 = (event) => {
    this.setState({
      pass1: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePass2 = (event) => {
    this.setState({
      pass2: event.target.value,
      errorDisplay: "none",
    });
  };

  handleCredit = (event) => {
    this.setState({
      credit: event.target.value,
      errorDisplay: "none",
    });
  };
  update = () => {
    const fname = this.state.fname;
    const lname = this.state.lname;
    const phone = this.state.phone;
    const email = this.state.email;
    const password = this.state.pass1;
    const confirmPassword = this.state.pass2;
    const voucher = this.state.credit;
    const promoterid = this.props.location.state.promoterid;

    const accountid = localStorage.getItem("businessid");
    const accounttype = localStorage.getItem("accounttype");

    if (
      fname.trim() == "" ||
      lname.trim() == "" ||
      phone.trim() == "" ||
      email.trim() == ""
    ) {
      this.setState({
        error: "All fields must are required",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (voucher < 100) {
      this.setState({
        error: "Credit should be more R100 or more",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (password != confirmPassword) {
      this.setState({
        error: "Make sure your passwords match",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=UPDATEPROMOTERDETAILS&businessid=" +
          accountid +
          "&promoterid=" +
          promoterid +
          "&fname=" +
          fname +
          "&lname=" +
          lname +
          "&phone=" +
          phone +
          "&email=" +
          email +
          "&voucher=" +
          voucher +
          "&password=" +
          password
      ).then((response) => {
        if (response.data.val == 2) {
          this.setState({
            error: "Email or phone number provided already exists",
            errorDisplay: "block",
            successError: "error-login",
          });
        } else if (response.data.val == 3) {
          this.setState({
            error: response.data.error,
            errorDisplay: "block",
            successError: "error-login",
          });
        } else {
          this.setState({
            error: "Profile updated",
            errorDisplay: "block",
            successError: "success-login",
          });
        }
      });
    }
  };

  render() {
    let { accountname, dashboard } = "";
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
    }

    const businessname = localStorage.getItem("businessname");
    return (
      <div className="view">
        {localStorage.getItem("customername") !== null ? (
          <Header2 />
        ) : (
          <Header3 />
        )}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to="/boosters" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          Update
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        EDIT BOOSTER PROFILE{" "}
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  Name
                  <br />
                  <input
                    type="text"
                    placeholder={"First name"}
                    value={this.state.fname}
                    onChange={this.handleFname}
                    className="edit-input"
                  />
                  <br /> <br />
                  Surname
                  <br />
                  <input
                    type="text"
                    placeholder={"Last name"}
                    value={this.state.lname}
                    onChange={this.handleLname}
                    className="edit-input"
                  />
                  <br /> <br />
                  Email
                  <br />
                  <input
                    type="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.handleEmail}
                    className="edit-input"
                  />
                  <br /> <br />
                  Phone number
                  <br />
                  <input
                    type="number"
                    placeholder="Phone number"
                    value={this.state.phone}
                    onChange={this.handlePhone}
                    className="edit-input"
                  />
                  <br /> <br />
                  Credit
                  <br />
                  <input
                    type="number"
                    placeholder="add credit"
                    value={this.state.credit}
                    onChange={this.handleCredit}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Set new password
                  <br />
                  <input
                    type="password"
                    placeholder="New password"
                    value={this.state.pass1}
                    onChange={this.handlePass1}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Retype the password
                  <br />
                  <input
                    type="password"
                    placeholder="Retype password"
                    value={this.state.pass2}
                    onChange={this.handlePass2}
                    className="edit-input"
                  />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Editbooster;
