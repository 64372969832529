import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import Header3 from "./components/Header3";
import Header from "./components/Header";
import TopBarProgress from "react-topbar-progress-indicator";

class Models extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      loadBarProgress: true,
    };
  }

  componentWillMount() {
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    const businessid = localStorage.getItem("businessid");
    document.title = "Models";

    const query = new URLSearchParams(this.props.location.search);
    const org_uid = query.get("org_uid");
    const e_uid = query.get("e_uid");

    if (org_uid !== null && e_uid !== null) {
      if (
        localStorage.getItem("org_uid") === null &&
        localStorage.getItem("e_uid") === null
      ) {
        localStorage.setItem("org_uid", org_uid);
        localStorage.setItem("e_uid", e_uid);
      }
    }

    // alert(localStorage.getItem("e_uid"));
    let eventid = null;

    if (localStorage.getItem("eventid") === null) {
      eventid = e_uid;
    } else {
      eventid = localStorage.getItem("eventid");
    }

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=MODELS&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  models = (modelid) => {
    localStorage.setItem("modelid", modelid);
    this.props.history.push({ pathname: "/modelprofile" });
  };

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let string = "";

    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            nb = "";
            poster =
              "https://karamale.com/apps/kscanner/models/" +
              item.businessid +
              "/" +
              item.eventid +
              "/" +
              item.photo;

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100 pr-0 pl-0 pb-2 pt-3">
                  <div onClick={() => this.models(item.id)} className="w-100">
                    <div
                      className="row justify-content-center align-items-center"
                      style={{ display: "flex" }}
                    >
                      <div className="col-lg-2 col-3 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 80,
                            height: 80,
                            borderRadius: 100,
                            backgroundSize: "cover",
                          }}
                        ></div>
                      </div>
                      <div
                        className="col-lg-10 col-9 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <label
                          className="item-names w-100"
                          style={{
                            color: "#000",
                            cursor: "pointer",
                            fontSize: 17,
                          }}
                        >
                          <span>{item.model_name}</span>
                          <br />
                          <label
                            style={{
                              color: "grey",
                              fontSize: 13,
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            <span>Age:</span> {item.age}
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  addModels = () => {
    const eventid = localStorage.getItem("eventid");
    localStorage.removeItem("modelid");
    localStorage.setItem("eventid", eventid);
    this.props.history.push({
      pathname: "/addmodels",
    });
  };

  render() {
    if (
      localStorage.getItem("businessid") !== null ||
      localStorage.getItem("org_uid") !== null
    ) {
    } else {
      return <Redirect to="/login" />;
    }
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        {localStorage.getItem("businessname") === null ? (
          <Header />
        ) : (
          <Header3 />
        )}
        {loadBarProgress && <TopBarProgress />}
        <div className="container ">
          <div className="row justify-content-center just-container-inside">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-6 pl-0">
                      <Link to="/addmodels" className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <span
                          to={{
                            pathname: "addmodels",
                          }}
                          className="link-btn"
                          onClick={this.addModels}
                        >
                          <div className="edit-btn font-normal">Add model</div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">MODELS</label>
                      {localStorage.getItem("org_uid") !== null &&
                      localStorage.getItem("e_uid") !== null ? (
                        ""
                      ) : (
                        <label className="mb-0">
                          <label className="small-details">
                            Page url:{" "}
                            <Link
                              to={{
                                pathname:
                                  "https://karamale.com/models?org_uid=" +
                                  localStorage.getItem("businessid") +
                                  "&e_uid=" +
                                  localStorage.getItem("eventid"),
                              }}
                              target="_blank"
                            >
                              <label className="color-blue">
                                https://karamale.com/models?org_uid=
                                {localStorage.getItem("businessid")}&e_uid=
                                {localStorage.getItem("eventid")}
                              </label>
                            </Link>
                          </label>
                        </label>
                      )}
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Models;
