import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactTooltip from "react-tooltip";
import logo from "../logo.png";
import "../App.css";
import { Link, NavLink } from "react-router-dom";
import SlideDrawer from "./slideDrawer/SlideDrawer4";
import Backdrop from "./backDrop/Backdrop";
import CloseX from "./CloseX/CloseX";
import {
  BsArrowLeftShort,
  BsPerson,
  BsList,
  BsAppIndicator,
  BsBattery,
} from "react-icons/bs";

import { IoIosLogOut } from "react-icons/io";
import { BiCartAlt } from "react-icons/bi";

class Header extends React.Component {
  state = {
    searchValue: "",
    response: [],
    queryResults: [],
    resultsComponent: false,
    open: true,
    openLogo: false,
    arrowIcon: true,
    email: "",
    phone: "",
    customername: "",
    sideDrawerOpen: false,
    showHeader: "",
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {}

  render() {
    let slideDrawer;
    let backDrop;
    let closeX;

    if (this.state.sideDrawerOpen) {
      slideDrawer = <SlideDrawer />;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
      closeX = <CloseX click={this.backDropClickHandler} />;
    }

    const boosterid = localStorage.getItem("boosterid");
    const boostername = localStorage.getItem("boostername");
    const boosteremail = localStorage.getItem("boosteremail");
    const boosterphone = localStorage.getItem("boosterphone");
    const activation = localStorage.getItem("activation");
    const clientid = localStorage.getItem("clientid");
    const balance = localStorage.getItem("balance");
    const credit = localStorage.getItem("credit");
    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");

    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};

    return (
      <div className="App">
        {slideDrawer}
        {backDrop}
        {closeX}
        <header className="App-header" onClick={this.Appheader}>
          <div className="row">
            <div className="Header-wrapper col-lg-4 col-md-10 col-sm-10 col-10 pl-4">
              <BsArrowLeftShort
                className="textGrey hand-cursor search-arrow"
                style={arrowIconStyle}
                onClick={this.handleSearchArrow}
              />{" "}
              &nbsp;
              <Link
                to={{
                  pathname: "/Boosterdashboard",
                  state: {
                    balance: balance,
                    credit: credit,
                    boosteremail: boosteremail,
                    boosterphone: boosterphone,
                    activation: activation,
                    businessid: businessid,
                    businessname: businessname,
                    boosterid: boosterid,
                    boostername: boostername,
                  },
                }}
                className="links logo-karamale"
                style={logoStyle}
              >
                <img
                  src={logo}
                  alt={logo}
                  className="header-app-logo"
                  align="left"
                />
              </Link>
            </div>

            <div
              className="col-lg-2 col-md-1 col-2 more-col-mobile mobile-top-links pl-0"
              onClick={this.drawerToggleClickHandler}
            >
              <div className="more-icons" data-tip="More">
                <ReactTooltip />
                <BsList size={25} />
              </div>
            </div>

            {/* <div className="col-lg-1 space-div"></div> */}

            <div className="menu-row-desktop col-lg-7 col-md-7 p-0">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 home-link mobile-top-links">
                  <NavLink
                    to={{
                      pathname: "/Boosterdashboard",
                      state: {
                        balance: balance,
                        credit: credit,
                        boosteremail: boosteremail,
                        boosterphone: boosterphone,
                        activation: activation,
                        businessid: businessid,
                        businessname: businessname,
                        boosterid: boosterid,
                        boostername: boostername,
                      },
                    }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Dashboard"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      {" "}
                      <ReactTooltip />
                      <BsAppIndicator size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  <NavLink
                    to={{
                      pathname: "/selltickets",
                      state: {
                        activation: activation,
                        boosteremail: boosteremail,
                        boosterphone: boosterphone,
                        businessid: businessid,
                        businessname: businessname,
                        boostername: boostername,
                        boosterid: boosterid,
                        credit: credit,
                        balance: balance,
                      },
                    }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Sell tickets"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BiCartAlt size={28} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-2 mobile-liner"></div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to={{
                      pathname: "/Boosterprofile",
                      state: {
                        activation: activation,
                        boosteremail: boosteremail,
                        boosterphone: boosterphone,
                        businessid: businessid,
                        businessname: businessname,
                        boostername: boostername,
                        boosterid: boosterid,
                        credit: credit,
                        balance: balance,
                      },
                    }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Profile"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsPerson size={26} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to={{
                      pathname: "/accountstatus",
                      state: {
                        activation: activation,
                        boosteremail: boosteremail,
                        boosterphone: boosterphone,
                        businessid: businessid,
                        businessname: businessname,
                        boostername: boostername,
                        boosterid: boosterid,
                        credit: credit,
                        balance: balance,
                      },
                    }}
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Account status"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsBattery size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/logout"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Log out"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <IoIosLogOut size={25} />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </header>
        <div className="spacer" />
      </div>
    );
  }
}

export default Header;
