import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import { BiCalendarEvent, BiCartAlt } from "react-icons/bi";
import { IoIosContact, IoIosLogOut } from "react-icons/io";
import { BsPerson, BsBattery } from "react-icons/bs";
import Header4 from "./components/Header4";

class Boosterdashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const boostername = localStorage.getItem("boostername");
    const boosterid = localStorage.getItem("boosterid");
    document.title = boostername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=REALTIMEBALANCE&boosterid=" +
        boosterid
    )
      .then((response) => {
        // alert(response.data.balance)
        this.setState({
          balance: response.data.balance,
          credit: response.data.amount,
        });
      })
      .catch((error) => console.log(error));
  }

  render() {
    if (localStorage.getItem("boosterid") === null) {
      return <Redirect to="/" />;
      //   return exit(1);
    } else {
    }
    const boosterid = localStorage.getItem("boosterid");
    const boosteremail = this.props.location.state.boosteremail;
    const boosterphone = this.props.location.state.boosterphone;
    const activation = this.props.location.state.activation;
    const businessid = this.props.location.state.businessid;
    const businessname = this.props.location.state.businessname;
    const boostername = this.props.location.state.boostername;
    const credit = this.props.location.state.credit;
    const balance = this.props.location.state.balance;
    return (
      <div className="view">
        <Header4 />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-1 pr-1 ">
                <div className="row align-self-center justify-content-center">
                  <div className="item-user">
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <IoIosContact size={30} className="profile-icon" />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-11">
                      <label className="custername-profile w-100 mb-0">
                        {boostername.toUpperCase()}
                        <label className="float-right small-details mb-0">
                          ZAR {this.state.credit}
                        </label>
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>

                  {activation == 0 ? (
                    <div className="item-select">
                      <div className="link-btn w-100 menu-links">
                        <div className="col-lg-1 col-2 col-sm-1 text-center">
                          <BiCartAlt size={30} color={"silver"} />
                        </div>
                        <div className="col-lg-11 col-10 col-sm-11">
                          <label
                            className="item-names w-100"
                            style={{ color: "silver" }}
                          >
                            Sell tickets
                            <label
                              className="float-right"
                              style={{ color: "red", fontSize: 10 }}
                            >
                              <i> This account not active</i>
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : this.state.balance < 30 ? (
                    <div className="item-select">
                      <div className="link-btn w-100 menu-links">
                        <div className="col-lg-1 col-2 col-sm-1 text-center">
                          <BiCartAlt size={30} color={"silver"} />
                        </div>
                        <div className="col-lg-11 col-10 col-sm-11">
                          <label
                            className="item-names w-100"
                            style={{ color: "silver" }}
                          >
                            Sell tickets
                            <label
                              className="float-right"
                              style={{ color: "red", fontSize: 10 }}
                            >
                              <i> Your account is low on credit</i>
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="item-select">
                      <Link
                        to={{
                          pathname: "selltickets",
                          state: {
                            activation: activation,
                            boosteremail: boosteremail,
                            boosterphone: boosterphone,
                            businessid: businessid,
                            businessname: businessname,
                            boostername: boostername,
                            boosterid: boosterid,
                            credit: credit,
                            balance: balance,
                          },
                        }}
                        className="link-btn w-100 menu-links"
                      >
                        <div className="col-lg-1 col-2 col-sm-1 text-center">
                          <BiCartAlt size={30} color={"#ff6666"} />
                        </div>
                        <div className="col-lg-11 col-10 col-sm-11">
                          <label className="item-names">Sell tickets</label>
                        </div>
                      </Link>
                    </div>
                  )}

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "Boosterprofile",
                        state: {
                          activation: activation,
                          boosteremail: boosteremail,
                          boosterphone: boosterphone,
                          businessid: businessid,
                          businessname: businessname,
                          boostername: boostername,
                          boosterid: boosterid,
                          credit: credit,
                          balance: balance,
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPerson size={30} color={"#3399cc"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Profile</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "accountstatus",
                        state: {
                          activation: activation,
                          boosteremail: boosteremail,
                          boosterphone: boosterphone,
                          businessid: businessid,
                          businessname: businessname,
                          boostername: boostername,
                          boosterid: boosterid,
                          credit: credit,
                          balance: balance,
                        },
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsBattery size={30} color={"#006699"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Account status</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select mb-2">
                    <Link
                      to={{
                        pathname: "logout",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosLogOut size={30} color={"red"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-10">
                        <label className="item-names">Log out</label>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Boosterdashboard;
