import React, { Component } from "react";
import "./SlideDrawer.css";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { BsAppIndicator, BsPerson, BsPen } from "react-icons/bs";

import { IoIosLogOut } from "react-icons/io";
import { FaHandshake } from "react-icons/fa";

class SlideDrawer extends Component {
  state = {
    products: [],
  };
  componentDidMount() {
    let search = window.location.search;
    let query = new URLSearchParams(search);
    let ticketid = query.get("ticketid");
    let eventid = query.get("eventid");
    // let ticketid = this.props.ticketid;
    // let eventid = this.props.eventid;

    Axios.get(
      "/api/select/payment/method?eventid=" + eventid + "&ticketid=" + ticketid
    ).then((res) => {
      const products = res.data;
      this.setState({ products });
    });
  }

  render() {
    // const { error, products } = this.state;
    let drawerClasses = "slide-drawer";
    if (this.props.show) {
      drawerClasses = "slide-drawer open";
    }
    return (
      <div className={drawerClasses}>
        <div className="row">
          <div className="col-lg-12 p-0">
            <NavLink
              to="/in"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight ">
                <BsAppIndicator size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Dashboard
              </div>
            </NavLink>
          </div>
          <div className="col-lg-12 p-0">
            <NavLink
              to="/recruits"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <FaHandshake size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Recruits
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/profile"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPerson size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Profile
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to="/feedback"
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <BsPen size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;Send feedback
              </div>
            </NavLink>
          </div>

          <div className="col-lg-12 p-0">
            <NavLink
              to={{
                pathname: "logout",
              }}
              className="links"
              exact
              activeClassName="activeLink"
            >
              <div className="col-lg-12 mobile-nav textLight">
                <IoIosLogOut size={25} /> &nbsp;&nbsp;&nbsp;&nbsp;log out
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideDrawer;
