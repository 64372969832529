import React, { Component } from "react";
import "./App.css";
import Header from "./components/Header";
import Header2 from "./components/Header2";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import Login from "./Login";
import Password from "./Password";
import Register from "./Register";
import Business from "./Business";
import Customer from "./Customer";
import Home from "./Home";
import Event from "./Event";
import Fees from "./Fees";
import Ranking from "./Ranking";
import Promoter from "./Promoter";
import SlideDrawer from "./components/slideDrawer/SlideDrawer";
import SlideDrawer2 from "./components/slideDrawer/SlideDrawer2";
import SlideDrawer5 from "./components/slideDrawer/SlideDrawer5";
import Backdrop from "./components/backDrop/Backdrop";
import CloseX from "./components/CloseX/CloseX";
import Buy from "./Buy";
import Finalize from "./Finalize";
import Eft from "./Eft";
import Success from "./Success";
import Successeft from "./SuccessEft";
import Cancelled from "./Cancelled";
import About from "./About";
import Footer from "./components/Footer";
import Feedback from "./Feedback";
import Budget from "./Budget";
import Customerdashboard from "./Customerdashboard";
import Logout from "./Logout";
import Tickets from "./Tickets";
import Previewtickets from "./Previewtickets";
import Transactions from "./Transactions";
import Transactiondetails from "./Transactiondetails";
import Likes from "./Likes";
import Profile from "./Profile";
import Editprofile from "./Editprofile";
import Changepassword from "./Changepassword";
import Report from "./Report";
import Verification from "./Verification";
import Categories from "./Categories";
import Category from "./Category";
import Dashboard from "./Dashboard";
import Myevents from "./Myevents";
import Editevent from "./Editevent";
import Updateeventdetails from "./Updateeventdetails";
import Campaign from "./Campaign";
import Campaigncancelled from "./Campaigncancelled";
import Campaignsuccess from "./Campaignsuccess";
import Addevent from "./Addevent";
import Customers from "./Customers";
import Customerprofile from "./Customerprofile";
import Boosters from "./Boosters";
import Addbooster from "./Addbooster";
import Editbooster from "./Editbooster";
import Explore from "./Explore";
import Boosterlogin from "./Boosterlogin";
import Boosterdashboard from "./Boosterdashboard";
import Boosterprofile from "./Boosterprofile";
import Accountstatus from "./Accountstatus";
import Selltickets from "./Selltickets";
import Eventdescription from "./Eventdescription";
import Customerpay from "./Customerpay";
import Codepage from "./Codepage";
import Zoom from "./Zoom";
import Analytics from "./Analytics";
import Campaignmanager from "./Campaignmanager";
import Sms from "./Sms";
import Marketing from "./Marketing";
import Smscampaign from "./Smscampaign";
import Smsmarketingsuccessful from "./Smsmarketingsuccessful";
import Uploaddata from "./Uploaddata";
import Smsmarketingcancel from "./Smsmarketingcancel";
import Aboutsalespartner from "./Aboutsalespartner";
import Partner from "./Partner";
import Bdetails from "./Bdetails";
import In from "./In";
import Recruits from "./Recruits";
import Wallet from "./Wallet";
import Downloadtickets from "./Downloadtickets";
import Ticketdownload from "./Ticketdownload";
import Download from "./Download";
import Aboutkaramale from "./Aboutkaramale";
import Addmodels from "./Addmodels";
import Models from "./Models";
import Modelprofile from "./Modelprofile";
import Privacy from "./Privacy";
import AccountDeletion from "./Acc_Del";
class App extends Component {
  state = {
    sideDrawerOpen: false,
    showHeader: "",
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let slideDrawer;
    let backDrop;
    let closeX;

    if (this.state.sideDrawerOpen) {
      slideDrawer = <SlideDrawer />;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
      closeX = <CloseX click={this.backDropClickHandler} />;
    }

    return (
      <div>
        <Router forceRefresh={false}>
          {slideDrawer}
          {backDrop}
          {closeX}

          <Switch>
            <Route path={"/"} exact component={Home} />
            <Route path={"/login"} component={Login} />
            <Route path={"/promoter"} component={Promoter} />
            <Route path={"/password"} component={Password} />
            <Route path={"/register"} component={Register} />
            <Route path={"/business"} component={Business} />
            <Route path={"/customer"} component={Customer} />
            <Route path={"/success"} component={Success} />
            <Route path={"/successeft"} component={Successeft} />
            <Route path={"/cancelled"} component={Cancelled} />
            <Route path={"/buy"} component={Buy} />
            <Route path={"/finalize"} component={Finalize} />
            <Route path={"/eft"} component={Eft} />
            <Route path={"/fees"} component={Fees} />
            <Route path={"/national"} component={Ranking} />
            <Route path={"/about"} component={About} />
            <Route path={"/event"} component={withRouter(Event)} />
            <Route path={"/feedback"} component={Feedback} />
            <Route path={"/budget"} component={Budget} />
            <Route path={"/logout"} component={Logout} />
            <Route path={"/tickets"} component={Tickets} />
            <Route path={"/previewtickets"} component={Previewtickets} />
            <Route path={"/transactions"} component={Transactions} />
            <Route path={"/likes"} component={Likes} />
            <Route path={"/profile"} component={Profile} />
            <Route path={"/editprofile"} component={Editprofile} />
            <Route path={"/changepassword"} component={Changepassword} />
            <Route path={"/report"} component={Report} />
            <Route path={"/verification"} component={Verification} />
            <Route path={"/categories"} component={Categories} />
            <Route path={"/category"} component={Category} />
            <Route path={"/dashboard"} component={Dashboard} />
            <Route path={"/myevents"} component={Myevents} />
            <Route path={"/editevent"} component={Editevent} />
            <Route path={"/campaign"} component={Campaign} />
            <Route path={"/addevent"} component={Addevent} />
            <Route path={"/customers"} component={Customers} />
            <Route path={"/boosters"} component={Boosters} />
            <Route path={"/addbooster"} component={Addbooster} />
            <Route path={"/editbooster"} component={Editbooster} />
            <Route path={"/explore"} component={Explore} />
            <Route path={"/boosterlogin"} component={Boosterlogin} />
            <Route path={"/boosterprofile"} component={Boosterprofile} />
            <Route path={"/accountstatus"} component={Accountstatus} />
            <Route path={"/selltickets"} component={Selltickets} />
            <Route path={"/customerpay"} component={Customerpay} />
            <Route path={"/eventdescription"} component={Eventdescription} />
            <Route path={"/analytics"} component={Analytics} />
            <Route path={"/bdetails"} component={Bdetails} />
            <Route path={"/in"} component={In} />
            <Route path={"/Ticketdownload"} component={Ticketdownload} />
            <Route path={"/download"} component={Download} />

            <Route path={"/boosterdashboard"} component={Boosterdashboard} />

            <Route path={"/customerprofile"} component={Customerprofile} />

            <Route path={"/campaignsuccess"} component={Campaignsuccess} />

            <Route path={"/campaigncancelled"} component={Campaigncancelled} />
            <Route path={"/codepage"} component={Codepage} />
            <Route path={"/zoom"} component={Zoom} />
            <Route path={"/campaignmanager"} component={Campaignmanager} />
            <Route path={"/sms"} component={Sms} />
            <Route path={"/smscampaign"} component={Smscampaign} />
            <Route path={"/uploaddata"} component={Uploaddata} />
            <Route path={"/uploaddata"} component={Uploaddata} />
            <Route path={"/partner"} component={Partner} />
            <Route path={"/aboutsalesparnter"} component={Aboutsalespartner} />
            <Route path={"/recruits"} component={Recruits} />
            <Route path={"/wallet"} component={Wallet} />
            <Route path={"/downloadtickets"} component={Downloadtickets} />
            <Route path={"/aboutkaramale"} component={Aboutkaramale} />
            <Route path={"/addmodels"} component={Addmodels} />
            <Route path={"/models"} component={Models} />
            <Route path={"/modelprofile"} component={Modelprofile} />

            <Route
              path={"/smsmarketingcancel"}
              component={Smsmarketingcancel}
            />
            <Route
              path={"/smsmarketingsuccessful"}
              component={Smsmarketingsuccessful}
            />
            <Route path={"/marketing"} component={Marketing} />
            <Route
              path={"/updateeventdetails"}
              component={Updateeventdetails}
            />
            <Route
              path={"/transactiondetails"}
              component={Transactiondetails}
            />
            <Route path={"/customerdashboard"} component={Customerdashboard} />
            <Route path={"/privacy"} component={Privacy} />
            <Route path={"/acc_del"} component={AccountDeletion} />
          </Switch>

          {/* <Footer /> */}
        </Router>
      </div>
    );
  }
}

export default App;
