import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactTooltip from "react-tooltip";
import logo from "../logo.png";
import "../App.css";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import SlideDrawer from "./slideDrawer/SlideDrawer2";
import Backdrop from "./backDrop/Backdrop";
import CloseX from "./CloseX/CloseX";

import TopBarProgress from "react-topbar-progress-indicator";
import {
  BsArrowLeftShort,
  BsPerson,
  BsList,
  BsSearch,
  BsCalendar,
  BsAppIndicator,
  BsHeart,
  BsPen,
} from "react-icons/bs";

import { IoIosLogOut } from "react-icons/io";

// import { render } from "@testing-library/react";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Header extends React.Component {
  state = {
    searchValue: "",
    response: [],
    queryResults: [],
    resultsComponent: false,
    open: true,
    openLogo: false,
    arrowIcon: true,
    email: "",
    phone: "",
    customername: "",
    sideDrawerOpen: false,
    showHeader: "",
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount() {
    const customerid = localStorage.getItem("customerid");
    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
        customerid
    ).then((response) => {
      this.setState({
        email: response.data.email,
        phone: response.data.phone,
        customername: response.data.customername,
      });
    });
  }
  handleSearchArrow = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
    // this.setState({ searchValue: "" });
  };

  Appheader = () => {
    this.setState({ open: true });
    this.setState({ openLogo: false });
    this.setState({ arrowIcon: true });
    // this.setState({ searchValue: "" });
  };

  handleSearch = async (event) => {
    this.setState({ searchValue: event.target.value });
    const searchVal = event.target.value;
    const formData = new FormData();
    formData.append("search", event.target.value);

    await Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=SEARCHEVENTS&search=" +
        searchVal
    ).then((response) => {
      if (response.data.val == 0) {
        this.setState({ queryResults: [] });
      } else {
        this.setState({ queryResults: response.data });
      }

      // console.log(response.data);
    });

    if (!searchVal) {
      this.setState({ open: true });
      this.setState({ openLogo: false });
      this.setState({ arrowIcon: true });
    } else {
      this.setState({ open: false });
      this.setState({ openLogo: true });
      this.setState({ arrowIcon: false });
    }
  };

  render() {
    // if (localStorage.getItem("customername") === null) {
    //   return <Redirect to="/" />;
    //   //   return exit(1);
    // } else {
    // }

    let slideDrawer;
    let backDrop;
    let closeX;

    if (this.state.sideDrawerOpen) {
      slideDrawer = <SlideDrawer />;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
      closeX = <CloseX click={this.backDropClickHandler} />;
    }

    const { queryResults } = this.state;
    const style = this.state.open ? { display: "none" } : {};
    const logoStyle = this.state.openLogo ? { display: "none" } : {};
    const arrowIconStyle = this.state.arrowIcon ? { display: "none" } : {};
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    let event = "";

    return (
      <div className="App">
        {slideDrawer}
        {backDrop}
        {closeX}
        <header className="App-header" onClick={this.Appheader}>
          <div className="row">
            <div className="Header-wrapper col-lg-4 col-md-10 col-sm-10 col-10 pl-4">
              <div style={arrowIconStyle} className="arrow-back">
                <BsArrowLeftShort
                  className="textGrey hand-cursor"
                  style={arrowIconStyle}
                  onClick={this.handleSearchArrow}
                />
              </div>{" "}
              &nbsp;
              <Link to="/" className="links logo-karamale" style={logoStyle}>
                <img
                  src={logo}
                  alt={logo}
                  className="header-app-logo"
                  align="left"
                />
              </Link>
              <input
                id="search-input"
                className="search-input"
                placeholder="Search Karamale"
                value={this.state.searchValue}
                onChange={this.handleSearch}
                autoComplete={"off"}
              />
              <div
                className="col-12 response textMedium search-results"
                style={style}
              >
                {this.state.queryResults.length < 1 ? (
                  <div className="no-match-found">
                    No match found for{" "}
                    <span className="search-value">
                      "{this.state.searchValue}..."
                    </span>
                  </div>
                ) : (
                  this.state.queryResults.map((item, index) => {
                    const posterUri =
                      "https://karamale.com/apps/kscanner/posters/" +
                      item.businessid +
                      "/" +
                      item.eventid +
                      "/" +
                      item.poster;
                    if (item.indexid >= 1) {
                      return "";
                    } else {
                      return (
                        <div className="row">
                          <div className="col-12 default-padding" key={index}>
                            <div className="row">
                              <Link
                                to={{
                                  pathname: "event",
                                  search:
                                    "q=" +
                                    uuid6 +
                                    "&queryid=" +
                                    uuid5 +
                                    "&pageid=" +
                                    uuid4 +
                                    "&uid=" +
                                    uuid3 +
                                    "&qKey=" +
                                    uuid1 +
                                    "&key=" +
                                    uuid2 +
                                    "&uuid=" +
                                    uuid +
                                    "&eventid=" +
                                    item.eventid +
                                    "&eventDate=" +
                                    item.event_day +
                                    "&fulldate=" +
                                    fulldate +
                                    "&customerid=" +
                                    localStorage.getItem("customerid") +
                                    "&posterUri=" +
                                    posterUri +
                                    "&accountid=" +
                                    localStorage.getItem("customerid") +
                                    "&accounttype=" +
                                    localStorage.getItem("accounttype") +
                                    "&customername=" +
                                    this.state.customername +
                                    "&email=" +
                                    this.state.email +
                                    "&phone=" +
                                    this.state.phone +
                                    "&venue=" +
                                    item.event_location +
                                    "&description=" +
                                    item.event_des +
                                    "&businessid=" +
                                    item.businessid +
                                    "&poster=" +
                                    item.poster +
                                    "&eventname=" +
                                    item.eventname +
                                    "&eventday=" +
                                    item.eventday +
                                    "&starttime=" +
                                    item.start_time +
                                    "&endtime=" +
                                    item.finish_time +
                                    "&previousPage=" +
                                    window.location.pathname.replace(
                                      /\\|\//g,
                                      ""
                                    ) +
                                    "&campaignStatus=running&category=",
                                  state: {
                                    fromHome: true,
                                    eventid: item.eventid,
                                    eventDate: item.event_day,
                                    fulldate: fulldate,
                                    customerid:
                                      localStorage.getItem("customerid"),
                                    posterUri: posterUri,
                                    accountid:
                                      localStorage.getItem("customerid"),
                                    accounttype:
                                      localStorage.getItem("accounttype"),
                                    customername: this.state.customername,
                                    email: this.state.email,
                                    phone: this.state.phone,
                                    venue: item.event_location,
                                    description: item.event_des,
                                    businessid: item.businessid,
                                    poster: item.poster,
                                    eventname: item.eventname,
                                    eventday: item.eventday,
                                    starttime: item.start_time,
                                    endtime: item.finish_time,
                                    previousPage:
                                      window.location.pathname.replace(
                                        /\\|\//g,
                                        ""
                                      ),
                                    campaignStatus: "running",
                                    category: "",
                                  },
                                }}
                                className="eventLinks links-width"
                              >
                                <div className="search-results-inner">
                                  <div className="search-container">
                                    <BsSearch />
                                  </div>
                                  <div className="results-container align-self-center text-left">
                                    {item.event_name.length > 27
                                      ? (event =
                                          item.event_name.substr(0, 27) + "...")
                                      : (event = item.event_name)}
                                    <br />

                                    {item.businessname.length > 30 ? (
                                      <span className="businessname">
                                        Event by{" "}
                                        {item.businessname.substr(0, 30)}...
                                      </span>
                                    ) : (
                                      <span className="businessname">
                                        Event by {item.businessname}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                )}
              </div>
            </div>

            <div
              className="col-lg-2 col-md-1 col-2 more-col-mobile mobile-top-links pl-0"
              onClick={this.drawerToggleClickHandler}
            >
              <div className="more-icons" data-tip="More">
                <ReactTooltip />
                <BsList size={25} />
              </div>
            </div>

            {/* <div className="col-lg-1 space-div"></div> */}

            <div className="menu-row-desktop col-lg-7 col-md-7 p-0">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 home-link mobile-top-links">
                  <NavLink
                    to="/customerdashboard"
                    className="links"
                    activeClassName="header-tabs-active"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Dashboard"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      {" "}
                      <ReactTooltip />
                      <BsAppIndicator size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  <NavLink
                    to=""
                    className="links "
                    exact
                    activeClassName="header-tabs-active"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Events"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsCalendar size={22} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-top-links">
                  <NavLink
                    to="/likes"
                    className="links"
                    activeClassName="header-tabs-active"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Likes"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsHeart size={24} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-2 mobile-liner"></div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/profile"
                    className="links"
                    exact
                    activeClassName="header-tabs-active"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Profile"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsPerson size={26} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/feedback"
                    className="links"
                    exact
                    activeClassName="header-tabs-active"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Feedback"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <BsPen size={25} />
                    </div>
                  </NavLink>
                </div>

                <div className="col-lg-2 col-md-2 col-2 mobile-bottom-links">
                  <NavLink
                    to="/logout"
                    className="links"
                    exact
                    activeClassName="activeLink"
                  >
                    <div
                      className="header-tabs"
                      data-tip="Log out"
                      data-effect="solid"
                      data-arrow-color="transparent"
                      data-type="dark"
                      data-place="bottom"
                    >
                      <ReactTooltip />
                      <IoIosLogOut size={25} />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </header>

        <div className="spacer" />
      </div>
    );
  }
}

TopBarProgress.config({
  barColors: {
    0: "red",
    "1.0": "red",
  },
  shadowBlur: 0,
});

export default Header;
