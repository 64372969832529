import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import {
  IoIosArrowBack,
  IoIosThumbsUp,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import Header3 from "./components/Header4";

import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Selltickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
    };
  }

  componentDidMount() {
    const businessid = localStorage.getItem("businessid");
    document.title = "Karamale";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETEVENTSTOSELL&businessid=" +
        businessid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ details: [] });
        } else {
          this.setState({ details: response.data });
        }
      })
      .catch((error) => console.log(error));

    // get budget

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=GETBUDGET&businessid=" +
        businessid
    )
      .then((response) => {
        // alert(response.data.balance)
        this.setState({ budget: response.data.budget });
      })
      .catch((error) => console.log(error));
  }

  details = () => {
    let activeString = "";
    const accountid = localStorage.getItem("businessid");
    const businessname = localStorage.getItem("businessname");
    const accounttype = localStorage.getItem("accounttype");
    let { event, poster, nb } = "";

    let currentDate = new Date();
    let month = "";
    let date = "";
    if (currentDate.getMonth() <= 9) {
      month = "0" + (currentDate.getMonth() + 1);
    } else {
      month = currentDate.getMonth() + 1;
    }

    if (currentDate.getDate() <= 9) {
      date = "0" + currentDate.getDate();
    } else {
      date = currentDate.getDate();
    }

    let curDate = currentDate.getFullYear() + "-" + month + "-" + date;

    let string = "";
    const businessid = this.props.location.state.businessid;

    const boosteremail = this.props.location.state.boosteremail;
    const boosterphone = this.props.location.state.boosterphone;
    const boostername = this.props.location.state.boostername;
    const boosterid = this.props.location.state.boosterid;
    const activation = this.props.location.state.activation;
    const balance = this.props.location.state.balance;
    const credit = this.props.location.state.credit;

    return (
      <div>
        {this.state.details.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.details.map((item, index) => {
            let tempEventname = item.eventname;
            const passEventname = tempEventname;
            const campaignstatus = item.campaignstatus;
            let budget = item.budget;
            let ticketset = item.ticketid;
            let markticketset = "";
            let inCheckmark = "";
            let thumbnail = item.thumbnail;
            let pausebtn = "";

            poster =
              "https://karamale.com/apps/kscanner/posters/" +
              businessid +
              "/" +
              item.eventid +
              "/" +
              item.thumbnail;

            return (
              <div className={"col-lg-12 p-0"}>
                {item.balance <= 6 && item.event_day > curDate ? (
                  <div className="item-select-active w-100 pr-1 pl-1 pb-2 pt-3">
                    <div className="w-100">
                      <div className="row" style={{ display: "flex" }}>
                        <div
                          className="col-12 mb-2 pt-0 font-size-12"
                          style={{ color: "red" }}
                        >
                          <i>
                            <IoIosCloseCircleOutline size={15} /> This event is
                            currently not active
                          </i>
                        </div>
                        <div className="col-lg-2 col-3 col-sm-2 text-center">
                          <div
                            style={{
                              backgroundImage: `url(` + poster + `)`,
                              width: 55,
                              height: 55,
                              borderRadius: 100,
                              backgroundSize: "100%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="col-lg-10 col-9 col-sm-10"
                          style={{ cursor: "pointer" }}
                        >
                          <label
                            className="item-names w-100"
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            {item.event_name}
                            <br />
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            >
                              {item.eventdate}
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : item.status !== "running" ? (
                  ""
                ) : item.event_day < curDate ? (
                  <div className="item-select-active w-100 pr-1 pl-1 pb-2 pt-3">
                    <div className="w-100">
                      <div className="row" style={{ display: "flex" }}>
                        <div
                          className="col-12 mb-2 pt-0 font-size-12"
                          style={{ color: "red" }}
                        >
                          <i>
                            <IoIosCloseCircleOutline size={15} /> This event has
                            passed, tickets can no longer be sold
                          </i>
                        </div>
                        <div className="col-lg-2 col-3 col-sm-2 text-center">
                          <div
                            style={{
                              backgroundImage: `url(` + poster + `)`,
                              width: 55,
                              height: 55,
                              borderRadius: 100,
                              backgroundSize: "100%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="col-lg-10 col-9 col-sm-10"
                          style={{ cursor: "pointer" }}
                        >
                          <label
                            className="item-names w-100"
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            {item.event_name}
                            <br />
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            >
                              {item.eventdate}
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="item-select w-100 pr-1 pl-1 pb-2 pt-3 mb-3">
                    <Link
                      to={{
                        pathname: "/eventdescription",
                        search:
                          "q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "&budgetBalance=" +
                          item.balance +
                          "&boostername=" +
                          localStorage.getItem("boostername") +
                          "&posterUri=https://karamale.com/apps/kscanner/posters/" +
                          businessid +
                          "/" +
                          item.eventid +
                          "/" +
                          item.thumbnail +
                          "&venue=" +
                          item.event_location +
                          "&description=" +
                          item.event_des +
                          "&businessid=" +
                          businessid +
                          "&eventid=" +
                          item.eventid +
                          "&eventname=" +
                          item.event_name +
                          "&eventday=" +
                          item.event_day +
                          "&starttime=" +
                          item.start_time +
                          "&endtime=" +
                          item.finish_time +
                          "&boosterid=" +
                          localStorage.getItem("boosterid") +
                          "&activation=" +
                          activation +
                          "&q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "&boosteremail=" +
                          this.props.location.state.boosteremail +
                          "&boosterphone=" +
                          this.props.location.state.boosterphone +
                          "&q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "&businessname=" +
                          this.props.location.state.businessname +
                          "&activation=" +
                          this.props.location.state.activation +
                          "&balance=" +
                          this.props.location.state.balance +
                          "&q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "&credit=" +
                          this.props.location.state.credit +
                          "&q=" +
                          uuid6 +
                          "&queryid=" +
                          uuid5 +
                          "&pageid=" +
                          uuid4 +
                          "&uid=" +
                          uuid3 +
                          "&qKey=" +
                          uuid1 +
                          "&key=" +
                          uuid2 +
                          "&uuid=" +
                          uuid +
                          "&eventDate=" +
                          item.eventdate,
                        state: {
                          budgetBalance: item.balance,
                          boostername: this.props.location.state.boostername,
                          posterUri:
                            "https://karamale.com/apps/kscanner/posters/" +
                            businessid +
                            "/" +
                            item.eventid +
                            "/" +
                            item.thumbnail,
                          venue: item.event_location,
                          description: item.event_des,
                          businessid: businessid,
                          eventid: item.eventid,
                          eventname: item.event_name,
                          eventday: item.eventdate,
                          starttime: item.start_time,
                          endtime: item.finish_time,
                          activation: activation,
                          boosteremail: this.props.location.state.boosteremail,
                          boosterphone: this.props.location.state.boosterphone,
                          boostername: this.props.location.state.boostername,
                          businessname: this.props.location.state.businessname,
                          businessid: this.props.location.state.businessid,
                          boosterid: this.props.location.state.boosterid,
                          activation: this.props.location.state.activation,
                          balance: this.props.location.state.balance,
                          credit: this.props.location.state.credit,
                          boosterid: localStorage.getItem("boosterid"),
                        },
                      }}
                      className="w-100 textDecoration"
                    >
                      <div className="row" style={{ display: "flex" }}>
                        <div className="col-12 mb-2 pt-0 font-size-12 color-green">
                          <i>
                            {" "}
                            <IoIosThumbsUp size={15} /> Scheduled to happen...
                          </i>
                        </div>
                        <div className="col-lg-2 col-3 col-sm-2 text-center">
                          <div
                            style={{
                              backgroundImage: `url(` + poster + `)`,
                              width: 55,
                              height: 55,
                              borderRadius: 100,
                              backgroundSize: "100%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="col-lg-10 col-9 col-sm-10"
                          style={{ cursor: "pointer" }}
                        >
                          <label
                            className="item-names w-100"
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            {item.event_name}
                            <br />
                            <label
                              style={{
                                color: "grey",
                                fontSize: 13,
                                fontWeight: "normal",
                                textTransform: "none",
                                cursor: "pointer",
                              }}
                            >
                              {item.eventdate}
                            </label>
                          </label>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    if (localStorage.getItem("boosterid") === null) {
      return <Redirect to="/" />;
    }

    const boosteremail = this.props.location.state.boosteremail;
    const boosterphone = this.props.location.state.boosterphone;
    const boostername = this.props.location.state.boostername;
    const businessname = this.props.location.state.businessname;
    const businessid = this.props.location.state.businessid;
    const boosterid = this.props.location.state.boosterid;
    const activation = this.props.location.state.activation;
    const balance = this.props.location.state.balance;
    const credit = this.props.location.state.credit;
    return (
      <div className="view">
        <Header3 />
        <div className="container ">
          <div className="row justify-content-center just-container-inside">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-6 pl-0">
                      <Link
                        to={{
                          pathname: "/Boosterdashboard",
                          state: {
                            balance: balance,
                            credit: credit,
                            boosteremail: boosteremail,
                            boosterphone: boosterphone,
                            activation: activation,
                            businessid: businessid,
                            businessname: businessname,
                            boosterid: boosterid,
                            boostername: boostername,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    {/* <div className="col-lg-7 col-6 text-right">
                      <label className="back-arrow">
                        <Link
                          to={{
                            pathname: "addevent",
                            state: { prevpage: "myevents" },
                          }}
                          className="link-btn"
                        >
                          <div className="edit-btn">Add event</div>
                        </Link>
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0 mb-0">
                        ACTIVE EVENTS
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.details()}
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Selltickets;
