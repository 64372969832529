import React from "react";
import "./App.css";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Header5 from "./components/Header5";
import { FaMoneyBillAlt } from "react-icons/fa";
import { IoMdBusiness } from "react-icons/io";
import { HiCursorClick } from "react-icons/hi";
import { BsAppIndicator } from "react-icons/bs";
import { SiMinutemailer } from "react-icons/si";

class Aboutkaramale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "About Karamale - Know more";
  }

  render() {
    return (
      <div className="view">
        {localStorage.getItem("partnerid") !== null ? <Header5 /> : <Header />}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-10 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 p-0">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12 p-0">
                    <div className="karamale-banner row justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <h2 className="color-white">
                          <span>Selling tickets made easy</span>
                        </h2>
                        <label className="color-white">
                          Create and launch events. Start selling in minutes.
                        </label>
                        <br />
                        <Link to="/business">
                          <label className="become-an-affiliate-btn">
                            Sign up
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12">
                    <span className="">
                      <h3> About Karamale software</h3>
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-selet">
                    <div className="col-12">
                      <label className="item-names">
                        Karamale.com is a ticket software, a platform that
                        allows event organisers, event planners and event
                        managers to create, launch events and sell tickets.
                        <br />
                        <br />
                        <span>Who can use Karamale?</span>
                        <br />
                        As mentioned above, <span>event organisers</span>,{" "}
                        <span>event planners</span> and{" "}
                        <span>event managers</span> are the primary users of the
                        software, anyone who can host an event and would like to
                        sell tickets; Karamale is probably the best platform to
                        use.
                        <br />
                        <br />
                        <span>How does it work?</span>
                        <br />
                        As someone who wants to sell tickets, the first step is
                        to create an account as an event organiser, Karamale
                        will then send you a verification code via email, use
                        that code to verify your account, after the verification
                        process, you'll then be taken to your dashboard where
                        all the magic happens, your dashboard is a gateway to
                        all the features.
                        <br />
                        <br />
                        You'll then be able to create events, load tickets, set
                        tickets price. <br />
                        <span>NB: </span> There's no limit to how many events
                        you can create, the possibilities are limitless.
                        <br />
                        <br />
                        <span>When can I create an event?</span>
                        <br />
                        That's why many organisers use Karamale because there's
                        no specific time to create an event, you can create an
                        event anytime anywhere, all you need is a computer and
                        internet connection, you can create an event at 00:00 or
                        15:00; whenever is suits you, you have control over the
                        system, your task is to feed the system with information
                        and the system will take care of the rest.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="mb-3">Join 100s of organisers</h5>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <HiCursorClick size={70} color={"#999900"} />
                        <br />
                        <br />
                        <span className="font-size-20">Sign up</span>
                        <br />
                        Don't deprive your organisation of progress, join 100s
                        of organisers and start creating and launching events.
                        <br />
                        <br />
                        Sell tickets right away using the best ticket software
                        in the market.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <BsAppIndicator size={70} color={"#ffa500"} />
                        <br />
                        <br />
                        <span className="font-size-20">Dashboard</span>
                        <br />
                        Access to your own dashboard, you can access the
                        dashboard anytime you want. <br />
                        <br />
                        This is where all the magic happens, it is from the
                        dashboard where you can do anything.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <SiMinutemailer size={70} color={"#329932"} />
                        <br />
                        <br />
                        <span className="font-size-20">Marketing</span>
                        <br />
                        Kick off your marketing campaign on Karamale, you can
                        choose between SMS and Email marketing, decide which
                        audience you want to market your next event to, you can
                        market to your existing audience, Karamale audience or
                        upload your own audience.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="mb-3">
                With Karamale, possibilities are limitless
              </h5>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/dashboard.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Dashboard</span>
                        <br />
                        Get all the features from your dashoard, this is your
                        gateway to all things you can do on Karamale.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/createevent.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Create an event</span>
                        <br />
                        In minutes; your event can be on the system and ready to
                        be listed for your audience to buy tickets.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/eventlisted.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Your listed event</span>
                        <br />
                        Your event will appear on the landing page, making it
                        open to your audience to explore and buy tickets.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="mb-3">All things Analytics</h5>
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-0">
                <div className="row align-self-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/revenue.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Revenue</span>
                        <br />
                        Know your revenue, know how much you are making.
                        Karamale gives you access to all things analytics, with
                        sales revenue at your fingertips nothing is impossible.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/salesprogress.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Sales progress</span>
                        <br />
                        Get insight to your sales, know how your team is
                        performing, know how much worth of tickets you sold and
                        how is left. All this at your fingertips. Know your
                        income per each ticket category.
                      </div>
                    </div>

                    <div className="col-lg-4 col-12 mb-4">
                      <div className="blocks-3">
                        <img
                          src="http://karamale.com/apps/kscanner/icons/performance.jpeg"
                          width={"100%"}
                        />
                        <br />
                        <br />
                        <span className="font-size-20">Performance</span>
                        <br />
                        Track your daily sales performance, compare today's
                        sales with yesterday's sales, determine whether or not
                        you are perfoming. With all things Analytics; you get
                        more insight to your sales data.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-selet">
                    <div className="col-12">
                      <label>
                        <span>What is a campaign?</span>
                        <br />
                        Karamale is campaign driven, as an organiser you can
                        create events for free; however, in order to activate
                        those events you have to create a campaign for whichever
                        event you want activate, a campaign requires a budget in
                        order to create it successfully.
                        <br />
                        <br />
                        A budget is an amount you pay Karamale to successfully
                        create a campaign and activate your event for others to
                        start buying your tickets, everytime your customers buy
                        your tickets, Karamale takes its share of 5% from that
                        budget, this process gets repeated until your buget is
                        depleted.
                        <br />
                        <br />
                        <label className="mb-0 small-details">
                          <span>NB:</span> A budget is the only money you'll
                          ever pay Karamale, whatever money you generate from
                          your ticket sales is all yours; Karamale has no claim
                          to it.
                        </label>
                        <br />
                        <br />
                        Campaigns also allows you to see data about your event,
                        you are able to see <span>Reach</span>,{" "}
                        <span>Impressions</span>,<span>Ticket admissions</span>,{" "}
                        <span>Unique buyers</span>, <span>Remaing balance</span>{" "}
                        from your budget, your <span>Expenditure</span> and
                        more...
                        <br />
                        <br />
                        <span>What is a budget?</span>
                        <br />
                        A budget is what you pay Karamale in order to sell
                        tickets on Karamale, a budget is not fixed, you decide
                        how much you spend on the budget, you can spend as
                        little as R10, R50, R100 etc, your money your rules.
                        <br />
                        <br />
                        That's another reason why organisers choose Karamale, it
                        is cost effective and we don't impose fees on you.
                        <br />
                        <br />
                        You can increase a budget as you wish, be it you
                        increase with R10 or more; that's up to you. We want
                        make Karamale convenient for you; not only tech wise,
                        but also cost-effective wise.
                        <br />
                        <br />
                        <label className="mb-0 small-details">
                          <span>NB: </span> A budget is the only money you'll
                          ever pay Karamale, whatever money you generate from
                          your ticket sales is all yours; Karamale has no claim
                          to it.
                        </label>
                        <br />
                        <br />
                        <span>(Take note)</span>
                        <br />
                        A budget is required when you are creating a campaign
                        for a particular event that you want to activate, and
                        depending on the dates you choose for your campaign; as
                        soon as you pay the budget, your event will be
                        immediately listed for your customers to start buyintg
                        tickets.
                        <br />
                        <br />
                        <span>Can I use promoters on Karamale?</span>
                        <br />
                        Excellent question: Karamale is a comprehensive oiled
                        machine, as an event organiser you can use promoters,
                        Karamale makes it possible for organisers to create and
                        manage accounts for their promoters.
                        <br />
                        <br />
                        In order for your promoters to sell tickets on your
                        behalf using Karamale software, as an organiser, you'll
                        have to create login details for each promoter, then
                        your promoter will use those details to login and start
                        selling tickets, as an organiser; you'll have access to
                        your promoters' sales performance, you'll know how many
                        tickets each promoter has sold, how much income they
                        have generated from those tickets, and how many tickets
                        they've sold per each category (General, VIP, VVIP).
                        <br />
                        <br />
                        <span>Non-performing promoter</span>
                        <br />
                        There will be those promoters who are under-performing
                        or are not even active on the system, such promoters
                        need to be dealt with decisively, as organiser, Karamale
                        has created tools for you to deal with such promoters,
                        if a promoter does not perform and they are of no value
                        to your organisation; you have two options to deal with
                        such, you can either deactivate their account which will
                        temporarily suspend them or you can delete their
                        account, however, deleting their account is irreversable
                        i.e. is a permanent action, so if you not sure if you'll
                        need them in future, you can just deactivate their
                        account and reactivate later.
                        <br />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-selet">
                    <div className="col-12">
                      <label>
                        <span>How do I scan?</span>
                        <br />
                        Another excellent question: Karamale has developed an
                        app that you an download on your mobile phone, this app
                        enables you to turn your mobile device into a scanner.
                        You don't have buy or rent a scanner, those are extra
                        costs for your organisation, remember what we said, we
                        said "Karamale is cost-effective", those are not just
                        words, we mean it.
                        <br />
                        <br />
                        Each scanning activity is tracked, Karamale knows how
                        many tickets were scanned. Collecting such data is
                        important for you as an organiser, why? because Karamale
                        is able to present to you the number of people that came
                        to the event vs the number of people that bought tickets
                        vs the number of people that didn't come to the event.
                        Karamale is a data processing machine; we have mastered
                        the art of data processing to the benefit of organisers.
                        <br />
                        <br />
                        With Karamale, fake tickets are a thing of the past,
                        with Karamale no way a fake ticket can gain access, our
                        app knows which ticket is valid and which one is not;
                        even if you use multiple devices to scan at various
                        entry points, the app will know, everytime you scan, the
                        app gives a response on the screen with a bit of
                        information about the ticket, information such as
                        admissions, category (Whether or not the ticket is a
                        VIP, VVIP, or General) and the name of the ticket
                        holder, if the ticket is valid the app will give a
                        response that says "Access granted", on the other hand;
                        if the ticket is a counterfeit, the app will give a
                        response that says "Ticket is already scanned, access
                        denied".
                        <br />
                        <br />
                        <span>
                          Does Karamale cater for both physical and digital?
                        </span>
                        <br />
                        Lol... another excellent question: Karamale does cater
                        for both formats, organisers can print tickets directly
                        from the system, however, the disadvantage of selling
                        physical tickets is that; Karamale won't be able to
                        collect data in terms of customer information, such as
                        name, email and phone, we strongly recommend that you go
                        digital, with digital we are able to curate, collect and
                        process data at a high scale and present that data to
                        you.
                        <br />
                        <br />
                        However, both formats have the same level of security in
                        terms of ticket validation, we are still able to track
                        scanning activities and more...
                        <br />
                        <br />
                        Printing tickets directly from Karamale only cost R1.50
                        per ticket.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 p-0">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12 p-0">
                    <div className="karamale-banner-bottom row justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <h2 className="color-white">
                          <span>
                            Create, launch and sell tickets right away
                          </span>
                        </h2>
                        <label className="color-white">
                          Easy and simple. 10 minutes is all you need.
                        </label>
                        <br />
                        <Link to="/business">
                          <label className="become-an-affiliate-btn">
                            Sign up
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Aboutkaramale;
