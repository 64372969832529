import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactTooltip from "react-tooltip";
import Navigation from "./components/Ranknav";
import National from "./National";
import Provincial from "./Provincial";
import Regional from "./Regional";
import { BsArrowLeft } from "react-icons/bs";
import { BsCircleFill } from "react-icons/bs";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";


class Ranking extends Component {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }
  handleBack() {
    this.props.history.push('/');
  }
  render() {
    // let history = useHistory();/
    return (
      <Router>
        <div className="row p-2 pl-4">
          <div className="col-lg-10 col-md-10 col-sm-12 offset-lg-2 offset-md-2 text-right">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-10">
                <span className="defineColor redText textLight text-size-small mt-3">
                  <BsCircleFill /> &nbsp; Attendance
                </span>
                <span className="defineColor greenText textLight text-size-small mt-3">
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  <BsCircleFill /> &nbsp; Position/Events
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-2 mt-3">
                <label to={"/"} className="backArrow" data-tip="Home" onClick={this.handleBack}>
                  <ReactTooltip />
                  <BsArrowLeft />
                </label>
                &nbsp;&nbsp;{" "}
                <span class="greyColor kr-text">Home</span>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3 p-0">
            <Navigation />
          </div>
          <div className="col-lg-10 col-md-10 col-sm-9">
            {/* <National /> */}
            <Switch>
              <Route path={"/national"} component={National} />
              <Route path={"/provincial"} component={Provincial} />
              <Route path={"/regional"} component={Regional} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default Ranking;
