import React from "react";
import "./App.css";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { FaHandshake } from "react-icons/fa";
import {
  IoIosRadioButtonOn,
  IoIosRadioButtonOff,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import Footer from "./components/Footer";
import Header from "./components/Header";

// export default withRouter (Redirect);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      spinning: "none",
      updateDisplay: "block",
      optionOne: "item-select",
      onRadioOne: false,
      offRadioOne: true,
    };
  }

  componentDidMount() {
    document.title = "Karamale - Log in or sign up";
    localStorage.removeItem("partner");
  }

  existingAudience = () => {
    const { onRadioOne, offRadioOne } = this.state;
    if (offRadioOne == true) {
      this.setState({
        optionOne: "item-select-active",
        offRadioOne: false,
        onRadioOne: true,
      });
      localStorage.setItem("partner", "yes");
    } else {
      this.setState({
        optionOne: "item-select",
        offRadioOne: true,
        onRadioOne: false,
      });
      localStorage.removeItem("partner");
    }
    // alert(localStorage.getItem("partner"));
    // localStorage.setItem("audience", audience);
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePassword = (event) => {
    this.setState({
      password: event.target.value,
      errorDisplay: "none",
    });
  };

  submitLogin = async (event) => {
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 4000);
    this.setState({ updateDisplay: "none", spinning: "block" });
    const email = this.state.email;
    const password = this.state.password;
    const posterUri = "";
    let partner = "";

    if (localStorage.getItem("partner") !== null) {
      partner = "&partner=yes";
    } else {
      partner = "";
    }

    // alert(partner);

    // get user operating system
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }
    const OS = os;
    if (email.trim() === "") {
      this.setState({
        error: "Email or phone required",
        errorDisplay: "block",
        updateDisplay: "block",
        spinning: "none",
      });
    } else if (password.trim() === "") {
      this.setState({
        error: "Password is required",
        errorDisplay: "block",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      await Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=LOGIN" +
          partner +
          "&os=" +
          OS +
          "&poster=" +
          posterUri +
          "&email=" +
          email +
          "&password=" +
          password
      ).then((res) => {
        if (res.data.value == 0) {
          this.setState({
            error: "Incorrect details. Login failed",
            errorDisplay: "block",
            updateDisplay: "block",
            spinning: "none",
          });
        } else {
          // alert(partner + " - " + res.data.accounttype);
          if (res.data.accounttype == "customer") {
            // alert(res.data.accounttype + " - " + partner);
            localStorage.setItem("customerid", res.data.customerid);
            localStorage.setItem("customername", res.data.customername);
            localStorage.setItem("accounttype", res.data.accounttype);
            this.props.history.push({
              pathname: "/customerdashboard",
              state: {
                accounttype: res.data.accounttype,
                accountid: res.data.customerid,
                customername: res.data.customername,
              },
            });
          } else if (res.data.accounttype == "business") {
            localStorage.setItem("businessid", res.data.businessid);
            localStorage.setItem("businessname", res.data.businessname);
            localStorage.setItem("accounttype", res.data.accounttype);
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                accounttype: res.data.accounttype,
                accountid: res.data.businessid,
                businessname: res.data.businessname,
              },
            });
          } else {
            // alert(res.data.accounttype);
            localStorage.setItem("partnerid", res.data.partnerid);
            localStorage.setItem("partnername", res.data.partnername);
            localStorage.setItem("accounttype", "partner");
            this.props.history.push({
              pathname: "/in",
              state: {
                accounttype: "partner",
                accountid: res.data.partnerid,
                partnername: res.data.partnername,
              },
            });
          }
        }
      });
    }
  };

  render() {
    if (localStorage.getItem("customername") !== null) {
      return <Redirect to="/customerdashboard" />;
      //   return exit(1);
    } else {
    }

    const { onRadioOne, offRadioOne } = this.state;

    return (
      <div className="view">
        {/* <Header /> */}
        <div className="container">
          <div className="row justify-content-center align-content-center login-screen-container">
            <div className="col-lg-6 col-10 align-self-center login-container-adjust">
              <div className="log-title login-logo">
                <Link to="/">
                  <img src="https://karamale.com/karamale.png" />
                </Link>
              </div>
              <h2 className="login-h2">
                Karamale helps event organizations to create, launch events and
                sell tickets.
                <br />
                <label className="mb-0 color-blue font-size-15">
                  <Link to="/aboutkaramale" className="color-blue font-normal">
                    Learn more...
                  </Link>
                </label>
              </h2>
            </div>

            <div className="col-lg-5 pl-0 pr-0">
              <div className="BoxDashboardContainer">
                <input
                  type="text"
                  placeholder="Email address or phone number"
                  value={this.state.email}
                  onChange={this.handleEmail}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handlePassword}
                />
                <div className="col-12 pl-0 pr-0 mb-2 mt-3">
                  <span>Are you an Affiliate?</span>
                </div>
                <div
                  className={this.state.optionOne + " w-100 mb-3"}
                  onClick={this.existingAudience}
                >
                  <div className="link-btn w-100 menu-links border-1">
                    <div className="col-1 col-lg-1 text-center">
                      {onRadioOne && (
                        <IoIosCheckmarkCircleOutline
                          size={28}
                          color={"#3399cc"}
                        />
                      )}
                      {offRadioOne && (
                        <IoIosRadioButtonOff size={28} color={"#3399cc"} />
                      )}
                    </div>
                    <div className="col-2 col-lg-1 text-center hand-shake-log-in">
                      <FaHandshake size={28} color={"#3399cc"} />
                    </div>

                    <div
                      className="col-11 col-lg-10 pl-4"
                      style={{ marginTop: -3 }}
                    >
                      <label className="item-names">
                        Yes, I am an Affiliate
                      </label>
                      {/* <label className="float-right pt-1">{audience}</label> */}
                    </div>
                  </div>
                </div>

                <div className="col-12 response textMedium pl-0">
                  {this.state.response}
                </div>
                <button onClick={() => this.submitLogin()}>
                  <span
                    style={{
                      display: this.state.updateDisplay,
                    }}
                  >
                    Log In
                  </span>
                  <span
                    style={{
                      display: this.state.spinning,
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      // css={override}
                      size={20}
                    />
                  </span>
                </button>
                <div className="col-12 text-center">
                  <Link
                    to={{ pathname: "/password", state: { password: true } }}
                  >
                    <label className="forgotPasswordText text-center">
                      Forgot password
                    </label>
                  </Link>
                  <label className="mb-0 pipe-holder">
                    {" "}
                    &nbsp;&nbsp;
                    <label className="color-grey-light pipe">|</label>
                    &nbsp;&nbsp;
                  </label>
                  <label className="break-to-next-line mb-0">
                    <br />
                  </label>
                  <Link to={{ pathname: "/aboutsalesparnter" }}>
                    <label className="forgotPasswordText text-center">
                      Become an Affiliate
                    </label>
                  </Link>
                </div>

                <div className="horizontal-line "></div>

                <Link to="/register">
                  <div className="create-account-btn text-center">
                    Create new Account
                  </div>
                </Link>
              </div>

              <div className="text-footer text-center mb-4">
                <span>Create an event</span>, load <span>tickets</span> and
                more...
              </div>
            </div>
          </div>
        </div>

        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Login;
