import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import {
  IoIosLogOut,
  IoIosAlbums,
  IoMdCopy,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsPerson, BsLock, BsPen } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import Header from "./components/Header5";
import TopBarProgress from "react-topbar-progress-indicator";
import ClipLoader from "react-spinners/ClipLoader";
import { v4 as uuidv4 } from "uuid";
// import ScriptTag from "react-script-tag";

const uuid = uuidv4().replace("-", "").substr(1, 11);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      error: "",
      errorDisplay: "none",
      details: [],
      affiliateid: "",
      loadBarProgress: false,
      showSavebtn: true,
      showCliploader: false,
      confirmedCopy: false,
      placeholderText: true,
      affiliateCount: 0,
    };
  }

  componentDidMount() {
    const partnername = localStorage.getItem("partnername");
    const accounttype = localStorage.getItem("accounttype");
    const partnerid = localStorage.getItem("partnerid");
    document.title = partnername + " - Affiliate program";

    // window.location.reload();

    if (!localStorage.getItem("customid")) {
      localStorage.setItem("customid", partnerid + "" + uuid);
    }

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=RECRUITS&subquery=AFFILIATECOUNT&partnerid=" +
        partnerid
    ).then((response) => {
      if (response.data.val == 1) {
        this.setState({
          affiliateCount: response.data.affiliateCount,
        });
      } else {
        this.setState({
          affiliateCount: 0,
        });
      }
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PARTNERDETAILS&partnerid=" +
        partnerid
    ).then((response) => {
      if (response.data.affiliateid == "") {
        this.setState({
          affiliateid: "",
        });
      } else {
        this.setState({
          affiliateid: response.data.affiliateid,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const partnername = localStorage.getItem("partnername");
    const partnerid = localStorage.getItem("partnerid");
    if (
      prevState.affiliateid !== this.state.affiliateid ||
      prevState.affiliateCount !== this.state.affiliateCount
    ) {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PARTNERDETAILS&partnerid=" +
          partnerid
      ).then((response) => {
        this.setState({
          affiliateid: response.data.affiliateid,
        });
      });
    }
  }
  save = () => {
    const partnerid = localStorage.getItem("partnerid");
    const affiliateid = localStorage.getItem("customid");

    this.setState({
      showCliploader: true,
      showSavebtn: false,
      loadBarProgress: true,
    });

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=SAVEAFFILIATEID&partnerid=" +
        partnerid +
        "&affiliateid=" +
        affiliateid
    ).then((response) => {
      this.setState({
        affiliateid: response.data.affiliateid,
        showCliploader: false,
        showSavebtn: true,
        loadBarProgress: false,
      });
    });

    this.props.history.push({
      pathname: "/in",
    });
  };

  copyURL = () => {
    navigator.clipboard.writeText(
      "https://karamale.com/?affiliateid=" + this.state.affiliateid
    );

    this.setState({ confirmedCopy: true, placeholderText: false });

    setTimeout(() => {
      this.setState({ confirmedCopy: false, placeholderText: true });
    }, 3500);
  };

  render() {
    if (localStorage.getItem("partnername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }

    const partnername = localStorage.getItem("partnername");
    const partnerid = localStorage.getItem("partnerid");
    const accounttype = localStorage.getItem("accounttype");

    const {
      loadBarProgress,
      showCliploader,
      showSavebtn,
      confirmedCopy,
      placeholderText,
    } = this.state;

    return (
      <div className="view">
        <Header />

        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 mt-5  pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-1 pr-1">
                <div className="row align-self-center justify-content-center">
                  <div className="item-user">
                    <div className="col-lg-1 col-2 col-sm-1 text-center">
                      <IoIosAlbums size={30} className="profile-icon" />
                    </div>
                    <div className="col-lg-11 col-10 col-sm-11">
                      <label className="custername-profile">
                        {partnername.toUpperCase()}
                      </label>
                    </div>
                  </div>

                  {this.state.affiliateid.trim() !== "" ? (
                    <div className="col-12 pl-1 pr-2 mt-2">
                      <div className="col-12">
                        <label className="small-details w-100 mb-0">
                          <div className="mb-0 url-holder w-100 align-items-center row">
                            <div className="col-12 pl-0 pr-0">
                              <div className="col-12 p-0 mb-2">
                                <span className="color-skyblue">
                                  Share your affiliate id
                                </span>
                              </div>
                              <div className="row w-100">
                                <div className="col-lg-2 col-4 col-md-2 p-0">
                                  <iframe
                                    src={
                                      "https://www.facebook.com/plugins/share_button.php?href=https://karamale.com/?affiliateid=" +
                                      this.state.affiliateid +
                                      "&layout=button_count&size=large&appId=389055581470164&width=110&height=28"
                                    }
                                    width="110"
                                    height="28"
                                    style={{
                                      border: "none",
                                      overflow: "hidden",
                                      marginRight: -8,
                                      // marginBottom: -10,
                                    }}
                                    scrolling="no"
                                    frameBorder="0"
                                    allowfullscreen="false"
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                  ></iframe>
                                </div>
                                {/* <div className="col-lg-2 col-3 col-md-2 p-0">
                                  <a
                                    class="twitter-share-button"
                                    data-text={
                                      "Selling tickets has never been this innovative, if you are an event organizer, you have to use Karamale. This software is something else https://karamale.com/?affiliateid=" +
                                      this.state.affiliateid
                                    }
                                    data-url={"#karamale"}
                                    href={"https://twitter.com/intent/share"}
                                    data-size="large"
                                  >
                                    Tweet
                                  </a>
                                </div> */}
                                <div className="col-lg-3 col-6 col-md-2 p-0">
                                  <a
                                    href={
                                      "whatsapp://send?text=Selling tickets has never been this innovative, if you are an event organizer, you have to use Karamale. This software is something else https://karamale.com/?affiliateid=" +
                                      this.state.affiliateid
                                    }
                                  >
                                    <label className="whatsapp-share-btn">
                                      <FaWhatsapp size={16} /> Whatsapp
                                    </label>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="h-divider w-100 mt-1 border-bottom-color mb-1" />
                            <div className="col-9 p-0">
                              https://karamale.com/?affiliateid=
                              {this.state.affiliateid}
                            </div>
                            <div
                              className="float-right color-blue text-right mb-0 col-3 p-0"
                              data-tip="Copy affiliate ID"
                            >
                              {confirmedCopy && (
                                <label className="font-size-12 color-grey mb-0 mr-2">
                                  ID copied!
                                </label>
                              )}

                              <IoMdCopy
                                size={25}
                                onClick={() => {
                                  this.copyURL();
                                }}
                                class="color-grey"
                              />
                              <ReactTooltip />
                              <br />
                            </div>
                          </div>
                          <label className="mt-1 mb-0 font-size-12">
                            Above is your Affiliate ID, use it to refer event
                            organisations to Karamale, this will enable Karamale
                            to link those organisations with your affiliate
                            account.
                          </label>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 pl-1 pr-2 mt-2">
                      <div className="col-12">
                        <label className="small-details w-100">
                          <span>Save this affiliate id</span>
                        </label>
                        <div className="row align-items-center">
                          <div className="col-lg-10 col-8 col-sm-10 col-md-10 p-0">
                            <label className="edit-input mt-0 text-input-bg-color-white small-details pl-0 pr-0">
                              https://karamale.com/?affiliateid=
                              {localStorage.getItem("customid")}
                            </label>
                          </div>
                          <div className="col-lg-2 col-4 col-sm-2 col-md-2 p-0 pl-2 text-right">
                            <label class="back-arrow" style={{ marginTop: 2 }}>
                              <div
                                class="save-btn pb-0 pt-0"
                                style={{ width: 70 }}
                              >
                                {showSavebtn && (
                                  <label
                                    className="w-100 pt-2 pb-2 mb-0"
                                    onClick={() => {
                                      this.save();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Save
                                  </label>
                                )}

                                {showCliploader && (
                                  <label className="w-100 pt-2 pb-2 mb-0">
                                    <ClipLoader
                                      color={"#fff"}
                                      loading={true}
                                      // css={override}
                                      size={20}
                                    />
                                  </label>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="h-divider w-100"></div>
                  <div className="item-select">
                    <Link
                      to="/aboutsalesparnter"
                      className="link-btn w-100 menu-links pb-2"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosInformationCircleOutline
                          size={30}
                          color={"#6ca4bc"}
                        />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names w-100 color-blue">
                          About the program
                        </label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to="/recruits"
                      className="link-btn w-100 menu-links pb-2"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <FaHandshake size={30} color={"#66b266"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names w-100">
                          Recruits (Karamale clients){" "}
                          <label className="float-right small-details">
                            {this.state.affiliateCount}
                          </label>
                        </label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "profile",
                        state: {
                          partnerid: partnerid,
                          partnername: partnername,
                          accountid: partnerid,
                          accounttype: accounttype,
                        },
                      }}
                      className="link-btn w-100 menu-links"
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPerson size={30} color={"#3399cc"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Profile</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "changepassword",
                        state: {
                          credentials: "password",
                        },
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsLock size={30} color={"#006699"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">Change password</label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select">
                    <Link
                      to={{
                        pathname: "feedback",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <BsPen size={30} color={"#ffc62b"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-11">
                        <label className="item-names">
                          Send us feedback and more...
                        </label>
                      </div>
                    </Link>
                  </div>

                  <div className="item-select mb-2">
                    <Link
                      to={{
                        pathname: "logout",
                      }}
                      className="link-btn w-100 menu-links "
                    >
                      <div className="col-lg-1 col-2 col-sm-1 text-center">
                        <IoIosLogOut size={30} color={"red"} />
                      </div>
                      <div className="col-lg-11 col-10 col-sm-10">
                        <label className="item-names">Log out</label>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
