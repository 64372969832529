import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import Header2 from "./components/Header2";
import TopBarProgress from "react-topbar-progress-indicator";
import ClipLoader from "react-spinners/ClipLoader";

class Tickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      tickets: [],
      loadBarProgress: true,
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    document.title = customername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERTICKETS&subquery=GETTICKETS&customerid=" +
        customerid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ tickets: [] });
        } else {
          this.setState({ tickets: response.data, loadBarProgress: false });
        }
      })
      .catch((error) => console.log(error));
  }

  tickets = () => {
    let activeString = "";
    const customerid = localStorage.getItem("customerid");
    const customername = localStorage.getItem("customername");
    let event = "";

    return (
      <div>
        {this.state.tickets.length < 1 ? (
          <div>
            <label
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 13.5,
                color: "#5B7083",
              }}
            >
              No data found{" "}
            </label>
          </div>
        ) : (
          this.state.tickets.map((item, index) => {
            {
              item.activation == 1
                ? (activeString = (
                    <IoIosCheckmarkCircleOutline size={18} color="green" />
                  ))
                : (activeString = (
                    <IoIosRemoveCircleOutline size={18} color="red" />
                  ));
            }

            {
              item.eventname.length > 20
                ? (event = item.eventname.substring(0, 20) + "...")
                : (event = item.eventname);
            }

            let poster =
              "https://karamale.com/apps/kscanner/posters/" +
              item.businessid +
              "/" +
              item.eventid +
              "/" +
              item.thumbnail;

            return (
              <div className={"col-lg-12 p-0"}>
                <div className="item-select w-100">
                  <Link
                    to={{
                      pathname: "/previewtickets",
                      state: {
                        customername: customername,
                        customerid: customerid,
                        eventid: item.eventid,
                        transactiondate: item.transactiondate,
                        venue: item.venue,
                        eventname: item.eventname,
                        eventtime: item.eventtime,
                        eventday: item.eventday,
                        yearcreated: item.yearcreated,
                        theme: item.theme,
                        admissions: item.admissions,
                        totalcost: item.totalcost,
                      },
                    }}
                    className="w-100"
                  >
                    <div className="row" style={{ display: "flex" }}>
                      {/* <div className="col-lg-2 col-3 col-sm-2 text-center">
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      </div> */}
                      <div
                        className="col-lg-12 col-12 col-sm-10"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(` + poster + `)`,
                            width: 55,
                            height: 55,
                            borderRadius: 100,
                            backgroundSize: "100%",
                            marginRight: 10,
                          }}
                          className="float-left"
                        />
                        <label
                          className="item-names"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {event} <br />
                          <label
                            style={{
                              color: "grey",
                              fontWeight: "normal",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                          >
                            {item.eventday}
                          </label>
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  searchQuery = (text) => {
    let value = text.target.value;
    this.setState({ query: value });
    let query = value.split(" ").join("+");
    this.setState({ isLoading: true, loadingMerch: false });
    const customerid = localStorage.getItem("customerid");

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERTICKETS&subquery=SEARCHTICKETS&search=" +
        query +
        "&customerid=" +
        customerid
    )
      .then((response) => {
        // alert(response.data.val);
        if (response.data.val == 0) {
          this.setState({
            tickets: [],
          });
        } else {
          this.setState({
            tickets: response.data,
          });
        }
        // alert(response.data.search);
      })
      .catch((error) => console.log(error));
  };

  render() {
    if (localStorage.getItem("customerid") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const { loadBarProgress } = this.state;
    return (
      <div className="view">
        <Header2 />
        {loadBarProgress && <TopBarProgress />}
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-5 col-5 pl-0">
                      <Link to="/customerdashboard" className="link-btn">
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-7 col-7">
                      <input
                        type="text"
                        placeholder="Search tickets by event name"
                        value={this.state.query}
                        onChange={this.searchQuery}
                        className="search-ticket-input"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer mb-5 pl-3 pr-3">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">TICKETS</label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                {this.tickets()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Tickets;
