import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import ClipLoader from "react-spinners/ClipLoader";
import Header3 from "./components/Header3";

class Updateeventdetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      successError: "",
      errorDisplay: "none",
      businessname: localStorage.getItem("businessname"),
      eventname: this.props.location.state.eventname,
      eventdate: this.props.location.state.eventdate,
      eventid: this.props.location.state.eventid,
      poster: this.props.location.state.poster,
      theme: this.props.location.state.theme,
      venue: this.props.location.state.venue,
      expectednum: this.props.location.state.expectednum,
      enddate: this.props.location.state.enddate,
      time: this.props.location.state.time,
      endtime: this.props.location.state.endtime,
      event_date: this.props.location.state.event_date,
      finish_date: this.props.location.state.finish_date,
      description: "Loading...",
      spinning: "none",
      updateDisplay: "block",
    };
  }

  componentDidMount() {
    const eventid = this.props.location.state.eventid;

    const businessname = localStorage.getItem("businessname");
    const businessid = localStorage.getItem("businessid");
    document.title = businessname + " - Edit ";

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=ADDEVENT&subquery=EVENTDETAILS&eventid=" +
        eventid +
        "&accountid=" +
        businessid
    ).then((response) => {
      this.setState({
        description: response.data.description,
      });
    });
  }

  handleEventname = (event) => {
    this.setState({
      eventname: event.target.value,
      errorDisplay: "none",
    });
  };

  handleVenue = (e) => {
    // alert(e.target.value);
    this.setState({
      venue: e.target.value,
      errorDisplay: "none",
    });
  };

  handleTheme = (event) => {
    this.setState({
      theme: event.target.value,
      errorDisplay: "none",
    });
  };

  handleExpected = (event) => {
    this.setState({
      expectednum: event.target.value,
      errorDisplay: "none",
    });
  };

  handleDate = (event) => {
    this.setState({
      event_date: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEnddate = (event) => {
    this.setState({
      finish_date: event.target.value,
      errorDisplay: "none",
    });
  };

  handleTime = (event) => {
    this.setState({
      time: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEndtime = (event) => {
    this.setState({
      endtime: event.target.value,
      errorDisplay: "none",
    });
  };

  handleDescription = (event) => {
    this.setState({
      description: event.target.value,
      errorDisplay: "none",
    });
  };

  update = () => {
    const businessid = localStorage.getItem("businessid");
    const eventid = this.props.location.state.eventid;
    const accounttype = localStorage.getItem("accounttype");
    const businessname = this.state.businessname;
    const eventname = this.state.eventname;
    const venue = this.state.venue;
    const theme = this.state.theme;
    const expectednum = this.state.expectednum;
    const eventdate = this.state.eventdate;
    const enddate = this.state.enddate;
    const time = this.state.time;
    const endtime = this.state.endtime;
    const description = this.state.description;
    const finish_date = this.state.finish_date;
    const event_date = this.state.event_date;
    this.setState({ updateDisplay: "none", spinning: "block" });
    // alert(venue);

    if (this.state.eventname.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Please enter event name",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (this.state.venue.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Please enter venue",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (this.state.expectednum.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter number of people expected to attended",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (event_date === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter the event date",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (finish_date === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter the event end date",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (this.state.time.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter event time",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (this.state.endtime.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter event end time",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else if (this.state.description.trim() === "") {
      this.setState({ updateDisplay: "block", spinning: "none" });
      this.setState({
        error: "Enter description",
        errorDisplay: "block",
        successError: "error-login",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=ADDEVENT&subquery=UPDATEEVENT&eventid=" +
          eventid +
          "&visa=1&cash=0&accountid=" +
          businessid +
          "&event=" +
          eventname +
          "&venue=" +
          venue +
          "&theme=" +
          theme +
          "&expected=" +
          expectednum +
          "&startdate=" +
          event_date +
          "&enddate=" +
          finish_date +
          "&starttime=" +
          time +
          "&endtime=" +
          endtime +
          "&description=" +
          description
      )
        .then((response) => {
          if (response.data.val == 1) {
            this.setState({ updateDisplay: "block", spinning: "none" });
            this.setState({
              error: "Details updated",
              errorDisplay: "block",
              successError: "success-login",
              updateDisplay: "block",
              spinning: "none",
            });
          } else {
            // alert(accountype);
            this.setState({ updateDisplay: "block", spinning: "none" });
            this.setState({
              error: response.data.error,
              errorDisplay: "block",
              successError: "error-login",
            });
          }

          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);
        })
        .catch(function (error) {
          this.setState({ updateDisplay: "block", spinning: "none" });
          this.setState({
            error: error,
            errorDisplay: "block",
            successError: "error-login",
          });

          setTimeout(() => {
            this.setState({ errorDisplay: "none" });
          }, 4000);
        });
    }
  };

  handleLocation = () => {
    alert("text");
  };

  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const dtregistered = this.props.location.state.dtregistered;

    const prevpage = this.props.location.state.prevpage;
    const backpage = this.props.location.state.currentage;
    const eventname = this.props.location.state.eventname;
    const eventdate = this.props.location.state.eventdate;
    const eventid = this.props.location.state.eventid;
    const poster = this.props.location.state.poster;
    const theme = this.props.location.state.theme;
    const venue = this.props.location.state.venue;
    const expectednum = this.props.location.state.expectednum;
    const enddate = this.props.location.state.enddate;
    const time = this.props.location.state.time;
    const endtime = this.props.location.state.endtime;
    const event_date = this.props.location.state.event_date;
    const finish_date = this.props.location.state.finish_date;

    const currentVenue = this.state.venue;

    return (
      <div className="view">
        <Header3 />
        <div className="container">
          <div className="row justify-content-center just-container-insid pb-5">
            <div className="col-lg-7">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link
                        to={{
                          pathname: backpage,
                          state: {
                            eventid: eventid,
                            eventname: eventname,
                            poster: poster,
                            eventdate: eventdate,
                            theme: theme,
                            venue: venue,
                            expectednum: expectednum,
                            enddate: enddate,
                            time: time,
                            endtime: endtime,
                            event_date: event_date,
                            finish_date: finish_date,
                            prevpage: prevpage,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="save-btn" onClick={() => this.update()}>
                          <span
                            style={{
                              display: this.state.updateDisplay,
                            }}
                          >
                            {" "}
                            Update
                          </span>
                          <span
                            style={{
                              display: this.state.spinning,
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer  pl-2 pr-2">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        EDITING EVENT
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  Event name
                  <br />
                  <input
                    type="text"
                    placeholder="Event name"
                    value={this.state.eventname}
                    onChange={this.handleEventname}
                    className="edit-input"
                  />
                  <br /> <br />
                  <label>Venue</label>
                  <br />
                  {/* <GooglePlacesAutocomplete
                    apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                    styles={{
                      textInputContainer: {
                        backgroundColor: "grey",
                      },
                      textInput: {
                        height: 38,
                        color: "#5d5d5d",
                        fontSize: 16,
                      },
                    }}
                    textInputProps={{
                      onChange: (text) => {
                        this.handleVenue(text);
                      },
                    }}
                    selectProps={{
                      onChange: (data, details = null) =>
                        this.setState({ venue: data["value"]["description"] }),
                      fetchDetails: true,
                      placeholder: "Enter address or zip code",
                      defaultInputValue: this.state.venue,
                    }}
                  /> */}
                  <input
                    type="text"
                    placeholder="Enter your address or zip code"
                    value={this.state.venue}
                    onChange={this.handleVenue}
                    className="edit-input"
                    id="location-search-input"
                  />
                  {/* <div id="map" style={{ display: "none" }}></div> */}
                  <br />
                  Theme
                  <br />
                  <input
                    type="text"
                    placeholder="Theme"
                    value={this.state.theme}
                    onChange={this.handleTheme}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Number of people expected to attend
                  <br />
                  <input
                    type="text"
                    placeholder="Number of expected to attend"
                    value={this.state.expectednum}
                    onChange={this.handleExpected}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Date:{" "}
                  <label className="small-details">
                    currently set to {this.state.eventdate}
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="Set date"
                    value={this.state.event_date}
                    onChange={this.handleDate}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  End date:{" "}
                  <label className="small-details">
                    currently set to {this.state.enddate}
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="End date"
                    value={this.state.finish_date}
                    onChange={this.handleEnddate}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Time
                  <br />
                  <input
                    type="time"
                    placeholder="Set time"
                    value={this.state.time}
                    onChange={this.handleTime}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  End time
                  <br />
                  <input
                    type="time"
                    placeholder="End time"
                    value={this.state.endtime}
                    onChange={this.handleEndtime}
                    className="edit-input"
                  />
                  <br />
                  <br />
                  Description
                  <br />
                  <textarea
                    placeholder="Description"
                    value={this.state.description}
                    onChange={this.handleDescription}
                    className="edit-input"
                  />
                  <label className="back-arrow mb-0 bottom__button">
                    <div
                      className="save-btn float-right"
                      onClick={() => this.update()}
                    >
                      <span
                        style={{
                          display: this.state.updateDisplay,
                        }}
                      >
                        {" "}
                        Update
                      </span>
                      <span
                        style={{
                          display: this.state.spinning,
                        }}
                      >
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          // css={override}
                          size={20}
                        />
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Updateeventdetails;
