import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { AiOutlineQrcode } from "react-icons/ai";
import { RiQrCodeLine } from "react-icons/ri";
import Header2 from "./components/Header2";

var QRCode = require("qrcode.react");

class Zoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      password: "",
      response: "",
      error: "",
      errorDisplay: "none",
      previews: [],
    };
  }

  componentDidMount() {
    const customername = localStorage.getItem("customername");
    const customerid = localStorage.getItem("customerid");
    const eventid = this.props.location.state.eventid;
    document.title = customername;

    Axios.get(
      "https://karamale.com/apps/kscanner/webservices.php?query=PREVIEWTICKETS&customerid=" +
        customerid +
        "&eventid=" +
        eventid
    )
      .then((response) => {
        if (response.data.val == 0) {
          this.setState({ previews: [] });
        } else {
          this.setState({ previews: response.data });
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    if (localStorage.getItem("customername") === null) {
      return <Redirect to="/login" />;
      //   return exit(1);
    } else {
    }
    const customername = localStorage.getItem("customername");
    const eventname = this.props.location.state.eventname;

    const customerid = localStorage.getItem("customerid");
    const eventid = this.props.location.state.eventid;
    const transactiondate = this.props.location.state.transactiondate;
    const venue = this.props.location.state.venue;
    const eventtime = this.props.location.state.eventtime;
    const eventday = this.props.location.state.eventday;
    const yearcreated = this.props.location.state.yearcreated;
    const theme = this.props.location.state.theme;
    const admissions = this.props.location.state.admissions;
    const totalcost = this.props.location.state.totalcost;
    const ticketnumber = this.props.location.state.ticketnumber;
    const qrcodeValue = customerid + "-" + ticketnumber + "-" + eventid;
    return (
      <div className="view">
        <Header2 />
        <div className="container pb-5">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-4 pl-0">
                      <Link
                        to={{
                          pathname: "/previewtickets",
                          state: {
                            customername: customername,
                            customerid: customerid,
                            eventid: eventid,
                            transactiondate: transactiondate,
                            venue: venue,
                            eventname: eventname,
                            eventtime: eventtime,
                            eventday: eventday,
                            yearcreated: yearcreated,
                            theme: theme,
                            admissions: admissions,
                            totalcost: totalcost,
                          },
                        }}
                        className="link-btn"
                      >
                        <label className="back-arrow">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-8 text-right">
                      <label className="back-arrow">
                        <IoIosInformationCircleOutline
                          size={40}
                          color={"#fff"}
                        />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <span className="note-info">{ticketnumber}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 text-center">
                      <label className="ticket-title scan-title pl-0 pr-0">
                        {/* SCAN QRCODE TO ENSURE ACCESS */}
                        scan the QRCode to confirm access
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-12 text-center pb-5 pt-5">
                  <QRCode value={qrcodeValue} logoSize={30} size={250} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="error-login"
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Zoom;
