import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";

import Header from "./components/Header";

// export default withRouter (Redirect);

class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Fees - Karamale";
  }

  render() {
    if (localStorage.getItem("customername") !== null) {
      return <Redirect to="/customerdashboard" />;
      //   return exit(1);
    } else {
    }
    return (
      <div className="view">
        <Header />
        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-10 mt-5 pl-0 pr-0">
              <div className=" BoxDashboardContainer mb-5 pl-2 pr-2 pt-3">
                <div className="row align-self-center justify-content-center">
                  <div className="col-12">
                    <span className="">
                      <h3>Karamale fees</h3>
                    </span>
                  </div>

                  <div className="h-divider w-100"></div>

                  <div className="item-selec">
                    <div className="col-12">
                      <label className="item-names">
                        Want to sell tickets on Karamale? Look no further,
                        you've landed on the right platform. Probably the first
                        thing that came to your mind was "How much does it cost
                        to sell tickets on Karamale?"
                        <br />
                        <br />
                        Karamale has made it convenient and cost effective for
                        event organizers to sell tickets on our platform, having
                        said that, read the following
                        <br />
                        <br />
                        The costs around selling tickets on Karamale are
                        campaign driven: As an event organizer you set your own
                        budget, you can choose to spend R10, R20, R30 etc... its
                        your choice, however, your budget should match the
                        number of tickets you are aiming to sell and how much
                        each ticket will cost; from VVIP, VIP down to General
                        tickets.
                        <br />
                        <br />
                        Karamale makes it possible for you to sell tickets with
                        the little that you have, start with a small budget and
                        increase when you have enough money.
                        <br />
                        <br />
                        <b>What is a campaign?</b>
                        <br />
                        As an event organizer, you can create and enlist
                        whatever number of events you like; there's no limit,
                        however, in order to make those events live and have
                        people start buying tickets; you have to activate them;
                        this is where the <b>campaign</b> comes in.
                        <br />
                        <br />
                        For an event to go live you have to create a{" "}
                        <b>Campaign</b> for that event, this is where you get to
                        choose your budget, how much you willing to spend to
                        sell tickets on Karamale. <br />
                        <br />
                        So everytime you sell or someone buys a ticket for your
                        event, Karamale will deduct its fee percentage from the
                        budget.
                        <br />
                        <br />
                        If you don't have enough money to fund your budget, you
                        can fund it with the little that you have, then sell few
                        tickets and use the sales money to further fund the
                        budget to sell more tickets.
                        <br />
                        <br />
                        Please keep in mind that if the budget is depleted; your
                        event will be disabled, meaning people won't be able to
                        buy your tickets; therefore you have to increase the
                        budget so that your event can be reinstated.
                        <br />
                        <br />
                        <b>
                          So what if I didn't sell all my tickets and I still
                          have a balance, what then?
                        </b>
                        <br />
                        First of all, what you need to know is that: Karamale
                        does not give refunds to event organizations, therefore;
                        the remaining balance will be transfered to your next
                        event as the initial budget to your campaign.
                        <br />
                        <br />
                        <b>
                          In a nutshell, Karamale campaigns work exactly like
                          Facebook and Twitter campaigns in terms of budget
                          funding.
                        </b>
                        <br />
                        <br />
                        <Link to="/budget">Learn more about the fees</Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Fees;
