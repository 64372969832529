import React, { Component, useState, useEffect } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Footer from "./components/Footer";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Header2 from "./components/Header2";
import Header from "./components/Header";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TopBarProgress from "react-topbar-progress-indicator";

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      currentEvent: [],
      provinces: [],
      eventcategory: [],
      msg: "",
      boolean: true,
      spinning: "flex",
      email: "",
      phone: "",
      customername: "",
      category: this.props.location.state.category,
      province: this.props.location.state.province,
      loadBarProgress: true,
      loadProvinces: true,
      loadCategories: true,
      loadMoreLink: false,
    };
  }

  componentDidMount() {
    const category = this.state.category;
    const province = this.state.province;

    document.title = category + " - Karamale";
    if (localStorage.getItem("customerid") !== null) {
      const customerid = localStorage.getItem("customerid");
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
          customerid
      ).then((response) => {
        this.setState({
          email: response.data.email,
          phone: response.data.phone,
          customername: response.data.customername,
        });
      });
    } else {
      this.setState({
        email: "",
        phone: "",
        customername: "",
      });
    }
    const apiUrlCur =
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTS&category=" +
      category;

    fetch(apiUrlCur)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.val == 0) {
            this.setState({
              currentEvent: [],
              boolean: false,
              spinning: "none",
            });
          } else {
            this.setState({
              currentEvent: result,
              boolean: false,
              spinning: "none",
              loadBarProgress: false,
            });
          }
        },
        (err) => {
          this.setState({ err });
        }
      );

    axios
      .get("https://karamale.com/apps/kscanner/webservices.php?query=PROVINCES")
      .then((response) => {
        this.setState({
          provinces: response.data,
          spinning: "none",
        });
      });

    axios
      .get(
        "https://karamale.com/apps/kscanner/webservices.php?query=EVENTCATEGORY&limit=9"
      )
      .then((response) => {
        this.setState({
          eventcategory: response.data,
          spinning: "none",
        });
      });
  }

  province = (pro) => {
    const province = pro;
    // document.title = category + " - Karamale";
    this.props.history.push({
      pathname: "/explore",
      state: { category: this.state.category, province: province },
    });
  };

  category = (cat) => {
    const category = cat;
    document.title = category + " - Karamale";

    this.setState({ category: category });

    const apiUrlCur =
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTS&category=" +
      category;

    fetch(apiUrlCur)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.val == 0) {
            this.setState({
              currentEvent: [],
              boolean: false,
              spinning: "none",
            });
          } else {
            this.setState({
              currentEvent: result,
              boolean: false,
              spinning: "none",
            });
          }
        },
        (err) => {
          this.setState({ err });
        }
      );
  };

  render() {
    const { error, products } = this.state;
    const { err, currentEvent } = this.state;
    const category = this.state.category;
    const province = this.state.province;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    let event = "";

    const accountid = 0;
    const customerid = 0;
    const accounttype = "";
    let customername = "";
    let display = "";
    const { loadBarProgress, loadProvinces, loadCategories, loadMoreLink } =
      this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="view pt-2">
          {localStorage.getItem("customername") === null ? (
            <Header />
          ) : (
            <Header2 />
          )}

          <div className="container mt-0 p-0">
            <div className="row mt-3 w-100">
              <div className="col-lg-3"></div>
              <div className="col-lg-9 category-name-heading">
                {this.state.category}
              </div>
              <div className="col-lg-3 left-navigation  house-container pl-0 pr-0">
                <div className="col-lg-12 pt-4 pr-0 pl-0">
                  <div className="row">
                    <div className="col-12 name-events pl-3 pt-0">
                      Filter By
                    </div>
                    <div className="col-lg-12 col-6 province-pads">
                      <div className="heading-container mb-4 pb-2">
                        <div className="heading">Provinces</div>
                        <div
                          className="col-12 text-center justify-content-center mt-4 mb-4"
                          style={{
                            display: this.state.spinning,
                          }}
                        >
                          <ClipLoader
                            color={"red"}
                            loading={true}
                            // css={override}
                            size={20}
                          />
                        </div>
                        <div className="col-12 p-2">
                          <div>
                            {this.state.provinces.length < 1 ? (
                              <div></div>
                            ) : (
                              this.state.provinces.map((items, index) => {
                                return (
                                  <div
                                    className={"provinces"}
                                    key={index}
                                    onClick={() =>
                                      this.province(items.province)
                                    }
                                  >
                                    {items.province}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-6 category-pads">
                      <div className="heading-container pb-2">
                        <div className="heading">Categories</div>
                        <div
                          className="col-12 text-center justify-content-center mt-4  mb-4"
                          style={{
                            display: this.state.spinning,
                          }}
                        >
                          <ClipLoader
                            color={"red"}
                            loading={true}
                            // css={override}
                            size={20}
                          />
                        </div>
                        <div className="col-12 p-2">
                          {this.state.eventcategory.map((items, index) => {
                            return (
                              <div
                                className={"provinces"}
                                key={index}
                                onClick={() => this.category(items.category)}
                              >
                                {items.category}
                              </div>
                            );
                          })}
                          <Link
                            to={{
                              pathname: "/categories",
                            }}
                            className="eventLinks"
                          >
                            <div className={"provinces more-cat"}>More...</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 middle-content house-event pl-0 pr-0">
                <div className="row mt-3">
                  {loadBarProgress && (
                    <div className="col-12 text-center justify-content-center">
                      {/* <ClipLoader
                      color={"red"}
                      loading={true}
                      // css={override}
                      size={30}
                    /> */}
                      <SkeletonTheme color="#BEC3C9" highlightColor="444">
                        <p>
                          <Skeleton
                            count={200}
                            width={100}
                            height={10}
                            delay={1}
                          />
                        </p>
                      </SkeletonTheme>
                    </div>
                  )}

                  {loadBarProgress && <TopBarProgress />}

                  {/* {this.state.msg} */}
                  <div className="col-12 name-events">Events</div>

                  {currentEvent.length < 1 ? (
                    <div className="col-12 pl-2 pt-2">
                      No event is active for this category. Try again later
                    </div>
                  ) : (
                    currentEvent.map((item, index) => {
                      const posterUri =
                        "https://karamale.com/apps/kscanner/posters/" +
                        item.businessid +
                        "/" +
                        item.eventid +
                        "/" +
                        item.poster;

                      // if (item.event_day >= fulldate && item.balance < 6) {
                      //   display = "none";
                      // } else {
                      //   display = "block";
                      // }

                      if (item.balance < 6 && item.event_day > fulldate) {
                        display = "none";
                      } else {
                        display = "block";
                      }

                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-6 mb-1 h-100 p-2"
                          style={{ display: display }}
                        >
                          <Link
                            // data-tip={item.event_name}
                            to={{
                              pathname: "/event",
                              search:
                                "q=" +
                                uuid6 +
                                "&queryid=" +
                                uuid5 +
                                "&pageid=" +
                                uuid4 +
                                "&uid=" +
                                uuid3 +
                                "&qKey=" +
                                uuid1 +
                                "&key=" +
                                uuid2 +
                                "&uuid=" +
                                uuid +
                                "&eventid=" +
                                item.eventid +
                                "&eventDate=" +
                                item.event_day +
                                "&fulldate=" +
                                fulldate +
                                "&customerid=" +
                                customerid +
                                "&posterUri=" +
                                posterUri +
                                "&accountid=" +
                                accountid +
                                "&accounttype=" +
                                accounttype +
                                "&customername=" +
                                this.state.customername +
                                "&email=" +
                                this.state.email +
                                "&phone=" +
                                this.state.phone +
                                "&venue=" +
                                item.event_location +
                                "&description=" +
                                item.event_des +
                                "&businessid=" +
                                item.businessid +
                                "&poster=" +
                                item.poster +
                                "&eventname=" +
                                item.eventname +
                                "&eventday=" +
                                item.eventday +
                                "&starttime=" +
                                item.start_time +
                                "&endtime=" +
                                item.finish_time +
                                "&previousPage=category&campaignStatus=running&category=" +
                                category +
                                "&province=" +
                                province,
                              state: {
                                fromHome: true,
                                eventid: item.eventid,
                                eventDate: item.event_day,
                                fulldate: fulldate,
                                customerid: customerid,
                                posterUri: posterUri,
                                accountid: accountid,
                                accounttype: accounttype,
                                // customername: customername,
                                customername: this.state.customername,
                                email: this.state.email,
                                phone: this.state.phone,
                                venue: item.event_location,
                                description: item.event_des,
                                businessid: item.businessid,
                                poster: item.poster,
                                eventname: item.eventname,
                                eventday: item.eventday,
                                starttime: item.start_time,
                                endtime: item.finish_time,
                                previousPage: "category",
                                campaignStatus: "running",
                                category: category,
                                province: province,
                              },
                            }}
                            className="eventLinks"
                          >
                            <div className="nainer">
                              <div
                                className="pt-4 text-center justify-content-center align-items-center"
                                style={{ display: "flex" }}
                              >
                                <div
                                  style={{
                                    backgroundImage:
                                      "url(https://karamale.com/apps/kscanner/posters/" +
                                      item.businessid +
                                      "/" +
                                      item.eventid +
                                      "/" +
                                      item.poster +
                                      ")",
                                    width: "83%",
                                    height: 100,
                                    backgroundPosition: "center",
                                    backgroundSize: 200,
                                    borderRadius: 5,
                                  }}
                                ></div>
                              </div>
                              <div className="eventName pl-3 pr-3">
                                <strong className="textWeight">
                                  {item.event_name.length > 16
                                    ? (event =
                                        item.event_name.substr(0, 16) + "...")
                                    : (event = item.event_name)}
                                </strong>
                                <br />
                                {item.eventDate}
                                <div className="time">
                                  {item.eventday}
                                  <br />
                                  <span className="at">
                                    At {item.start_time}
                                  </span>
                                  <br /> <br />
                                  <span>Category</span>
                                  <br />
                                  <span className="category-name">
                                    {item.eventcategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <ReactTooltip /> */}
                          </Link>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

export default Category;
