import React from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./components/Footer";
import {
  IoIosContact,
  IoIosCalendar,
  IoIosBarcode,
  IoIosCard,
  IoMdHeartEmpty,
  IoMdPerson,
  IoIosLock,
  IoIosPaper,
  IoIosInformationCircleOutline,
  IoIosLogOut,
  IoIosRemoveCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosArrowBack,
} from "react-icons/io";
import { RiEditLine } from "react-icons/ri";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";

class Customerprofile extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const customername = this.props.location.state.clientename;
    document.title = customername+" / Karamale";
  }

  render() {
    if (localStorage.getItem("businessname") === null) {
      return <Redirect to="/login" />;
    }

    const customername = this.props.location.state.clientename;
    const email = this.props.location.state.email;
    const phone = this.props.location.state.phone;
    return (
      <div className="view">
        <Header3 />

        <div className="container">
          <div className="row justify-content-center just-container-insid">
            <div className="col-lg-7 pl-0 pr-0">
              <div className=" BoxDashboardContainer mt-5 mb-4 mrgin-search  pl-0 pr-0">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-4 col-6 pl-0">
                      <Link to={"customers"} className="link-btn">
                        <label className="back-arrow mb-0">
                          <IoIosArrowBack size={40} />
                          Back
                        </label>
                      </Link>
                    </div>

                    <div className="col-lg-8 col-6 text-right">
                      <label className="back-arrow mb-0">
                        <div className="edit-btn">{customername}</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" BoxDashboardContainer pl-2 pr-2 mb-5">
                <div className="row align-self-center justify-content-center">
                  <div className="item-list p-0">
                    <div className="col-lg-12 pl-0">
                      <label className="ticket-title pl-0 pr-0">
                        CUSTOMER PROFILE
                      </label>
                    </div>
                  </div>

                  <div className="h-divider w-100"></div>
                </div>
                <div className="col-lg-12 details-body">
                  <span>Details</span>
                  <br />
                  <br />
                  Fullname
                  <br />
                  <label className="small-details">{customername}</label>
                  <br /> <br />
                  Email
                  <br />
                  <label className="small-details">{email}</label>
                  <br /> <br />
                  Phone number
                  <br />
                  <label className="small-details">{phone}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Customerprofile;
