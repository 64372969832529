import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import md5 from "md5";
import { BsPlus, BsDash, BsCircle, BsCheckCircle } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { IoIosArrowBack } from "react-icons/io";
import { ImMan } from "react-icons/im";
import { FaChild, FaMale } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import ClipLoader from "react-spinners/ClipLoader";
import Header4 from "./components/Header4";
const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

class Customerpay extends Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    document.title = query.get("eventname");
    localStorage.setItem("group", "adults");
    let { eventid, eventname, ticketid } = 0;
    if (
      query.has("eventid") === true &&
      query.has("eventname") === true &&
      query.has("boostername") === true &&
      query.has("posterUri") === true &&
      query.has("venue") === true &&
      query.has("description") === true &&
      query.has("businessid") === true &&
      query.has("eventday") === true &&
      query.has("starttime") === true &&
      query.has("endtime") === true &&
      query.has("boosterid") === true &&
      query.has("activation") === true &&
      query.has("boosteremail") === true &&
      query.has("boosterphone") === true &&
      query.has("businessname") === true &&
      query.has("balance") === true &&
      query.has("boostername") === true &&
      query.has("credit") === true
    ) {
    } else {
      this.props.history.push("");
    }

    const avail_tickets = query.get("avail_tickets");
    const kids_tickets = query.get("kids_tickets");

    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({ admissionValue: 0, total: 0 });
    }

    if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({ admissionValue: 0, total: 0 });
    }
  }

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      error: "",
      successError: "",
      errorDisplay: "none",
      products: [],
      isChecked: false,
      isBtnDisabled: true,
      add: null,
      minus: null,
      admissionValue: 1,
      total: query.get("price"),
      price: query.get("price"),
      kidsPrice: query.get("kids_price"),

      btnClasses: ["btnDisabled w-100"],
      fullname: null,
      response: "",
      maxMessage: "",
      ticketnumber: Math.random().toString(36).substr(2, 7).toUpperCase(),
      phone: "",
      email: "",
      key: Math.random().toString(36).substr(1, 100).toUpperCase(),
      spinning: "none",
      updateDisplay: "block",
      checkedOne: true,
      uncheckedOne: false,
      checkedTwo: false,
      uncheckedTwo: true,
      activeOne: "item-select-active",
      activeTwo: "item-select",
      showChildIcon: false,
      showAdultIcone: true,
    };
  }

  handleTotal(event) {
    this.setState({ total: event.target.value });
  }

  handleAdmission(event) {
    this.setState({ admissionValue: event.target.value });
  }

  addHandle = () => {
    // const pricetag = this.props.location.state.price;
    const admissionValue = this.state.admissionValue;
    const query = new URLSearchParams(this.props.location.search);

    const eventid = query.get("eventid");
    const businessid = query.get("businessid");
    const ticketid = query.get("ticketid");
    let pricetag = query.get("price");
    const avail_tickets = query.get("avail_tickets");
    const kids_tickets = query.get("kids_tickets");
    let ticketsAvailable = avail_tickets;

    if (localStorage.getItem("group") == "kids") {
      pricetag = query.get("kids_price");
      ticketsAvailable = kids_tickets;
    } else {
      pricetag = query.get("price");
      ticketsAvailable = avail_tickets;
    }

    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({
        maxMessage:
          "Tickets for " + localStorage.getItem("group") + " are sold out",
      });
    } else if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({
        maxMessage:
          "Tickets for " + localStorage.getItem("group") + " are sold out",
      });
    } else {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CHECKAVAILABLETICKETS&eventid=" +
          eventid +
          "&ticketid=" +
          ticketid
      ).then((response) => {
        let category = "";
        if (response.data.category == "general") {
          category = "General";
        } else {
          category = response.data.category;
        }
        if (admissionValue == parseInt(ticketsAvailable)) {
          let plural,
            pastTense = "";

          if (ticketsAvailable == 1) {
            plural = "ticket";
            pastTense = "was";
          } else {
            plural = "tickets";
            pastTense = "are";
          }

          this.setState({
            maxMessage:
              "There " +
              " " +
              pastTense +
              " " +
              ticketsAvailable +
              " " +
              category +
              " " +
              plural +
              " left",
          });
        } else {
          if (admissionValue == 15) {
            this.setState({
              maxMessage:
                "You've reached the maximum of 15 " + ticketsAvailable,
            });
          } else {
            if (admissionValue > 0) {
              this.setState({ maxMessage: "" });
            }

            this.setState((prevState) => ({
              admissionValue: prevState.admissionValue + 1,
              total: parseInt(prevState.total) + parseInt(pricetag),
            }));
          }
        }
      });
    }
  };

  minusHandle = () => {
    const query = new URLSearchParams(this.props.location.search);
    let pricetag = query.get("price");

    if (localStorage.getItem("group") == "kids") {
      pricetag = query.get("kids_price");
    } else {
      pricetag = query.get("price");
    }

    const admissionValue = this.state.admissionValue;
    if (admissionValue == 1 || admissionValue <= 0) {
      this.setState({ maxMessage: "You've reached the minimum" });
    } else {
      if (admissionValue < 16) {
        this.setState({ maxMessage: "" });
      }
      this.setState((prevState) => ({
        admissionValue: prevState.admissionValue - 1,
        total: prevState.total - pricetag,
      }));
    }
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
      errorDisplay: "none",
    });
  };

  handlePhone = (event) => {
    this.setState({
      phone: event.target.value,
      errorDisplay: "none",
    });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      errorDisplay: "none",
    });
  };

  submit = () => {
    const query = new URLSearchParams(this.props.location.search);

    const email = this.state.email;
    const phone = this.state.phone;
    const fullname = this.state.name;

    this.setState({ updateDisplay: "none", spinning: "block" });

    if (email == "" || phone == "" || fullname == "") {
      this.setState({
        error: "All fields must are required",
        errorDisplay: "block",
        successError: "error-login",
        updateDisplay: "block",
        spinning: "none",
      });
    } else {
      const email = this.state.email;
      const phone = this.state.phone;
      const fullname = this.state.name;
      const admission = this.state.admissionValue;
      const boosterid = localStorage.getItem("boosterid");
      let eventname = query.get("evetname");
      const category = query.get("category");
      const price = query.get("price");
      const kids_price = query.get("kids_price");
      const businessid = query.get("businessid");
      const eventid = query.get("eventid");
      const ticketid = query.get("ticketid");

      let prices = 0;

      if (localStorage.getItem("group") == "kids") {
        prices = kids_price;
      } else {
        prices = price;
      }

      const total = prices * admission;

      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=PROCESSTICKET&ticketid=" +
          ticketid +
          "&eventid=" +
          eventid +
          "&businessid=" +
          businessid +
          "&boosterid=" +
          localStorage.getItem("boosterid") +
          "&category=" +
          category +
          "&price=" +
          price +
          "&kids_price=" +
          kids_price +
          "&total=" +
          total +
          "&phone=" +
          phone +
          "&email=" +
          email +
          "&fullname=" +
          fullname +
          "&admission=" +
          admission +
          "&group=" +
          localStorage.getItem("group")
      )
        .then((response) => {
          if (response.data.val == 1) {
            this.setState({
              error: "Successful!",
              errorDisplay: "block",
              successError: "success-login",
              updateDisplay: "block",
              spinning: "none",
            });
          } else {
            this.setState({
              error: "Email or phone provided already exist",
              errorDisplay: "block",
              successError: "error-login",
              updateDisplay: "block",
              spinning: "none",
            });
          }
        })
        .catch((error) => console.log(error));
    }
    setTimeout(() => {
      this.setState({ errorDisplay: "none" });
    }, 5000);
  };

  closeError = () => {
    this.setState({
      errorDisplay: "none",
    });
  };

  adults = () => {
    const query = new URLSearchParams(this.props.location.search);
    localStorage.setItem("group", "adults");
    const avail_tickets = query.get("avail_tickets");
    if (avail_tickets == 0 && localStorage.getItem("group") == "adults") {
      this.setState({ admissionValue: 0, total: 0 });
    } else {
      this.setState({ admissionValue: 1, total: this.state.price });
    }

    this.setState({
      activeOne: "item-select-active",
      activeTwo: "item-select",
      checkedOne: true,
      uncheckedOne: false,
      checkedTwo: false,
      uncheckedTwo: true,
      maxMessage: "",
      showAdultIcone: true,
      showChildIcon: false,
    });
  };

  kids = () => {
    const query = new URLSearchParams(this.props.location.search);
    localStorage.setItem("group", "kids");

    const kids_tickets = query.get("kids_tickets");
    if (kids_tickets == 0 && localStorage.getItem("group") == "kids") {
      this.setState({ admissionValue: 0, total: 0 });
    } else {
      this.setState({ admissionValue: 1, total: this.state.kidsPrice });
    }
    this.setState({
      activeOne: "item-select",
      activeTwo: "item-select-active",
      checkedOne: false,
      uncheckedOne: true,
      checkedTwo: true,
      uncheckedTwo: false,
      maxMessage: "",
      showAdultIcone: false,
      showChildIcon: true,
    });
  };

  render() {
    if (localStorage.getItem("boosterid") === null) {
      return <Redirect to="/" />;
      //   return exit(1);
    } else {
    }
    const {
      products,
      checkedOne,
      uncheckedOne,
      checkedTwo,
      uncheckedTwo,
      activeOne,
      activeTwo,
      showChildIcon,
      showAdultIcone,
    } = this.state;
    const query = new URLSearchParams(this.props.location.search);

    const accountid = query.get("accountid");
    const eventid = query.get("eventid");
    const eventDate = query.get("eventDate");
    // const fulldate = query.get("fulldate");
    const customerid = query.get("customerid");
    const posterUri = query.get("posterUri");
    const accounttype = query.get("accounttype");
    const customername = query.get("customername");
    const venue = query.get("venue");
    const description = query.get("description");
    const businessid = query.get("businessid");
    const poster = query.get("poster");
    const eventname = query.get("eventname");
    const eventday = query.get("eventday");
    const starttime = query.get("starttime");
    const endtime = query.get("endtime");
    const price = query.get("price");
    const kids_price = query.get("kids_price");
    const eventtype = query.get("eventtype");
    const province = query.get("province");
    const category = query.get("category");
    const previousPage = query.get("previousPage");
    const prevPage = query.get("prevPage");

    const sold = query.get("sold");
    const avail_tickets = query.get("avail_tickets");
    const kids_sold = query.get("kids_sold");
    const kids_tickets = query.get("kids_tickets");

    const campaignStatus = query.get("campaignStatus");
    const email = query.get("email");
    const phone = query.get("phone");

    const boosterid = localStorage.getItem("boosterid");
    const boosteremail = query.get("boosteremail");
    const boosterphone = query.get("boosterphone");
    const activation = query.get("activation");
    const businessname = query.get("businessname");
    const boostername = query.get("boostername");
    const credit = query.get("credit");
    const balance = query.get("balance");

    let soldOutAdults,
      soldOutKids = "";
    if (parseInt(avail_tickets) === 0) {
      soldOutAdults = (
        <label className="mb-0 color-red font-size-12 font-normal w-100 text-center">
          Sold out
        </label>
      );
    } else {
      soldOutAdults = (
        <label className="mb-0 color-green font-size-12 font-normal w-100 text-center">
          You can still sell
        </label>
      );
    }

    if (parseInt(kids_tickets) === 0) {
      soldOutKids = (
        <label className="mb-0 color-red font-size-12 font-normal w-100 text-center">
          Sold out
        </label>
      );
    } else {
      soldOutKids = (
        <label className="mb-0 color-green font-size-12 font-normal w-100 text-center">
          You can still sell
        </label>
      );
    }

    return (
      <div className="view pt-4">
        <Header4 />
        <div className="container">
          <div className="row mt-4 mb-0 w-100">
            <div className="row justify-content-center align-self-center desktop-width">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12 mb-5 pl-0 pr-0">
                <div className="content-detail">
                  <div className="">
                    <img
                      src={posterUri}
                      alt={poster}
                      width="100%"
                      className="img-poster"
                    />
                  </div>
                  <div className="col-lg-12 p-0 mt-4 pb-4">
                    <div className="pb-5">
                      <span className="bolddiv">Description</span>

                      <br />
                      {description}
                      <br />
                      <br />
                      <span className="bolddiv">Venue</span>
                      <br />
                      {venue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="tickets pl-0 pr-0">
                <div className="ticket-title">
                  {category}{" "}
                  <label className="mb-0 float-right">
                    {price <= 0 ? (
                      ""
                    ) : (
                      <label className="mb-0">
                        {" "}
                        {showAdultIcone && (
                          <FaMale size={15} className="color-green" />
                        )}
                      </label>
                    )}
                    {kids_price <= 0 ? (
                      ""
                    ) : (
                      <label className="mb-0">
                        {" "}
                        {showChildIcon && (
                          <FaChild size={15} className="color-dark-orange" />
                        )}
                      </label>
                    )}
                  </label>
                  <br />
                  <div className="row w-100 mt-2 align-items-center">
                    {price <= 0 ? (
                      ""
                    ) : (
                      <div className="col-6 pl-0 pr-1">
                        <div
                          className={activeOne + " p-2 w-100 border-1"}
                          onClick={this.adults}
                        >
                          <label className="small-details mb-0 font-normal">
                            {checkedOne && <BsCheckCircle size={20} />}
                            {uncheckedOne && <BsCircle size={20} />}
                            &nbsp;&nbsp;Adults:
                            <label className="m-0">R{price}</label>
                          </label>
                        </div>
                        {soldOutAdults}
                      </div>
                    )}

                    {kids_price <= 0 ? (
                      ""
                    ) : (
                      <div className="col-6 pl-1 pr-0">
                        <div
                          className={activeTwo + " p-2 w-100 border-1"}
                          onClick={this.kids}
                        >
                          <label className="small-details mb-0 font-normal">
                            {checkedTwo && <BsCheckCircle size={20} />}
                            {uncheckedTwo && <BsCircle size={20} />}
                            &nbsp;&nbsp;Kids: R{kids_price}
                          </label>
                        </div>
                        {soldOutKids}
                      </div>
                    )}
                  </div>
                  <div className="h-divider w-100" />
                </div>
                <div className="pl-0 pr-0 pt-0">
                  <div className="upperCasedText">
                    <span className="capitalizedText textLight">
                      <div className="row">
                        <div className="col-lg-12 form-components ">
                          <span className="buy-text">Name</span>
                          <br />
                          <input
                            type="text"
                            placeholder={"Name and surname"}
                            value={this.state.name}
                            onChange={this.handleName}
                            className="edit-input"
                          />
                          <br />
                          <span className="buy-text">Phone</span>
                          <br />
                          <input
                            type="number"
                            placeholder={"Phone number"}
                            value={this.state.phone}
                            onChange={this.handlePhone}
                            className="edit-input"
                          />
                          <br />
                          <span className="buy-text">Email</span>
                          <br />
                          <input
                            type="email"
                            placeholder={"Email address"}
                            value={this.state.email}
                            onChange={this.handleEmail}
                            className="edit-input"
                          />
                          <br />
                          <label className="textLight w-100">
                            Add Admission
                            <span className="float-right textLight text-15">
                              Total: R
                              <input
                                type="text"
                                readOnly
                                value={this.state.total}
                                onChange={this.handleTotal}
                                className="totalAmount"
                              />
                            </span>
                          </label>
                          <label className="max-message">
                            {this.state.maxMessage}
                          </label>
                          <br />
                          <div className="row mb-3">
                            <div className="col-4 pr-1 pl-0">
                              <button
                                className="minus"
                                type="button"
                                onClick={this.minusHandle}
                              >
                                <BsDash />
                              </button>
                            </div>
                            <div className="col-4 pr-2 pl-2">
                              <input
                                type="text"
                                name="admission"
                                className="admissionInput"
                                placeholder="0"
                                readOnly
                                value={this.state.admissionValue}
                                onChange={this.handleAdmission}
                              />
                            </div>
                            <div className="col-4 pl-1 pr-0">
                              <button
                                className="add"
                                id="add"
                                type="button"
                                onClick={this.addHandle}
                              >
                                <BsPlus />
                              </button>
                              <input
                                type="hidden"
                                value={
                                  localStorage.getItem("group") == "kids"
                                    ? this.state.kidsPrice
                                    : this.state.price
                                }
                              />
                              <input
                                type="hidden"
                                value={query.get("businessid")}
                              />
                              <input
                                type="hidden"
                                value={query.get("ticketid")}
                              />
                              <input
                                type="hidden"
                                value={query.get("eventid")}
                              />
                              <input
                                type="hidden"
                                value={this.state.ticketnumber}
                              />

                              <input type="hidden" name="cmd" value="_paynow" />

                              <input
                                type="hidden"
                                name="receiver"
                                value="15455113"
                              />

                              <input
                                type="hidden"
                                name="item_name"
                                value={eventname}
                              />

                              <input
                                type="hidden"
                                name="amount"
                                id="amount"
                                value={this.state.total}
                              />

                              <input
                                type="hidden"
                                name="item_description"
                                value=""
                              />

                              <input
                                type="hidden"
                                name="return_url"
                                id="return_url_"
                                value={
                                  "http://localhost:3000/success?buid=" +
                                  uuid6 +
                                  "&queryid=" +
                                  uuid5 +
                                  "&cancellationid=" +
                                  uuid4 +
                                  "&uid=" +
                                  uuid3 +
                                  "&qKey=" +
                                  uuid1 +
                                  "&key=" +
                                  uuid2 +
                                  "&uuid=" +
                                  uuid +
                                  "&category=" +
                                  category +
                                  "&phone=" +
                                  phone +
                                  "&total=" +
                                  this.state.total +
                                  "&admission=" +
                                  this.state.admissionValue +
                                  "&price=" +
                                  this.state.price +
                                  "&key=" +
                                  this.state.key +
                                  "&email=" +
                                  email +
                                  "&ticketnumber=" +
                                  this.state.ticketnumber +
                                  "&ticketid=" +
                                  query.get("ticketid") +
                                  "&eventid=" +
                                  query.get("eventid") +
                                  "&businessid=" +
                                  query.get("businessid") +
                                  "&method=card"
                                }
                              />

                              <input
                                type="hidden"
                                name="cancel_url"
                                id="cancel_url_"
                                value={
                                  "http://localhost:3000/cancelled?buid=" +
                                  uuid6 +
                                  "&queryid=" +
                                  uuid5 +
                                  "&cancellationid=" +
                                  uuid4 +
                                  "&uid=" +
                                  uuid3 +
                                  "&qKey=" +
                                  uuid1 +
                                  "&key=" +
                                  uuid2 +
                                  "&uuid=" +
                                  uuid +
                                  "&email=" +
                                  this.state.email +
                                  "&phaseValue=1" +
                                  "&businessId=" +
                                  query.get("businessid") +
                                  "&ticketId=" +
                                  query.get("ticketid") +
                                  "&ticketnumber=" +
                                  this.state.ticketnumber +
                                  "&eventId=" +
                                  query.get("eventid")
                                }
                              />

                              <input
                                type="hidden"
                                name="custom_amount"
                                id="custom_amounts"
                                value={0}
                                class="pricing"
                              />
                            </div>
                          </div>

                          <button
                            //   disabled={this.state.isBtnDisabled}
                            onClick={() => this.submit()}
                            className={"karamale-btn-square karamale-btn w-100"}
                          >
                            <span
                              style={{
                                display: this.state.updateDisplay,
                              }}
                            >
                              Proceed to sell
                            </span>
                            <span
                              style={{
                                display: this.state.spinning,
                              }}
                            >
                              <ClipLoader
                                color={"#fff"}
                                loading={true}
                                // css={override}
                                size={20}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="event-name justify-content-center"
          style={{ display: "flex" }}
        >
          <div
            className="backbtn event-title justify-content-center"
            style={{ display: "flex" }}
          >
            <div className="pt-1 pr-2">
              <Link
                to={{
                  pathname: "/eventdescription",
                  search:
                    "qQuery=" +
                    uuid6 +
                    "&BID=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&BigQuery=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&boosterid=" +
                    localStorage.getItem("boosterid") +
                    "&q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&eventid=" +
                    query.get("eventid") +
                    "&eventDate=" +
                    query.get("eventDate") +
                    "&fulldate=" +
                    query.get("fulldate") +
                    "&customerid=" +
                    query.get("customerid") +
                    "&posterUri=" +
                    query.get("posterUri") +
                    "&accountid=" +
                    query.get("accountid") +
                    "&accounttype=" +
                    query.get("accounttype") +
                    "&customername=" +
                    query.get("customername") +
                    "&email=" +
                    query.get("email") +
                    "&phone=" +
                    query.get("phone") +
                    "&venue=" +
                    query.get("venue") +
                    "&description=" +
                    query.get("description") +
                    "&businessid=" +
                    query.get("businessid") +
                    "&poster=" +
                    query.get("poster") +
                    "&eventname=" +
                    query.get("eventname") +
                    "&eventday=" +
                    query.get("eventday") +
                    "&starttime=" +
                    query.get("starttime") +
                    "&endtime=" +
                    query.get("endtime") +
                    "&previousPage=" +
                    previousPage +
                    "&campaignStatus=" +
                    campaignStatus +
                    "&category=" +
                    eventtype +
                    "&province=" +
                    province +
                    "&boosteremail=" +
                    boosteremail +
                    "&boosterphone=" +
                    boosterphone +
                    "&boostername=" +
                    boostername +
                    "&q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&businessname=" +
                    businessname +
                    "&activation=" +
                    activation +
                    "&balance=" +
                    balance +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&credit=" +
                    credit +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&boosteremail=" +
                    boosteremail +
                    "&boosterphone=" +
                    boosterphone +
                    "&boostername=" +
                    boostername +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&businessname=" +
                    businessname +
                    "&activation=" +
                    activation +
                    "&balance=" +
                    balance +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid +
                    "&credit=" +
                    credit +
                    "q=" +
                    uuid6 +
                    "&queryid=" +
                    uuid5 +
                    "&pageid=" +
                    uuid4 +
                    "&uid=" +
                    uuid3 +
                    "&qKey=" +
                    uuid1 +
                    "&key=" +
                    uuid2 +
                    "&uuid=" +
                    uuid,
                  state: {
                    fromHome: true,
                    eventid: query.get("eventid"),
                    eventDate: query.get("eventDate"),
                    customerid: query.get("customerid"),
                    posterUri: query.get("posterUri"),
                    accountid: query.get("accountid"),
                    accounttype: query.get("accounttype"),
                    customername: query.get("customername"),
                    email: query.get("email"),
                    phone: query.get("phone"),
                    venue: query.get("venue"),
                    description: query.get("description"),
                    businessid: query.get("businessid"),
                    poster: query.get("poster"),
                    eventname: query.get("eventname"),
                    eventday: query.get("eventday"),
                    starttime: query.get("starttime"),
                    endtime: query.get("endtime"),
                    previousPage: previousPage,
                    campaignStatus: campaignStatus,
                    category: eventtype,
                    province: province,
                  },
                }}
                className="backArrow"
                data-tip="Back"
                data-effect="solid"
                data-arrow-color="transparent"
                data-type="dark"
                data-place="top"
              >
                <ReactTooltip />
                <IoIosArrowBack size={40} className="Back-arrow-blue" />
              </Link>
            </div>
            <div className="" style={{ textAlign: "center" }}>
              {eventname}
              <br />
              <span style={{ fontSize: 13, color: "grey", fontWeight: 400 }}>
                {eventDate} @ {starttime}
              </span>
            </div>
          </div>
        </div>
        <div className="blanket"></div>
        <div
          onClick={() => this.closeError()}
          className={this.state.successError}
          style={{ display: this.state.errorDisplay, textAlign: "center" }}
        >
          {this.state.error}
        </div>
      </div>
    );
  }
}

export default Customerpay;
